<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Copy material</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-materialList" *ngIf="!fields.materialList.hidden" 
                            class="field-container full {{fields.materialList.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.materialList.styles.style"
                            [ngClass]="fields.materialList.styles.classes">
                        <div class="label-container"
                              title="{{fields.materialList.label}}{{fields.materialList.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.materialList.label}}">{{fields.materialList.label}}<span *ngIf="fields.materialList.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="materialList"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.materialList.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.materialList.control.placeholder}}"
                                [ngStyle]="fields.materialList.control.styles.style"
                                [ngClass]="fields.materialList.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.materialList.invalid">
                          <ng-container *ngFor="let error of fields.materialList.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden" 
                            class="field-container full {{fields.material.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                              title="{{fields.material.label}}{{fields.material.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material.label}}">{{fields.material.label}}<span *ngIf="fields.material.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-materials_by_project_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.material.control.type"
                            formControlName="material"
                            (displayTextChange)="fields.material.control.displayText=$event"
                            [placeholder]="fields.material.control.placeholder"
                            [styles]="fields.material.control.styles"
                          [projectId]="$fields_material_selector_inParams_projectId"
                        >
                        </Materials-materials_by_project_dd_multi>
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-targetOwner" *ngIf="!fields.targetOwner.hidden" 
                            class="field-container standard {{fields.targetOwner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.targetOwner.styles.style"
                            [ngClass]="fields.targetOwner.styles.classes">
                        <div class="label-container"
                              title="{{fields.targetOwner.label}}{{fields.targetOwner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.targetOwner.label}}">{{fields.targetOwner.label}}<span *ngIf="fields.targetOwner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.targetOwner.control.type"
                            formControlName="targetOwner"
                            (displayTextChange)="fields.targetOwner.control.displayText=$event"
                            [placeholder]="fields.targetOwner.control.placeholder"
                            [styles]="fields.targetOwner.control.styles"
                          [statusId]="$fields_targetOwner_selector_inParams_statusId"
                          [projectId]="$fields_targetOwner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.targetOwner.invalid">
                          <ng-container *ngFor="let error of fields.targetOwner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-targetProject" *ngIf="!fields.targetProject.hidden" 
                            class="field-container standard {{fields.targetProject.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.targetProject.styles.style"
                            [ngClass]="fields.targetProject.styles.classes">
                        <div class="label-container"
                              title="{{fields.targetProject.label}}{{fields.targetProject.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.targetProject.label}}">{{fields.targetProject.label}}<span *ngIf="fields.targetProject.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.targetProject.control.type"
                            formControlName="targetProject"
                            (displayTextChange)="fields.targetProject.control.displayText=$event"
                            [placeholder]="fields.targetProject.control.placeholder"
                            [styles]="fields.targetProject.control.styles"
                          [statusId]="$fields_targetProject_selector_inParams_statusId"
                          [ownerId]="$fields_targetProject_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.targetProject.invalid">
                          <ng-container *ngFor="let error of fields.targetProject.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>