import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { DockAppointments_reasoncodes_dd_singleComponent } from './DockAppointments.reasoncodes_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => DockAppointments_reasoncodes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-unreceiving_single_taskId_form',
  templateUrl: './FootPrintManager.unreceiving_single_taskId_form.component.html'
})
export class FootPrintManager_unreceiving_single_taskId_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, taskId: number } = { licenseplateId: null, lotId: null, packagingId: null, packagedAmount: null, taskId: null };
  //#region Inputs
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  @Input('packagedAmount') set $inParams_packagedAmount(v: number) {
    this.inParams.packagedAmount = v;
  }
  get $inParams_packagedAmount(): number {
    return this.inParams.packagedAmount;
  }
  @Input('taskId') set $inParams_taskId(v: number) {
    this.inParams.taskId = v;
  }
  get $inParams_taskId(): number {
    return this.inParams.taskId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    updated_packaged_amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    reasoncode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    title: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), '', false)
,
    packaged_amount: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'Packaged amount', false)
,
    updated_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['updated_packaged_amount'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Updated packaged amount', true)
,
    reasoncode: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reasoncode'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Reason code', true)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

  //#region fields inParams
  get $fields_reasoncode_selector_inParams_parentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_reasoncode_selector_inParams_parentEntity(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = 'OperationType';
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    updated_packaged_amount: this.fields.updated_packaged_amount.control.valueChanges
    ,
    reasoncode: this.fields.reasoncode.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
      if(isNil(this.inParams.lotId)) {
        this.$missingRequiredInParams.push('lotId');
      }
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
      if(isNil(this.inParams.packagedAmount)) {
        this.$missingRequiredInParams.push('packagedAmount');
      }
      if(isNil(this.inParams.taskId)) {
        this.$missingRequiredInParams.push('taskId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Unreceive Inventory';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.packaged_amount.control as TextModel).text = $form.inParams.packagedAmount.toString();

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .updated_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reasoncode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_unreceiving_single_taskId_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_unreceiving_single_taskId_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Handle double click scenario
  $form.toolbar.confirm.control.readOnly = true;
  try {
  
      // Check required fields
      const allRequiredFieldHaveValue = $utils.isAllDefined(
          $form.fields.updated_packaged_amount.control.value,
          $form.fields.reasoncode.control.value
      );
  
      if (!allRequiredFieldHaveValue) {
          $shell.FootPrintManager.openErrorDialog('Unreceiving Inventory Error', 'Please enter in data in all required fields.');
          return;
      }
  
      if ($form.fields.updated_packaged_amount.control.value > ($form.inParams.packagedAmount)) {
  
          throw new Error('Updated packaged amount cannot be more than the on hand amount.');
      }
  
      if ($form.fields.updated_packaged_amount.control.value === ($form.inParams.packagedAmount)) {
          $form.toolbar.confirm.control.readOnly = false;
          throw new Error('Updated packaged amount cannot be equal to the current on hand amount, if you are looking to unreceive the entire amount set the value to zero.');
      }
  
      if ($form.fields.updated_packaged_amount.control.value < 0) {
          $form.toolbar.confirm.control.readOnly = false;
          throw new Error('Updated packaged amount must be a positive number.');
      }
  
      const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $form.inParams.lotId })).result[0];
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: lot.MaterialId
      })).result[0];
  
      if ($utils.isDefined(material)) {
          let serialNumberIds: number[];
          if ($form.fields.updated_packaged_amount.control.value > 0 && (material.ControllerTypeId == 3 || material.ControllerTypeId == 4
              || material.IsFixedWeight == false || material.IsFixedVolume == false
              || material.IsFixedLength == false)) {
  
              const dialogResult = await $shell.FootPrintManager.openserialnumbers_selection_gridDialog({
                  lotId: $form.inParams.lotId,
                  licenseplateId: $form.inParams.licenseplateId,
                  packagingId: $form.inParams.packagingId,
                  packagedAmountRequest: $form.inParams.packagedAmount - $form.fields.updated_packaged_amount.control.value,
              });
  
              if (!dialogResult.confirm) {
                  throw new Error();
              }
  
              serialNumberIds = dialogResult.SerialNumberIds;
          }
  
          let result = await $flows.PurchaseOrders.unreceive_content_flow({
              taskId: $form.inParams.taskId,
              updatedPackagedAmount: $form.fields.updated_packaged_amount.control.value,
              reasonCodeId: $form.fields.reasoncode.control.value,
              serialNumberIds: serialNumberIds
          });
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
          }
  
          $form.close();
      }
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      if ($utils.isDefined(targetError.message)) {
          await $shell.FootPrintManager.openErrorDialog('Error unreceiving inventory', targetError.message);
      }
  }
  
  
  $form.toolbar.confirm.control.readOnly = false;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_unreceiving_single_taskId_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  var title = '';
  
  const licenseplates = (await $datasources.PurchaseOrders.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $form.inParams.licenseplateId })).result;
  if ($utils.isDefined(licenseplates)) {
      const licenseplate = licenseplates[0].LookupCode.toUpperCase();
      const locationId = licenseplates[0].LocationId;
  
      const locations = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      if ($utils.isDefined(locations)) {
          const locationName = locations[0].Name.toUpperCase();
  
          const lots = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $form.inParams.lotId })).result;
          if ($utils.isDefined(lots)) {
              const lotLookupcode = lots[0].LookupCode.toUpperCase();
              const materialId = lots[0].MaterialId;
  
              const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result;
              if ($utils.isDefined(materials)) {
                  const materialLookupcode = materials[0].LookupCode.toUpperCase();
  
                  const packagings = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({unitId: $form.inParams.packagingId})).result;
                  if ($utils.isDefined(packagings)){
                  const packagingShortName = packagings[0].ShortName.toUpperCase();
                  title = `Unreceiving inventory in Location ${locationName} for Licenseplate ${licenseplate} Material ${materialLookupcode} Lot ${lotLookupcode} Packaging ${packagingShortName}`;
  
                  }
              }
          }
      }
  }
  
  // Set title field
  $form.title = title;
  
  // Set focus on updated packaged amount field
  $form.fields.updated_packaged_amount.control.focus();
  }
  //#endregion private flows
}
