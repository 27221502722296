<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="create_warehouse" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_create_warehouse_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator1" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="import_locations" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_import_locations_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                    title="{{filters.warehouse.label}}{{filters.warehouse.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.warehouse.label}}">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Locations-warehouses_dd_single 
                                  data-cy="selector"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                              >
                              </Locations-warehouses_dd_single>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-time_zone" *ngIf="!filters.time_zone.hidden" 
                                  class="field-container standard {{filters.time_zone.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.time_zone.styles.style"
                                  [ngClass]="filters.time_zone.styles.classes">
                              <div class="label-container"
                                    title="{{filters.time_zone.label}}{{filters.time_zone.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.time_zone.label}}">{{filters.time_zone.label}}<span *ngIf="filters.time_zone.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Locations-timezones_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.time_zone.control.type"
                                  formControlName="time_zone"
                                  (displayTextChange)="filters.time_zone.control.displayText=$event"
                                  [placeholder]="filters.time_zone.control.placeholder"
                                  [styles]="filters.time_zone.control.styles"
                              >
                              </Locations-timezones_dd_multi>
                              <ng-container *ngIf="filters.time_zone.invalid">
                                <ng-container *ngFor="let error of filters.time_zone.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.locations_by_count_widget.hidden" class="widget-container">
            <Locations-locations_by_count_widget
            #$widgets_locations_by_count_widget 
              [warehouseId]="$widgets_locations_by_count_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_locations_by_count_widget')"
            >
            </Locations-locations_by_count_widget>
          </div>
          <div *ngIf="!widgets.locations_empty_by_count_widget.hidden" class="widget-container">
            <Locations-locations_empty_by_count_widget
            #$widgets_locations_empty_by_count_widget 
              [warehouseId]="$widgets_locations_empty_by_count_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_locations_empty_by_count_widget')"
            >
            </Locations-locations_empty_by_count_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.warehouses.hidden" class="tab" data-cy="tab-warehouses">
              <h2 [className]="tabs.warehouses.active? 'active': ''" (click)="tabs.warehouses.activate()">{{tabs.warehouses.title}}</h2>
            </div>
            <div *ngIf="!tabs.replenishments.hidden" class="tab" data-cy="tab-replenishments">
              <h2 [className]="tabs.replenishments.active? 'active': ''" (click)="tabs.replenishments.activate()">{{tabs.replenishments.title}}</h2>
            </div>
            <div *ngIf="!tabs.equipment.hidden" class="tab" data-cy="tab-equipment">
              <h2 [className]="tabs.equipment.active? 'active': ''" (click)="tabs.equipment.activate()">{{tabs.equipment.title}}</h2>
            </div>
            <div *ngIf="!tabs.pallets.hidden" class="tab" data-cy="tab-pallets">
              <h2 [className]="tabs.pallets.active? 'active': ''" (click)="tabs.pallets.activate()">{{tabs.pallets.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-warehouses_navigation_grid *ngIf="tabs.warehouses.active"
              #$tabs_warehouses
              [warehouseId]="$tabs_warehouses_warehouses_navigation_grid_inParams_warehouseId"
              [timezone]="$tabs_warehouses_warehouses_navigation_grid_inParams_timezone"
              ($refreshEvent)="refresh(false, false, '$tabs_warehouses')">
              </FootPrintManager-warehouses_navigation_grid>
              <FootPrintManager-locations_replenishments_grid *ngIf="tabs.replenishments.active"
              #$tabs_replenishments
              [warehouseIds]="$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds"
              ($refreshEvent)="refresh(false, false, '$tabs_replenishments')">
              </FootPrintManager-locations_replenishments_grid>
              <Equipment-equipment_grid *ngIf="tabs.equipment.active"
              #$tabs_equipment
              [warehouseId]="$tabs_equipment_equipment_grid_inParams_warehouseId"
              ($refreshEvent)="refresh(false, false, '$tabs_equipment')">
              </Equipment-equipment_grid>
              <ng-container
                *ngIf="tabs.pallets.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.pallets.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.pallets.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.pallets.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-pallet_inventory_by_class_grid
                   *ngIf="tabs.pallets.tabs.pallet_inventory_by_class_grid.active"
                  #$tabs_pallets_pallet_inventory_by_class_grid
                  ($refreshEvent)="refresh(false, false, '$tabs_pallets_pallet_inventory_by_class_grid')">
                  </FootPrintManager-pallet_inventory_by_class_grid>
                  <FootPrintManager-pallet_inventory_by_project_grid
                   *ngIf="tabs.pallets.tabs.pallet_inventory_by_project_grid.active"
                  #$tabs_pallets_pallet_inventory_by_project_grid
                  ($refreshEvent)="refresh(false, false, '$tabs_pallets_pallet_inventory_by_project_grid')">
                  </FootPrintManager-pallet_inventory_by_project_grid>
                  <FootPrintManager-pallet_transactions_grid
                   *ngIf="tabs.pallets.tabs.pallet_transactions_grid.active"
                  #$tabs_pallets_pallet_transactions_grid
                  ($refreshEvent)="refresh(false, false, '$tabs_pallets_pallet_transactions_grid')">
                  </FootPrintManager-pallet_transactions_grid>
              </ng-container>
        </div>

      </ng-container>
    </div>
  </div>
</div>