import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Replenishments_replenishment_comparison_dd_singleComponent } from './Replenishments.replenishment_comparison_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Replenishments_replenishment_comparison_dd_singleComponent),
  ],
  selector: 'FootPrintManager-location_replenishment_creation_form',
  templateUrl: './FootPrintManager.location_replenishment_creation_form.component.html'
})
export class FootPrintManager_location_replenishment_creation_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { projectId?: number, warehouseId?: number, locationId?: number, materialId?: number, ownerId?: number } = { projectId: null, warehouseId: null, locationId: null, materialId: null, ownerId: null };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { nextId?: number, warehouseId?: number, projectId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    minimum_packaged_quantity: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    minimum_packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    replenish_by_quantity_toggle: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    replenishment_packaged_amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    replenishment_packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    maximum_packaged_amount: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    maximum_packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    rule_trigger: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    system_triggered: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    on_demand: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      create_rule: new ToolModel(new ButtonModel('create_rule', new ButtonStyles(['primary'], null), false, 'Create rule', '')
    ),
      discard: new ToolModel(new ButtonModel('discard', new ButtonStyles(['secondary'], null), false, 'Discard', '')
    )
  };

  fields = {
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', true)
,
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Location', true)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Material', true)
,
    minimum_packaged_quantity: new FieldModel(new NumberBoxModel(this.formGroup.controls['minimum_packaged_quantity'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Minimum quantity', true)
,
    minimum_packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['minimum_packaging'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Minimum packaging', true)
,
    replenish_by_quantity_toggle: new FieldModel(new CheckBoxModel(this.formGroup.controls['replenish_by_quantity_toggle'] as DatexFormControl, null, false, 'Replenish by quantity')
, new ControlContainerStyles(null, null), '', false)
,
    replenishment_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['replenishment_packaged_amount'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Replenishment quantity', true)
,
    replenishment_packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['replenishment_packaging'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Replenishment packaging', true)
,
    maximum_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['maximum_packaged_amount'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Maximum quantity', false)
,
    maximum_packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['maximum_packaging'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Maximum packaging', false)
,
    rule_trigger: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['rule_trigger'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Trigger if', true)
,
    system_triggered: new FieldModel(new CheckBoxModel(this.formGroup.controls['system_triggered'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Triggered by the system', false)
,
    on_demand: new FieldModel(new CheckBoxModel(this.formGroup.controls['on_demand'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Triggered on demand', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Replenishment Information', false, true, true),
  newGroup2: new FieldsetModel('Rule trigger', false, true, true),
};

  //#region fields inParams
  get $fields_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.warehouseId;
    
    return expr;
  }

  get $fields_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  get $fields_material_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.projectId;
    
    return expr;
  }

  get $fields_minimum_packaging_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  get $fields_replenishment_packaging_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  get $fields_maximum_packaging_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.material.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    location: this.fields.location.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    minimum_packaged_quantity: this.fields.minimum_packaged_quantity.control.valueChanges
    ,
    minimum_packaging: this.fields.minimum_packaging.control.valueChanges
    ,
    replenish_by_quantity_toggle: this.fields.replenish_by_quantity_toggle.control.valueChanges
    ,
    replenishment_packaged_amount: this.fields.replenishment_packaged_amount.control.valueChanges
    ,
    replenishment_packaging: this.fields.replenishment_packaging.control.valueChanges
    ,
    maximum_packaged_amount: this.fields.maximum_packaged_amount.control.valueChanges
    ,
    maximum_packaging: this.fields.maximum_packaging.control.valueChanges
    ,
    rule_trigger: this.fields.rule_trigger.control.valueChanges
    ,
    system_triggered: this.fields.system_triggered.control.valueChanges
    ,
    on_demand: this.fields.on_demand.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create new rule';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.warehouse.control as SelectBoxModel).reset($form.inParams.warehouseId);
    (this.fields.location.control as SelectBoxModel).reset($form.inParams.locationId);
    (this.fields.material.control as SelectBoxModel).reset($form.inParams.materialId);
    (this.fields.replenish_by_quantity_toggle.control as CheckBoxModel).reset(false);
    (this.fields.system_triggered.control as CheckBoxModel).reset(true);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_warehouse_change();
      });
    this.$formGroupFieldValidationObservables
      .location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .material
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_change();
      });
    this.$formGroupFieldValidationObservables
      .minimum_packaged_quantity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .minimum_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .replenish_by_quantity_toggle
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_replenish_by_quantity_toggled();
      });
    this.$formGroupFieldValidationObservables
      .replenishment_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .replenishment_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .maximum_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .maximum_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .rule_trigger
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .system_triggered
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .on_demand
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_discard_clicked(event = null) {
    return this.on_discard_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discard_clickedInternal(
    $form: FootPrintManager_location_replenishment_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_location_replenishment_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($form.inParams.locationId)) {
  
      $form.fields.warehouse.hidden = true;
      $form.fields.location.hidden = false;
      $form.fields.location.control.readOnly = true;
      $form.fields.material.control.focus();
  
      if (!$utils.isDefined($form.inParams.warehouseId)) {
          $form.inParams.warehouseId = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: $form.inParams.locationId})).result[0].WarehouseId;
      }
  
      $form.vars.warehouseId = $form.inParams.warehouseId;
  
  } else {
  
      if ($utils.isDefined($form.inParams.warehouseId)) {
  
          $form.vars.warehouseId = $form.inParams.warehouseId;
          $form.fields.warehouse.hidden = true;
          $form.fields.location.control.focus();
      }
      else {
          $form.fields.warehouse.control.focus();
          $form.fields.location.hidden = true;
      }
  
  }
  if ($utils.isDefined($form.inParams.materialId)) {
  
      $form.fields.owner.hidden = true;
      $form.fields.project.hidden = true;
      $form.fields.material.hidden = false;
      $form.fields.material.control.readOnly = true;
  
      $form.fields.minimum_packaging.control.readOnly = false;
      $form.fields.maximum_packaging.control.readOnly = false;
      $form.fields.replenishment_packaging.control.readOnly = false;
  
  
  }
  else {
  
      $form.fields.minimum_packaging.control.readOnly = true;
      $form.fields.maximum_packaging.control.readOnly = true;
      $form.fields.replenishment_packaging.control.readOnly = true;
  
      if ($utils.isDefined($form.inParams.ownerId) && !$utils.isDefined($form.inParams.projectId)) {
          $form.fields.owner.control.value = $form.inParams.ownerId;
          $form.fields.owner.hidden = false;
          $form.fields.project.hidden = false;
          $form.fields.material.hidden = true;
      }
  
      else if ($utils.isDefined($form.inParams.projectId)) {
          $form.vars.projectId = $form.inParams.projectId;
          $form.fields.owner.hidden = true;
          $form.fields.project.hidden = true;
  
      }
      else {
          $form.fields.material.hidden = true;
  
      }
  
  }
  
  $form.on_replenish_by_quantity_toggled();
  }
  on_material_change(event = null) {
    return this.on_material_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changeInternal(
    $form: FootPrintManager_location_replenishment_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  var materialId = $form.fields.material.control.value
  
  if ($utils.isDefined(materialId)) {
  
      // Unlock packaging fields since material is known.
      $form.fields.minimum_packaging.control.readOnly = false;
      $form.fields.maximum_packaging.control.readOnly = false;
      $form.fields.replenishment_packaging.control.readOnly = false;
      
      var basepackaging = (await $datasources.Materials.ds_get_materials_basepackaging_by_materialId.get({ materialId: materialId })).result;
  
      if ($utils.isDefined(basepackaging)) {
  
          var basepackagingId = basepackaging.PackagingId
  
          if ($utils.isAllDefined(materialId, basepackagingId)) {
  
              // Default the decimal formatting
              const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
                  materialId: materialId,
                  packagingId: basepackagingId
              }));
  
              if ($utils.isDefined(decimalFormat)) {
                  $form.fields.minimum_packaged_quantity.control.format = decimalFormat.format;
                  $form.fields.replenishment_packaged_amount.control.format = decimalFormat.format;
                  $form.fields.maximum_packaged_amount.control.format = decimalFormat.format;
  
              }
  
          }
      }
  }
  else {
      // Clear out material related fields
      $form.fields.minimum_packaged_quantity.control.value = null;
      $form.fields.minimum_packaging.control.value = null;
      $form.fields.replenishment_packaged_amount.control.value = null;
      $form.fields.replenishment_packaging.control.value = null;
      $form.fields.maximum_packaged_amount.control.value = null;
      $form.fields.maximum_packaging.control.value = null;
  
      // Lock down packaging fields until material is selected
      $form.fields.minimum_packaging.control.readOnly = true;
      $form.fields.maximum_packaging.control.readOnly = true;
      $form.fields.replenishment_packaging.control.readOnly = true;
  
  
  }
  
  
  }
  on_create_rule(event = null) {
    return this.on_create_ruleInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_ruleInternal(
    $form: FootPrintManager_location_replenishment_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.location.control.value,
      $form.fields.material.control.value,
      $form.fields.minimum_packaged_quantity.control.value,
      $form.fields.minimum_packaging.control.value,
      $form.fields.rule_trigger.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      throw new Error('Missing data in mandatory fields.')
  }
  
  // Validations
  if ($utils.isDefined($form.fields.maximum_packaging.control.value) && !$utils.isDefined($form.fields.maximum_packaged_amount.control.value)) {
      throw new Error('Maximum amount value is missing.')
  }
  if (!$utils.isDefined($form.fields.maximum_packaging.control.value) && $utils.isDefined($form.fields.maximum_packaged_amount.control.value)) {
      throw new Error('Maximum packaging must be selected.')
  }
  
  if ($utils.isDefined($form.fields.minimum_packaged_quantity.control.value)) {
      if ($form.fields.minimum_packaged_quantity.control.value < 0) {
          throw new Error('Minimum amount cannot be negative')
      }
  }
  if ($utils.isDefined($form.fields.replenishment_packaged_amount.control.value)) {
      if ($form.fields.replenishment_packaged_amount.control.value < 0) {
          throw new Error('Replenishment amount cannot be negative')
      }
  }
  
  if ($utils.isDefined($form.fields.maximum_packaged_amount.control.value)) {
      if ($form.fields.maximum_packaged_amount.control.value <= 0) {
          throw new Error('Maximum amount must be greater than 0')
      }
  
      // TO DO convert the amounts to the base packaging when validating, for now we will comment this code
      // if ($form.fields.replenishment_packaged_amount > $form.fields.maximum_packaged_amount.control.value) {
      //     throw new Error('Replenishment amount cannot exceed the maximum amount.')
      // }
  
      // if ($form.fields.minimum_packaged_quantity > $form.fields.maximum_packaged_amount.control.value) {
      //     throw new Error('Minimum amount cannot exceed the maximum amount.')
      // }
  
  
  }
  
  if(!$form.fields.replenish_by_quantity_toggle.control.value){
      $form.fields.replenishment_packaged_amount.control.value = $form.fields.minimum_packaged_quantity.control.value
      $form.fields.replenishment_packaging.control.value = $form.fields.minimum_packaging.control.value
  }
  
  const locationReplenishmentRequest = (await $flows.Replenishments.create_location_replenishment_threshold_flow({
      locationId: $form.fields.location.control.value,
      materialId: $form.fields.material.control.value,
      minimumPackagedAmount: $form.fields.minimum_packaged_quantity.control.value,
      minimumPackagingId: $form.fields.minimum_packaging.control.value,
      replenishmentPackagedAmount: $form.fields.replenishment_packaged_amount.control.value,
      replenishmentPackagingId: $form.fields.replenishment_packaging.control.value,
      maximumPackagedAmount: $form.fields.maximum_packaged_amount.control.value,
      maximumPackingId: $form.fields.maximum_packaging.control.value,
      onDemand: $form.fields.on_demand.control.value,
      systemTriggered: $form.fields.system_triggered.control.value,
      comparisonTypeId: $form.fields.rule_trigger.control.value
  }));
  
  if ($utils.isDefined(locationReplenishmentRequest)) {
  
  
      var locationReplenishmentId = locationReplenishmentRequest.locationReplenishmentId;
      if ($utils.isDefined(locationReplenishmentId)) {
          $form.outParams.confirm = true;
  
          $form.close();
  
      }
      else {
  
          var errorMessage = locationReplenishmentRequest.reasons
          if ($utils.isDefined(errorMessage)) {
  
              throw errorMessage
          }
      }
  
  
  
  } else {
  
      throw new Error('Error when attempting to create new replenishment rule.')
  }
  }
  on_warehouse_change(event = null) {
    return this.on_warehouse_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_warehouse_changeInternal(
    $form: FootPrintManager_location_replenishment_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var warehouseId = $form.fields.warehouse.control.value;
  
  if (warehouseId == null) {
  
  } else if ($utils.isDefined(warehouseId)) {
  
      $form.vars.warehouseId = warehouseId;
      $form.fields.warehouse.hidden = true;
      $form.fields.location.hidden = false;
      $form.fields.location.control.focus();
  
  }
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: FootPrintManager_location_replenishment_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Clear out the project selection
  $form.fields.project.control.value = null;
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: FootPrintManager_location_replenishment_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const projectId = $form.fields.project.control.value
  
  
  // Set Owner from Project
  if ($utils.isDefined(projectId)) {
   
  
      $form.vars.projectId = projectId;
      $form.fields.owner.hidden = true;
      $form.fields.project.hidden = true;
      $form.fields.material.hidden = false;
      $form.fields.material.control.focus();
  
  
  }
  }
  on_replenish_by_quantity_toggled(event = null) {
    return this.on_replenish_by_quantity_toggledInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_replenish_by_quantity_toggledInternal(
    $form: FootPrintManager_location_replenishment_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if($form.fields.replenish_by_quantity_toggle.control.value){
      //Shown
      $form.fields.replenishment_packaged_amount.hidden = false
      $form.fields.replenishment_packaging.hidden = false
      $form.fields.maximum_packaged_amount.hidden = false
      $form.fields.maximum_packaging.hidden = false
      
      //Required
      $form.fields.replenishment_packaged_amount.required = true
      $form.fields.replenishment_packaging.required = true
  } else {
      //Shown
      $form.fields.replenishment_packaged_amount.hidden = true
      $form.fields.replenishment_packaging.hidden = true
      $form.fields.maximum_packaged_amount.hidden = true
      $form.fields.maximum_packaging.hidden = true
      
      //Required
      $form.fields.replenishment_packaged_amount.required = false
      $form.fields.replenishment_packaging.required = false
  }
  }
  //#endregion private flows
}
