import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Ultraship_ds_get_order_details_by_orderId_shipmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, shipmentId?: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, ProjectId?: number, TotalCost?: number, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, AvailableDate?: string, BrokerReference?: string, CarrierId?: number, CarrierServiceTypeId?: number, ExpectedDate?: string, ExpectedWarehouseId?: number, LoadContainerSequence?: number, LoadingStartDate?: string, LookupCode?: string, PickupDate?: string, ReferenceNumber?: string, ExpectedWarehouse?: { Name?: string }, LoadContainer?: { AvailableDate?: string, ContainerSize?: string, Notes?: string, StatusId?: number, TrailerNumber?: string }, Carrier?: { Name?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, PaymentTerm?: { Name?: string, ShortName?: string }, DockAppointmentsShipmentsLookup?: { DockAppointmentId?: number, ShipmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledArrival?: string, ScheduledDeparture?: string } }[] } }[], OrderLines?: { LineNumber?: number, Amount?: number, GrossWeight?: number, MaterialId?: number, NetWeight?: number, PackagedAmount?: number, PurchaseOrderIdentifier?: string, StatusId?: number, WeightUomId?: number, Material?: { Description?: string, LookupCode?: string, Name?: string, MaterialGroup?: { Name?: string } }, WeightUom?: { Name?: string, Short_name?: string }, InventoryMeasurementUnit?: { Name?: string, ShortName?: string }, MaterialPackagingLookup?: { Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, WeightUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string } } }[], Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Ultraship/datasources/ds_get_order_details_by_orderId_shipmentId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, shipmentId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, ProjectId?: number, TotalCost?: number, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, AvailableDate?: string, BrokerReference?: string, CarrierId?: number, CarrierServiceTypeId?: number, ExpectedDate?: string, ExpectedWarehouseId?: number, LoadContainerSequence?: number, LoadingStartDate?: string, LookupCode?: string, PickupDate?: string, ReferenceNumber?: string, ExpectedWarehouse?: { Name?: string }, LoadContainer?: { AvailableDate?: string, ContainerSize?: string, Notes?: string, StatusId?: number, TrailerNumber?: string }, Carrier?: { Name?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, PaymentTerm?: { Name?: string, ShortName?: string }, DockAppointmentsShipmentsLookup?: { DockAppointmentId?: number, ShipmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledArrival?: string, ScheduledDeparture?: string } }[] } }[], OrderLines?: { LineNumber?: number, Amount?: number, GrossWeight?: number, MaterialId?: number, NetWeight?: number, PackagedAmount?: number, PurchaseOrderIdentifier?: string, StatusId?: number, WeightUomId?: number, Material?: { Description?: string, LookupCode?: string, Name?: string, MaterialGroup?: { Name?: string } }, WeightUom?: { Name?: string, Short_name?: string }, InventoryMeasurementUnit?: { Name?: string, ShortName?: string }, MaterialPackagingLookup?: { Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, WeightUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string } } }[], Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Ultraship/datasources/ds_get_order_details_by_orderId_shipmentId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, shipmentId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AccountId?: number, BillingAddresId?: number, LookupCode?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, ProjectId?: number, TotalCost?: number, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, AvailableDate?: string, BrokerReference?: string, CarrierId?: number, CarrierServiceTypeId?: number, ExpectedDate?: string, ExpectedWarehouseId?: number, LoadContainerSequence?: number, LoadingStartDate?: string, LookupCode?: string, PickupDate?: string, ReferenceNumber?: string, ExpectedWarehouse?: { Name?: string }, LoadContainer?: { AvailableDate?: string, ContainerSize?: string, Notes?: string, StatusId?: number, TrailerNumber?: string }, Carrier?: { Name?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, PaymentTerm?: { Name?: string, ShortName?: string }, DockAppointmentsShipmentsLookup?: { DockAppointmentId?: number, ShipmentId?: number, DockAppointment?: { LookupCode?: string, ScheduledArrival?: string, ScheduledDeparture?: string } }[] } }[], OrderLines?: { LineNumber?: number, Amount?: number, GrossWeight?: number, MaterialId?: number, NetWeight?: number, PackagedAmount?: number, PurchaseOrderIdentifier?: string, StatusId?: number, WeightUomId?: number, Material?: { Description?: string, LookupCode?: string, Name?: string, MaterialGroup?: { Name?: string } }, WeightUom?: { Name?: string, Short_name?: string }, InventoryMeasurementUnit?: { Name?: string, ShortName?: string }, MaterialPackagingLookup?: { Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, WeightUom?: { Name?: string, Short_name?: string }, VolumeUom?: { Name?: string, Short_name?: string } } }[], Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Ultraship/datasources/ds_get_order_details_by_orderId_shipmentId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
