import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintApiManager.frontend.types' 



interface IFootPrintApiManager_statuses_gridComponentEntity {
id?: string, status?: string}



class FootPrintApiManager_statuses_gridComponentRowModel extends GridRowModel {
  grid: FootPrintApiManager_statuses_gridComponent;
  entity: IFootPrintApiManager_statuses_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintApiManager_ShellService,
private datasources: FootPrintApiManager_DatasourceService,
private flows: FootPrintApiManager_FlowService,
private reports: FootPrintApiManager_ReportService,
private localization: FootPrintApiManager_LocalizationService,
private operations: FootPrintApiManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintApiManager_statuses_gridComponent, entity: IFootPrintApiManager_statuses_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintApiManager_statuses_gridComponent, entity?: IFootPrintApiManager_statuses_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.FootPrintApiManager.ds_storage_statuses.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.status.displayControl as TextModel).text = $row.entity.status;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintApiManager-statuses_grid',
  templateUrl: './FootPrintApiManager.statuses_grid.component.html'
})
export class FootPrintApiManager_statuses_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintApiManager_statuses_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;



  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintApiManager_statuses_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;


  topToolbar = {
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      refresh_grid: new ToolModel(new ButtonModel('refresh_grid', new ButtonStyles(null, null), false, 'Refresh', 'ms-Icon ms-Icon--Refresh')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintApiManager_ShellService,
    private datasources: FootPrintApiManager_DatasourceService,
    private flows: FootPrintApiManager_FlowService,
    private reports: FootPrintApiManager_ReportService,
    private localization: FootPrintApiManager_LocalizationService,
    private operations: FootPrintApiManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Statuses grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.FootPrintApiManager.ds_storage_statuses.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintApiManager_statuses_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintApiManager_statuses_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/13/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { status: string, id: string, errorMessages: string[] }[] = [];
  let resubmitted_records = [];
  
  let logs = (await $flows.FootPrintApiManager.storage_logs({action: "", integration_name: ""})).payload;
  let log_payload: typeof logs[0];
  
  if (selected_rows.length === 0) {
      $shell.FootPrintApiManager.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.FootPrintApiManager.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  await $flows.FootPrintApiManager.list_statuses({action: "Delete", status: row.entity.status});
  
                  log_payload = {
                      "process": "statuses_grid",
                      "level": 1,
                      "notes": `Deleted record! Status: [${row.entity.status}] Status No: [${row.entity.id}]`
                  };
  
                  await $flows.FootPrintApiManager.storage_logs({ action: "Write", payload: log_payload, integration_name: "" });
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  resubmitted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      status: row.entity.status, id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.FootPrintApiManager.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  resubmitted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: resubmitted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Status: ${error.status}` })))
          };
      };
  };
  
  $grid.refresh();
  }
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: FootPrintApiManager_statuses_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/31/2024
  
  await $flows.FootPrintApiManager.list_statuses({action: "Refresh"});
  
  $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintApiManager_statuses_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/31/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_selected.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintApiManager_statuses_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/31/2024
  
  $grid.title = 'FootPrint API Manager Statuses Grid'
  
  $grid.headers.status.hidden = true;
  
  $grid.topToolbar.delete_selected.hidden = true;
  $grid.topToolbar.refresh_grid.hidden = true;
  
  $grid.topToolbar.delete_selected.control.readOnly = true;
  
  if (await $operations.FootPrintApiManager.Enable_ApiManager_StatusesGrid.isAuthorized()) {
      $grid.topToolbar.delete_selected.hidden = false;
      $grid.topToolbar.refresh_grid.hidden = false;
  };
  
  if (await $operations.FootPrintApiManager.Enable_ApiManager_StatusesGrid.isAuthorized()) {
      $grid.headers.status.hidden = false;
  };
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
