import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './ShipTheory.frontend.types'

@Injectable({ providedIn: 'root' })
export class ShipTheory_create_shipment_manifestService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { referenceNumber?: string, carrier: string, service: string, containerCount?: number, weight?: number, fromAddress: any, toAddress: any, value: number, products: any[] }): Promise<{ reason?: string, label?: string, tracking?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.carrier)) {
      missingRequiredInParams.push('\'carrier\'');
    }
    if (isNil(inParams.service)) {
      missingRequiredInParams.push('\'service\'');
    }
    if (isNil(inParams.fromAddress)) {
      missingRequiredInParams.push('\'fromAddress\'');
    }
    if (isNil(inParams.toAddress)) {
      missingRequiredInParams.push('\'toAddress\'');
    }
    if (isNil(inParams.value)) {
      missingRequiredInParams.push('\'value\'');
    }
    if (isNil(inParams.products)) {
      missingRequiredInParams.push('\'products\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShipTheory/functions/create_shipment_manifest`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

