<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="create_purchase_order" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_create_purchase_order_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="create_sales_order" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_create_sales_order_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator1" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="import_orders" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_import_orders_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                    title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                    title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-materials" *ngIf="!filters.materials.hidden" 
                                  class="field-container standard {{filters.materials.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.materials.styles.style"
                                  [ngClass]="filters.materials.styles.classes">
                              <div class="label-container"
                                    title="{{filters.materials.label}}{{filters.materials.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.materials.label}}">{{filters.materials.label}}<span *ngIf="filters.materials.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Materials-materials_by_project_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.materials.control.type"
                                  formControlName="materials"
                                  (displayTextChange)="filters.materials.control.displayText=$event"
                                  [placeholder]="filters.materials.control.placeholder"
                                  [styles]="filters.materials.control.styles"
                                [projectId]="$fields_materials_selector_inParams_projectId"
                              >
                              </Materials-materials_by_project_dd_multi>
                              <ng-container *ngIf="filters.materials.invalid">
                                <ng-container *ngFor="let error of filters.materials.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                    title="{{filters.warehouse.label}}{{filters.warehouse.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.warehouse.label}}">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
                                  class="field-container double {{filters.status.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                    title="{{filters.status.label}}{{filters.status.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.status.label}}">{{filters.status.label}}<span *ngIf="filters.status.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <PurchaseOrders-orderstatuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.status.control.type"
                                  formControlName="status"
                                  (displayTextChange)="filters.status.control.displayText=$event"
                                  [placeholder]="filters.status.control.placeholder"
                                  [styles]="filters.status.control.styles"
                                [statusIds]="$fields_status_selector_inParams_statusIds"
                              >
                              </PurchaseOrders-orderstatuses_dd_multi>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.order_total_received_order_count_by_date_widget.hidden" class="widget-container">
            <PurchaseOrders-order_total_received_order_count_by_date_widget
            #$widgets_order_total_received_order_count_by_date_widget 
              [date]="$widgets_order_total_received_order_count_by_date_widget_inParams_date"
              [ownerId]="$widgets_order_total_received_order_count_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_order_total_received_order_count_by_date_widget_inParams_projectId"
              [warehouseId]="$widgets_order_total_received_order_count_by_date_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_received_order_count_by_date_widget')"
            >
            </PurchaseOrders-order_total_received_order_count_by_date_widget>
          </div>
          <div *ngIf="!widgets.order_total_picked_order_count_by_date_widget.hidden" class="widget-container">
            <SalesOrders-order_total_picked_order_count_by_date_widget
            #$widgets_order_total_picked_order_count_by_date_widget 
              [date]="$widgets_order_total_picked_order_count_by_date_widget_inParams_date"
              [ownerId]="$widgets_order_total_picked_order_count_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_order_total_picked_order_count_by_date_widget_inParams_projectId"
              [warehouseId]="$widgets_order_total_picked_order_count_by_date_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_picked_order_count_by_date_widget')"
            >
            </SalesOrders-order_total_picked_order_count_by_date_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inbound_orders.hidden" class="tab" data-cy="tab-inbound_orders">
              <h2 [className]="tabs.inbound_orders.active? 'active': ''" (click)="tabs.inbound_orders.activate()">{{tabs.inbound_orders.title}}</h2>
            </div>
            <div *ngIf="!tabs.outbound_orders.hidden" class="tab" data-cy="tab-outbound_orders">
              <h2 [className]="tabs.outbound_orders.active? 'active': ''" (click)="tabs.outbound_orders.activate()">{{tabs.outbound_orders.title}}</h2>
            </div>
            <div *ngIf="!tabs.calendar.hidden" class="tab" data-cy="tab-calendar">
              <h2 [className]="tabs.calendar.active? 'active': ''" (click)="tabs.calendar.activate()">{{tabs.calendar.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inbound_orders_grid *ngIf="tabs.inbound_orders.active"
              #$tabs_inbound_orders
              [ownerId]="$tabs_inbound_orders_inbound_orders_grid_inParams_ownerId"
              [projectId]="$tabs_inbound_orders_inbound_orders_grid_inParams_projectId"
              [warehouseId]="$tabs_inbound_orders_inbound_orders_grid_inParams_warehouseId"
              [statusIds]="$tabs_inbound_orders_inbound_orders_grid_inParams_statusIds"
              [materialIds]="$tabs_inbound_orders_inbound_orders_grid_inParams_materialIds"
              ($refreshEvent)="refresh(false, false, '$tabs_inbound_orders')">
              </FootPrintManager-inbound_orders_grid>
              <FootPrintManager-outbound_orders_grid *ngIf="tabs.outbound_orders.active"
              #$tabs_outbound_orders
              [ownerId]="$tabs_outbound_orders_outbound_orders_grid_inParams_ownerId"
              [projectId]="$tabs_outbound_orders_outbound_orders_grid_inParams_projectId"
              [warehouseId]="$tabs_outbound_orders_outbound_orders_grid_inParams_warehouseId"
              [statusIds]="$tabs_outbound_orders_outbound_orders_grid_inParams_statusIds"
              [materialIds]="$tabs_outbound_orders_outbound_orders_grid_inParams_materialIds"
              ($refreshEvent)="refresh(false, false, '$tabs_outbound_orders')">
              </FootPrintManager-outbound_orders_grid>
              <FootPrintManager-dock_appointments_calendar *ngIf="tabs.calendar.active"
              #$tabs_calendar
              [warehouseIds]="$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds"
              [typeIds]="$tabs_calendar_dock_appointments_calendar_inParams_typeIds"
              [ownerId]="$tabs_calendar_dock_appointments_calendar_inParams_ownerId"
              [projectId]="$tabs_calendar_dock_appointments_calendar_inParams_projectId"
              [dockDoorIds]="$tabs_calendar_dock_appointments_calendar_inParams_dockDoorIds"
              [viewDate]="$tabs_calendar_dock_appointments_calendar_inParams_viewDate"
              (outParamsChange)="on_calendar_filters_updated($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_calendar')">
              </FootPrintManager-dock_appointments_calendar>
        </div>

      </ng-container>
    </div>
  </div>
</div>