import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Addresses_create_generic_order_address_flowService } from './Addresses.flow.index';
import { Addresses_delete_address_flowService } from './Addresses.flow.index';
import { Addresses_delete_orderaddress_flowService } from './Addresses.flow.index';
import { Addresses_delete_owneraccount_flowService } from './Addresses.flow.index';
import { Addresses_get_address_single_string_by_orderIdService } from './Addresses.flow.index';
import { Addresses_get_addresses_single_string_by_orderIdsService } from './Addresses.flow.index';
import { Addresses_is_address_deletable_flowService } from './Addresses.flow.index';
import { Addresses_is_orderaddress_deletable_flowService } from './Addresses.flow.index';
import { Addresses_validate_address_flowService } from './Addresses.flow.index';

import { $frontendTypes } from './Addresses.frontend.types'

@Injectable({ providedIn: 'root' })
export class Addresses_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Addresses: Addresses_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_generic_order_address_flow: Addresses_create_generic_order_address_flowService;
  public async create_generic_order_address_flow(inParams: { orderId: number }): Promise< { accountId?: number, contactId?: number, addressId?: number, reason?: string }> {
    if(!this._create_generic_order_address_flow) {
      this._create_generic_order_address_flow = this.injector.get(Addresses_create_generic_order_address_flowService);
    }
    return this._create_generic_order_address_flow.run(inParams);
  }
   
   

   
 
  private _delete_address_flow: Addresses_delete_address_flowService;
  public async delete_address_flow(inParams: { addressId: number }): Promise< { reason?: string }> {
    if(!this._delete_address_flow) {
      this._delete_address_flow = this.injector.get(Addresses_delete_address_flowService);
    }
    return this._delete_address_flow.run(inParams);
  }
   
   

   
 
  private _delete_orderaddress_flow: Addresses_delete_orderaddress_flowService;
  public async delete_orderaddress_flow(inParams: { addressId: number }): Promise< { reason?: string }> {
    if(!this._delete_orderaddress_flow) {
      this._delete_orderaddress_flow = this.injector.get(Addresses_delete_orderaddress_flowService);
    }
    return this._delete_orderaddress_flow.run(inParams);
  }
   
   

   
 
  private _delete_owneraccount_flow: Addresses_delete_owneraccount_flowService;
  public async delete_owneraccount_flow(inParams: { addressId: number, contactId: number, accountId: number, ownerId: number }): Promise< { reason?: string }> {
    if(!this._delete_owneraccount_flow) {
      this._delete_owneraccount_flow = this.injector.get(Addresses_delete_owneraccount_flowService);
    }
    return this._delete_owneraccount_flow.run(inParams);
  }
   
   

   
 
  private _get_address_single_string_by_orderId: Addresses_get_address_single_string_by_orderIdService;
  public async get_address_single_string_by_orderId(inParams: { orderId: number }): Promise< { address?: string, country?: string }> {
    if(!this._get_address_single_string_by_orderId) {
      this._get_address_single_string_by_orderId = this.injector.get(Addresses_get_address_single_string_by_orderIdService);
    }
    return this._get_address_single_string_by_orderId.run(inParams);
  }
   
   

   
 
  private _get_addresses_single_string_by_orderIds: Addresses_get_addresses_single_string_by_orderIdsService;
  public async get_addresses_single_string_by_orderIds(inParams: { orderIds: number[] }): Promise< { Addresses?: { OrderId?: number, Address?: string, Country?: string }[] }> {
    if(!this._get_addresses_single_string_by_orderIds) {
      this._get_addresses_single_string_by_orderIds = this.injector.get(Addresses_get_addresses_single_string_by_orderIdsService);
    }
    return this._get_addresses_single_string_by_orderIds.run(inParams);
  }
   
   

   
 
  private _is_address_deletable_flow: Addresses_is_address_deletable_flowService;
  public async is_address_deletable_flow(inParams: { addressId: number }): Promise< { reason?: string }> {
    if(!this._is_address_deletable_flow) {
      this._is_address_deletable_flow = this.injector.get(Addresses_is_address_deletable_flowService);
    }
    return this._is_address_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_orderaddress_deletable_flow: Addresses_is_orderaddress_deletable_flowService;
  public async is_orderaddress_deletable_flow(inParams: { addressId: number }): Promise< { reason?: string }> {
    if(!this._is_orderaddress_deletable_flow) {
      this._is_orderaddress_deletable_flow = this.injector.get(Addresses_is_orderaddress_deletable_flowService);
    }
    return this._is_orderaddress_deletable_flow.run(inParams);
  }
   
   

   
 
  private _validate_address_flow: Addresses_validate_address_flowService;
  public async validate_address_flow(inParams: { line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string }): Promise< { errors?: string[] }> {
    if(!this._validate_address_flow) {
      this._validate_address_flow = this.injector.get(Addresses_validate_address_flowService);
    }
    return this._validate_address_flow.run(inParams);
  }
   
   

   
}
