import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { EasyPost_DatasourceService } from './EasyPost.datasource.index';
import { ShipTheory_DatasourceService } from './ShipTheory.datasource.index';

import { Manifesting_ds_containers_by_type_ddService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_buyer_address_by_orderIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_contact_address_by_addressIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_container_type_by_containerTypeIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_order_account_address_by_orderIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_order_address_by_orderId_top1Service } from './Manifesting.datasource.index';
import { Manifesting_ds_get_order_ship_to_account_vs_order_addressService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_shipment_by_shipmentIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_shipment_details_for_manifesting_by_shipmentIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_shippingcontainers_by_shipmentIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_shippingcontainers_count_by_shipmentIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_third_party_address_by_orderIdService } from './Manifesting.datasource.index';
import { Manifesting_ds_get_warehouse_addressService } from './Manifesting.datasource.index';
import { Manifesting_ds_manifesting_shipments_gridService } from './Manifesting.datasource.index';

@Injectable({ providedIn: 'root' })
export class Manifesting_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.EasyPost = this.injector.get(EasyPost_DatasourceService);
    this.ShipTheory = this.injector.get(ShipTheory_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
    public EasyPost: EasyPost_DatasourceService;
    public ShipTheory: ShipTheory_DatasourceService;
  public Manifesting: Manifesting_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_containers_by_type_dd: Manifesting_ds_containers_by_type_ddService;
  public get ds_containers_by_type_dd(): Manifesting_ds_containers_by_type_ddService {
    if(!this._ds_containers_by_type_dd) {
      this._ds_containers_by_type_dd = this.injector.get(Manifesting_ds_containers_by_type_ddService);
    }
    return this._ds_containers_by_type_dd;
  }
  private _ds_get_buyer_address_by_orderId: Manifesting_ds_get_buyer_address_by_orderIdService;
  public get ds_get_buyer_address_by_orderId(): Manifesting_ds_get_buyer_address_by_orderIdService {
    if(!this._ds_get_buyer_address_by_orderId) {
      this._ds_get_buyer_address_by_orderId = this.injector.get(Manifesting_ds_get_buyer_address_by_orderIdService);
    }
    return this._ds_get_buyer_address_by_orderId;
  }
  private _ds_get_contact_address_by_addressId: Manifesting_ds_get_contact_address_by_addressIdService;
  public get ds_get_contact_address_by_addressId(): Manifesting_ds_get_contact_address_by_addressIdService {
    if(!this._ds_get_contact_address_by_addressId) {
      this._ds_get_contact_address_by_addressId = this.injector.get(Manifesting_ds_get_contact_address_by_addressIdService);
    }
    return this._ds_get_contact_address_by_addressId;
  }
  private _ds_get_container_type_by_containerTypeId: Manifesting_ds_get_container_type_by_containerTypeIdService;
  public get ds_get_container_type_by_containerTypeId(): Manifesting_ds_get_container_type_by_containerTypeIdService {
    if(!this._ds_get_container_type_by_containerTypeId) {
      this._ds_get_container_type_by_containerTypeId = this.injector.get(Manifesting_ds_get_container_type_by_containerTypeIdService);
    }
    return this._ds_get_container_type_by_containerTypeId;
  }
  private _ds_get_order_account_address_by_orderId: Manifesting_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): Manifesting_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(Manifesting_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_address_by_orderId_top1: Manifesting_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): Manifesting_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(Manifesting_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_ship_to_account_vs_order_address: Manifesting_ds_get_order_ship_to_account_vs_order_addressService;
  public get ds_get_order_ship_to_account_vs_order_address(): Manifesting_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._ds_get_order_ship_to_account_vs_order_address) {
      this._ds_get_order_ship_to_account_vs_order_address = this.injector.get(Manifesting_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_to_account_vs_order_address;
  }
  private _ds_get_ownerscarrierslookup_by_ownerId_carrierId: Manifesting_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService;
  public get ds_get_ownerscarrierslookup_by_ownerId_carrierId(): Manifesting_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService {
    if(!this._ds_get_ownerscarrierslookup_by_ownerId_carrierId) {
      this._ds_get_ownerscarrierslookup_by_ownerId_carrierId = this.injector.get(Manifesting_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService);
    }
    return this._ds_get_ownerscarrierslookup_by_ownerId_carrierId;
  }
  private _ds_get_shipment_by_shipmentId: Manifesting_ds_get_shipment_by_shipmentIdService;
  public get ds_get_shipment_by_shipmentId(): Manifesting_ds_get_shipment_by_shipmentIdService {
    if(!this._ds_get_shipment_by_shipmentId) {
      this._ds_get_shipment_by_shipmentId = this.injector.get(Manifesting_ds_get_shipment_by_shipmentIdService);
    }
    return this._ds_get_shipment_by_shipmentId;
  }
  private _ds_get_shipment_details_for_manifesting_by_shipmentId: Manifesting_ds_get_shipment_details_for_manifesting_by_shipmentIdService;
  public get ds_get_shipment_details_for_manifesting_by_shipmentId(): Manifesting_ds_get_shipment_details_for_manifesting_by_shipmentIdService {
    if(!this._ds_get_shipment_details_for_manifesting_by_shipmentId) {
      this._ds_get_shipment_details_for_manifesting_by_shipmentId = this.injector.get(Manifesting_ds_get_shipment_details_for_manifesting_by_shipmentIdService);
    }
    return this._ds_get_shipment_details_for_manifesting_by_shipmentId;
  }
  private _ds_get_shippingcontainers_by_shipmentId: Manifesting_ds_get_shippingcontainers_by_shipmentIdService;
  public get ds_get_shippingcontainers_by_shipmentId(): Manifesting_ds_get_shippingcontainers_by_shipmentIdService {
    if(!this._ds_get_shippingcontainers_by_shipmentId) {
      this._ds_get_shippingcontainers_by_shipmentId = this.injector.get(Manifesting_ds_get_shippingcontainers_by_shipmentIdService);
    }
    return this._ds_get_shippingcontainers_by_shipmentId;
  }
  private _ds_get_shippingcontainers_count_by_shipmentId: Manifesting_ds_get_shippingcontainers_count_by_shipmentIdService;
  public get ds_get_shippingcontainers_count_by_shipmentId(): Manifesting_ds_get_shippingcontainers_count_by_shipmentIdService {
    if(!this._ds_get_shippingcontainers_count_by_shipmentId) {
      this._ds_get_shippingcontainers_count_by_shipmentId = this.injector.get(Manifesting_ds_get_shippingcontainers_count_by_shipmentIdService);
    }
    return this._ds_get_shippingcontainers_count_by_shipmentId;
  }
  private _ds_get_third_party_address_by_orderId: Manifesting_ds_get_third_party_address_by_orderIdService;
  public get ds_get_third_party_address_by_orderId(): Manifesting_ds_get_third_party_address_by_orderIdService {
    if(!this._ds_get_third_party_address_by_orderId) {
      this._ds_get_third_party_address_by_orderId = this.injector.get(Manifesting_ds_get_third_party_address_by_orderIdService);
    }
    return this._ds_get_third_party_address_by_orderId;
  }
  private _ds_get_warehouse_address: Manifesting_ds_get_warehouse_addressService;
  public get ds_get_warehouse_address(): Manifesting_ds_get_warehouse_addressService {
    if(!this._ds_get_warehouse_address) {
      this._ds_get_warehouse_address = this.injector.get(Manifesting_ds_get_warehouse_addressService);
    }
    return this._ds_get_warehouse_address;
  }
  private _ds_manifesting_shipments_grid: Manifesting_ds_manifesting_shipments_gridService;
  public get ds_manifesting_shipments_grid(): Manifesting_ds_manifesting_shipments_gridService {
    if(!this._ds_manifesting_shipments_grid) {
      this._ds_manifesting_shipments_grid = this.injector.get(Manifesting_ds_manifesting_shipments_gridService);
    }
    return this._ds_manifesting_shipments_grid;
  }
}

