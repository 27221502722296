import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';
import { EasyPost_OperationService } from './EasyPost.operation.service';
import { ShipTheory_OperationService } from './ShipTheory.operation.service';


@Injectable({ providedIn: 'root' })
export class Manifesting_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
    this.EasyPost = this.injector.get(EasyPost_OperationService);
    this.ShipTheory = this.injector.get(ShipTheory_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public PrintNode: PrintNode_OperationService;
    public EasyPost: EasyPost_OperationService;
    public ShipTheory: ShipTheory_OperationService;
  public Manifesting: Manifesting_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/Manifesting/operations/${operationName}/isauthorized`, null);
  }
}
