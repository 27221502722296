import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 



interface IFootPrintManager_billing_records_by_invoiceline_gridComponentEntity {
BillingTaskId?: number, ActualAmount?: number, ActualUOM?: number, ApplicableMinimumCharge?: number, ApplicableRate?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, InvoiceLineId?: number, Notes?: string, Total?: number, BillingTask?: { Id?: number, Amount?: number, BillingCodeId?: number, Cancelled?: boolean, Notes?: string, ProjectId?: number, UomId?: number, CancelledReasonCode?: { Id?: number, LookupCode?: string }, BillingCode?: { Name?: string }, Uom?: { Name?: string, Short_name?: string }, LicensePlate?: { LookupCode?: string }, Location?: { Name?: string }, Lot?: { LookupCode?: string }, Material?: { LookupCode?: string }, Order?: { LookupCode?: string }, Warehouse?: { Name?: string }, WorkOrder?: { Id?: number }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, OperationCode?: { Name?: string }, Packaging?: { ShortName?: string } }, ActualMeasurementUnit?: { Short_name?: string }}

interface IFootPrintManager_billing_records_by_invoiceline_gridComponentInParams {
  invoiceLineId: number}


class FootPrintManager_billing_records_by_invoiceline_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_billing_records_by_invoiceline_gridComponent;
  entity: IFootPrintManager_billing_records_by_invoiceline_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    cancelled_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    billing_task_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    created_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    actual_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    actual_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    applicable_rate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    applicable_minimum_charge: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    total: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    details: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    cancelled: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['cancelled_display'] as DatexFormControl, null, true, '')
,
null
      ),
    cancelled_reason_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_billing_records_by_invoiceline_gridComponent, entity: IFootPrintManager_billing_records_by_invoiceline_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.BillingTaskId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_billing_records_by_invoiceline_gridComponent, entity?: IFootPrintManager_billing_records_by_invoiceline_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.BillingTaskId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.BillingTaskId;
    const inParams = {
      $keys:[$resultKey],
      invoiceLineId:  $grid.inParams.invoiceLineId ,
    };
    const data = await this.datasources.Invoices.ds_billing_records_by_invoiceline_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.billing_task_id.displayControl as TextModel).text = $row.entity.BillingTaskId?.toString();
    (this.cells.actual_amount.displayControl as TextModel).text = $row.entity.ActualAmount?.toString() ?? $row.entity.BillingTask?.Amount?.toString();
    (this.cells.actual_uom.displayControl as TextModel).text = $row.entity.ActualMeasurementUnit?.Short_name ?? $row.entity.BillingTask?.Uom?.Short_name;
    (this.cells.applicable_rate.displayControl as TextModel).text = $row.entity.ApplicableRate?.toString();
    (this.cells.applicable_minimum_charge.displayControl as TextModel).text = $row.entity.ApplicableMinimumCharge?.toString();
    (this.cells.total.displayControl as TextModel).text = $row.entity.Total?.toString();
    (this.cells.notes.displayControl as TextModel).text = $row.entity.BillingTask?.Notes;
    (this.cells.cancelled.displayControl as CheckBoxModel).reset($row.entity.BillingTask?.Cancelled);
    (this.cells.cancelled_reason_code.displayControl as TextModel).text = $row.entity.BillingTask?.CancelledReasonCode?.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'billing_task_id' : {
        this.on_billing_record_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_billing_records_by_invoiceline_gridComponentRowModel,
  $grid: FootPrintManager_billing_records_by_invoiceline_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  if ($utils.isDefined($row.entity.CreatedSysDateTime) && $utils.isDefined(format)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var createdDate = $utils.date.format($row.entity.CreatedSysDateTime, format);
      $row.cells.created_date.displayControl.text = createdDate;
  }
  
  
  
  
  
  const licenseplate = $utils.isDefined($row.entity.BillingTask?.LicensePlate?.LookupCode) ? ` Licenseplate ${$row.entity.BillingTask?.LicensePlate?.LookupCode}` : '';
  const locationName = $utils.isDefined($row.entity.BillingTask?.Location?.Name) ? ` Location ${$row.entity.BillingTask?.Location?.Name}` : '';
  const material = $utils.isDefined($row.entity.BillingTask?.Material?.LookupCode) ? ` Material ${$row.entity.BillingTask?.Material?.LookupCode}` : '';
  const packaging = $utils.isDefined($row.entity.BillingTask?.Packaging?.ShortName) ? ` Packaging ${$row.entity.BillingTask?.Packaging?.ShortName}` : '';
  const lot = $utils.isDefined($row.entity.BillingTask?.Lot?.LookupCode) ? ` Lot ${$row.entity.BillingTask?.Lot?.LookupCode}` : '';
  const order = $utils.isDefined($row.entity.BillingTask?.Order?.LookupCode) ? ` Order ${$row.entity.BillingTask?.Order?.LookupCode}` : '';
  const warehouse = $utils.isDefined($row.entity.BillingTask?.Warehouse?.Name) ? `Warehouse ${$row.entity.BillingTask?.Warehouse?.Name}` : '';
  const workorder = $utils.isDefined($row.entity.BillingTask?.WorkOrder?.Id) ? ` Workorder ${$row.entity.BillingTask?.WorkOrder?.Id?.toString()}` : '';
  const operationcode = $utils.isDefined($row.entity.BillingTask?.OperationCode?.Name) ? ` Operation ${$row.entity.BillingTask?.OperationCode?.Name}` : '';
  
  
  const details = `${warehouse}${locationName}${licenseplate}${material}${packaging}${lot}${order}${workorder}${operationcode}`
  $row.cells.details.displayControl.text = details;
  }
  on_billing_record_clicked(event = null) {
    return this.on_billing_record_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_billing_record_clickedInternal(
    $row: FootPrintManager_billing_records_by_invoiceline_gridComponentRowModel,
  $grid: FootPrintManager_billing_records_by_invoiceline_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.openbilling_record_activity_listDialog({billingTaskId: $row.entity.BillingTaskId},'flyout');
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-billing_records_by_invoiceline_grid',
  templateUrl: './FootPrintManager.billing_records_by_invoiceline_grid.component.html'
})
export class FootPrintManager_billing_records_by_invoiceline_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_billing_records_by_invoiceline_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_billing_records_by_invoiceline_gridComponentInParams = { invoiceLineId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     billing_task_id: new GridHeaderModel(new HeaderStyles(null, null), 'Billing record', false, false, null),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'Created', false, false, null),       actual_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount', false, false, null),       actual_uom: new GridHeaderModel(new HeaderStyles(null, null), 'Uom', false, false, null),       applicable_rate: new GridHeaderModel(new HeaderStyles(null, null), 'Rate', false, false, null),       applicable_minimum_charge: new GridHeaderModel(new HeaderStyles(null, null), 'Minimum charge', false, false, null),       total: new GridHeaderModel(new HeaderStyles(null, null), 'Total', false, false, null),       details: new GridHeaderModel(new HeaderStyles(null, null), 'Details', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       cancelled: new GridHeaderModel(new HeaderStyles(null, null), 'Cancelled', false, false, null),       cancelled_reason_code: new GridHeaderModel(new HeaderStyles(null, null), 'Cancelled reason code', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_billing_records_by_invoiceline_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('invoiceLineId') set $inParams_invoiceLineId(value: any) {
    this.inParams['invoiceLineId'] = value;
  }
  get $inParams_invoiceLineId(): any {
    return this.inParams['invoiceLineId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Billing records by invoice line';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.invoiceLineId)) {
        this.$missingRequiredInParams.push('invoiceLineId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      invoiceLineId:  $grid.inParams.invoiceLineId ,
    };
    try {
    const data = await this.datasources.Invoices.ds_billing_records_by_invoiceline_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_billing_records_by_invoiceline_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }



 
  close() {
    this.$finish.emit();
  }
}
