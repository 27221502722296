import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { PrintNode_ShellService, EModalSize, EToasterType, EToasterPosition } from './PrintNode.shell.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { PrintNode_LocalizationService } from './PrintNode.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './PrintNode.frontend.types'
import { $frontendTypes as $types} from './PrintNode.frontend.types' 

import { PrintNode_printers_dd_singleComponent } from './PrintNode.printers_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => PrintNode_printers_dd_singleComponent),
  ],
  selector: 'PrintNode-printnode_tester_form',
  templateUrl: './PrintNode.printnode_tester_form.component.html'
})
export class PrintNode_printnode_tester_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    letter_only: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    printers: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    label: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    pdf_base64: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    print_job_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      validate_credentials: new ToolModel(new ButtonModel('validate_credentials', new ButtonStyles(null, null), false, 'Validate credentials', 'ms-Icon ms-Icon--WorkforceManagement')
    )
  };

  fields = {
    letter_only: new FieldModel(new CheckBoxModel(this.formGroup.controls['letter_only'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Letter only', false)
,
    printers: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['printers'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Printer', false)
,
    label: new FieldModel(new TextBoxModel(this.formGroup.controls['label'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Label URL', false)
,
    pdf_base64: new FieldModel(new TextBoxModel(this.formGroup.controls['pdf_base64'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'PDF', false)
,
    print: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Print', 'ms-Icon ms-Icon--AutomateFlow')
, new ControlContainerStyles(null, null), '', false)
,
    print_job_id: new FieldModel(new NumberBoxModel(this.formGroup.controls['print_job_id'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Printjob ID', false)
,
    printjobs: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Printjobs', 'ms-Icon ms-Icon--CloudPrinter')
, new ControlContainerStyles(null, null), '', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', false, true, true),
};

  //#region fields inParams
  get $fields_printers_selector_inParams_letterOnly(): boolean {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.letter_only.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    letter_only: this.fields.letter_only.control.valueChanges
    ,
    printers: this.fields.printers.control.valueChanges
    ,
    label: this.fields.label.control.valueChanges
    ,
    pdf_base64: this.fields.pdf_base64.control.valueChanges
    ,
    print_job_id: this.fields.print_job_id.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: PrintNode_ShellService,
private datasources: PrintNode_DatasourceService,
private flows: PrintNode_FlowService,
private reports: PrintNode_ReportService,
private localization: PrintNode_LocalizationService,
private operations: PrintNode_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Test harness';
  
    const $form = this;
    const $utils = this.utils;

    
    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .letter_only
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .printers
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .label
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .pdf_base64
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .print_job_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_print_button_clicked(event = null) {
    return this.on_print_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_button_clickedInternal(
    $form: PrintNode_printnode_tester_formComponent,
  
    $shell: PrintNode_ShellService,
    $datasources: PrintNode_DatasourceService,
    $flows: PrintNode_FlowService,
    $reports: PrintNode_ReportService,
    $settings: SettingsValuesService,
    $operations: PrintNode_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: PrintNode_LocalizationService,
    $event: any
  ) {
    this.logger.log('PrintNode', 'printnode_tester_form.on_print_button_clicked');
  let flowPrintParams: any;
  
  if ($utils.isDefined($form.fields.label.control.value)) {
      flowPrintParams = {
          content: $form.fields.label.control.value,
          printerId: $form.fields.printers.control.value
      };
  
      const printResponse = await $flows.PrintNode.print_request_flow(flowPrintParams);
  
      $form.fields.print_job_id.control.value = printResponse.printJobId;
  }
  else if ($utils.isDefined($form.fields.pdf_base64.control.value)) {
      flowPrintParams = {
          contentType: "pdf_base64",
          content: $form.fields.pdf_base64.control.value,
          printerId: $form.fields.printers.control.value
      };
  }
  
  
  if ($utils.isDefined(flowPrintParams)) {
      const printResponse = await $flows.PrintNode.print_request_flow(flowPrintParams);
  
      $form.fields.print_job_id.control.value = printResponse.printJobId;
  }
  }
  on_printjob_button_clicked(event = null) {
    return this.on_printjob_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_printjob_button_clickedInternal(
    $form: PrintNode_printnode_tester_formComponent,
  
    $shell: PrintNode_ShellService,
    $datasources: PrintNode_DatasourceService,
    $flows: PrintNode_FlowService,
    $reports: PrintNode_ReportService,
    $settings: SettingsValuesService,
    $operations: PrintNode_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: PrintNode_LocalizationService,
    $event: any
  ) {
    this.logger.log('PrintNode', 'printnode_tester_form.on_printjob_button_clicked');
  var printJobId = $form.fields.print_job_id.control.value;
  var printerId = $form.fields.printers.control.value;
  
  
  $shell.PrintNode.openprintjob_gridDialog({printJobId:printJobId, printerId: printerId},'modal')
  
  }
  on_validate_credentials_button_clicked(event = null) {
    return this.on_validate_credentials_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_validate_credentials_button_clickedInternal(
    $form: PrintNode_printnode_tester_formComponent,
  
    $shell: PrintNode_ShellService,
    $datasources: PrintNode_DatasourceService,
    $flows: PrintNode_FlowService,
    $reports: PrintNode_ReportService,
    $settings: SettingsValuesService,
    $operations: PrintNode_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: PrintNode_LocalizationService,
    $event: any
  ) {
    this.logger.log('PrintNode', 'printnode_tester_form.on_validate_credentials_button_clicked');
  
  
  const validateResponse = await $flows.PrintNode.validate_credentials_flow({});
  console.log(validateResponse.validation);
  
  const title = 'PrintNode Validation';
  const Message = `Validation response ${validateResponse.validation}`;
  await $shell.PrintNode.openInfoDialog(title, Message);
  return;
  
  
  
  
  }
  //#endregion private flows
}
