import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Utilities.frontend.types'

@Injectable({ providedIn: 'root' })
export class Utilities_configurationsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { payload?: $frontendTypes.Utilities.Configuration[], action: string, skip?: number, take?: number, application_name: string, full_text_search?: string }): Promise<{ payload?: $frontendTypes.Utilities.Configuration[], success?: boolean, error?: any[], count?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.action)) {
      missingRequiredInParams.push('\'action\'');
    }
    if (isNil(inParams.application_name)) {
      missingRequiredInParams.push('\'application_name\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Utilities/functions/configurations`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

