<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="manage" let-tool>
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.configurations.readOnly"
                              [ngStyle]="tool.control.buttons.configurations.styles.style"
                              [ngClass]="tool.control.buttons.configurations.styles.classes"
                              (click)="on_manage_configurations_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.configurations.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.configurations.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.configurations.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.manage_debug.readOnly"
                              [ngStyle]="tool.control.buttons.manage_debug.styles.style"
                              [ngClass]="tool.control.buttons.manage_debug.styles.classes"
                              (click)="on_manage_debug_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.manage_debug.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.manage_debug.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.manage_debug.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                    title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Invoices-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Invoices-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                    title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Invoices-invoicing_projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Invoices-invoicing_projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.invoicing_rules.hidden" class="tab" data-cy="tab-invoicing_rules">
              <h2 [className]="tabs.invoicing_rules.active? 'active': ''" (click)="tabs.invoicing_rules.activate()">{{tabs.invoicing_rules.title}}</h2>
            </div>
            <div *ngIf="!tabs.template_rules.hidden" class="tab" data-cy="tab-template_rules">
              <h2 [className]="tabs.template_rules.active? 'active': ''" (click)="tabs.template_rules.activate()">{{tabs.template_rules.title}}</h2>
            </div>
          </div>
        
              <Invoices-invoicing_rules_grid *ngIf="tabs.invoicing_rules.active"
              #$tabs_invoicing_rules
              [projectId]="$tabs_invoicing_rules_invoicing_rules_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_invoicing_rules')">
              </Invoices-invoicing_rules_grid>
              <Invoices-invoicing_rules_grid *ngIf="tabs.template_rules.active"
              #$tabs_template_rules
              [projectId]="$tabs_template_rules_invoicing_rules_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_template_rules')">
              </Invoices-invoicing_rules_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>