import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { ExcelLocationImport_sample_batch_crudService } from './ExcelLocationImport.flow.index';

import { $frontendTypes } from './ExcelLocationImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelLocationImport_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public ExcelLocationImport: ExcelLocationImport_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _sample_batch_crud: ExcelLocationImport_sample_batch_crudService;
  public async sample_batch_crud(inParams: {  }): Promise< { reasons?: string[], response?: string }> {
    if(!this._sample_batch_crud) {
      this._sample_batch_crud = this.injector.get(ExcelLocationImport_sample_batch_crudService);
    }
    return this._sample_batch_crud.run(inParams);
  }
   
   

   
}
