import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_pack_verification_shipping_container_contents_gridComponent } from './FootPrintManager.pack_verification_shipping_container_contents_grid.component';
import { FootPrintManager_scanned_values_gridComponent } from './FootPrintManager.scanned_values_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_pack_verification_shipping_container_contents_gridComponent),
    forwardRef(() => FootPrintManager_scanned_values_gridComponent),
  ],
  selector: 'FootPrintManager-pack_verification_verify_container_editor',
  templateUrl: './FootPrintManager.pack_verification_verify_container_editor.component.html'
})
export class FootPrintManager_pack_verification_verify_container_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { shippingContainerId: number, startVerificationTaskId: number, isBlind?: boolean, targetLocationId?: number, isReadOnly?: boolean } = { shippingContainerId: null, startVerificationTaskId: null, isBlind: null, targetLocationId: null, isReadOnly: null };
  //#region Inputs
  @Input('shippingContainerId') set $inParams_shippingContainerId(v: number) {
    this.inParams.shippingContainerId = v;
  }
  get $inParams_shippingContainerId(): number {
    return this.inParams.shippingContainerId;
  }
  @Input('startVerificationTaskId') set $inParams_startVerificationTaskId(v: number) {
    this.inParams.startVerificationTaskId = v;
  }
  get $inParams_startVerificationTaskId(): number {
    return this.inParams.startVerificationTaskId;
  }
  @Input('isBlind') set $inParams_isBlind(v: boolean) {
    this.inParams.isBlind = v;
  }
  get $inParams_isBlind(): boolean {
    return this.inParams.isBlind;
  }
  @Input('targetLocationId') set $inParams_targetLocationId(v: number) {
    this.inParams.targetLocationId = v;
  }
  get $inParams_targetLocationId(): number {
    return this.inParams.targetLocationId;
  }
  @Input('isReadOnly') set $inParams_isReadOnly(v: boolean) {
    this.inParams.isReadOnly = v;
  }
  get $inParams_isReadOnly(): boolean {
    return this.inParams.isReadOnly;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { scannedValues?: { scannedValue?: string, materialId?: number, serialNumberId?: number, isVerified?: boolean, reasons?: string[], lotId?: number, packagingId?: number }[] } = { scannedValues: [] };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { isReadOnly?: boolean, invalidSerialValues?: string[], expectedSerialNumbers?: { lookupCode: string, lotId: number, packagingId: number, id: number, licensePlateId: number }[], isUpcCode?: boolean } = { };
  //#endregion
  entity: { Id?: number, Height?: number, Length?: number, LookupCode?: string, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, Weight?: number, Width?: number, LicensePlates?: { Id?: number, LocationId?: number, WarehouseId?: number, SerialNumbers?: { Id?: number, LicensePlateId?: number, LookupCode?: string, LotId?: number, PackagingId?: number }[] }[], Shipment?: { Id?: number, ActualWarehouseId?: number, LookupCode?: string, TrailerId?: string, OrderLookups?: { CreatedSysDateTime?: string, Order?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, Account?: { Id?: number, LookupCode?: string }, PreferredCarrier?: { Name?: string }, PreferredCarrierServiceType?: { Name?: string } } }[], Carrier?: { Name?: string }, CarrierServiceType?: { Name?: string } }, ContainerType?: { Id?: number, Name?: string }, DimensionUom?: { Id?: number, Name?: string }, WeightUom?: { Id?: number, Name?: string }, VolumeUom?: { Id?: number, Name?: string } };

  formGroup: FormGroup = new FormGroup({
    input_scan: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      fail_verification: new ToolModel(new ButtonModel('fail_verification', new ButtonStyles(['destructive'], null), false, 'Fail verification', 'ms-Icon ms-Icon--Blocked')
    )
  };

  fields = {
    input_scan: new FieldModel(new TextBoxModel(this.formGroup.controls['input_scan'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Scan item to start checking', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      container_contents: new TabItemModel(
        this.rootTabGroup, 
        'Container contents', 
        ),
      invalid_serial_numbers: new TabItemModel(
        this.rootTabGroup, 
        'Invalid serials', 
        ),
      scanned_values: new TabItemModel(
        this.rootTabGroup, 
        'Scanned values', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_shippingContainerId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_canAddManually(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = true;
      
      return expr;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_startVerificationTaskId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.startVerificationTaskId;
      
      return expr;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isReadOnly(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.isReadOnly;
      
      return expr;
    }
  
    cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues: { scannedValue?: string, lotId?: number, packagingId?: number }[];
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues(): { scannedValue?: string, lotId?: number, packagingId?: number }[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.outParams.scannedValues.map(v => ({ scannedValue: v.scannedValue, lotId: v.lotId, packagingId: v.packagingId }));
      
      if(!isEqual(this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues, expr)) {
        this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues = expr;
      }
      return this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isBlind(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.isBlind;
      
      return expr;
    }
  
    cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_expectedSerialNumbers: { lookupCode: string, lotId: number, packagingId: number, id: number, licensePlateId: number }[];
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_expectedSerialNumbers(): { lookupCode: string, lotId: number, packagingId: number, id: number, licensePlateId: number }[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.expectedSerialNumbers;
      
      if(!isEqual(this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_expectedSerialNumbers, expr)) {
        this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_expectedSerialNumbers = expr;
      }
      return this.cacheValueFor_$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_expectedSerialNumbers;
    }
  
    get $tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_locationId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.targetLocationId;
      
      return expr;
    }
  
    cacheValueFor_$tabs_invalid_serial_numbers_scanned_values_grid_inParams_scannedValues: { Value?: string, Note?: string }[];
    get $tabs_invalid_serial_numbers_scanned_values_grid_inParams_scannedValues(): { Value?: string, Note?: string }[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = Array.from($editor.vars.invalidSerialValues.map(v => ({Value: v, Note: null}))).reverse();
      
      if(!isEqual(this.cacheValueFor_$tabs_invalid_serial_numbers_scanned_values_grid_inParams_scannedValues, expr)) {
        this.cacheValueFor_$tabs_invalid_serial_numbers_scanned_values_grid_inParams_scannedValues = expr;
      }
      return this.cacheValueFor_$tabs_invalid_serial_numbers_scanned_values_grid_inParams_scannedValues;
    }
  
    cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_scannedValues: { Value?: string, Note?: string }[];
    get $tabs_scanned_values_scanned_values_grid_inParams_scannedValues(): { Value?: string, Note?: string }[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = Array.from($editor.outParams.scannedValues.map(v => ({ Value: v.scannedValue, Note: null }))).reverse();
      
      if(!isEqual(this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_scannedValues, expr)) {
        this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_scannedValues = expr;
      }
      return this.cacheValueFor_$tabs_scanned_values_scanned_values_grid_inParams_scannedValues;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_container_contents', { read: FootPrintManager_pack_verification_shipping_container_contents_gridComponent }) $tabs_container_contents: FootPrintManager_pack_verification_shipping_container_contents_gridComponent;
      @ViewChild('$tabs_invalid_serial_numbers', { read: FootPrintManager_scanned_values_gridComponent }) $tabs_invalid_serial_numbers: FootPrintManager_scanned_values_gridComponent;
      @ViewChild('$tabs_scanned_values', { read: FootPrintManager_scanned_values_gridComponent }) $tabs_scanned_values: FootPrintManager_scanned_values_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    input_scan: this.fields.input_scan.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.container_contents,
      this.tabs.invalid_serial_numbers,
      this.tabs.scanned_values,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shippingContainerId)) {
        this.$missingRequiredInParams.push('shippingContainerId');
      }
      if(isNil(this.inParams.startVerificationTaskId)) {
        this.$missingRequiredInParams.push('startVerificationTaskId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Verify Container';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      shippingContainerId:  $editor.inParams.shippingContainerId 
    };

    const data = await this.datasources.PackVerification.ds_pack_verification_verify_container_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_container_contents') {
        if (!isNil(this.$tabs_container_contents) && !this.tabs.container_contents.hidden) {
          this.$tabs_container_contents.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_invalid_serial_numbers') {
        if (!isNil(this.$tabs_invalid_serial_numbers) && !this.tabs.invalid_serial_numbers.hidden) {
          this.$tabs_invalid_serial_numbers.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_scanned_values') {
        if (!isNil(this.$tabs_scanned_values) && !this.tabs.scanned_values.hidden) {
          this.$tabs_scanned_values.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .input_scan
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_input_scan_changed();
      });
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_pack_verification_verify_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.tabs.container_contents.title = `Container ${$editor.entity.LookupCode}`;
  
  $editor.tabs.invalid_serial_numbers.hidden = $editor.inParams.isBlind;
  
  $editor.fields.input_scan.control.readOnly = $editor.vars.isReadOnly;
  
  $editor.vars.expectedSerialNumbers = $editor.entity.LicensePlates.reduce((output, lp) => output.concat(lp.SerialNumbers.map(sn => ({ lookupCode: sn.LookupCode, lotId: sn.LotId, packagingId: sn.PackagingId}))), [])
  
  $editor.set_focus();
  }
  on_input_scan_changed(event = null) {
    return this.on_input_scan_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_input_scan_changedInternal(
    $editor: FootPrintManager_pack_verification_verify_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/31/2024
  //Wavelength Component 174748: Client Replace - Pack Verification advanced enhancement
  
  try {
      if ($utils.isDefined($editor.fields.input_scan.control.value)) {
  
          let input_value: string = ($editor.fields.input_scan.control.value).toString();
          let comma_separated_values = input_value.split(",");
  
          for (let separated_value of comma_separated_values) {
  
              let parsingResult = (await $flows.BarcodeParsing.parse_gs1_barcode_flow({ inputScan: separated_value }));
  
              let serialNumberResult = (await $flows.BarcodeParsing.get_serial_number_code_by_parsedValues({ parsedValues: parsingResult.extractedValues }));
  
              if ($utils.isDefined(serialNumberResult.reasons)) {
                  throw new Error(`Error determining serial number: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: serialNumberResult.reasons })).formattedValue}`);
              }
  
              $editor.outParams.scannedValues.push({ scannedValue: serialNumberResult.serialNumberCode });
  
              if ($editor.outParams.scannedValues.filter(v => v.scannedValue.trim().toUpperCase() === separated_value.trim().toUpperCase()).length <= 1 || $editor.vars.isUpcCode) {
  
                  let verify: boolean = true;
  
                  if (!$editor.inParams.isBlind) {
                      // Check if invalid serial number
                      if ($editor.entity.LicensePlates.reduce((output, lp) => output.concat(lp.SerialNumbers.map(sn => sn.LookupCode.trim().toUpperCase())), [])?.indexOf(serialNumberResult.serialNumberCode.trim().toUpperCase()) < 0
                      ) {
                          let isSerialNumber = (await checkInvalidSerialScan(serialNumberResult.serialNumberCode, parsingResult.extractedValues.serialNumber));
                          if (isSerialNumber) { verify = false }
                      }
                  }
  
                  if (verify) {
                      $editor.tabs.container_contents.activate();
                      verifyScan(parsingResult.extractedValues.gtin, serialNumberResult.serialNumberCode);
                  }
              }
          }
  
          $editor.fields.input_scan.control.value = null;
          $editor.tabs.scanned_values.title = `Scanned values (${$editor.outParams.scannedValues.length})`;
  
          $editor.fields.input_scan.control.focus();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error verifying content', targetError.message);
  }
  
  /***********************************************
   * FUNCTIONS
  ************************************************/
  async function verifyScan(gtin: string, serialNumberCode: string) {
      let scannedValue = $editor.outParams.scannedValues[$editor.outParams.scannedValues.length - 1];
  
      let result = await $flows.PackVerification.verify_content_flow({
          shippingContainerId: $editor.inParams.shippingContainerId,
          startPackVerificationTaskId: $editor.inParams.startVerificationTaskId,
          inputScan: {
              materialCode: gtin,
              serialNumberCode: serialNumberCode
          },
          canSubstituteSerials: true
      });
  
      scannedValue.isVerified = result.isVerified;
      scannedValue.materialId = result.materialId;
      scannedValue.lotId = result.lotId;
      scannedValue.serialNumberId = result.serialNumberId;
      scannedValue.reasons = result.reasons;
      scannedValue.packagingId = result.packagingId;
  
      $editor.vars.isUpcCode = result.isUpcCode ?? false;
  
      document.getElementsByTagName('footprintmanager-pack_verification_wizard')[0].dispatchEvent(new CustomEvent('handle_scanned_values_changed', { 'detail': $editor.outParams.scannedValues }));
  }
  
  async function checkInvalidSerialScan(scan: string, serial_lookup: string) {
      let isSerialNumber: boolean = false;
      // Check if actual serial number
      let serial_match = (await $datasources.SerialNumbers.ds_find_serialnumbers.getList({ lookupcode: scan, $top: 1 })).result;
  
      if ($utils.isDefined(serial_match)) {
          $editor.vars.invalidSerialValues.push(scan);
          $editor.tabs.invalid_serial_numbers.title = `Invalid serials (${$editor.vars.invalidSerialValues.length})`;
          $editor.tabs.invalid_serial_numbers.activate();
  
          if (!serial_match[0].Archived && $utils.isDefinedTrimmed(serial_match[0].Lot.Material.LookupCode) && $utils.isDefinedTrimmed(serial_lookup)) {
              verifyScan(serial_match[0].Lot.Material.LookupCode, serial_lookup);
          }
          isSerialNumber = true;
      }
      return isSerialNumber;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_pack_verification_verify_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.scannedValues = [];
  $editor.vars.isReadOnly = false;
  $editor.inParams.isBlind = $editor.inParams.isBlind ?? true;
  $editor.vars.invalidSerialValues = [];
  $editor.vars.expectedSerialNumbers = [];
  $editor.vars.isUpcCode = false;
  
  try {
      if ($utils.isDefined($editor.inParams.isReadOnly)) {
          $editor.vars.isReadOnly = $editor.inParams.isReadOnly;
      }
  
      add_events();
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error initializing editor', targetError.message);
  
      $editor.close();
  }
  
  /*************************************
   * CUSTOM EVENTS
  **************************************/
  async function add_events() {
      await new Promise(result => setTimeout(result, 300));
      // Get element to attach to 
      const element = document.getElementsByTagName('footprintmanager-pack_verification_wizard')[0];
  
      if (element == null) {
          throw new Error(`Failed to identify the HTML element for pack verification wizard`);
      }
  
      element.addEventListener('handlepack_verification_verify_container_editorValidated', ((event: CustomEvent) => {
          $editor.vars.isReadOnly = true;
          $editor.refresh();
      }) as EventListener);
  
  
      element.dispatchEvent(new CustomEvent('on_pack_verification_verify_container_editor_initialized'));
  }
  }
  on_fail_verification_clicked(event = null) {
    return this.on_fail_verification_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fail_verification_clickedInternal(
    $editor: FootPrintManager_pack_verification_verify_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let confirmationResult = (await $shell.FootPrintManager.openpack_verification_failure_formDialog({ shippingContainerCode: $editor.entity.LookupCode }));
  
      if (confirmationResult.isConfirmed) {
          let targetLocationId = (await $flows.PackVerification.get_failure_location_flow({
              inputs: {
                  context: 'ManualFail',
                  defaultLocationId: $editor.inParams.targetLocationId,
                  taskId: $editor.inParams.startVerificationTaskId
              }
          })).targetLocationId;
  
  
          let result = (await $flows.PackVerification.fail_pack_verification_flow({
              shippingContainerId: $editor.entity.Id,
              startPackVerificationTaskId: $editor.inParams.startVerificationTaskId,
              reasonCodeId: confirmationResult.reasonCodeId,
              targetLocationId: targetLocationId
      }));
  
          document.getElementsByTagName('footprintmanager-pack_verification_wizard')[0].dispatchEvent(new CustomEvent('handleCloseWizard'));
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error verifying content', targetError.message);
  }
  }
  set_focus(event = null) {
    return this.set_focusInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_focusInternal(
    $editor: FootPrintManager_pack_verification_verify_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.input_scan.control.focus();
  }
  on_verification_tasks_cancelled(event = null) {
    return this.on_verification_tasks_cancelledInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_verification_tasks_cancelledInternal(
    $editor: FootPrintManager_pack_verification_verify_container_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const SERIAL_IDS: number[] = $event?.map(d => d.serial_number_id) ?? [];
  
  for (const SERIAL_ID of SERIAL_IDS) {
      const INDEX = $editor.outParams.scannedValues.findIndex(sv => sv.serialNumberId === SERIAL_ID);
  
      if (INDEX > -1) {
          $editor.outParams.scannedValues.splice(INDEX, 1);
      }
  }
  }
  //#endregion private flows
}
