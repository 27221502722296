import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './WarehouseTransfers.frontend.types'

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_create_or_update_warehouse_transfer_line_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { line_number?: number, warehouse_transfer_id: number, material_id: number, vendor_lot_id?: number, lot_id?: number, packaging_id: number, packaged_amount: number, license_plate_id?: number, serial_number_id?: number }): Promise<{ reasons?: string[], lineNumber?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouse_transfer_id)) {
      missingRequiredInParams.push('\'warehouse_transfer_id\'');
    }
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.packaging_id)) {
      missingRequiredInParams.push('\'packaging_id\'');
    }
    if (isNil(inParams.packaged_amount)) {
      missingRequiredInParams.push('\'packaged_amount\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/functions/create_or_update_warehouse_transfer_line_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

