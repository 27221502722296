import { Component, ContentChildren, Directive, Input, QueryList, TemplateRef, ChangeDetectorRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ToolModel } from '../models/tool';
import { SeparatorModel } from '../models/control';
import { merge, Subscription } from 'rxjs';

@Directive({
  selector: '[toolbarToolDef]'
})
export class ToolbarToolDef {
  @Input('toolbarToolDef')
  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
  protected _id: string;

  constructor(public template: TemplateRef<any>) { }
}

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements AfterViewInit, OnDestroy {
  @Input() toolbar: { [key: string]: ToolModel<any> };
  @ContentChildren(ToolbarToolDef) _toolbarToolDefs: QueryList<ToolbarToolDef>;
  _toolDefsByName = new Map<string, ToolbarToolDef>();
  private separatorUpdatePending = false;
  private hiddenChangeSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    const hiddenChangeObservables = Object.values(this.toolbar).map(tool => tool.hiddenChange.asObservable());
    this.hiddenChangeSubscription = merge(...hiddenChangeObservables).subscribe(() => {
      this.queueSeparatorUpdate();
    });

    this._cacheToolDefs();
    this.queueSeparatorUpdate(); // Update visibility after caching tool definitions
  }

  ngOnDestroy(): void {
    if (this.hiddenChangeSubscription) {
      this.hiddenChangeSubscription.unsubscribe();
    }
    this._toolDefsByName.clear();
  }

  private _cacheToolDefs() {
    this._toolDefsByName.clear();
    this._toolbarToolDefs.forEach(toolDef => {
      this._toolDefsByName.set(toolDef.id, toolDef);
    });
  }

  private queueSeparatorUpdate(): void {
    if (!this.separatorUpdatePending) {
      this.separatorUpdatePending = true;
      this.updateSeparatorVisibility();
      this.cdr.detectChanges();  // Trigger change detection manually
      this.separatorUpdatePending = false;
    }
  }

  updateSeparatorVisibility(): void {
    const toolbarKeys = Object.keys(this.toolbar);

    toolbarKeys.forEach((key, index) => {
      const tool = this.toolbar[key];

      if (!this.isSeparator(tool)) {
        return;
      }
      else {
        let prevIsNonSeparator = false;
        let nextIsNonSeparator = false;

        // Check for visible tool before the separator
        for (let i = index - 1; i >= 0; i--) {
          if (this.toolbar[toolbarKeys[i]].hidden) {
            continue;
          }
          if (!this.isSeparator(this.toolbar[toolbarKeys[i]])) {
            prevIsNonSeparator = true;
          }
          break;
        }

        // Check for visible tool after the separator
        for (let i = index + 1; i < toolbarKeys.length; i++) {
          if (this.toolbar[toolbarKeys[i]].hidden) {
            continue;
          }
          if (!this.isSeparator(this.toolbar[toolbarKeys[i]])) {
            nextIsNonSeparator = true;
          }
          break;
        }

        // Hide the separator if there isn't a non-separator item before and after it 
        tool.hidden = !(prevIsNonSeparator && nextIsNonSeparator);
      }
    });
  }

  isSeparator(tool: any): boolean {
    return tool.control instanceof SeparatorModel;
  }

  keepOriginalOrder() {
    return 0; // ensures no sorting is done 
  }
}