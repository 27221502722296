import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './ExcelMaterialImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelMaterialImport_material_import_stagingService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { payload?: $frontendTypes.ExcelMaterialImport.MaterialImportRecord[], action?: string, skip?: number, take?: number, full_text_search?: string, import_status?: string, lookup?: string, project?: string, request_id?: string }): Promise<{ payload?: $frontendTypes.ExcelMaterialImport.MaterialImportRecord[], success?: boolean, messages?: any[], count?: number }> 
  {
    let url = `${environment.backendUrl}api/ExcelMaterialImport/functions/material_import_staging`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

