import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';

interface IFootPrintApiManager_transaction_reportServiceInParams {
  transaction_id: string, log_ids?: string[], message_ids?: string[]}

interface IFootPrintApiManager_transaction_reportServiceData {
  transaction_report?: { result?: { transaction_id?: string, ingress_id?: string, egress_id?: string, integration_name?: string, status?: string, direction?: string, reference?: string, sender?: string, receiver?: string, payload?: string, transaction_type?: string, environment?: string, process?: string, level?: number, created_on?: string, created_by?: string, log_notes?: string, message_notes?: string, log_details?: { field?: string, content?: string }[], message_details?: { field?: string, content?: string }[] } }
}

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_transaction_reportService extends ReportBaseService<IFootPrintApiManager_transaction_reportServiceInParams, IFootPrintApiManager_transaction_reportServiceData> {

  protected reportReferenceName = 'transaction_report';
  protected appReferenceName = 'FootPrintApiManager';

  constructor(
    utils: UtilsService,
    private datasources: FootPrintApiManager_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('FootPrintApiManager', 'transaction_report');
  }

  override throwIfMissingRequiredInParams (inParams: IFootPrintApiManager_transaction_reportServiceInParams) {
  }

  protected async getData(inParams: IFootPrintApiManager_transaction_reportServiceInParams): Promise<IFootPrintApiManager_transaction_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IFootPrintApiManager_transaction_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IFootPrintApiManager_transaction_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        transaction_id:  $report.inParams.transaction_id ,
        log_ids:  $report.inParams.log_ids ,
        message_ids:  $report.inParams.message_ids 
      };
      
      const dsData = await this.datasources.FootPrintApiManager.ds_transaction_report.get(dsParams);
      
      data.transaction_report = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
