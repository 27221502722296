import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { ExcelProjectImport_ShellService, EModalSize, EToasterType, EToasterPosition } from './ExcelProjectImport.shell.service';
import { ExcelProjectImport_OperationService } from './ExcelProjectImport.operation.service';
import { ExcelProjectImport_DatasourceService } from './ExcelProjectImport.datasource.index';
import { ExcelProjectImport_FlowService } from './ExcelProjectImport.flow.index';
import { ExcelProjectImport_ReportService } from './ExcelProjectImport.report.index';
import { ExcelProjectImport_LocalizationService } from './ExcelProjectImport.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './ExcelProjectImport.frontend.types'
import { $frontendTypes as $types} from './ExcelProjectImport.frontend.types' 



interface IExcelProjectImport_excel_project_import_gridComponentEntity {
Id?: number, LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string }, ProjectsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[]}



class ExcelProjectImport_excel_project_import_gridComponentRowModel extends GridRowModel {
  grid: ExcelProjectImport_excel_project_import_gridComponent;
  entity: IExcelProjectImport_excel_project_import_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    project_lookupcode_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    project_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    owner_lookupcode_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    owner_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    contact_first_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    contact_last_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    contact_primary_email_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    contact_primary_telephone_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    contact_notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    address_attention_of_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    address_line1_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    address_line2_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    address_city_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    address_state_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    address_postalcode_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    address_country_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    Id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    error: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['project_lookupcode_edit'] as DatexFormControl, null, false, '')
      ),
    project_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['project_name_edit'] as DatexFormControl, null, false, '')
      ),
    owner_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['owner_lookupcode_edit'] as DatexFormControl, null, false, '')
      ),
    owner_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['owner_name_edit'] as DatexFormControl, null, false, '')
      ),
    contact_first_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['contact_first_name_edit'] as DatexFormControl, null, false, '')
      ),
    contact_last_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['contact_last_name_edit'] as DatexFormControl, null, false, '')
      ),
    contact_primary_email: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['contact_primary_email_edit'] as DatexFormControl, null, false, '')
      ),
    contact_primary_telephone: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['contact_primary_telephone_edit'] as DatexFormControl, null, false, '')
      ),
    contact_notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['contact_notes_edit'] as DatexFormControl, null, false, '')
      ),
    address_attention_of: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['address_attention_of_edit'] as DatexFormControl, null, false, '')
      ),
    address_line1: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['address_line1_edit'] as DatexFormControl, null, false, '')
      ),
    address_line2: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['address_line2_edit'] as DatexFormControl, null, false, '')
      ),
    address_city: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['address_city_edit'] as DatexFormControl, null, false, '')
      ),
    address_state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['address_state_edit'] as DatexFormControl, null, false, '')
      ),
    address_postalcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['address_postalcode_edit'] as DatexFormControl, null, false, '')
      ),
    address_country: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['address_country_edit'] as DatexFormControl, null, false, '')
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: ExcelProjectImport_ShellService,
private datasources: ExcelProjectImport_DatasourceService,
private flows: ExcelProjectImport_FlowService,
private reports: ExcelProjectImport_ReportService,
private localization: ExcelProjectImport_LocalizationService,
private operations: ExcelProjectImport_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: ExcelProjectImport_excel_project_import_gridComponent, entity: IExcelProjectImport_excel_project_import_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: ExcelProjectImport_excel_project_import_gridComponent, entity?: IExcelProjectImport_excel_project_import_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      projectId:  $grid.vars.projectId ,
    };
    const data = await this.datasources.ExcelProjectImport.ds_project_import_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Id.displayControl as TextModel).text = $row.entity?.Id?.toString();
    (this.cells.project_lookupcode.displayControl as TextModel).text = $row.entity?.LookupCode;
    (this.cells.project_lookupcode.editControl as TextBoxModel).reset($row.entity?.LookupCode);
    (this.cells.project_name.displayControl as TextModel).text = $row.entity?.Name;
    (this.cells.project_name.editControl as TextBoxModel).reset($row.entity?.Name);
    (this.cells.owner_lookupcode.displayControl as TextModel).text = $row.entity?.Owner?.LookupCode;
    (this.cells.owner_lookupcode.editControl as TextBoxModel).reset($row.entity?.Owner?.LookupCode);
    (this.cells.owner_name.displayControl as TextModel).text = $row.entity?.Owner?.Name;
    (this.cells.owner_name.editControl as TextBoxModel).reset($row.entity?.Owner?.Name);
    (this.cells.contact_first_name.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.FirstName;
    (this.cells.contact_first_name.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.FirstName);
    (this.cells.contact_last_name.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.LastName;
    (this.cells.contact_last_name.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.FirstName);
    (this.cells.contact_primary_email.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.PrimaryEmail;
    (this.cells.contact_primary_email.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.PrimaryEmail);
    (this.cells.contact_primary_telephone.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.PrimaryTelephone;
    (this.cells.contact_primary_telephone.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.PrimaryTelephone);
    (this.cells.contact_notes.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.Notes;
    (this.cells.contact_notes.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.Notes);
    (this.cells.address_attention_of.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.AttentionOf;
    (this.cells.address_attention_of.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.AttentionOf);
    (this.cells.address_line1.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.Line1;
    (this.cells.address_line1.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.Line1);
    (this.cells.address_line2.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.Line2;
    (this.cells.address_line2.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.Line2);
    (this.cells.address_city.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.City;
    (this.cells.address_city.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.City);
    (this.cells.address_state.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.State;
    (this.cells.address_state.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.State);
    (this.cells.address_postalcode.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.PostalCode;
    (this.cells.address_postalcode.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.PostalCode);
    (this.cells.address_country.displayControl as TextModel).text = $row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.Country;
    (this.cells.address_country.editControl as TextBoxModel).reset($row.entity?.ProjectsContactsLookup[0]?.Contact?.Address?.Country);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: ExcelProjectImport_excel_project_import_gridComponentRowModel,
  $grid: ExcelProjectImport_excel_project_import_gridComponent, 
    $shell: ExcelProjectImport_ShellService,
    $datasources: ExcelProjectImport_DatasourceService,
    $flows: ExcelProjectImport_FlowService,
    $reports: ExcelProjectImport_ReportService,
    $settings: SettingsValuesService,
    $operations: ExcelProjectImport_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: ExcelProjectImport_LocalizationService,
    $event: any
  ) {
  
  
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Project" })).nextId;
  const nextContactId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Contact" })).nextId;
  
  
  $row.entity = {
      Id: nextId,
      ProjectsContactsLookup: [
          {
              ContactId: nextContactId
  
          }
      ]
  };
  
  
  
  
  
  
  
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: ExcelProjectImport_excel_project_import_gridComponentRowModel,
  $grid: ExcelProjectImport_excel_project_import_gridComponent, 
    $shell: ExcelProjectImport_ShellService,
    $datasources: ExcelProjectImport_DatasourceService,
    $flows: ExcelProjectImport_FlowService,
    $reports: ExcelProjectImport_ReportService,
    $settings: SettingsValuesService,
    $operations: ExcelProjectImport_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: ExcelProjectImport_LocalizationService,
    $event: any
  ) {
  
  
  // Update project
  let payload: any = {};
  
  
  
  if ($row.cells.project_lookupcode.editControl.isChanged) {
  
      var projectLookupcode = $row.cells.project_lookupcode.editControl.value;
      if (projectLookupcode.length > 16){
            $shell.ExcelProjectImport.openErrorDialog('Project Update Error', 'Project lookupcode cannot exceed 16 characters.');
          return;
      }
      // Check for project duplicates
      const projects = (await $datasources.ExcelProjectImport.ds_get_project_by_lookupcode_and_ownerId.get({
          lookupcode: projectLookupcode,
          ownerId: $row.entity.OwnerId
      })).result;
      if ($utils.isDefined(projects)) {
  
          $shell.ExcelProjectImport.openErrorDialog('Project Update Error', 'Project lookupcode already exists!.');
          return;
  
      } else {
          payload.LookupCode = projectLookupcode;
      }
  }
  
  
  if ($row.cells.project_name.editControl.isChanged) {
      payload.Name = $row.cells.project_name.editControl.value;
  }
  
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Projects', id: $row.entity.Id, entity: payload });
    
      $row.refresh();
  
  }
  
  catch (error) {
      
      $shell.ExcelProjectImport.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: ExcelProjectImport_excel_project_import_gridComponentRowModel,
  $grid: ExcelProjectImport_excel_project_import_gridComponent, 
    $shell: ExcelProjectImport_ShellService,
    $datasources: ExcelProjectImport_DatasourceService,
    $flows: ExcelProjectImport_FlowService,
    $reports: ExcelProjectImport_ReportService,
    $settings: SettingsValuesService,
    $operations: ExcelProjectImport_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: ExcelProjectImport_LocalizationService,
    $event: any
  ) {
  // Check required fields
  const allRequiredFieldsHaveValue = $utils.isAllDefined(
      $row.cells.project_lookupcode.editControl.value,
      $row.cells.owner_lookupcode.editControl.value
  );
  
  var errors = '';
  
  if (allRequiredFieldsHaveValue === false) {
      $shell.ExcelProjectImport.openErrorDialog('Save', 'Missing required fields');
      throw new Error('Missing required fields'); // To prevent display mode
  }
  else {
      try {
  
          var projectLookupcode = $row.cells.project_lookupcode.editControl.value;
          var ownerLookupcode = $row.cells.owner_lookupcode.editControl.value;
          var firstName = $row.cells.contact_first_name.editControl.value;
          var lastName = $row.cells.contact_last_name.editControl.value;
          var primaryEmail = $row.cells.contact_primary_email.editControl.value;
          var primaryTelephone = $row.cells.contact_primary_telephone.editControl.value;
          var contactNotes = $row.cells.contact_notes.editControl.value;
          var attentionOf = $row.cells.address_attention_of.editControl.value;
          var line1 = $row.cells.address_line1.editControl.value;
          var line2 = $row.cells.address_line2.editControl.value;
          var city = $row.cells.address_city.editControl.value;
          var state = $row.cells.address_state.editControl.value;
          var postalCode = $row.cells.address_postalcode.editControl.value;
          var country = $row.cells.address_country.editControl.value;
  
          const projects = (await $datasources.ExcelProjectImport.ds_get_project_by_lookupcode_ownerLookupcode.get({
              projectLookupcode: projectLookupcode,
              ownerLookupcode: ownerLookupcode
          })).result;
          if ($utils.isDefined(projects)) {
              errors += 'Duplicate project found';
              $row.cells.error.displayControl.text = errors;
  
              throw new Error('Project lookupcode already exists');
          }
  
          // Check lookupcode length
          if (projectLookupcode.length > 16) {
              errors += 'Project '
                  + projectLookupcode
                  + '  cannot exceed 16 characters'
              $row.cells.error.displayControl.text = errors;
              throw new Error('Project lookupcode length exceeded.');
  
          }
  
          if (ownerLookupcode.length > 16) {
              errors += 'Owner '
                  + ownerLookupcode
                  + '  cannot exceed 16 characters'
              $row.cells.error.displayControl.text = errors;
              throw new Error('Project lookupcode length exceeded.');
  
          }
  
          const owners = (await $datasources.ExcelProjectImport.ds_get_owner_by_lookupcode.get({ ownerLookupcode: ownerLookupcode })).result;
          if ($utils.isDefined(owners)) {
              var ownerId = owners.Id;
  
  
          }
  
          if (!$utils.isAllDefined(firstName, lastName, line1)
              && (
                  $utils.isDefined(primaryEmail) ||
                  $utils.isDefined(primaryTelephone) ||
                  $utils.isDefined(contactNotes) ||
                  $utils.isDefined(attentionOf) ||
                  $utils.isDefined(line2) ||
                  $utils.isDefined(city) ||
                  $utils.isDefined(state) ||
                  $utils.isDefined(postalCode) ||
                  $utils.isDefined(country)
              )
          ) {
              errors += 'Contact details provided but missing either first name, last name and or line 1' + ' : ';
  
          }
  
  
          let payload: any = {};
  
          var nextProjectId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Project" })).nextId;
  
          if ($utils.isDefined(ownerId)) {
              payload = {
                  "OwnerId": ownerId,
                  "Id": nextProjectId,
                  "LookupCode": projectLookupcode,
                  "Name": $utils.isDefined($row.cells.project_name.editControl.value) ? $row.cells.project_name.editControl.value : projectLookupcode,
                  "StatusId": 1,
                  "IsDefault": true,
                  "AllowOneSerialScanForFullLpPick": false,
                  "ControlledByGlobalMaterialCatalogs": false,
                  "ByPassAutoSelectSerialNumbersRestriction": false,
                  "ByPassShowSerialNumbersRestriction": false,
                  "ByPassReverseSelectSerialNumbersRestriction": false,
                  "MobileUseNonBlockingPickConfirmation": false,
                  "MobilePickSlipSearchMask": 2047,
                  "ReceivingAutoCreateSerialSuppressScreen": false,
                  "PickingAutoSelectSerialSuppressScreen": false,
                  "NewOrderApprovalRequired": false,
                  "AllowMultipleShippingLpsPerShippingContainer": false,
                  "SuppressBatchLoading": false,
                  "SkipAdjustmentWhenPicking": false,
                  "MobileRequireLicensePlateMoveBlindCount": false,
                  "MobileHideTotalOnSerialNumberSelection": false,
                  "HandleContainerCode": false
              }
  
  
          }
          else {
              var nextOwnerId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Owner" })).nextId;
  
              payload = {
                  "Owner": {
                      "Id": nextOwnerId,
                      "EnterpriseId": 1,
                      "StatusId": 1,
                      "LookupCode": ownerLookupcode,
                      "Name": $utils.isDefined($row.cells.owner_name.editControl.value) ? $row.cells.owner_name.editControl.value : ownerLookupcode,
                      "Notes": '',
                      "MobileAutoPopulateActualQuantityOnPickTask": false,
                      "MobileAutoPopulateActualQuantityOnManufacturingMoveTask": false,
                      "MobilePickingRequireMaterialUPCScan": false,
                      "MobilePickingIncrementQuantityDuringMaterialUpcScaning": false,
                      "MobilePickingAutoSelectNextSequentialPickTask": false,
                      "MobilePickingAutoSelectNextSequentialClusterPickTask": false,
                      "MobilePickingAutoPopulateTargetLicensePlate": false,
                      "MobileAutoPopulateBatchMovesTargetLocation": false,
                      "MobileAutoPopulateReplenishmentTargetLocation": false,
                      "MobileClearReceiptNumberOnPartialReceipts": false,
                      "MobileDefaultCursorIntoReceiptNumberDuringInitialReceiving": false,
                      "DefaultReceivingQuantityToMaterialPalletHighAndTieCalculation": false,
                      "DisableWaveValidation": false,
                      "MobilePutAwayIncrementQuantityDuringMaterialUpcScaning": false,
                      "MobileReceivingMustUpdateSerialNumber": false,
                      "MobileLpMoveIncrementQuantityDuringMaterialUpcScaning": false,
                      "EPortalRejectOutboundOrderCreationIfNotEnoughAvailableInventory": false,
                      "MobilePickingRequireLotScan": false,
                      "MobileCountBackRequired": false,
                      "MobilePackingIncQtyInMaterialUpcScan": false,
                      "MobilePackingRestrictManualSelectMaterialViaF4": false,
                      "MobilePickingShowReportingPackaging": false,
                      "MobilePickingAutoSelectPickSlipOnSingleFound": false,
                      "MobilePickingSkipSourceLooseLocationScanning": false,
                      "MobileBlindReceivingAutoGenerateLicensePlate": false,
                      "MobileInventoryMoveRequireMaterialUPCScan": false,
                      "MobileInventoryMoveIncrementQuantityDuringMaterialUpcScaning": false,
                      "MobileManufacturingOrdersInventoryMoveRequireMaterialUPCScan": false,
                      "MobileManufacturingOrdersInventoryMoveIncrementQuantityDuringMaterialUpcScaning": false,
                      "MobileReplenishmentRequireMaterialUPCScan": false,
                      "MobileReplenishmentIncrementQuantityDuringMaterialUpcScaning": false,
                      "MobileBatchMoveRequireMaterialUPCScan": false,
                      "MobileBatchMoveIncrementQuantityDuringMaterialUpcScaning": false,
                      "MobilePickingAutoConfirmAndLoad": false,
                      "MobileFullLpPickingAutoConfirmAndLoad": false,
                      "MobilePickingIncrementQuantityDuringLotScan": false,
                      "DesctopManufacturingFeedbackAutoGenerateLp": false,
                      "MobilePickRequireLocationScan": false,
                      "MobilePickingDoNotChangeTargetLPWhenShipmentIsCartonized": false,
                      "MobileReceivingRequireSelectPackaging": false,
                      "MobilePickingAutoSelectNextSequentialManualAllocation": false,
                      "MobileReceivingQuantiyOnlyFromParser": false
                  },
                  "Id": nextProjectId,
                  "LookupCode": $row.cells.project_lookupcode.editControl.value,
                  "Name": $utils.isDefined($row.cells.project_name.editControl.value) ? $row.cells.project_name.editControl.value : $row.cells.project_lookupcode.editControl.value,
                  "StatusId": 1,
                  "IsDefault": true,
                  "AllowOneSerialScanForFullLpPick": false,
                  "ControlledByGlobalMaterialCatalogs": false,
                  "ByPassAutoSelectSerialNumbersRestriction": false,
                  "ByPassShowSerialNumbersRestriction": false,
                  "ByPassReverseSelectSerialNumbersRestriction": false,
                  "MobileUseNonBlockingPickConfirmation": false,
                  "MobilePickSlipSearchMask": 2047,
                  "ReceivingAutoCreateSerialSuppressScreen": false,
                  "PickingAutoSelectSerialSuppressScreen": false,
                  "NewOrderApprovalRequired": false,
                  "AllowMultipleShippingLpsPerShippingContainer": false,
                  "SuppressBatchLoading": false,
                  "SkipAdjustmentWhenPicking": false,
                  "MobileRequireLicensePlateMoveBlindCount": false,
                  "MobileHideTotalOnSerialNumberSelection": false,
                  "HandleContainerCode": false
              }
  
          }
  
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'Projects', entity: payload });
  
          $row.entity.OwnerId = ownerId ? ownerId : nextOwnerId;
          $row.entity.Id = nextProjectId;
  
          // Handle contact and address creation
  
  
          if ($utils.isAllDefined($row.cells.address_line1.editControl.value,
              $row.cells.contact_first_name.editControl.value,
              $row.cells.contact_last_name.editControl.value)) {
  
              const nextAddressId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Address" })).nextId;
              const payloadAddress = {
                  "Id": nextAddressId,
                  "Line1": $row.cells.address_line1.editControl.value,
                  "Line2": $row.cells.address_line2.editControl.value,
                  "City": $row.cells.address_city.editControl.value,
                  "State": $row.cells.address_state.editControl.value,
                  "PostalCode": $row.cells.address_postalcode.editControl.value,
                  "Country": $row.cells.address_country.editControl.value,
                  "AttentionOf": $row.cells.address_attention_of.editControl.value
              };
  
              await $flows.Utilities.crud_create_flow({ entitySet: 'Addresses', entity: payloadAddress });
  
              const payloadContacts = {
                  "Id": $row.entity.ProjectsContactsLookup[0].ContactId,
                  "OwnerEntity": 'Project',
                  "OwnerId": $row.entity.Id,
                  "TypeId": 1,
                  "AddressId": nextAddressId,
                  "FirstName": $row.cells.contact_first_name.editControl.value,
                  "LastName": $row.cells.contact_last_name.editControl.value,
                  "PrimaryEmail": $row.cells.contact_primary_email.editControl.value,
                  "PrimaryTelephone": $row.cells.contact_primary_telephone.editControl.value,
                  "Notes": $row.cells.contact_notes.editControl.value
              };
              await $flows.Utilities.crud_create_flow({ entitySet: 'Contacts', entity: payloadContacts });
  
          }
  
          $row.refresh();
          $row.cells.error.displayControl.text = '';
  
          $row.cells.owner_lookupcode.editControl.readOnly = true;
          $row.cells.owner_name.editControl.readOnly = true;
          $row.cells.address_line1.editControl.readOnly = true;
          $row.cells.address_line2.editControl.readOnly = true;
          $row.cells.address_city.editControl.readOnly = true;
          $row.cells.address_state.editControl.readOnly = true;
          $row.cells.address_postalcode.editControl.readOnly = true;
          $row.cells.address_country.editControl.readOnly = true;
          $row.cells.address_attention_of.editControl.readOnly = true;
          $row.cells.contact_first_name.editControl.readOnly = true;
          $row.cells.contact_last_name.editControl.readOnly = true;
          $row.cells.contact_primary_email.editControl.readOnly = true;
          $row.cells.contact_primary_telephone.editControl.readOnly = true;
          $row.cells.contact_notes.editControl.readOnly = true;
  
  
      }
      catch (error) {
          $shell.ExcelProjectImport.openErrorDialog('Save', 'Error on save');
          throw error; // To prevent display mode
      }
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'ExcelProjectImport-excel_project_import_grid',
  templateUrl: './ExcelProjectImport.excel_project_import_grid.component.html'
})
export class ExcelProjectImport_excel_project_import_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IExcelProjectImport_excel_project_import_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);




  //#region Variables
  vars: { projectId?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Id: new GridHeaderModel(new HeaderStyles(null, null), 'Id', false, false, null),       error: new GridHeaderModel(new HeaderStyles(null, null), 'Errors', false, false, null),       project_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Project lookup', true, false, null),       project_name: new GridHeaderModel(new HeaderStyles(null, null), 'Project name', false, false, null),       owner_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Owner lookup', true, false, null),       owner_name: new GridHeaderModel(new HeaderStyles(null, null), 'Owner name', false, false, null),       contact_first_name: new GridHeaderModel(new HeaderStyles(null, null), 'First name', false, false, null),       contact_last_name: new GridHeaderModel(new HeaderStyles(null, null), 'Last name', false, false, null),       contact_primary_email: new GridHeaderModel(new HeaderStyles(null, null), 'Primary email', false, false, null),       contact_primary_telephone: new GridHeaderModel(new HeaderStyles(null, null), 'Primary telephone', false, false, null),       contact_notes: new GridHeaderModel(new HeaderStyles(null, null), 'Contact notes', false, false, null),       address_attention_of: new GridHeaderModel(new HeaderStyles(null, null), 'Attention of', false, false, null),       address_line1: new GridHeaderModel(new HeaderStyles(null, null), 'Line1', false, false, null),       address_line2: new GridHeaderModel(new HeaderStyles(null, null), 'Line2', false, false, null),       address_city: new GridHeaderModel(new HeaderStyles(null, null), 'City', false, false, null),       address_state: new GridHeaderModel(new HeaderStyles(null, null), 'State', false, false, null),       address_postalcode: new GridHeaderModel(new HeaderStyles(null, null), 'Postal code', false, false, null),       address_country: new GridHeaderModel(new HeaderStyles(null, null), 'Country', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: ExcelProjectImport_excel_project_import_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;


  topToolbar = {
      linecount: new ToolModel(new ButtonModel('linecount', new ButtonStyles(null, null), true, 'Line count', '')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: ExcelProjectImport_ShellService,
    private datasources: ExcelProjectImport_DatasourceService,
    private flows: ExcelProjectImport_FlowService,
    private reports: ExcelProjectImport_ReportService,
    private localization: ExcelProjectImport_LocalizationService,
    private operations: ExcelProjectImport_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Project import';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      projectId:  $grid.vars.projectId ,
    };
    try {
    const data = await this.datasources.ExcelProjectImport.ds_project_import_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new ExcelProjectImport_excel_project_import_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  async addRow(entity?: IExcelProjectImport_excel_project_import_gridComponentEntity) {
    const row = new ExcelProjectImport_excel_project_import_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_excel_export(event = null) {
    return this.on_excel_exportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_exportInternal(
    $grid: ExcelProjectImport_excel_project_import_gridComponent,
  
    $shell: ExcelProjectImport_ShellService,
    $datasources: ExcelProjectImport_DatasourceService,
    $flows: ExcelProjectImport_FlowService,
    $reports: ExcelProjectImport_ReportService,
    $settings: SettingsValuesService,
    $operations: ExcelProjectImport_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: ExcelProjectImport_LocalizationService,
    $event: any
  ) {
  const wb = $event;
  
  const projects = [];
  
  projects.push({
  
  ProjectLookupCode: 'Required',	
  ProjectName: '',	
  OwnerLookupCode: 'Required',	
  OwnerName: '',		
  FirstName: 'Required if entering in contact',	
  LastName: 'Required if entering in contact',	
  PrimaryEmail: '',
  PrimaryTelephone: '',	
  ContactNotes: '',	
  AttentionOf: '',
  Line1: 'Required if entering in contact',	
  Line2: '',	
  City: '',	
  State: '',	
  PostalCode: '',	
  Country: ''
  });
  
  
  const ws = $utils.excel.json_to_sheet(projects);
  
  $utils.excel.book_append_sheet(wb, ws, 'ProjectImport');
   
     
  
  }
  on_excel_import(event = null) {
    return this.on_excel_importInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_importInternal(
    $grid: ExcelProjectImport_excel_project_import_gridComponent,
  
    $shell: ExcelProjectImport_ShellService,
    $datasources: ExcelProjectImport_DatasourceService,
    $flows: ExcelProjectImport_FlowService,
    $reports: ExcelProjectImport_ReportService,
    $settings: SettingsValuesService,
    $operations: ExcelProjectImport_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: ExcelProjectImport_LocalizationService,
    $event: any
  ) {
  
  
  const wb = $event;
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  
  $grid.topToolbar.linecount.hidden = false;
  $grid.topToolbar.linecount.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  $grid.topToolbar.linecount.control.label = 'Extracting Data..';
  
  const Projects = $utils.excel.sheet_to_json<{
      ProjectLookupCode: string,
      ProjectName: string,
      OwnerLookupCode: string,
      OwnerName: string,
      FirstName: string,
      LastName: string,
      PrimaryEmail: string,
      PrimaryTelephone: string,
      ContactNotes: string,
      AttentionOf: string,
      Line1: string,
      Line2: string,
      City: string,
      State: string,
      PostalCode: string,
      Country: string
  
  }>(ws);
  
  $grid.topToolbar.linecount.control.label = 'Preparing Data...';
  
  // Initialize grid and data
  const candidates = [];
  var linecount = 0;
  var confirmLineCount = 0;
  var orderCheckLineCount = 0;
  var totalLineCount = Projects.length;
  
  if (totalLineCount > 500) {
      $grid.topToolbar.linecount.hidden = true;
      throw new Error('Excel file cannot exceed 500 rows, please break file into smaller groups of projects.')
  }
  
  var projectsLookupCode = Projects.filter(ol => $utils.isDefinedTrimmed(ol.ProjectLookupCode?.toString())).map(ol => ol.ProjectLookupCode?.toString());
  var ownersLookupCode = Projects.filter(ol => $utils.isDefinedTrimmed(ol.OwnerLookupCode?.toString())).map(ol => ol.OwnerLookupCode?.toString());
  
  
  var projects = (await $datasources.ExcelProjectImport.ds_get_projects_by_lookupcode_ownerLookupcode.get({
      projectLookupcodes: projectsLookupCode,
      ownerLookupcodes: ownersLookupCode
  
  })).result;
  
  var owners = (await $datasources.ExcelProjectImport.ds_get_owners_by_lookupcode.get({
      ownerLookupcodes: ownersLookupCode
  
  })).result;
  
  
  
  for (const Project of Projects) {
  
  
      linecount = linecount + 1;
      $grid.topToolbar.linecount.control.label = 'Validating Line ' + linecount.toString() + ' of ' + totalLineCount.toString();
  
      var errors = '';
  
  
  
      var nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Project" })).nextId;
      const nextContactId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Contact" })).nextId;
  
      const row = await $grid.addRow({
          Id: nextId,
          ProjectsContactsLookup: [
              {
                  ContactId: nextContactId
  
              }]
      })
  
      var projectLookupcode = Project.ProjectLookupCode?.toString();
      var projectName = Project.ProjectName?.toString();
      var ownerLookupcode = Project.OwnerLookupCode?.toString();
      var ownerName = Project.OwnerName?.toString();
  
      row.cells.project_lookupcode.editControl.value = projectLookupcode;
      row.cells.project_name.editControl.value = projectName;
      row.cells.owner_lookupcode.editControl.value = ownerLookupcode;
      row.cells.owner_name.editControl.value = ownerName;
  
      row.cells.project_lookupcode.displayControl.text = projectLookupcode;
      row.cells.project_name.displayControl.text = projectName;
      row.cells.owner_lookupcode.displayControl.text = ownerLookupcode;
      row.cells.owner_name.displayControl.text = ownerName;
  
  
      // Check lookupcode lengthfi
      if (projectLookupcode.length > 16) {
          errors += 'Project '
              + projectLookupcode
              + ' cannot exceed 16 characters'
      }
  
      if (ownerLookupcode.length > 16) {
          errors += 'Owner '
              + ownerLookupcode
              + ' cannot exceed 16 characters'
      }
  
      // Check if the combination of the provided project and owner exist.
      if ($utils.isAllDefined(ownerLookupcode, projectLookupcode)) {
          const projectFound = projects.find(
              p => p.LookupCode === projectLookupcode
                  && p.Owner.LookupCode === ownerLookupcode
  
          );
          if ($utils.isDefined(projectFound)) {
  
  
              errors += 'Project '
                  + projectLookupcode
                  + ', Owner '
                  + ownerLookupcode
                  + ' already exists' + ' : ';
  
  
  
          } else {
  
  
          }
      } else {
          errors += 'Owner lookupcode and or project lookupcode not provided' + ' : ';
  
  
      }
  
      // Check if only the owner exists
      if ($utils.isDefined(ownerLookupcode)) {
          const ownerFound = owners.find(o => o.LookupCode === ownerLookupcode);
  
          if ($utils.isDefined(ownerFound)) {
  
              row.entity.OwnerId = ownerFound.Id;
  
  
          } else {
  
  
          }
  
      }
  
      var firstName = $utils.isDefined(Project.FirstName) ? Project.FirstName.toString() : null;
      var lastName = $utils.isDefined(Project.LastName) ? Project.LastName.toString() : null;
      var email = $utils.isDefined(Project.PrimaryEmail) ? Project.PrimaryEmail.toString() : null;
      var telephone = $utils.isDefined(Project.PrimaryTelephone) ? Project.PrimaryTelephone.toString() : null;
      var notes = $utils.isDefined(Project.ContactNotes) ? Project.ContactNotes.toString() : null;
      var attention = $utils.isDefined(Project.AttentionOf) ? Project.AttentionOf.toString() : null;
      var line1 = $utils.isDefined(Project.Line1) ? Project.Line1.toString() : null;
      var line2 = $utils.isDefined(Project.Line2) ? Project.Line2.toString() : null;
      var city = $utils.isDefined(Project.City) ? Project.City.toString() : null;
      var state = $utils.isDefined(Project.State) ? Project.State.toString() : null;
      var postal = $utils.isDefined(Project.PostalCode) ? Project.PostalCode.toString() : null;
      var country = $utils.isDefined(Project.Country) ? Project.Country.toString() : null;
  
       if (firstName?.length > 32) {
          errors += 'First name '
              + firstName
              + ' cannot exceed 32 characters'
      }
  
       if (lastName?.length > 32) {
          errors += 'Last name '
              + lastName
              + ' cannot exceed 32 characters'
      }
  
      row.cells.contact_first_name.displayControl.text = firstName;
      row.cells.contact_last_name.displayControl.text = lastName;
      row.cells.contact_primary_email.displayControl.text = email;
      row.cells.contact_primary_telephone.displayControl.text = telephone;
      row.cells.contact_notes.displayControl.text = notes;
      row.cells.address_attention_of.displayControl.text = attention;
      row.cells.address_line1.displayControl.text = line1;
      row.cells.address_line2.displayControl.text = line2;
      row.cells.address_city.displayControl.text = city;
      row.cells.address_state.displayControl.text = state;
      row.cells.address_postalcode.displayControl.text = postal;
      row.cells.address_country.displayControl.text = country;
  
  
      row.cells.contact_first_name.editControl.value = firstName;
      row.cells.contact_last_name.editControl.value = lastName;
      row.cells.contact_primary_email.editControl.value = email;
      row.cells.contact_primary_telephone.editControl.value = telephone;
      row.cells.contact_notes.editControl.value = notes;
      row.cells.address_attention_of.editControl.value = attention;
      row.cells.address_line1.editControl.value = line1;
      row.cells.address_line2.editControl.value = line2;
      row.cells.address_city.editControl.value = city;
      row.cells.address_state.editControl.value = state;
      row.cells.address_postalcode.editControl.value = postal;
      row.cells.address_country.editControl.value = country;
  
      if (!$utils.isAllDefined(Project.FirstName, Project.LastName, Project.Line1)
          && (
              $utils.isDefined(Project.PrimaryEmail) ||
              $utils.isDefined(Project.PrimaryTelephone) ||
              $utils.isDefined(Project.ContactNotes) ||
              $utils.isDefined(Project.AttentionOf) ||
              $utils.isDefined(Project.Line2) ||
              $utils.isDefined(Project.City) ||
              $utils.isDefined(Project.State) ||
              $utils.isDefined(Project.PostalCode) ||
              $utils.isDefined(Project.Country)
          )
      ) {
          errors += 'Contact details provided but missing either first name, last name and or line 1' + ' : ';
  
      }
  
  
  
  
  
  
      $grid.topToolbar.linecount.control.label = 'Validated Line ' + linecount.toString() + ' of ' + totalLineCount.toString();
  
      row.cells.error.displayControl.text = errors;
  
      candidates.push({ row: row, errors: errors });
  
  
  }
  
  
  
  $grid.topToolbar.linecount.control.label = 'Updating Grid...'
  var totalConfirmLineCount = candidates.length;
  
  for (const candidate of candidates) {
      const noErrors = candidate.errors === '';
      if (noErrors) {
  
          try {
  
              confirmLineCount = confirmLineCount + 1;
              $grid.topToolbar.linecount.control.label = 'Creating Project ' + confirmLineCount.toString() + ' of ' + totalConfirmLineCount.toString();
  
              let payload: any = {};
              if ($utils.isDefined(candidate.row.entity.OwnerId)) {
                  payload = {
                      "OwnerId": candidate.row.entity.OwnerId,
                      "Id": candidate.row.entity.Id,
                      "LookupCode": candidate.row.cells.project_lookupcode.editControl.value,
                      "Name": $utils.isDefined(candidate.row.cells.project_name.editControl.value) ? candidate.row.cells.project_name.editControl.value : candidate.row.cells.project_lookupcode.editControl.value,
                      "StatusId": 1,
                      "IsDefault": true,
                      "AllowOneSerialScanForFullLpPick": false,
                      "ControlledByGlobalMaterialCatalogs": false,
                      "ByPassAutoSelectSerialNumbersRestriction": false,
                      "ByPassShowSerialNumbersRestriction": false,
                      "ByPassReverseSelectSerialNumbersRestriction": false,
                      "MobileUseNonBlockingPickConfirmation": false,
                      "MobilePickSlipSearchMask": 2047,
                      "ReceivingAutoCreateSerialSuppressScreen": false,
                      "PickingAutoSelectSerialSuppressScreen": false,
                      "NewOrderApprovalRequired": false,
                      "AllowMultipleShippingLpsPerShippingContainer": false,
                      "SuppressBatchLoading": false,
                      "SkipAdjustmentWhenPicking": false,
                      "MobileRequireLicensePlateMoveBlindCount": false,
                      "MobileHideTotalOnSerialNumberSelection": false,
                      "HandleContainerCode": false
                  }
  
  
              }
              else {
                  var nextOwnerId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Owner" })).nextId;
  
                  payload = {
                      "Owner": {
                          "Id": nextOwnerId,
                          "EnterpriseId": 1,
                          "StatusId": 1,
                          "LookupCode": candidate.row.cells.owner_lookupcode.editControl.value,
                          "Name": $utils.isDefined(candidate.row.cells.owner_name.editControl.value) ? candidate.row.cells.owner_name.editControl.value : candidate.row.cells.owner_lookupcode.editControl.value,
                          "Notes": '',
                          "MobileAutoPopulateActualQuantityOnPickTask": false,
                          "MobileAutoPopulateActualQuantityOnManufacturingMoveTask": false,
                          "MobilePickingRequireMaterialUPCScan": false,
                          "MobilePickingIncrementQuantityDuringMaterialUpcScaning": false,
                          "MobilePickingAutoSelectNextSequentialPickTask": false,
                          "MobilePickingAutoSelectNextSequentialClusterPickTask": false,
                          "MobilePickingAutoPopulateTargetLicensePlate": false,
                          "MobileAutoPopulateBatchMovesTargetLocation": false,
                          "MobileAutoPopulateReplenishmentTargetLocation": false,
                          "MobileClearReceiptNumberOnPartialReceipts": false,
                          "MobileDefaultCursorIntoReceiptNumberDuringInitialReceiving": false,
                          "DefaultReceivingQuantityToMaterialPalletHighAndTieCalculation": false,
                          "DisableWaveValidation": false,
                          "MobilePutAwayIncrementQuantityDuringMaterialUpcScaning": false,
                          "MobileReceivingMustUpdateSerialNumber": false,
                          "MobileLpMoveIncrementQuantityDuringMaterialUpcScaning": false,
                          "EPortalRejectOutboundOrderCreationIfNotEnoughAvailableInventory": false,
                          "MobilePickingRequireLotScan": false,
                          "MobileCountBackRequired": false,
                          "MobilePackingIncQtyInMaterialUpcScan": false,
                          "MobilePackingRestrictManualSelectMaterialViaF4": false,
                          "MobilePickingShowReportingPackaging": false,
                          "MobilePickingAutoSelectPickSlipOnSingleFound": false,
                          "MobilePickingSkipSourceLooseLocationScanning": false,
                          "MobileBlindReceivingAutoGenerateLicensePlate": false,
                          "MobileInventoryMoveRequireMaterialUPCScan": false,
                          "MobileInventoryMoveIncrementQuantityDuringMaterialUpcScaning": false,
                          "MobileManufacturingOrdersInventoryMoveRequireMaterialUPCScan": false,
                          "MobileManufacturingOrdersInventoryMoveIncrementQuantityDuringMaterialUpcScaning": false,
                          "MobileReplenishmentRequireMaterialUPCScan": false,
                          "MobileReplenishmentIncrementQuantityDuringMaterialUpcScaning": false,
                          "MobileBatchMoveRequireMaterialUPCScan": false,
                          "MobileBatchMoveIncrementQuantityDuringMaterialUpcScaning": false,
                          "MobilePickingAutoConfirmAndLoad": false,
                          "MobileFullLpPickingAutoConfirmAndLoad": false,
                          "MobilePickingIncrementQuantityDuringLotScan": false,
                          "DesctopManufacturingFeedbackAutoGenerateLp": false,
                          "MobilePickRequireLocationScan": false,
                          "MobilePickingDoNotChangeTargetLPWhenShipmentIsCartonized": false,
                          "MobileReceivingRequireSelectPackaging": false,
                          "MobilePickingAutoSelectNextSequentialManualAllocation": false,
                          "MobileReceivingQuantiyOnlyFromParser": false
                      },
                      "Id": candidate.row.entity.Id,
                      "LookupCode": candidate.row.cells.project_lookupcode.editControl.value,
                      "Name": $utils.isDefined(candidate.row.cells.project_name.editControl.value) ? candidate.row.cells.project_name.editControl.value : candidate.row.cells.project_lookupcode.editControl.value,
                      "StatusId": 1,
                      "IsDefault": true,
                      "AllowOneSerialScanForFullLpPick": false,
                      "ControlledByGlobalMaterialCatalogs": false,
                      "ByPassAutoSelectSerialNumbersRestriction": false,
                      "ByPassShowSerialNumbersRestriction": false,
                      "ByPassReverseSelectSerialNumbersRestriction": false,
                      "MobileUseNonBlockingPickConfirmation": false,
                      "MobilePickSlipSearchMask": 2047,
                      "ReceivingAutoCreateSerialSuppressScreen": false,
                      "PickingAutoSelectSerialSuppressScreen": false,
                      "NewOrderApprovalRequired": false,
                      "AllowMultipleShippingLpsPerShippingContainer": false,
                      "SuppressBatchLoading": false,
                      "SkipAdjustmentWhenPicking": false,
                      "MobileRequireLicensePlateMoveBlindCount": false,
                      "MobileHideTotalOnSerialNumberSelection": false,
                      "HandleContainerCode": false
                  }
  
                  candidate.row.entity.OwnerId = nextOwnerId;
  
                  candidates
                      .filter(c => c.row.cells.owner_lookupcode.editControl.value.trim().toUpperCase() == candidate.row.cells.owner_lookupcode.editControl.value.trim().toUpperCase() && !$utils.isDefined(c.row.entity.OwnerId))
                      .forEach(c => c.row.entity.OwnerId = nextOwnerId);
              }
  
  
  
              await $flows.Utilities.crud_create_flow({ entitySet: 'Projects', entity: payload });
  
              // Handle contact and address creation
  
  
              if ($utils.isAllDefined(candidate.row.cells.address_line1.editControl.value,
                  candidate.row.cells.contact_first_name.editControl.value,
                  candidate.row.cells.contact_last_name.editControl.value)) {
  
                  const nextAddressId = (await $flows.Utilities.reserve_nextId_flow({ entity: "Address" })).nextId;
                  const payloadAddress = {
                      "Id": nextAddressId,
                      "Line1": candidate.row.cells.address_line1.editControl.value,
                      "Line2": candidate.row.cells.address_line2.editControl.value,
                      "City": candidate.row.cells.address_city.editControl.value,
                      "State": candidate.row.cells.address_state.editControl.value,
                      "PostalCode": candidate.row.cells.address_postalcode.editControl.value,
                      "Country": candidate.row.cells.address_country.editControl.value,
                      "AttentionOf": candidate.row.cells.address_attention_of.editControl.value,
                  };
  
                  await $flows.Utilities.crud_create_flow({ entitySet: 'Addresses', entity: payloadAddress });
  
  
  
                  const payloadContacts = {
                      "Id": candidate.row.entity.ProjectsContactsLookup[0].ContactId,
                      "OwnerEntity": 'Project',
                      "OwnerId": candidate.row.entity.Id,
                      "TypeId": 1,
                      "AddressId": nextAddressId,
                      "FirstName": candidate.row.cells.contact_first_name.editControl.value,
                      "LastName": candidate.row.cells.contact_last_name.editControl.value,
                      "PrimaryEmail": candidate.row.cells.contact_primary_email.editControl.value,
                      "PrimaryTelephone": candidate.row.cells.contact_primary_telephone.editControl.value,
                      "Notes": candidate.row.cells.contact_notes.editControl.value
                  };
                  await $flows.Utilities.crud_create_flow({ entitySet: 'Contacts', entity: payloadContacts });
  
              }
  
  
  
              candidate.row.refresh();
              candidate.row.setDisplayMode();
              candidate.row.isNew = false;
  
              candidate.row.cells.owner_lookupcode.editControl.readOnly = true;
              candidate.row.cells.owner_name.editControl.readOnly = true;
              candidate.row.cells.address_line1.editControl.readOnly = true;
              candidate.row.cells.address_line2.editControl.readOnly = true;
              candidate.row.cells.address_city.editControl.readOnly = true;
              candidate.row.cells.address_state.editControl.readOnly = true;
              candidate.row.cells.address_postalcode.editControl.readOnly = true;
              candidate.row.cells.address_country.editControl.readOnly = true;
              candidate.row.cells.address_attention_of.editControl.readOnly = true;
              candidate.row.cells.contact_first_name.editControl.readOnly = true;
              candidate.row.cells.contact_last_name.editControl.readOnly = true;
              candidate.row.cells.contact_primary_email.editControl.readOnly = true;
              candidate.row.cells.contact_primary_telephone.editControl.readOnly = true;
              candidate.row.cells.contact_notes.editControl.readOnly = true;
  
  
          }
  
          catch (error) {
  
  
              errors += 'Error calling crud creation flow.'
              candidate.row.cells.error.displayControl.text = errors;
  
              $grid.topToolbar.linecount.hidden = true;
              throw error; // to prevent displayMode 
          }
      }
  
  }
  
  $grid.topToolbar.linecount.hidden = true;
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: ExcelProjectImport_excel_project_import_gridComponent,
  
    $shell: ExcelProjectImport_ShellService,
    $datasources: ExcelProjectImport_DatasourceService,
    $flows: ExcelProjectImport_FlowService,
    $reports: ExcelProjectImport_ReportService,
    $settings: SettingsValuesService,
    $operations: ExcelProjectImport_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: ExcelProjectImport_LocalizationService,
    $event: any
  ) {
  $grid.vars.projectId = 0
  
  $grid.topToolbar.linecount.hidden = true;
  
  
  
  }
  //#endregion private flows

  $importExcel(fileInput: HTMLInputElement) {
    if(fileInput.files?.length !== 1) {
      throw new Error('Cannot import multiple files');
    }
    const file = fileInput.files[0];
    if(file.size > 15 * 1000 * 1000) {
      throw new Error('Cannot import a file with size greater than 15MB');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const ab: ArrayBuffer = e.target?.result as ArrayBuffer;
      const wb: WorkBook = readExcelFile(ab);
      this.on_excel_import(wb);
    };
    reader.readAsArrayBuffer(file);
    fileInput.value = '';
  }

  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export(wb);

    writeExcelFile(wb, 'ExcelProjectImport_excel_project_import_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
