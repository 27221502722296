import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Discussions_ShellService, EModalSize, EToasterType, EToasterPosition } from './Discussions.shell.service';
import { Discussions_OperationService } from './Discussions.operation.service';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { Discussions_FlowService } from './Discussions.flow.index';
import { Discussions_ReportService } from './Discussions.report.index';
import { Discussions_LocalizationService } from './Discussions.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Discussions.frontend.types'
import { $frontendTypes as $types} from './Discussions.frontend.types' 



interface IDiscussions_discussions_gridComponentEntity {
id?: string, entity_name?: string, entity_id?: string, reference?: string, discussion?: string, created_by?: string, created_on?: string, read_on?: string, alerted_on?: string, application_id?: string}

interface IDiscussions_discussions_gridComponentInParams {
  entity_name?: string, entity_id?: string, title?: string, application_id?: string, reference?: string}


class Discussions_discussions_gridComponentRowModel extends GridRowModel {
  grid: Discussions_discussions_gridComponent;
  entity: IDiscussions_discussions_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    discussion_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    discussion: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['discussion_edit'] as DatexFormControl, null, false, '')
      ),
    created_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    entity_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    entity_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    read_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    alerted_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    application_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Discussions_ShellService,
private datasources: Discussions_DatasourceService,
private flows: Discussions_FlowService,
private reports: Discussions_ReportService,
private localization: Discussions_LocalizationService,
private operations: Discussions_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Discussions_discussions_gridComponent, entity: IDiscussions_discussions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Discussions_discussions_gridComponent, entity?: IDiscussions_discussions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      entity_name:  $grid.inParams.entity_name ,
      entity_id:  $grid.inParams.entity_id ,
      discussion:  $grid.fullTextSearch ,
      application_id:  $grid.inParams.application_id ,
      read_on:  ($grid.filters.view_unread.control.value === true) ? "unread" : "all" ,
    };
    const data = await this.datasources.Discussions.ds_storage_discussions.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.discussion.displayControl as TextModel).text = $row.entity.discussion;
    (this.cells.discussion.editControl as TextBoxModel).reset($row.entity.discussion);
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.created_by;
    (this.cells.reference.displayControl as TextModel).text = $row.entity.reference;
    (this.cells.entity_name.displayControl as TextModel).text = $row.entity.entity_name;
    (this.cells.entity_id.displayControl as TextModel).text = $row.entity.entity_id;
    (this.cells.application_id.displayControl as TextModel).text = $row.entity.application_id;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_existing_row();
      this.isNew = false;
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'entity_id' : {
        this.on_entity_id_click();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Discussions_discussions_gridComponentRowModel,
  $grid: Discussions_discussions_gridComponent, 
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
    this.logger.log('Discussions', 'discussions_grid.on_row_data_loaded');
  //O.Arias - 02/13/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.created_on)) {
          $row.cells.created_on.displayControl.text = $utils.date.format($row.entity.created_on, format);
      };
      if ($utils.isDefined($row.entity.read_on)) {
          $row.cells.read_on.displayControl.text = $utils.date.format($row.entity.read_on, format);
      };
      if ($utils.isDefined($row.entity.alerted_on)) {
          $row.cells.alerted_on.displayControl.text = $utils.date.format($row.entity.alerted_on, format);
      };
  };
  
  if (!$utils.isDefined($row.entity.read_on)) {
  
      const style_nameAndUnit: string = 'font-weight';
      const style_value: string = 'bold';
  
      $row.cells.alerted_on.styles.setStyle(style_nameAndUnit, style_value);
      $row.cells.discussion.styles.setStyle(style_nameAndUnit, style_value);
      $row.cells.created_by.styles.setStyle(style_nameAndUnit, style_value);
      $row.cells.created_on.styles.setStyle(style_nameAndUnit, style_value);
      $row.cells.entity_id.styles.setStyle(style_nameAndUnit, style_value);
      $row.cells.entity_name.styles.setStyle(style_nameAndUnit, style_value);
      $row.cells.application_id.styles.setStyle(style_nameAndUnit, style_value);
      $row.cells.read_on.styles.setStyle(style_nameAndUnit, style_value);
  };
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Discussions_discussions_gridComponentRowModel,
  $grid: Discussions_discussions_gridComponent, 
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
    this.logger.log('Discussions', 'discussions_grid.on_save_existing_row');
  //O.Arias - 02/13/2024
  
  const allRequiredFieldHaveValue =
      $utils.isDefined($grid.inParams.entity_id) &&
      $utils.isDefined($grid.inParams.entity_name) &&
      $utils.isDefined($row.cells.discussion.editControl.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Discussions.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  };
  
  if ($row.cells.discussion.editControl.isChanged) {
  
      let id: string = $row.entity?.id ?? "";
      let entity_id: string = $grid.inParams.entity_id;
      let entity_name: string = $grid.inParams.entity_name;
      let reference: string = $grid.inParams.reference;
      let application_id: string = $grid.inParams.application_id;
      let discussion: string = $row.cells.discussion.editControl.value;
  
      try {
  
          await $flows.Discussions.storage_discussions({
              action: "Write",
              payload: {
                  id: id,
                  entity_id: entity_id,
                  entity_name: entity_name,
                  reference: reference,
                  discussion: discussion,
                  application_id: application_id
              }
          });
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.Discussions.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      };
  };
  }
  on_entity_id_click(event = null) {
    return this.on_entity_id_clickInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_entity_id_clickInternal(
    $row: Discussions_discussions_gridComponentRowModel,
  $grid: Discussions_discussions_gridComponent, 
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
    this.logger.log('Discussions', 'discussions_grid.on_entity_id_click');
  //O.Arias - 02/13/2024
  
  await $shell.Discussions.opendiscussions_gridDialog({
      entity_name: $row.entity.entity_name,
      entity_id: $row.entity.entity_id,
      application_id: $row.entity.application_id
  });
  
  $grid.refresh();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Discussions-discussions_grid',
  templateUrl: './Discussions.discussions_grid.component.html'
})
export class Discussions_discussions_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IDiscussions_discussions_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IDiscussions_discussions_gridComponentInParams = { entity_name: null, entity_id: null, title: null, application_id: null, reference: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     discussion: new GridHeaderModel(new HeaderStyles(null, null), 'Discussion', true, false, null),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       reference: new GridHeaderModel(new HeaderStyles(null, null), 'Reference', false, false, null),       entity_name: new GridHeaderModel(new HeaderStyles(null, null), 'Entity name', false, false, null),       entity_id: new GridHeaderModel(new HeaderStyles(null, null), 'Entity id', false, false, null),       read_on: new GridHeaderModel(new HeaderStyles(null, null), 'Read on', false, false, null),       alerted_on: new GridHeaderModel(new HeaderStyles(null, null), 'Alerted on', false, false, null),       application_id: new GridHeaderModel(new HeaderStyles(null, null), 'Application id', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Discussions_discussions_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('entity_name') set $inParams_entity_name(value: any) {
    this.inParams['entity_name'] = value;
  }
  get $inParams_entity_name(): any {
    return this.inParams['entity_name'] ;
  }
  @Input('entity_id') set $inParams_entity_id(value: any) {
    this.inParams['entity_id'] = value;
  }
  get $inParams_entity_id(): any {
    return this.inParams['entity_id'] ;
  }
  @Input('title') set $inParams_title(value: any) {
    this.inParams['title'] = value;
  }
  get $inParams_title(): any {
    return this.inParams['title'] ;
  }
  @Input('application_id') set $inParams_application_id(value: any) {
    this.inParams['application_id'] = value;
  }
  get $inParams_application_id(): any {
    return this.inParams['application_id'] ;
  }
  @Input('reference') set $inParams_reference(value: any) {
    this.inParams['reference'] = value;
  }
  get $inParams_reference(): any {
    return this.inParams['reference'] ;
  }

  topToolbar = {
      mark_as_read: new ToolModel(new ButtonModel('mark_as_read', new ButtonStyles(null, null), false, 'Mark as read', 'ms-Icon ms-Icon--Read')
    ),
      mark_as_unread: new ToolModel(new ButtonModel('mark_as_unread', new ButtonStyles(null, null), false, 'Mark as unread', 'ms-Icon ms-Icon--Mail')
    ),
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    view_unread: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  filters = {
    view_unread: new FieldModel(new CheckBoxModel(this.formGroup.controls['view_unread'] as DatexFormControl, null, false, 'View Unread')
, new ControlContainerStyles(null, null), '', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Discussions_ShellService,
    private datasources: Discussions_DatasourceService,
    private flows: Discussions_FlowService,
    private reports: Discussions_ReportService,
    private localization: Discussions_LocalizationService,
    private operations: Discussions_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'discussions_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.view_unread.control as CheckBoxModel).reset(false);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      entity_name:  $grid.inParams.entity_name ,
      entity_id:  $grid.inParams.entity_id ,
      discussion:  $grid.fullTextSearch ,
      application_id:  $grid.inParams.application_id ,
      read_on:  ($grid.filters.view_unread.control.value === true) ? "unread" : "all" ,
    };
    try {
    const data = await this.datasources.Discussions.ds_storage_discussions.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Discussions_discussions_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IDiscussions_discussions_gridComponentEntity) {
    const row = new Discussions_discussions_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Discussions_discussions_gridComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
    this.logger.log('Discussions', 'discussions_grid.on_init');
  //O.Arias - 02/13/2024
  
  $grid.title = 'FootPrint API Manager Comments Grid'
  
  $grid.topToolbar.delete_selected.hidden = true;
  $grid.topToolbar.delete_selected.control.readOnly = true;
  
  if ($utils.isDefined($grid.inParams.entity_id)) {
      $grid.headers.entity_id.hidden = true;
  } else {
      $grid.inParams.entity_id = "0000000";
  };
  if ($utils.isDefined($grid.inParams.entity_name)) {
      $grid.headers.entity_name.hidden = true;
  } else {
      $grid.inParams.entity_name = "test";
  };
  if ($utils.isDefined($grid.inParams.title)) {
      $grid.title = $grid.inParams.title
  } else {
      if ($utils.isDefined($grid.inParams.entity_name)) {
          $grid.title = `Comments for entity [${$grid.inParams.entity_name}] and id [${$grid.inParams.entity_id}].`
      };
  };
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Discussions_discussions_gridComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
    this.logger.log('Discussions', 'discussions_grid.on_row_selected');
  //O.Arias - 02/13/2024
  
  const row_count = $grid.selectedRows.length;
  
  let read_count = $grid.selectedRows.filter(r => $utils.isDefined(r.entity.read_on)).length;
  let unread_count = $grid.selectedRows.filter(r => !$utils.isDefined(r.entity.read_on)).length;
  
  $grid.topToolbar.mark_as_read.control.readOnly = (row_count === 0 || unread_count === 0) ? true : false;
  $grid.topToolbar.mark_as_unread.control.readOnly = (row_count === 0 || read_count === 0) ? true : false;
  $grid.topToolbar.delete_selected.control.readOnly = (row_count === 0) ? true : false;
  }
  on_mark_as_read(event = null) {
    return this.on_mark_as_readInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_mark_as_readInternal(
    $grid: Discussions_discussions_gridComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
    this.logger.log('Discussions', 'discussions_grid.on_mark_as_read');
  //O.Arias - 02/13/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { reference: string, id: string, errorMessages: string[] }[] = [];
  let mark_records: string[] = [];
  let mark_transactions: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.Discussions.openErrorDialog('Mark as read error!', 'No records were selected!');
      return;
  } else {
  
      for (const row of selected_rows) {
  
          let current_error: string;
          let success = false;
          current_error = null;
  
          try {
  
              let entity = row.entity
  
              if ($utils.isDefined(entity.read_on)) {
                  continue;
              };
  
              await $flows.Discussions.storage_discussions({
                  action: "Update", payload: {
                      id: row.entity.id,
                      read_on: "set"
                  }
              });
  
              success = true;
  
          } catch (error) {
  
              let target_error = error;
  
              if (typeof target_error === "string") {
                  target_error = { message: target_error };
              } else {
                  while ($utils.isDefined(target_error.error)) {
                      target_error = target_error.error;
                  };
              };
  
              if (!$utils.isDefined(target_error.message)) {
                  target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
              };
  
              current_error = target_error.message;
          };
  
          if (success) {
              mark_records.push(row.entity.id.toString())
          } else {
              errors.push({
                  reference: row.entity.reference, id: row.entity.id, errorMessages: [current_error]
              });
          };
      };
  
      if ($utils.isDefined(errors)) {
  
          await $shell.Discussions.openErrorDialog(
              `Marked record${selected_rows.length > 1 ? 's' : ''}`,
              mark_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} successfully marked.` : `No records were marked.`,
              null,
              `Errors marking record${selected_rows.length > 1 ? 's' : ''}`,
              errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Reference: ${error.reference}` })))
      };
  };
  
  $grid.refresh();
  }
  mark_as_unread(event = null) {
    return this.mark_as_unreadInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async mark_as_unreadInternal(
    $grid: Discussions_discussions_gridComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
    this.logger.log('Discussions', 'discussions_grid.mark_as_unread');
  //O.Arias - 02/13/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { reference: string, id: string, errorMessages: string[] }[] = [];
  let mark_records: string[] = [];
  let mark_transactions: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.Discussions.openErrorDialog('Mark as unread error!', 'No records were selected!');
      return;
  } else {
  
      for (const row of selected_rows) {
  
          let current_error: string;
          let success = false;
          current_error = null;
  
          try {
  
              let entity = row.entity
  
              if (!$utils.isDefined(entity.read_on)) {
                  continue;
              };
  
              await $flows.Discussions.storage_discussions({
                  action: "Update", payload: {
                      id: row.entity.id,
                      read_on: "delete"
                  }
              });
  
              success = true;
  
          } catch (error) {
  
              let target_error = error;
  
              if (typeof target_error === "string") {
                  target_error = { message: target_error };
              } else {
                  while ($utils.isDefined(target_error.error)) {
                      target_error = target_error.error;
                  };
              };
  
              if (!$utils.isDefined(target_error.message)) {
                  target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
              };
  
              current_error = target_error.message;
          };
  
          if (success) {
              mark_records.push(row.entity.id.toString())
          } else {
              errors.push({
                  reference: row.entity.reference, id: row.entity.id, errorMessages: [current_error]
              });
          };
      };
  
      if ($utils.isDefined(errors)) {
  
          await $shell.Discussions.openErrorDialog(
              `Unmarked record${selected_rows.length > 1 ? 's' : ''}`,
              mark_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} successfully unmarked.` : `No records were unmarked.`,
              null,
              `Errors unmarking record${selected_rows.length > 1 ? 's' : ''}`,
              errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Reference: ${error.reference}` })))
      };
  };
  
  $grid.refresh();
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: Discussions_discussions_gridComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
    this.logger.log('Discussions', 'discussions_grid.on_delete');
  //O.Arias - 02/13/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { reference: string, id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.Discussions.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.Discussions.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  await $flows.Discussions.storage_discussions({
                      action: "Delete", payload: {
                          "id": entity.id
                      }
                  });
                  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  deleted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      reference: row.entity.reference ?? "", id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
  
              await $shell.Discussions.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Reference: ${error.reference}` })))
          };
      };
  };
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
