import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { LoadContainers_ds_carrier_service_types_ddService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_carriers_ddService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_container_sizes_ddService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_container_types_ddService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_get_dock_appointments_by_orderIdsService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_get_load_tasks_by_shipmentIdsService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_get_orders_by_licenseplateService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_get_orders_from_shipmentsService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_get_pick_tasks_by_orderIdsService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_get_shipment_load_containersService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_get_shipments_by_orderIdService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_get_shipments_from_ordersService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_container_archived_contentsService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_container_contentsService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_container_editorService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_container_expectedService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_container_license_platesService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_container_processedService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_container_tasksService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_containers_gridService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_load_containers_statuses_ddService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_order_shipment_by_load_container_gridService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_order_types_ddService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_orders_by_load_container_gridService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_shipment_archived_contentsService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_shipment_contentsService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_shipment_expectedService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_shipment_prioritiesService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_shipment_processedService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_shipment_tasksService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_unassigned_orders_ddService } from './LoadContainers.datasource.index';
import { LoadContainers_ds_unassigned_orders_shipments_gridService } from './LoadContainers.datasource.index';

@Injectable({ providedIn: 'root' })
export class LoadContainers_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public LoadContainers: LoadContainers_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_carrier_service_types_dd: LoadContainers_ds_carrier_service_types_ddService;
  public get ds_carrier_service_types_dd(): LoadContainers_ds_carrier_service_types_ddService {
    if(!this._ds_carrier_service_types_dd) {
      this._ds_carrier_service_types_dd = this.injector.get(LoadContainers_ds_carrier_service_types_ddService);
    }
    return this._ds_carrier_service_types_dd;
  }
  private _ds_carriers_dd: LoadContainers_ds_carriers_ddService;
  public get ds_carriers_dd(): LoadContainers_ds_carriers_ddService {
    if(!this._ds_carriers_dd) {
      this._ds_carriers_dd = this.injector.get(LoadContainers_ds_carriers_ddService);
    }
    return this._ds_carriers_dd;
  }
  private _ds_container_sizes_dd: LoadContainers_ds_container_sizes_ddService;
  public get ds_container_sizes_dd(): LoadContainers_ds_container_sizes_ddService {
    if(!this._ds_container_sizes_dd) {
      this._ds_container_sizes_dd = this.injector.get(LoadContainers_ds_container_sizes_ddService);
    }
    return this._ds_container_sizes_dd;
  }
  private _ds_container_types_dd: LoadContainers_ds_container_types_ddService;
  public get ds_container_types_dd(): LoadContainers_ds_container_types_ddService {
    if(!this._ds_container_types_dd) {
      this._ds_container_types_dd = this.injector.get(LoadContainers_ds_container_types_ddService);
    }
    return this._ds_container_types_dd;
  }
  private _ds_get_dock_appointments_by_orderIds: LoadContainers_ds_get_dock_appointments_by_orderIdsService;
  public get ds_get_dock_appointments_by_orderIds(): LoadContainers_ds_get_dock_appointments_by_orderIdsService {
    if(!this._ds_get_dock_appointments_by_orderIds) {
      this._ds_get_dock_appointments_by_orderIds = this.injector.get(LoadContainers_ds_get_dock_appointments_by_orderIdsService);
    }
    return this._ds_get_dock_appointments_by_orderIds;
  }
  private _ds_get_load_tasks_by_shipmentIds: LoadContainers_ds_get_load_tasks_by_shipmentIdsService;
  public get ds_get_load_tasks_by_shipmentIds(): LoadContainers_ds_get_load_tasks_by_shipmentIdsService {
    if(!this._ds_get_load_tasks_by_shipmentIds) {
      this._ds_get_load_tasks_by_shipmentIds = this.injector.get(LoadContainers_ds_get_load_tasks_by_shipmentIdsService);
    }
    return this._ds_get_load_tasks_by_shipmentIds;
  }
  private _ds_get_orders_by_licenseplate: LoadContainers_ds_get_orders_by_licenseplateService;
  public get ds_get_orders_by_licenseplate(): LoadContainers_ds_get_orders_by_licenseplateService {
    if(!this._ds_get_orders_by_licenseplate) {
      this._ds_get_orders_by_licenseplate = this.injector.get(LoadContainers_ds_get_orders_by_licenseplateService);
    }
    return this._ds_get_orders_by_licenseplate;
  }
  private _ds_get_orders_from_shipments: LoadContainers_ds_get_orders_from_shipmentsService;
  public get ds_get_orders_from_shipments(): LoadContainers_ds_get_orders_from_shipmentsService {
    if(!this._ds_get_orders_from_shipments) {
      this._ds_get_orders_from_shipments = this.injector.get(LoadContainers_ds_get_orders_from_shipmentsService);
    }
    return this._ds_get_orders_from_shipments;
  }
  private _ds_get_pick_tasks_by_orderIds: LoadContainers_ds_get_pick_tasks_by_orderIdsService;
  public get ds_get_pick_tasks_by_orderIds(): LoadContainers_ds_get_pick_tasks_by_orderIdsService {
    if(!this._ds_get_pick_tasks_by_orderIds) {
      this._ds_get_pick_tasks_by_orderIds = this.injector.get(LoadContainers_ds_get_pick_tasks_by_orderIdsService);
    }
    return this._ds_get_pick_tasks_by_orderIds;
  }
  private _ds_get_shipment_load_containers: LoadContainers_ds_get_shipment_load_containersService;
  public get ds_get_shipment_load_containers(): LoadContainers_ds_get_shipment_load_containersService {
    if(!this._ds_get_shipment_load_containers) {
      this._ds_get_shipment_load_containers = this.injector.get(LoadContainers_ds_get_shipment_load_containersService);
    }
    return this._ds_get_shipment_load_containers;
  }
  private _ds_get_shipments_by_orderId: LoadContainers_ds_get_shipments_by_orderIdService;
  public get ds_get_shipments_by_orderId(): LoadContainers_ds_get_shipments_by_orderIdService {
    if(!this._ds_get_shipments_by_orderId) {
      this._ds_get_shipments_by_orderId = this.injector.get(LoadContainers_ds_get_shipments_by_orderIdService);
    }
    return this._ds_get_shipments_by_orderId;
  }
  private _ds_get_shipments_from_orders: LoadContainers_ds_get_shipments_from_ordersService;
  public get ds_get_shipments_from_orders(): LoadContainers_ds_get_shipments_from_ordersService {
    if(!this._ds_get_shipments_from_orders) {
      this._ds_get_shipments_from_orders = this.injector.get(LoadContainers_ds_get_shipments_from_ordersService);
    }
    return this._ds_get_shipments_from_orders;
  }
  private _ds_load_container_archived_contents: LoadContainers_ds_load_container_archived_contentsService;
  public get ds_load_container_archived_contents(): LoadContainers_ds_load_container_archived_contentsService {
    if(!this._ds_load_container_archived_contents) {
      this._ds_load_container_archived_contents = this.injector.get(LoadContainers_ds_load_container_archived_contentsService);
    }
    return this._ds_load_container_archived_contents;
  }
  private _ds_load_container_contents: LoadContainers_ds_load_container_contentsService;
  public get ds_load_container_contents(): LoadContainers_ds_load_container_contentsService {
    if(!this._ds_load_container_contents) {
      this._ds_load_container_contents = this.injector.get(LoadContainers_ds_load_container_contentsService);
    }
    return this._ds_load_container_contents;
  }
  private _ds_load_container_editor: LoadContainers_ds_load_container_editorService;
  public get ds_load_container_editor(): LoadContainers_ds_load_container_editorService {
    if(!this._ds_load_container_editor) {
      this._ds_load_container_editor = this.injector.get(LoadContainers_ds_load_container_editorService);
    }
    return this._ds_load_container_editor;
  }
  private _ds_load_container_expected: LoadContainers_ds_load_container_expectedService;
  public get ds_load_container_expected(): LoadContainers_ds_load_container_expectedService {
    if(!this._ds_load_container_expected) {
      this._ds_load_container_expected = this.injector.get(LoadContainers_ds_load_container_expectedService);
    }
    return this._ds_load_container_expected;
  }
  private _ds_load_container_license_plates: LoadContainers_ds_load_container_license_platesService;
  public get ds_load_container_license_plates(): LoadContainers_ds_load_container_license_platesService {
    if(!this._ds_load_container_license_plates) {
      this._ds_load_container_license_plates = this.injector.get(LoadContainers_ds_load_container_license_platesService);
    }
    return this._ds_load_container_license_plates;
  }
  private _ds_load_container_processed: LoadContainers_ds_load_container_processedService;
  public get ds_load_container_processed(): LoadContainers_ds_load_container_processedService {
    if(!this._ds_load_container_processed) {
      this._ds_load_container_processed = this.injector.get(LoadContainers_ds_load_container_processedService);
    }
    return this._ds_load_container_processed;
  }
  private _ds_load_container_tasks: LoadContainers_ds_load_container_tasksService;
  public get ds_load_container_tasks(): LoadContainers_ds_load_container_tasksService {
    if(!this._ds_load_container_tasks) {
      this._ds_load_container_tasks = this.injector.get(LoadContainers_ds_load_container_tasksService);
    }
    return this._ds_load_container_tasks;
  }
  private _ds_load_containers_grid: LoadContainers_ds_load_containers_gridService;
  public get ds_load_containers_grid(): LoadContainers_ds_load_containers_gridService {
    if(!this._ds_load_containers_grid) {
      this._ds_load_containers_grid = this.injector.get(LoadContainers_ds_load_containers_gridService);
    }
    return this._ds_load_containers_grid;
  }
  private _ds_load_containers_statuses_dd: LoadContainers_ds_load_containers_statuses_ddService;
  public get ds_load_containers_statuses_dd(): LoadContainers_ds_load_containers_statuses_ddService {
    if(!this._ds_load_containers_statuses_dd) {
      this._ds_load_containers_statuses_dd = this.injector.get(LoadContainers_ds_load_containers_statuses_ddService);
    }
    return this._ds_load_containers_statuses_dd;
  }
  private _ds_order_shipment_by_load_container_grid: LoadContainers_ds_order_shipment_by_load_container_gridService;
  public get ds_order_shipment_by_load_container_grid(): LoadContainers_ds_order_shipment_by_load_container_gridService {
    if(!this._ds_order_shipment_by_load_container_grid) {
      this._ds_order_shipment_by_load_container_grid = this.injector.get(LoadContainers_ds_order_shipment_by_load_container_gridService);
    }
    return this._ds_order_shipment_by_load_container_grid;
  }
  private _ds_order_types_dd: LoadContainers_ds_order_types_ddService;
  public get ds_order_types_dd(): LoadContainers_ds_order_types_ddService {
    if(!this._ds_order_types_dd) {
      this._ds_order_types_dd = this.injector.get(LoadContainers_ds_order_types_ddService);
    }
    return this._ds_order_types_dd;
  }
  private _ds_orders_by_load_container_grid: LoadContainers_ds_orders_by_load_container_gridService;
  public get ds_orders_by_load_container_grid(): LoadContainers_ds_orders_by_load_container_gridService {
    if(!this._ds_orders_by_load_container_grid) {
      this._ds_orders_by_load_container_grid = this.injector.get(LoadContainers_ds_orders_by_load_container_gridService);
    }
    return this._ds_orders_by_load_container_grid;
  }
  private _ds_shipment_archived_contents: LoadContainers_ds_shipment_archived_contentsService;
  public get ds_shipment_archived_contents(): LoadContainers_ds_shipment_archived_contentsService {
    if(!this._ds_shipment_archived_contents) {
      this._ds_shipment_archived_contents = this.injector.get(LoadContainers_ds_shipment_archived_contentsService);
    }
    return this._ds_shipment_archived_contents;
  }
  private _ds_shipment_contents: LoadContainers_ds_shipment_contentsService;
  public get ds_shipment_contents(): LoadContainers_ds_shipment_contentsService {
    if(!this._ds_shipment_contents) {
      this._ds_shipment_contents = this.injector.get(LoadContainers_ds_shipment_contentsService);
    }
    return this._ds_shipment_contents;
  }
  private _ds_shipment_expected: LoadContainers_ds_shipment_expectedService;
  public get ds_shipment_expected(): LoadContainers_ds_shipment_expectedService {
    if(!this._ds_shipment_expected) {
      this._ds_shipment_expected = this.injector.get(LoadContainers_ds_shipment_expectedService);
    }
    return this._ds_shipment_expected;
  }
  private _ds_shipment_priorities: LoadContainers_ds_shipment_prioritiesService;
  public get ds_shipment_priorities(): LoadContainers_ds_shipment_prioritiesService {
    if(!this._ds_shipment_priorities) {
      this._ds_shipment_priorities = this.injector.get(LoadContainers_ds_shipment_prioritiesService);
    }
    return this._ds_shipment_priorities;
  }
  private _ds_shipment_processed: LoadContainers_ds_shipment_processedService;
  public get ds_shipment_processed(): LoadContainers_ds_shipment_processedService {
    if(!this._ds_shipment_processed) {
      this._ds_shipment_processed = this.injector.get(LoadContainers_ds_shipment_processedService);
    }
    return this._ds_shipment_processed;
  }
  private _ds_shipment_tasks: LoadContainers_ds_shipment_tasksService;
  public get ds_shipment_tasks(): LoadContainers_ds_shipment_tasksService {
    if(!this._ds_shipment_tasks) {
      this._ds_shipment_tasks = this.injector.get(LoadContainers_ds_shipment_tasksService);
    }
    return this._ds_shipment_tasks;
  }
  private _ds_unassigned_orders_dd: LoadContainers_ds_unassigned_orders_ddService;
  public get ds_unassigned_orders_dd(): LoadContainers_ds_unassigned_orders_ddService {
    if(!this._ds_unassigned_orders_dd) {
      this._ds_unassigned_orders_dd = this.injector.get(LoadContainers_ds_unassigned_orders_ddService);
    }
    return this._ds_unassigned_orders_dd;
  }
  private _ds_unassigned_orders_shipments_grid: LoadContainers_ds_unassigned_orders_shipments_gridService;
  public get ds_unassigned_orders_shipments_grid(): LoadContainers_ds_unassigned_orders_shipments_gridService {
    if(!this._ds_unassigned_orders_shipments_grid) {
      this._ds_unassigned_orders_shipments_grid = this.injector.get(LoadContainers_ds_unassigned_orders_shipments_gridService);
    }
    return this._ds_unassigned_orders_shipments_grid;
  }
}

