import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ShipTheory_shiptheory_library_homeComponent } from './ShipTheory.shiptheory_library_home.component';


@Injectable({ providedIn: 'root' })
export class ShipTheory_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public ShipTheory: ShipTheory_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openshiptheory_library_home(replaceCurrentView?: boolean) {
    this.logger.log('ShipTheory', 'shiptheory_library_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'ShipTheory_shiptheory_library_home',
        component: ShipTheory_shiptheory_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openshiptheory_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('ShipTheory', 'shiptheory_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ShipTheory_shiptheory_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'ShipTheory_shiptheory_library_home') {
      this.logger.log('ShipTheory', 'shiptheory_library_home');
      const title = 'Home';
      const component = ShipTheory_shiptheory_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    return result;
  }
}
