import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Invoices.frontend.types'

@Injectable({ providedIn: 'root' })
export class Invoices_submit_entity_import_json_requestService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { entityImport?: any, referenceCode?: string, project?: string, warehouse?: string, groupId?: string, transactionGroupId?: string }): Promise<{ requestId?: string }> 
  {
    let url = `${environment.backendUrl}api/Invoices/functions/submit_entity_import_json_request`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

