<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="update" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_update_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Replenishment information</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-location" *ngIf="!fields.location.hidden" 
                            class="field-container double {{fields.location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.location.styles.style"
                            [ngClass]="fields.location.styles.classes">
                        <div class="label-container"
                              title="{{fields.location.label}}{{fields.location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.location.label}}">{{fields.location.label}}<span *ngIf="fields.location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="location"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.location.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.location.control.placeholder}}"
                                [ngStyle]="fields.location.control.styles.style"
                                [ngClass]="fields.location.control.styles.classes"> 
                        <ng-container *ngIf="fields.location.invalid">
                          <ng-container *ngFor="let error of fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden" 
                            class="field-container double {{fields.material.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                              title="{{fields.material.label}}{{fields.material.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material.label}}">{{fields.material.label}}<span *ngIf="fields.material.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="material"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.material.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.material.control.placeholder}}"
                                [ngStyle]="fields.material.control.styles.style"
                                [ngClass]="fields.material.control.styles.classes"> 
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-minimum_packaged_quantity" *ngIf="!fields.minimum_packaged_quantity.hidden" 
                            class="field-container standard {{fields.minimum_packaged_quantity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.minimum_packaged_quantity.styles.style"
                            [ngClass]="fields.minimum_packaged_quantity.styles.classes">
                        <div class="label-container"
                              title="{{fields.minimum_packaged_quantity.label}}{{fields.minimum_packaged_quantity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.minimum_packaged_quantity.label}}">{{fields.minimum_packaged_quantity.label}}<span *ngIf="fields.minimum_packaged_quantity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="minimum_packaged_quantity"
                                matInput
                                numberBox
                                [format]="fields.minimum_packaged_quantity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.minimum_packaged_quantity.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.minimum_packaged_quantity.control.placeholder}}"
                                [ngStyle]="fields.minimum_packaged_quantity.control.styles.style"
                                [ngClass]="fields.minimum_packaged_quantity.control.styles.classes">
                        <ng-container *ngIf="fields.minimum_packaged_quantity.invalid">
                          <ng-container *ngFor="let error of fields.minimum_packaged_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-minimum_packaging" *ngIf="!fields.minimum_packaging.hidden" 
                            class="field-container standard {{fields.minimum_packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.minimum_packaging.styles.style"
                            [ngClass]="fields.minimum_packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.minimum_packaging.label}}{{fields.minimum_packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.minimum_packaging.label}}">{{fields.minimum_packaging.label}}<span *ngIf="fields.minimum_packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.minimum_packaging.control.type"
                            formControlName="minimum_packaging"
                            (displayTextChange)="fields.minimum_packaging.control.displayText=$event"
                            [placeholder]="fields.minimum_packaging.control.placeholder"
                            [styles]="fields.minimum_packaging.control.styles"
                          [materialId]="$fields_minimum_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.minimum_packaging.invalid">
                          <ng-container *ngFor="let error of fields.minimum_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-replenish_by_quantity_toggle" *ngIf="!fields.replenish_by_quantity_toggle.hidden" 
                            class="field-container full {{fields.replenish_by_quantity_toggle.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.replenish_by_quantity_toggle.styles.style"
                            [ngClass]="fields.replenish_by_quantity_toggle.styles.classes">
                        <div class="label-container"
                              title="{{fields.replenish_by_quantity_toggle.label}}{{fields.replenish_by_quantity_toggle.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.replenish_by_quantity_toggle.label}}">{{fields.replenish_by_quantity_toggle.label}}<span *ngIf="fields.replenish_by_quantity_toggle.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="replenish_by_quantity_toggle"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.replenish_by_quantity_toggle.control.styles.style"
                                      [ngClass]="fields.replenish_by_quantity_toggle.control.styles.classes">{{fields.replenish_by_quantity_toggle.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.replenish_by_quantity_toggle.invalid">
                          <ng-container *ngFor="let error of fields.replenish_by_quantity_toggle.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-replenishment_packaged_amount" *ngIf="!fields.replenishment_packaged_amount.hidden" 
                            class="field-container standard {{fields.replenishment_packaged_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.replenishment_packaged_amount.styles.style"
                            [ngClass]="fields.replenishment_packaged_amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.replenishment_packaged_amount.label}}{{fields.replenishment_packaged_amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.replenishment_packaged_amount.label}}">{{fields.replenishment_packaged_amount.label}}<span *ngIf="fields.replenishment_packaged_amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="replenishment_packaged_amount"
                                matInput
                                numberBox
                                [format]="fields.replenishment_packaged_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.replenishment_packaged_amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.replenishment_packaged_amount.control.placeholder}}"
                                [ngStyle]="fields.replenishment_packaged_amount.control.styles.style"
                                [ngClass]="fields.replenishment_packaged_amount.control.styles.classes">
                        <ng-container *ngIf="fields.replenishment_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.replenishment_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-replenishment_packaging" *ngIf="!fields.replenishment_packaging.hidden" 
                            class="field-container standard {{fields.replenishment_packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.replenishment_packaging.styles.style"
                            [ngClass]="fields.replenishment_packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.replenishment_packaging.label}}{{fields.replenishment_packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.replenishment_packaging.label}}">{{fields.replenishment_packaging.label}}<span *ngIf="fields.replenishment_packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.replenishment_packaging.control.type"
                            formControlName="replenishment_packaging"
                            (displayTextChange)="fields.replenishment_packaging.control.displayText=$event"
                            [placeholder]="fields.replenishment_packaging.control.placeholder"
                            [styles]="fields.replenishment_packaging.control.styles"
                          [materialId]="$fields_replenishment_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.replenishment_packaging.invalid">
                          <ng-container *ngFor="let error of fields.replenishment_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-maximum_packaged_amount" *ngIf="!fields.maximum_packaged_amount.hidden" 
                            class="field-container standard {{fields.maximum_packaged_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.maximum_packaged_amount.styles.style"
                            [ngClass]="fields.maximum_packaged_amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.maximum_packaged_amount.label}}{{fields.maximum_packaged_amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.maximum_packaged_amount.label}}">{{fields.maximum_packaged_amount.label}}<span *ngIf="fields.maximum_packaged_amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="maximum_packaged_amount"
                                matInput
                                numberBox
                                [format]="fields.maximum_packaged_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.maximum_packaged_amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.maximum_packaged_amount.control.placeholder}}"
                                [ngStyle]="fields.maximum_packaged_amount.control.styles.style"
                                [ngClass]="fields.maximum_packaged_amount.control.styles.classes">
                        <ng-container *ngIf="fields.maximum_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.maximum_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-maximum_packaging" *ngIf="!fields.maximum_packaging.hidden" 
                            class="field-container standard {{fields.maximum_packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.maximum_packaging.styles.style"
                            [ngClass]="fields.maximum_packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.maximum_packaging.label}}{{fields.maximum_packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.maximum_packaging.label}}">{{fields.maximum_packaging.label}}<span *ngIf="fields.maximum_packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.maximum_packaging.control.type"
                            formControlName="maximum_packaging"
                            (displayTextChange)="fields.maximum_packaging.control.displayText=$event"
                            [placeholder]="fields.maximum_packaging.control.placeholder"
                            [styles]="fields.maximum_packaging.control.styles"
                          [materialId]="$fields_maximum_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.maximum_packaging.invalid">
                          <ng-container *ngFor="let error of fields.maximum_packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Rule trigger</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-rule_trigger" *ngIf="!fields.rule_trigger.hidden" 
                            class="field-container double {{fields.rule_trigger.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.rule_trigger.styles.style"
                            [ngClass]="fields.rule_trigger.styles.classes">
                        <div class="label-container"
                              title="{{fields.rule_trigger.label}}{{fields.rule_trigger.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.rule_trigger.label}}">{{fields.rule_trigger.label}}<span *ngIf="fields.rule_trigger.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Replenishments-replenishment_comparison_dd_single 
                            data-cy="selector"
                            [type]="fields.rule_trigger.control.type"
                            formControlName="rule_trigger"
                            (displayTextChange)="fields.rule_trigger.control.displayText=$event"
                            [placeholder]="fields.rule_trigger.control.placeholder"
                            [styles]="fields.rule_trigger.control.styles"
                        >
                        </Replenishments-replenishment_comparison_dd_single>
                        <ng-container *ngIf="fields.rule_trigger.invalid">
                          <ng-container *ngFor="let error of fields.rule_trigger.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-system_triggered" *ngIf="!fields.system_triggered.hidden" 
                            class="field-container double {{fields.system_triggered.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.system_triggered.styles.style"
                            [ngClass]="fields.system_triggered.styles.classes">
                        <div class="label-container"
                              title="{{fields.system_triggered.label}}{{fields.system_triggered.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.system_triggered.label}}">{{fields.system_triggered.label}}<span *ngIf="fields.system_triggered.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="system_triggered"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.system_triggered.control.styles.style"
                                      [ngClass]="fields.system_triggered.control.styles.classes">{{fields.system_triggered.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.system_triggered.invalid">
                          <ng-container *ngFor="let error of fields.system_triggered.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-on_demand" *ngIf="!fields.on_demand.hidden" 
                            class="field-container double {{fields.on_demand.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.on_demand.styles.style"
                            [ngClass]="fields.on_demand.styles.classes">
                        <div class="label-container"
                              title="{{fields.on_demand.label}}{{fields.on_demand.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.on_demand.label}}">{{fields.on_demand.label}}<span *ngIf="fields.on_demand.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="on_demand"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.on_demand.control.styles.style"
                                      [ngClass]="fields.on_demand.control.styles.classes">{{fields.on_demand.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.on_demand.invalid">
                          <ng-container *ngFor="let error of fields.on_demand.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>