<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="delete_rate" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="Rate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Rate.displayControl.styles.style"
          [ngClass]="row.cells.Rate.displayControl.styles.classes">{{row.cells.Rate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['Rate_edit']"
            matInput
            numberBox
            [format]="row.cells.Rate.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.Rate.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Rate.editControl.placeholder}}"
            [ngStyle]="row.cells.Rate.editControl.styles.style"
            [ngClass]="row.cells.Rate.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MinimumCharge">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.MinimumCharge.displayControl.styles.style"
          [ngClass]="row.cells.MinimumCharge.displayControl.styles.classes">{{row.cells.MinimumCharge.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['MinimumCharge_edit']"
            matInput
            numberBox
            [format]="row.cells.MinimumCharge.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.MinimumCharge.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.MinimumCharge.editControl.placeholder}}"
            [ngStyle]="row.cells.MinimumCharge.editControl.styles.style"
            [ngClass]="row.cells.MinimumCharge.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="RangeStart">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.RangeStart.displayControl.styles.style"
          [ngClass]="row.cells.RangeStart.displayControl.styles.classes">{{row.cells.RangeStart.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['RangeStart_edit']"
            matInput
            numberBox
            [format]="row.cells.RangeStart.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.RangeStart.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.RangeStart.editControl.placeholder}}"
            [ngStyle]="row.cells.RangeStart.editControl.styles.style"
            [ngClass]="row.cells.RangeStart.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="RangeEnd">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.RangeEnd.displayControl.styles.style"
          [ngClass]="row.cells.RangeEnd.displayControl.styles.classes">{{row.cells.RangeEnd.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['RangeEnd_edit']"
            matInput
            numberBox
            [format]="row.cells.RangeEnd.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.RangeEnd.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.RangeEnd.editControl.placeholder}}"
            [ngStyle]="row.cells.RangeEnd.editControl.styles.style"
            [ngClass]="row.cells.RangeEnd.editControl.styles.classes">
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
