<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="deleteLine" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="BillingCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.BillingCode.displayControl.styles.style"
          [ngClass]="row.cells.BillingCode.displayControl.styles.classes">{{row.cells.BillingCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Invoices-billingcodes_dd_single 
        data-cy="selector"
        [type]="row.cells.BillingCode.editControl.type"
        [formControl]="row.formGroup.controls['BillingCode_edit']"
        (displayTextChange)="row.cells.BillingCode.editControl.displayText=$event"
        [placeholder]="row.cells.BillingCode.editControl.placeholder"
        [styles]="row.cells.BillingCode.editControl.styles"
    >
    </Invoices-billingcodes_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Rate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Rate.displayControl.styles.style"
          [ngClass]="row.cells.Rate.displayControl.styles.classes">{{row.cells.Rate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['Rate_edit']"
            matInput
            numberBox
            [format]="row.cells.Rate.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.Rate.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Rate.editControl.placeholder}}"
            [ngStyle]="row.cells.Rate.editControl.styles.style"
            [ngClass]="row.cells.Rate.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Value.displayControl.styles.style"
          [ngClass]="row.cells.Value.displayControl.styles.classes">{{row.cells.Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['Value_edit']"
            matInput
            numberBox
            [format]="row.cells.Value.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.Value.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Value.editControl.placeholder}}"
            [ngStyle]="row.cells.Value.editControl.styles.style"
            [ngClass]="row.cells.Value.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PercentOfBillingCharge">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['PercentOfBillingCharge_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.PercentOfBillingCharge.displayControl.styles.style"
                  [ngClass]="row.cells.PercentOfBillingCharge.displayControl.styles.classes">{{row.cells.PercentOfBillingCharge.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['PercentOfBillingCharge_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.PercentOfBillingCharge.editControl.styles.style"
                  [ngClass]="row.cells.PercentOfBillingCharge.editControl.styles.classes">{{row.cells.PercentOfBillingCharge.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
