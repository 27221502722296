import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-generic_form',
  templateUrl: './FootPrintManager.generic_form.component.html'
})
export class FootPrintManager_generic_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { stringField1?: string, stringField2?: string, stringField3?: string, stringField4?: string, stringField5?: string, dateField1?: string, dateField2?: string, dateField3?: string, dateField4?: string, dateField5?: string, numberField1?: string, numberField2?: string, numberField3?: string, numberField4?: string, numberField5?: string, title?: string } = { stringField1: null, stringField2: null, stringField3: null, stringField4: null, stringField5: null, dateField1: null, dateField2: null, dateField3: null, dateField4: null, dateField5: null, numberField1: null, numberField2: null, numberField3: null, numberField4: null, numberField5: null, title: null };
  //#region Inputs
  @Input('stringField1') set $inParams_stringField1(v: string) {
    this.inParams.stringField1 = v;
  }
  get $inParams_stringField1(): string {
    return this.inParams.stringField1;
  }
  @Input('stringField2') set $inParams_stringField2(v: string) {
    this.inParams.stringField2 = v;
  }
  get $inParams_stringField2(): string {
    return this.inParams.stringField2;
  }
  @Input('stringField3') set $inParams_stringField3(v: string) {
    this.inParams.stringField3 = v;
  }
  get $inParams_stringField3(): string {
    return this.inParams.stringField3;
  }
  @Input('stringField4') set $inParams_stringField4(v: string) {
    this.inParams.stringField4 = v;
  }
  get $inParams_stringField4(): string {
    return this.inParams.stringField4;
  }
  @Input('stringField5') set $inParams_stringField5(v: string) {
    this.inParams.stringField5 = v;
  }
  get $inParams_stringField5(): string {
    return this.inParams.stringField5;
  }
  @Input('dateField1') set $inParams_dateField1(v: string) {
    this.inParams.dateField1 = v;
  }
  get $inParams_dateField1(): string {
    return this.inParams.dateField1;
  }
  @Input('dateField2') set $inParams_dateField2(v: string) {
    this.inParams.dateField2 = v;
  }
  get $inParams_dateField2(): string {
    return this.inParams.dateField2;
  }
  @Input('dateField3') set $inParams_dateField3(v: string) {
    this.inParams.dateField3 = v;
  }
  get $inParams_dateField3(): string {
    return this.inParams.dateField3;
  }
  @Input('dateField4') set $inParams_dateField4(v: string) {
    this.inParams.dateField4 = v;
  }
  get $inParams_dateField4(): string {
    return this.inParams.dateField4;
  }
  @Input('dateField5') set $inParams_dateField5(v: string) {
    this.inParams.dateField5 = v;
  }
  get $inParams_dateField5(): string {
    return this.inParams.dateField5;
  }
  @Input('numberField1') set $inParams_numberField1(v: string) {
    this.inParams.numberField1 = v;
  }
  get $inParams_numberField1(): string {
    return this.inParams.numberField1;
  }
  @Input('numberField2') set $inParams_numberField2(v: string) {
    this.inParams.numberField2 = v;
  }
  get $inParams_numberField2(): string {
    return this.inParams.numberField2;
  }
  @Input('numberField3') set $inParams_numberField3(v: string) {
    this.inParams.numberField3 = v;
  }
  get $inParams_numberField3(): string {
    return this.inParams.numberField3;
  }
  @Input('numberField4') set $inParams_numberField4(v: string) {
    this.inParams.numberField4 = v;
  }
  get $inParams_numberField4(): string {
    return this.inParams.numberField4;
  }
  @Input('numberField5') set $inParams_numberField5(v: string) {
    this.inParams.numberField5 = v;
  }
  get $inParams_numberField5(): string {
    return this.inParams.numberField5;
  }
  @Input('title') set $inParams_title(v: string) {
    this.inParams.title = v;
  }
  get $inParams_title(): string {
    return this.inParams.title;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { stringField1?: string, stringField2?: string, stringField3?: string, stringField4?: string, stringField5?: string, dateField1?: string, dateField2?: string, dateField3?: string, dateField4?: string, dateField5?: string, numberField1?: number, numberField2?: number, numberField3?: number, numberField4?: number, numberField5?: number } = { stringField1: null, stringField2: null, stringField3: null, stringField4: null, stringField5: null, dateField1: null, dateField2: null, dateField3: null, dateField4: null, dateField5: null, numberField1: null, numberField2: null, numberField3: null, numberField4: null, numberField5: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ stringField1?: string, stringField2?: string, stringField3?: string, stringField4?: string, stringField5?: string, dateField1?: string, dateField2?: string, dateField3?: string, dateField4?: string, dateField5?: string, numberField1?: number, numberField2?: number, numberField3?: number, numberField4?: number, numberField5?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    stringField1: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    stringField2: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    stringField3: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    stringField4: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    stringField5: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dateField1: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dateField2: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dateField3: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dateField4: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dateField5: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    numberField1: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    numberField2: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    numberField3: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    numberField4: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    numberField5: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), true, 'Confirm', '')
    ),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    stringField1: new FieldModel(new TextBoxModel(this.formGroup.controls['stringField1'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'String field 1', false)
,
    stringField2: new FieldModel(new TextBoxModel(this.formGroup.controls['stringField2'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'String field 2', false)
,
    stringField3: new FieldModel(new TextBoxModel(this.formGroup.controls['stringField3'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'String field 3', false)
,
    stringField4: new FieldModel(new TextBoxModel(this.formGroup.controls['stringField4'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'String field 4', false)
,
    stringField5: new FieldModel(new TextBoxModel(this.formGroup.controls['stringField5'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'String field 5', false)
,
    dateField1: new FieldModel(new DateBoxModel(this.formGroup.controls['dateField1'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Date field 1', false)
,
    dateField2: new FieldModel(new DateBoxModel(this.formGroup.controls['dateField2'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Date field 2', false)
,
    dateField3: new FieldModel(new DateBoxModel(this.formGroup.controls['dateField3'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Date field 3', false)
,
    dateField4: new FieldModel(new DateBoxModel(this.formGroup.controls['dateField4'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Date field 4', false)
,
    dateField5: new FieldModel(new DateBoxModel(this.formGroup.controls['dateField5'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Date field 5', false)
,
    numberField1: new FieldModel(new NumberBoxModel(this.formGroup.controls['numberField1'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Number field 1', false)
,
    numberField2: new FieldModel(new NumberBoxModel(this.formGroup.controls['numberField2'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Number field 2', false)
,
    numberField3: new FieldModel(new NumberBoxModel(this.formGroup.controls['numberField3'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Number field 3', false)
,
    numberField4: new FieldModel(new NumberBoxModel(this.formGroup.controls['numberField4'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Number field 4', false)
,
    numberField5: new FieldModel(new NumberBoxModel(this.formGroup.controls['numberField5'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Number field 5', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    numberField1: validateControlOnChange(this.fields.numberField1.control, () => this.numberField_validation(this.fields.numberField1.control))
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form())))
    .pipe(shareReplay()),
    numberField2: validateControlOnChange(this.fields.numberField2.control, () => this.numberField_validation(this.fields.numberField2.control))
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form())))
    .pipe(shareReplay()),
    numberField3: validateControlOnChange(this.fields.numberField3.control, () => this.numberField_validation(this.fields.numberField3.control))
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form())))
    .pipe(shareReplay()),
    numberField4: validateControlOnChange(this.fields.numberField4.control, () => this.numberField_validation(this.fields.numberField4.control))
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form())))
    .pipe(shareReplay()),
    numberField5: validateControlOnChange(this.fields.numberField5.control, () => this.numberField_validation(this.fields.numberField5.control))
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form())))
    .pipe(shareReplay()),
    stringField1: this.fields.stringField1.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    stringField2: this.fields.stringField2.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    stringField3: this.fields.stringField3.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    stringField4: this.fields.stringField4.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    stringField5: this.fields.stringField5.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    dateField1: this.fields.dateField1.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    dateField2: this.fields.dateField2.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    dateField3: this.fields.dateField3.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    dateField4: this.fields.dateField4.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
    dateField5: this.fields.dateField5.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, () => this.on_validate_form()))),
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'generic_form';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .stringField1
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .stringField2
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .stringField3
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .stringField4
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .stringField5
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dateField1
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dateField2
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dateField3
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dateField4
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dateField5
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .numberField1
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .numberField2
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .numberField3
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .numberField4
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .numberField5
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_generic_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Header
  $form.title = $form.inParams.title ?? '';
  
  
  // String Fields
  if ($utils.isDefined($form.inParams.stringField1)) {
      $form.fields.stringField1.label = $form.inParams.stringField1;
  }
  else {
      $form.fields.stringField1.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.stringField2)) {
      $form.fields.stringField2.label = $form.inParams.stringField2;
  }
  else {
      $form.fields.stringField2.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.stringField3)) {
      $form.fields.stringField3.label = $form.inParams.stringField3;
  }
  else {
      $form.fields.stringField3.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.stringField4)) {
      $form.fields.stringField4.label = $form.inParams.stringField4;
  }
  else {
      $form.fields.stringField4.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.stringField5)) {
      $form.fields.stringField5.label = $form.inParams.stringField5;
  }
  else {
      $form.fields.stringField5.hidden = true;
  }
  
  // Date Fields
  if ($utils.isDefined($form.inParams.dateField1)) {
      $form.fields.dateField1.label = $form.inParams.dateField1;
  }
  else {
      $form.fields.dateField1.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.dateField2)) {
      $form.fields.dateField2.label = $form.inParams.dateField2;
  }
  else {
      $form.fields.dateField2.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.dateField3)) {
      $form.fields.dateField3.label = $form.inParams.dateField3;
  }
  else {
      $form.fields.dateField3.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.dateField4)) {
      $form.fields.dateField4.label = $form.inParams.dateField4;
  }
  else {
      $form.fields.dateField4.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.dateField5)) {
      $form.fields.dateField5.label = $form.inParams.dateField5;
  }
  else {
      $form.fields.dateField5.hidden = true;
  }
  
  // Number Fields
  if ($utils.isDefined($form.inParams.numberField1)) {
      $form.fields.numberField1.label = $form.inParams.numberField1;
  }
  else {
      $form.fields.numberField1.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.numberField2)) {
      $form.fields.numberField2.label = $form.inParams.numberField2;
  }
  else {
      $form.fields.numberField2.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.numberField3)) {
      $form.fields.numberField3.label = $form.inParams.numberField3;
  }
  else {
      $form.fields.numberField3.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.numberField4)) {
      $form.fields.numberField4.label = $form.inParams.numberField4;
  }
  else {
      $form.fields.numberField4.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.numberField5)) {
      $form.fields.numberField5.label = $form.inParams.numberField5;
  }
  else {
      $form.fields.numberField5.hidden = true;
  }
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_generic_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // String fields
  $form.outParams.stringField1 = $form.fields.stringField1.control.value;
  $form.outParams.stringField2 = $form.fields.stringField2.control.value;
  $form.outParams.stringField3 = $form.fields.stringField3.control.value;
  $form.outParams.stringField4 = $form.fields.stringField4.control.value;
  $form.outParams.stringField5 = $form.fields.stringField5.control.value;
  
  // Date fields
  $form.outParams.dateField1 = $form.fields.dateField1.control.value;
  $form.outParams.dateField2 = $form.fields.dateField2.control.value;
  $form.outParams.dateField3 = $form.fields.dateField3.control.value;
  $form.outParams.dateField4 = $form.fields.dateField4.control.value;
  $form.outParams.dateField5 = $form.fields.dateField5.control.value;
  
  // Number fields
  $form.outParams.numberField1 = $form.fields.numberField1.control.value;
  $form.outParams.numberField2 = $form.fields.numberField2.control.value;
  $form.outParams.numberField3 = $form.fields.numberField3.control.value;
  $form.outParams.numberField4 = $form.fields.numberField4.control.value;
  $form.outParams.numberField5 = $form.fields.numberField5.control.value;
  
  
  $form.close();
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_generic_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  //#endregion private flows
  //#region validation flows
  numberField_validation<TC extends ValueControlModel>(field: TC) {
    if (!this.initialized){
      return Promise.resolve([]);
    }
    return this.numberField_validationInternal(
      { control: field, errors: [] },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async numberField_validationInternal<TC extends ValueControlModel>(
    $validation:{
      errors: Pick<string[], 'push'>,
      control: TC
    },
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
  ) {
    
  if (!$utils.isDefined($validation.control.value)) {
      $validation.errors.push(`Numeric value required`)
  }
  
  if (isNaN(Number($validation.control.value))) {
      $validation.errors.push(`Value must be numeric`)
  }
    return $validation.errors as string[];
  }
  //#endregion validation flows
  //#region validation flows
  
  on_validate_form() {
    const emptyResult = { stringField1:[],stringField2:[],stringField3:[],stringField4:[],stringField5:[],dateField1:[],dateField2:[],dateField3:[],dateField4:[],dateField5:[],numberField1:[],numberField2:[],numberField3:[],numberField4:[],numberField5:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.on_validate_formInternal(
      this,
      { fieldErrors: emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async on_validate_formInternal(
    $form: FootPrintManager_generic_formComponent,
    $validation:{
      fieldErrors: { stringField1: Pick<string[], 'push'>,stringField2: Pick<string[], 'push'>,stringField3: Pick<string[], 'push'>,stringField4: Pick<string[], 'push'>,stringField5: Pick<string[], 'push'>,dateField1: Pick<string[], 'push'>,dateField2: Pick<string[], 'push'>,dateField3: Pick<string[], 'push'>,dateField4: Pick<string[], 'push'>,dateField5: Pick<string[], 'push'>,numberField1: Pick<string[], 'push'>,numberField2: Pick<string[], 'push'>,numberField3: Pick<string[], 'push'>,numberField4: Pick<string[], 'push'>,numberField5: Pick<string[], 'push'>, }
    },
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
  ) {
    let anyDefinedFields = false;
  
  for (let key in $form.fields) {
      if ($utils.isDefined($form.fields[key]?.control?.value)) {
          anyDefinedFields = true;
      }
  }
  
  $form.toolbar.confirm.control.readOnly = !anyDefinedFields;
    return $validation.fieldErrors as { stringField1:[],stringField2:[],stringField3:[],stringField4:[],stringField5:[],dateField1:[],dateField2:[],dateField3:[],dateField4:[],dateField5:[],numberField1:[],numberField2:[],numberField3:[],numberField4:[],numberField5:[], };
  }
  //#endregion validation flows
}
