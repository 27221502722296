import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Utilities.frontend.types'

@Injectable({ providedIn: 'root' })
export class Utilities_crud_delete_dual_pk_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { entitySet: string, pk1String: string, pk1: number, pk2String: string, pk2: number }): Promise<{ reason?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.entitySet)) {
      missingRequiredInParams.push('\'entitySet\'');
    }
    if (isNil(inParams.pk1String)) {
      missingRequiredInParams.push('\'pk1String\'');
    }
    if (isNil(inParams.pk1)) {
      missingRequiredInParams.push('\'pk1\'');
    }
    if (isNil(inParams.pk2String)) {
      missingRequiredInParams.push('\'pk2String\'');
    }
    if (isNil(inParams.pk2)) {
      missingRequiredInParams.push('\'pk2\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Utilities/functions/crud_delete_dual_pk_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

