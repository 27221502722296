<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Printing</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-printer_packinglist" *ngIf="!filters.printer_packinglist.hidden" 
                                  class="field-container double {{filters.printer_packinglist.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.printer_packinglist.styles.style"
                                  [ngClass]="filters.printer_packinglist.styles.classes">
                              <div class="label-container"
                                    title="{{filters.printer_packinglist.label}}{{filters.printer_packinglist.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.printer_packinglist.label}}">{{filters.printer_packinglist.label}}<span *ngIf="filters.printer_packinglist.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <PrintNode-printers_dd_single 
                                  data-cy="selector"
                                  [type]="filters.printer_packinglist.control.type"
                                  formControlName="printer_packinglist"
                                  (displayTextChange)="filters.printer_packinglist.control.displayText=$event"
                                  [placeholder]="filters.printer_packinglist.control.placeholder"
                                  [styles]="filters.printer_packinglist.control.styles"
                                [letterOnly]="$fields_printer_packinglist_selector_inParams_letterOnly"
                              >
                              </PrintNode-printers_dd_single>
                              <ng-container *ngIf="filters.printer_packinglist.invalid">
                                <ng-container *ngFor="let error of filters.printer_packinglist.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-printer" *ngIf="!filters.printer.hidden" 
                                  class="field-container double {{filters.printer.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.printer.styles.style"
                                  [ngClass]="filters.printer.styles.classes">
                              <div class="label-container"
                                    title="{{filters.printer.label}}{{filters.printer.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.printer.label}}">{{filters.printer.label}}<span *ngIf="filters.printer.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <PrintNode-printers_dd_single 
                                  data-cy="selector"
                                  [type]="filters.printer.control.type"
                                  formControlName="printer"
                                  (displayTextChange)="filters.printer.control.displayText=$event"
                                  [placeholder]="filters.printer.control.placeholder"
                                  [styles]="filters.printer.control.styles"
                                [letterOnly]="$fields_printer_selector_inParams_letterOnly"
                              >
                              </PrintNode-printers_dd_single>
                              <ng-container *ngIf="filters.printer.invalid">
                                <ng-container *ngFor="let error of filters.printer.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-label_size" *ngIf="!filters.label_size.hidden" 
                                  class="field-container standard {{filters.label_size.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.label_size.styles.style"
                                  [ngClass]="filters.label_size.styles.classes">
                              <div class="label-container"
                                    title="{{filters.label_size.label}}{{filters.label_size.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.label_size.label}}">{{filters.label_size.label}}<span *ngIf="filters.label_size.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Manifesting-label_size_dd_single 
                                  data-cy="selector"
                                  [type]="filters.label_size.control.type"
                                  formControlName="label_size"
                                  (displayTextChange)="filters.label_size.control.displayText=$event"
                                  [placeholder]="filters.label_size.control.placeholder"
                                  [styles]="filters.label_size.control.styles"
                              >
                              </Manifesting-label_size_dd_single>
                              <ng-container *ngIf="filters.label_size.invalid">
                                <ng-container *ngFor="let error of filters.label_size.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!filtersets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup2.collapsible }">
                    <div *ngIf="!filtersets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Container details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup2.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-utilize_shipment_containers" *ngIf="!filters.utilize_shipment_containers.hidden" 
                                  class="field-container standard {{filters.utilize_shipment_containers.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.utilize_shipment_containers.styles.style"
                                  [ngClass]="filters.utilize_shipment_containers.styles.classes">
                              <div class="label-container"
                                    title="{{filters.utilize_shipment_containers.label}}{{filters.utilize_shipment_containers.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.utilize_shipment_containers.label}}">{{filters.utilize_shipment_containers.label}}<span *ngIf="filters.utilize_shipment_containers.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="utilize_shipment_containers"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.utilize_shipment_containers.control.styles.style"
                                            [ngClass]="filters.utilize_shipment_containers.control.styles.classes">{{filters.utilize_shipment_containers.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.utilize_shipment_containers.invalid">
                                <ng-container *ngFor="let error of filters.utilize_shipment_containers.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-container_types" *ngIf="!filters.container_types.hidden" 
                                  class="field-container standard {{filters.container_types.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.container_types.styles.style"
                                  [ngClass]="filters.container_types.styles.classes">
                              <div class="label-container"
                                    title="{{filters.container_types.label}}{{filters.container_types.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.container_types.label}}">{{filters.container_types.label}}<span *ngIf="filters.container_types.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Manifesting-containers_by_type_dd_single 
                                  data-cy="selector"
                                  [type]="filters.container_types.control.type"
                                  formControlName="container_types"
                                  (displayTextChange)="filters.container_types.control.displayText=$event"
                                  [placeholder]="filters.container_types.control.placeholder"
                                  [styles]="filters.container_types.control.styles"
                                [containerTypeCategoriesId]="$fields_container_types_selector_inParams_containerTypeCategoriesId"
                              >
                              </Manifesting-containers_by_type_dd_single>
                              <ng-container *ngIf="filters.container_types.invalid">
                                <ng-container *ngFor="let error of filters.container_types.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-container_count" *ngIf="!filters.container_count.hidden" 
                                  class="field-container standard {{filters.container_count.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.container_count.styles.style"
                                  [ngClass]="filters.container_count.styles.classes">
                              <div class="label-container"
                                    title="{{filters.container_count.label}}{{filters.container_count.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.container_count.label}}">{{filters.container_count.label}}<span *ngIf="filters.container_count.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="numberBox" formControlName="container_count"
                                      matInput
                                      numberBox
                                      [format]="filters.container_count.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{filters.container_count.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.container_count.control.placeholder}}"
                                      [ngStyle]="filters.container_count.control.styles.style"
                                      [ngClass]="filters.container_count.control.styles.classes">
                              <ng-container *ngIf="filters.container_count.invalid">
                                <ng-container *ngFor="let error of filters.container_count.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-length" *ngIf="!filters.length.hidden" 
                                  class="field-container standard {{filters.length.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.length.styles.style"
                                  [ngClass]="filters.length.styles.classes">
                              <div class="label-container"
                                    title="{{filters.length.label}}{{filters.length.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.length.label}}">{{filters.length.label}}<span *ngIf="filters.length.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="numberBox" formControlName="length"
                                      matInput
                                      numberBox
                                      [format]="filters.length.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{filters.length.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.length.control.placeholder}}"
                                      [ngStyle]="filters.length.control.styles.style"
                                      [ngClass]="filters.length.control.styles.classes">
                              <ng-container *ngIf="filters.length.invalid">
                                <ng-container *ngFor="let error of filters.length.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-width" *ngIf="!filters.width.hidden" 
                                  class="field-container standard {{filters.width.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.width.styles.style"
                                  [ngClass]="filters.width.styles.classes">
                              <div class="label-container"
                                    title="{{filters.width.label}}{{filters.width.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.width.label}}">{{filters.width.label}}<span *ngIf="filters.width.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="numberBox" formControlName="width"
                                      matInput
                                      numberBox
                                      [format]="filters.width.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{filters.width.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.width.control.placeholder}}"
                                      [ngStyle]="filters.width.control.styles.style"
                                      [ngClass]="filters.width.control.styles.classes">
                              <ng-container *ngIf="filters.width.invalid">
                                <ng-container *ngFor="let error of filters.width.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-height" *ngIf="!filters.height.hidden" 
                                  class="field-container standard {{filters.height.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.height.styles.style"
                                  [ngClass]="filters.height.styles.classes">
                              <div class="label-container"
                                    title="{{filters.height.label}}{{filters.height.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.height.label}}">{{filters.height.label}}<span *ngIf="filters.height.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="numberBox" formControlName="height"
                                      matInput
                                      numberBox
                                      [format]="filters.height.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{filters.height.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.height.control.placeholder}}"
                                      [ngStyle]="filters.height.control.styles.style"
                                      [ngClass]="filters.height.control.styles.classes">
                              <ng-container *ngIf="filters.height.invalid">
                                <ng-container *ngFor="let error of filters.height.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-weight" *ngIf="!filters.weight.hidden" 
                                  class="field-container standard {{filters.weight.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.weight.styles.style"
                                  [ngClass]="filters.weight.styles.classes">
                              <div class="label-container"
                                    title="{{filters.weight.label}}{{filters.weight.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.weight.label}}">{{filters.weight.label}}<span *ngIf="filters.weight.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="numberBox" formControlName="weight"
                                      matInput
                                      numberBox
                                      [format]="filters.weight.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{filters.weight.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.weight.control.placeholder}}"
                                      [ngStyle]="filters.weight.control.styles.style"
                                      [ngClass]="filters.weight.control.styles.classes">
                              <ng-container *ngIf="filters.weight.invalid">
                                <ng-container *ngFor="let error of filters.weight.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-contents_explanation" *ngIf="!filters.contents_explanation.hidden" 
                                  class="field-container standard {{filters.contents_explanation.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.contents_explanation.styles.style"
                                  [ngClass]="filters.contents_explanation.styles.classes">
                              <div class="label-container"
                                    title="{{filters.contents_explanation.label}}{{filters.contents_explanation.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.contents_explanation.label}}">{{filters.contents_explanation.label}}<span *ngIf="filters.contents_explanation.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="contents_explanation"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.contents_explanation.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.contents_explanation.control.placeholder}}"
                                      [ngStyle]="filters.contents_explanation.control.styles.style"
                                      [ngClass]="filters.contents_explanation.control.styles.classes"> 
                              <ng-container *ngIf="filters.contents_explanation.invalid">
                                <ng-container *ngFor="let error of filters.contents_explanation.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.shipments_easypost.hidden" class="tab" data-cy="tab-shipments_easypost">
              <h2 [className]="tabs.shipments_easypost.active? 'active': ''" (click)="tabs.shipments_easypost.activate()">{{tabs.shipments_easypost.title}}</h2>
            </div>
            <div *ngIf="!tabs.shipments_shiptheory.hidden" class="tab" data-cy="tab-shipments_shiptheory">
              <h2 [className]="tabs.shipments_shiptheory.active? 'active': ''" (click)="tabs.shipments_shiptheory.activate()">{{tabs.shipments_shiptheory.title}}</h2>
            </div>
          </div>
        
              <Manifesting-easypost_manifesting_shipments_grid *ngIf="tabs.shipments_easypost.active"
              #$tabs_shipments_easypost
              [shipmentIds]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_shipmentIds"
              [printerId]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_printerId"
              [length]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_length"
              [width]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_width"
              [height]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_height"
              [weight]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_weight"
              [utilizeShipmentContainer]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_utilizeShipmentContainer"
              [labelSize]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_labelSize"
              [contentsExplanation]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_contentsExplanation"
              [containerCount]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_containerCount"
              [printerIdPackinglist]="$tabs_shipments_easypost_easypost_manifesting_shipments_grid_inParams_printerIdPackinglist"
              ($refreshEvent)="refresh(false, false, '$tabs_shipments_easypost')">
              </Manifesting-easypost_manifesting_shipments_grid>
              <Manifesting-shiptheory_manifesting_shipments_grid *ngIf="tabs.shipments_shiptheory.active"
              #$tabs_shipments_shiptheory
              [shipmentIds]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_shipmentIds"
              [printerId]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_printerId"
              [length]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_length"
              [width]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_width"
              [height]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_height"
              [weight]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_weight"
              [utilizeShipmentContainer]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_utilizeShipmentContainer"
              [labelSize]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_labelSize"
              [contentsExplanation]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_contentsExplanation"
              [containerCount]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_containerCount"
              [printerIdPackinglist]="$tabs_shipments_shiptheory_shiptheory_manifesting_shipments_grid_inParams_printerIdPackinglist"
              ($refreshEvent)="refresh(false, false, '$tabs_shipments_shiptheory')">
              </Manifesting-shiptheory_manifesting_shipments_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>