import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ds_serialnumbers_expandService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { rows: number, materialId: number, packagingId: number, $top?: number, $skip?: number }): Promise<{ result?: { id?: number, Weight?: number, ShippingWeight?: number, WeightUomId?: number, Width?: number, Height?: number, Length?: number, DimensionUomId?: number, Volume?: number, ShippingVolume?: number, VolumeUomId?: number, DimensionsUom_Short_name?: string, WeightUom_Short_name?: string, VolumeUom_Short_name?: string, Packaging_Short_name?: string, Sub_packagingId?: number, Sub_Packaging_Amount?: number }[], totalCount?: number }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.rows)) {
      missingRequiredInParams.push('\'rows\'');
    }
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_expand/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { rows?: number, materialId?: number, packagingId?: number, $keys: number[] }): Promise<{ result?: { id?: number, Weight?: number, ShippingWeight?: number, WeightUomId?: number, Width?: number, Height?: number, Length?: number, DimensionUomId?: number, Volume?: number, ShippingVolume?: number, VolumeUomId?: number, DimensionsUom_Short_name?: string, WeightUom_Short_name?: string, VolumeUom_Short_name?: string, Packaging_Short_name?: string, Sub_packagingId?: number, Sub_Packaging_Amount?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_expand/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
