import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Document360_OperationService } from './Document360.operation.service';
import { Usersnap_OperationService } from './Usersnap.operation.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { PrintNode_OperationService } from './PrintNode.operation.service';
import { Materials_OperationService } from './Materials.operation.service';
import { PalletTransactions_OperationService } from './PalletTransactions.operation.service';
import { PurchaseOrders_OperationService } from './PurchaseOrders.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Lots_OperationService } from './Lots.operation.service';
import { Ultraship_OperationService } from './Ultraship.operation.service';
import { Reports_OperationService } from './Reports.operation.service';
import { SalesOrders_OperationService } from './SalesOrders.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';


@Injectable({ providedIn: 'root' })
export class app_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Document360 = this.injector.get(Document360_OperationService);
    this.Usersnap = this.injector.get(Usersnap_OperationService);
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.PrintNode = this.injector.get(PrintNode_OperationService);
    this.Materials = this.injector.get(Materials_OperationService);
    this.PalletTransactions = this.injector.get(PalletTransactions_OperationService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.Lots = this.injector.get(Lots_OperationService);
    this.Ultraship = this.injector.get(Ultraship_OperationService);
    this.Reports = this.injector.get(Reports_OperationService);
    this.SalesOrders = this.injector.get(SalesOrders_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
    this.FootPrintManager = this.injector.get(FootPrintManager_OperationService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_OperationService);
    this.Dynamics365BusinessCentral = this.injector.get(Dynamics365BusinessCentral_OperationService);
  }

    public Document360: Document360_OperationService;
    public Usersnap: Usersnap_OperationService;
    public Utilities: Utilities_OperationService;
    public PrintNode: PrintNode_OperationService;
    public Materials: Materials_OperationService;
    public PalletTransactions: PalletTransactions_OperationService;
    public PurchaseOrders: PurchaseOrders_OperationService;
    public Notifications: Notifications_OperationService;
    public Lots: Lots_OperationService;
    public Ultraship: Ultraship_OperationService;
    public Reports: Reports_OperationService;
    public SalesOrders: SalesOrders_OperationService;
    public Invoices: Invoices_OperationService;
    public FootPrintManager: FootPrintManager_OperationService;
    public FootPrintApiManager: FootPrintApiManager_OperationService;
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_OperationService;
  public app: app_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/app/operations/${operationName}/isauthorized`, null);
  }
}
