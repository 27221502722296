<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="confirm_container" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_confirm_container_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                    title="{{filters.warehouse.label}}{{filters.warehouse.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.warehouse.label}}">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Locations-warehouses_dd_single 
                                  data-cy="selector"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                              >
                              </Locations-warehouses_dd_single>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-location" *ngIf="!filters.location.hidden" 
                                  class="field-container standard {{filters.location.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.location.styles.style"
                                  [ngClass]="filters.location.styles.classes">
                              <div class="label-container"
                                    title="{{filters.location.label}}{{filters.location.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.location.label}}">{{filters.location.label}}<span *ngIf="filters.location.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Returns-returns_location_dd_single 
                                  data-cy="selector"
                                  [type]="filters.location.control.type"
                                  formControlName="location"
                                  (displayTextChange)="filters.location.control.displayText=$event"
                                  [placeholder]="filters.location.control.placeholder"
                                  [styles]="filters.location.control.styles"
                                [warehouseId]="$fields_location_selector_inParams_warehouseId"
                                [typeId]="$fields_location_selector_inParams_typeId"
                              >
                              </Returns-returns_location_dd_single>
                              <ng-container *ngIf="filters.location.invalid">
                                <ng-container *ngFor="let error of filters.location.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-shipping_container_code" *ngIf="!filters.shipping_container_code.hidden" 
                                  class="field-container standard {{filters.shipping_container_code.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.shipping_container_code.styles.style"
                                  [ngClass]="filters.shipping_container_code.styles.classes">
                              <div class="label-container"
                                    title="{{filters.shipping_container_code.label}}{{filters.shipping_container_code.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.shipping_container_code.label}}">{{filters.shipping_container_code.label}}<span *ngIf="filters.shipping_container_code.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="shipping_container_code"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.shipping_container_code.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.shipping_container_code.control.placeholder}}"
                                      [ngStyle]="filters.shipping_container_code.control.styles.style"
                                      [ngClass]="filters.shipping_container_code.control.styles.classes"> 
                              <ng-container *ngIf="filters.shipping_container_code.invalid">
                                <ng-container *ngFor="let error of filters.shipping_container_code.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-input_scan" *ngIf="!filters.input_scan.hidden" 
                                  class="field-container full {{filters.input_scan.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.input_scan.styles.style"
                                  [ngClass]="filters.input_scan.styles.classes">
                              <div class="label-container"
                                    title="{{filters.input_scan.label}}{{filters.input_scan.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.input_scan.label}}">{{filters.input_scan.label}}<span *ngIf="filters.input_scan.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="input_scan"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.input_scan.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.input_scan.control.placeholder}}"
                                      [ngStyle]="filters.input_scan.control.styles.style"
                                      [ngClass]="filters.input_scan.control.styles.classes"> 
                              <ng-container *ngIf="filters.input_scan.invalid">
                                <ng-container *ngFor="let error of filters.input_scan.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.return_tasks.hidden" class="tab" data-cy="tab-return_tasks">
              <h2 [className]="tabs.return_tasks.active? 'active': ''" (click)="tabs.return_tasks.activate()">{{tabs.return_tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.container_orders.hidden" class="tab" data-cy="tab-container_orders">
              <h2 [className]="tabs.container_orders.active? 'active': ''" (click)="tabs.container_orders.activate()">{{tabs.container_orders.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-blind_shipping_container_return_tasks_grid *ngIf="tabs.return_tasks.active"
              #$tabs_return_tasks
              [warehouseId]="$tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_warehouseId"
              [locationId]="$tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_locationId"
              [sourceShippingContainerId]="$tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_sourceShippingContainerId"
              [sourceLicensePlateId]="$tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_sourceLicensePlateId"
              [inputScan]="$tabs_return_tasks_blind_shipping_container_return_tasks_grid_inParams_inputScan"
              (tasksChanged)="on_tasks_changed($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_return_tasks')">
              </FootPrintManager-blind_shipping_container_return_tasks_grid>
              <FootPrintManager-return_orders_grid *ngIf="tabs.container_orders.active"
              #$tabs_container_orders
              [blindShippingContainerId]="$tabs_container_orders_return_orders_grid_inParams_blindShippingContainerId"
              ($refreshEvent)="refresh(false, false, '$tabs_container_orders')">
              </FootPrintManager-return_orders_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>