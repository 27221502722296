import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_outbound_details_bill_of_lading_reportService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, shipmentId?: number }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, Material?: { Description?: string, IsFixedWeight?: boolean, LookupCode?: string, PackagingLookups?: { PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, Weight?: number, WeightUomId?: number, WeightUom?: { Short_name?: string } }[] } }, Packaged?: { ShortName?: string }, ShipmentLine?: { OrderId?: number, ShipmentId?: number }, LicensePlate?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_details_bill_of_lading_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, shipmentId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, Material?: { Description?: string, IsFixedWeight?: boolean, LookupCode?: string, PackagingLookups?: { PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, Weight?: number, WeightUomId?: number, WeightUom?: { Short_name?: string } }[] } }, Packaged?: { ShortName?: string }, ShipmentLine?: { OrderId?: number, ShipmentId?: number }, LicensePlate?: { LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_details_bill_of_lading_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, shipmentId?: number, $keys: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, Material?: { Description?: string, IsFixedWeight?: boolean, LookupCode?: string, PackagingLookups?: { PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, Weight?: number, WeightUomId?: number, WeightUom?: { Short_name?: string } }[] } }, Packaged?: { ShortName?: string }, ShipmentLine?: { OrderId?: number, ShipmentId?: number }, LicensePlate?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_outbound_details_bill_of_lading_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
