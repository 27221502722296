<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.blind_receive.readOnly"
                [ngStyle]="row.options.blind_receive.styles.style"
                [ngClass]="row.options.blind_receive.styles.classes"
                (click)="row.blind_receive()">
          <span *ngIf="row.options.blind_receive.label">{{row.options.blind_receive.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="blind_receive" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_blind_receive_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-return_states" *ngIf="!filters.return_states.hidden" 
            class="field-container standard {{filters.return_states.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.return_states.styles.style"
            [ngClass]="filters.return_states.styles.classes">
        <div class="label-container"
              title="{{filters.return_states.label}}{{filters.return_states.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.return_states.label}}">{{filters.return_states.label}}<span *ngIf="filters.return_states.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Returns-shipping_container_return_states_dd_multi 
            data-cy="selector-multi"
            [type]="filters.return_states.control.type"
            formControlName="return_states"
            (displayTextChange)="filters.return_states.control.displayText=$event"
            [placeholder]="filters.return_states.control.placeholder"
            [styles]="filters.return_states.control.styles"
        >
        </Returns-shipping_container_return_states_dd_multi>
        <ng-container *ngIf="filters.return_states.invalid">
          <ng-container *ngFor="let error of filters.return_states.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="container_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.container_code.displayControl.styles.style"
          [ngClass]="row.cells.container_code.displayControl.styles.classes">{{row.cells.container_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.state.displayControl.styles.style"
          [ngClass]="row.cells.state.displayControl.styles.classes">{{row.cells.state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.location.displayControl.styles.style"
          [ngClass]="row.cells.location.displayControl.styles.classes">{{row.cells.location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.order_code.displayControl.styles.style"
          [ngClass]="row.cells.order_code.displayControl.styles.classes">{{row.cells.order_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner_code.displayControl.styles.style"
          [ngClass]="row.cells.owner_code.displayControl.styles.classes">{{row.cells.owner_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project_code.displayControl.styles.style"
          [ngClass]="row.cells.project_code.displayControl.styles.classes">{{row.cells.project_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
