import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';

import { Utilities_OperationService } from './Utilities.operation.service';
import { Attachments_OperationService } from './Attachments.operation.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Invoices_OperationService } from './Invoices.operation.service';


@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
    this.Utilities = this.injector.get(Utilities_OperationService);
    this.Attachments = this.injector.get(Attachments_OperationService);
    this.Notifications = this.injector.get(Notifications_OperationService);
    this.Invoices = this.injector.get(Invoices_OperationService);
  }

    public Utilities: Utilities_OperationService;
    public Attachments: Attachments_OperationService;
    public Notifications: Notifications_OperationService;
    public Invoices: Invoices_OperationService;
  public Dynamics365BusinessCentral: Dynamics365BusinessCentral_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/Dynamics365BusinessCentral/operations/${operationName}/isauthorized`, null);
  }
}
