import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';
import { Attachments_ReportService } from './Attachments.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Invoices_ReportService } from './Invoices.report.index';


@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Attachments = this.injector.get(Attachments_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
  }
    public Utilities: Utilities_ReportService;
    public Attachments: Attachments_ReportService;
    public Notifications: Notifications_ReportService;
    public Invoices: Invoices_ReportService;

  public Dynamics365BusinessCentral: Dynamics365BusinessCentral_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

