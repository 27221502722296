<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="delete_selected" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="integration_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.integration_name.displayControl.styles.style"
          [ngClass]="row.cells.integration_name.displayControl.styles.classes">{{row.cells.integration_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['integration_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.integration_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.integration_name.editControl.placeholder}}"
            [ngStyle]="row.cells.integration_name.editControl.styles.style"
            [ngClass]="row.cells.integration_name.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="alert_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.alert_id.displayControl.styles.style"
          [ngClass]="row.cells.alert_id.displayControl.styles.classes">{{row.cells.alert_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['alert_id_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.alert_id.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.alert_id.editControl.placeholder}}"
            [ngStyle]="row.cells.alert_id.editControl.styles.style"
            [ngClass]="row.cells.alert_id.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="filter_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.filter_name.displayControl.styles.style"
          [ngClass]="row.cells.filter_name.displayControl.styles.classes">{{row.cells.filter_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <FootPrintApiManager-message_properties_single 
        data-cy="selector"
        [type]="row.cells.filter_name.editControl.type"
        [formControl]="row.formGroup.controls['filter_name_edit']"
        (displayTextChange)="row.cells.filter_name.editControl.displayText=$event"
        [placeholder]="row.cells.filter_name.editControl.placeholder"
        [styles]="row.cells.filter_name.editControl.styles"
    >
    </FootPrintApiManager-message_properties_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="filter_value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.filter_value.displayControl.styles.style"
          [ngClass]="row.cells.filter_value.displayControl.styles.classes">{{row.cells.filter_value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['filter_value_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.filter_value.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.filter_value.editControl.placeholder}}"
            [ngStyle]="row.cells.filter_value.editControl.styles.style"
            [ngClass]="row.cells.filter_value.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="delete">

    <ng-template gridCellDisplayControlDef let-row>
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="row.cells.delete.displayControl.readOnly"
            [ngStyle]="row.cells.delete.displayControl.styles.style"
            [ngClass]="row.cells.delete.displayControl.styles.classes"
            (click)="row.on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="row.cells.delete.displayControl.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{row.cells.delete.displayControl.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{row.cells.delete.displayControl.label}}</div>
      </div>
    
    </button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
