import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Lots_ds_check_inventory_existsService } from './Lots.datasource.index';
import { Lots_ds_check_order_lines_existService } from './Lots.datasource.index';
import { Lots_ds_check_tasks_existService } from './Lots.datasource.index';
import { Lots_ds_find_lotsService } from './Lots.datasource.index';
import { Lots_ds_find_vendorlotsService } from './Lots.datasource.index';
import { Lots_ds_get_first_task_for_lotService } from './Lots.datasource.index';
import { Lots_ds_get_glot_by_materialIdService } from './Lots.datasource.index';
import { Lots_ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcodeService } from './Lots.datasource.index';
import { Lots_ds_get_lot_by_lotIdService } from './Lots.datasource.index';
import { Lots_ds_get_lot_status_change_reason_codesService } from './Lots.datasource.index';
import { Lots_ds_get_lots_by_materialIdService } from './Lots.datasource.index';
import { Lots_ds_get_material_by_materialIdService } from './Lots.datasource.index';
import { Lots_ds_get_status_by_statusIdService } from './Lots.datasource.index';
import { Lots_ds_get_vendor_lots_by_lookupcodeService } from './Lots.datasource.index';
import { Lots_ds_get_vendorlot_by_lookupcode_and_materialIdService } from './Lots.datasource.index';
import { Lots_ds_get_vendorlot_by_lotIdService } from './Lots.datasource.index';
import { Lots_ds_get_vendorlot_by_vendorlotIdService } from './Lots.datasource.index';
import { Lots_ds_lot_editorService } from './Lots.datasource.index';
import { Lots_ds_lot_statuses_ddService } from './Lots.datasource.index';
import { Lots_ds_lots_ddService } from './Lots.datasource.index';
import { Lots_ds_lots_gridService } from './Lots.datasource.index';
import { Lots_ds_lots_grid_flat_flow_basedService } from './Lots.datasource.index';
import { Lots_ds_lots_grid_oprhan_vendor_lotsService } from './Lots.datasource.index';
import { Lots_ds_lots_udf_gridService } from './Lots.datasource.index';
import { Lots_ds_reason_codes_ddService } from './Lots.datasource.index';
import { Lots_ds_vendorlots_ddService } from './Lots.datasource.index';

@Injectable({ providedIn: 'root' })
export class Lots_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Lots: Lots_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_check_inventory_exists: Lots_ds_check_inventory_existsService;
  public get ds_check_inventory_exists(): Lots_ds_check_inventory_existsService {
    if(!this._ds_check_inventory_exists) {
      this._ds_check_inventory_exists = this.injector.get(Lots_ds_check_inventory_existsService);
    }
    return this._ds_check_inventory_exists;
  }
  private _ds_check_order_lines_exist: Lots_ds_check_order_lines_existService;
  public get ds_check_order_lines_exist(): Lots_ds_check_order_lines_existService {
    if(!this._ds_check_order_lines_exist) {
      this._ds_check_order_lines_exist = this.injector.get(Lots_ds_check_order_lines_existService);
    }
    return this._ds_check_order_lines_exist;
  }
  private _ds_check_tasks_exist: Lots_ds_check_tasks_existService;
  public get ds_check_tasks_exist(): Lots_ds_check_tasks_existService {
    if(!this._ds_check_tasks_exist) {
      this._ds_check_tasks_exist = this.injector.get(Lots_ds_check_tasks_existService);
    }
    return this._ds_check_tasks_exist;
  }
  private _ds_find_lots: Lots_ds_find_lotsService;
  public get ds_find_lots(): Lots_ds_find_lotsService {
    if(!this._ds_find_lots) {
      this._ds_find_lots = this.injector.get(Lots_ds_find_lotsService);
    }
    return this._ds_find_lots;
  }
  private _ds_find_vendorlots: Lots_ds_find_vendorlotsService;
  public get ds_find_vendorlots(): Lots_ds_find_vendorlotsService {
    if(!this._ds_find_vendorlots) {
      this._ds_find_vendorlots = this.injector.get(Lots_ds_find_vendorlotsService);
    }
    return this._ds_find_vendorlots;
  }
  private _ds_get_first_task_for_lot: Lots_ds_get_first_task_for_lotService;
  public get ds_get_first_task_for_lot(): Lots_ds_get_first_task_for_lotService {
    if(!this._ds_get_first_task_for_lot) {
      this._ds_get_first_task_for_lot = this.injector.get(Lots_ds_get_first_task_for_lotService);
    }
    return this._ds_get_first_task_for_lot;
  }
  private _ds_get_glot_by_materialId: Lots_ds_get_glot_by_materialIdService;
  public get ds_get_glot_by_materialId(): Lots_ds_get_glot_by_materialIdService {
    if(!this._ds_get_glot_by_materialId) {
      this._ds_get_glot_by_materialId = this.injector.get(Lots_ds_get_glot_by_materialIdService);
    }
    return this._ds_get_glot_by_materialId;
  }
  private _ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode: Lots_ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcodeService;
  public get ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode(): Lots_ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcodeService {
    if(!this._ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode) {
      this._ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode = this.injector.get(Lots_ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcodeService);
    }
    return this._ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode;
  }
  private _ds_get_lot_by_lotId: Lots_ds_get_lot_by_lotIdService;
  public get ds_get_lot_by_lotId(): Lots_ds_get_lot_by_lotIdService {
    if(!this._ds_get_lot_by_lotId) {
      this._ds_get_lot_by_lotId = this.injector.get(Lots_ds_get_lot_by_lotIdService);
    }
    return this._ds_get_lot_by_lotId;
  }
  private _ds_get_lot_status_change_reason_codes: Lots_ds_get_lot_status_change_reason_codesService;
  public get ds_get_lot_status_change_reason_codes(): Lots_ds_get_lot_status_change_reason_codesService {
    if(!this._ds_get_lot_status_change_reason_codes) {
      this._ds_get_lot_status_change_reason_codes = this.injector.get(Lots_ds_get_lot_status_change_reason_codesService);
    }
    return this._ds_get_lot_status_change_reason_codes;
  }
  private _ds_get_lots_by_materialId: Lots_ds_get_lots_by_materialIdService;
  public get ds_get_lots_by_materialId(): Lots_ds_get_lots_by_materialIdService {
    if(!this._ds_get_lots_by_materialId) {
      this._ds_get_lots_by_materialId = this.injector.get(Lots_ds_get_lots_by_materialIdService);
    }
    return this._ds_get_lots_by_materialId;
  }
  private _ds_get_material_by_materialId: Lots_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): Lots_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(Lots_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_status_by_statusId: Lots_ds_get_status_by_statusIdService;
  public get ds_get_status_by_statusId(): Lots_ds_get_status_by_statusIdService {
    if(!this._ds_get_status_by_statusId) {
      this._ds_get_status_by_statusId = this.injector.get(Lots_ds_get_status_by_statusIdService);
    }
    return this._ds_get_status_by_statusId;
  }
  private _ds_get_vendor_lots_by_lookupcode: Lots_ds_get_vendor_lots_by_lookupcodeService;
  public get ds_get_vendor_lots_by_lookupcode(): Lots_ds_get_vendor_lots_by_lookupcodeService {
    if(!this._ds_get_vendor_lots_by_lookupcode) {
      this._ds_get_vendor_lots_by_lookupcode = this.injector.get(Lots_ds_get_vendor_lots_by_lookupcodeService);
    }
    return this._ds_get_vendor_lots_by_lookupcode;
  }
  private _ds_get_vendorlot_by_lookupcode_and_materialId: Lots_ds_get_vendorlot_by_lookupcode_and_materialIdService;
  public get ds_get_vendorlot_by_lookupcode_and_materialId(): Lots_ds_get_vendorlot_by_lookupcode_and_materialIdService {
    if(!this._ds_get_vendorlot_by_lookupcode_and_materialId) {
      this._ds_get_vendorlot_by_lookupcode_and_materialId = this.injector.get(Lots_ds_get_vendorlot_by_lookupcode_and_materialIdService);
    }
    return this._ds_get_vendorlot_by_lookupcode_and_materialId;
  }
  private _ds_get_vendorlot_by_lotId: Lots_ds_get_vendorlot_by_lotIdService;
  public get ds_get_vendorlot_by_lotId(): Lots_ds_get_vendorlot_by_lotIdService {
    if(!this._ds_get_vendorlot_by_lotId) {
      this._ds_get_vendorlot_by_lotId = this.injector.get(Lots_ds_get_vendorlot_by_lotIdService);
    }
    return this._ds_get_vendorlot_by_lotId;
  }
  private _ds_get_vendorlot_by_vendorlotId: Lots_ds_get_vendorlot_by_vendorlotIdService;
  public get ds_get_vendorlot_by_vendorlotId(): Lots_ds_get_vendorlot_by_vendorlotIdService {
    if(!this._ds_get_vendorlot_by_vendorlotId) {
      this._ds_get_vendorlot_by_vendorlotId = this.injector.get(Lots_ds_get_vendorlot_by_vendorlotIdService);
    }
    return this._ds_get_vendorlot_by_vendorlotId;
  }
  private _ds_lot_editor: Lots_ds_lot_editorService;
  public get ds_lot_editor(): Lots_ds_lot_editorService {
    if(!this._ds_lot_editor) {
      this._ds_lot_editor = this.injector.get(Lots_ds_lot_editorService);
    }
    return this._ds_lot_editor;
  }
  private _ds_lot_statuses_dd: Lots_ds_lot_statuses_ddService;
  public get ds_lot_statuses_dd(): Lots_ds_lot_statuses_ddService {
    if(!this._ds_lot_statuses_dd) {
      this._ds_lot_statuses_dd = this.injector.get(Lots_ds_lot_statuses_ddService);
    }
    return this._ds_lot_statuses_dd;
  }
  private _ds_lots_dd: Lots_ds_lots_ddService;
  public get ds_lots_dd(): Lots_ds_lots_ddService {
    if(!this._ds_lots_dd) {
      this._ds_lots_dd = this.injector.get(Lots_ds_lots_ddService);
    }
    return this._ds_lots_dd;
  }
  private _ds_lots_grid: Lots_ds_lots_gridService;
  public get ds_lots_grid(): Lots_ds_lots_gridService {
    if(!this._ds_lots_grid) {
      this._ds_lots_grid = this.injector.get(Lots_ds_lots_gridService);
    }
    return this._ds_lots_grid;
  }
  private _ds_lots_grid_flat_flow_based: Lots_ds_lots_grid_flat_flow_basedService;
  public get ds_lots_grid_flat_flow_based(): Lots_ds_lots_grid_flat_flow_basedService {
    if(!this._ds_lots_grid_flat_flow_based) {
      this._ds_lots_grid_flat_flow_based = this.injector.get(Lots_ds_lots_grid_flat_flow_basedService);
    }
    return this._ds_lots_grid_flat_flow_based;
  }
  private _ds_lots_grid_oprhan_vendor_lots: Lots_ds_lots_grid_oprhan_vendor_lotsService;
  public get ds_lots_grid_oprhan_vendor_lots(): Lots_ds_lots_grid_oprhan_vendor_lotsService {
    if(!this._ds_lots_grid_oprhan_vendor_lots) {
      this._ds_lots_grid_oprhan_vendor_lots = this.injector.get(Lots_ds_lots_grid_oprhan_vendor_lotsService);
    }
    return this._ds_lots_grid_oprhan_vendor_lots;
  }
  private _ds_lots_udf_grid: Lots_ds_lots_udf_gridService;
  public get ds_lots_udf_grid(): Lots_ds_lots_udf_gridService {
    if(!this._ds_lots_udf_grid) {
      this._ds_lots_udf_grid = this.injector.get(Lots_ds_lots_udf_gridService);
    }
    return this._ds_lots_udf_grid;
  }
  private _ds_reason_codes_dd: Lots_ds_reason_codes_ddService;
  public get ds_reason_codes_dd(): Lots_ds_reason_codes_ddService {
    if(!this._ds_reason_codes_dd) {
      this._ds_reason_codes_dd = this.injector.get(Lots_ds_reason_codes_ddService);
    }
    return this._ds_reason_codes_dd;
  }
  private _ds_vendorlots_dd: Lots_ds_vendorlots_ddService;
  public get ds_vendorlots_dd(): Lots_ds_vendorlots_ddService {
    if(!this._ds_vendorlots_dd) {
      this._ds_vendorlots_dd = this.injector.get(Lots_ds_vendorlots_ddService);
    }
    return this._ds_vendorlots_dd;
  }
}

