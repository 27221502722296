<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="release_count" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_release_count_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="hold_count" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_hold_count_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="complete_count" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_complete_count_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator2" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="duplicate_count" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_duplicate_count_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator1" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="cancel_count" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_cancel_count_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator3" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="delete_count" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_delete_count_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-only_discrepancies" *ngIf="!filters.only_discrepancies.hidden" 
            class="field-container standard {{filters.only_discrepancies.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.only_discrepancies.styles.style"
            [ngClass]="filters.only_discrepancies.styles.classes">
        <div class="label-container"
              title="{{filters.only_discrepancies.label}}{{filters.only_discrepancies.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.only_discrepancies.label}}">{{filters.only_discrepancies.label}}<span *ngIf="filters.only_discrepancies.required"
                  class="required-asterisk">*</span></label>
        </div>
        <mat-checkbox data-cy="checkBox" formControlName="only_discrepancies"
                      class="datex-checkbox"
                      color="primary"
                      [ngStyle]="filters.only_discrepancies.control.styles.style"
                      [ngClass]="filters.only_discrepancies.control.styles.classes">{{filters.only_discrepancies.control.label}}</mat-checkbox>
        <ng-container *ngIf="filters.only_discrepancies.invalid">
          <ng-container *ngFor="let error of filters.only_discrepancies.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
            class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.status.styles.style"
            [ngClass]="filters.status.styles.classes">
        <div class="label-container"
              title="{{filters.status.label}}{{filters.status.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.status.label}}">{{filters.status.label}}<span *ngIf="filters.status.required"
                  class="required-asterisk">*</span></label>
        </div>
        <InventoryCounts-inventory_count_statuses_dd_multi 
            data-cy="selector-multi"
            [type]="filters.status.control.type"
            formControlName="status"
            (displayTextChange)="filters.status.control.displayText=$event"
            [placeholder]="filters.status.control.placeholder"
            [styles]="filters.status.control.styles"
        >
        </InventoryCounts-inventory_count_statuses_dd_multi>
        <ng-container *ngIf="filters.status.invalid">
          <ng-container *ngFor="let error of filters.status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="status_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status_name.displayControl.styles.style"
          [ngClass]="row.cells.status_name.displayControl.styles.classes">{{row.cells.status_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="discrepancy">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.discrepancy.displayControl.styles.style"
          [ngClass]="row.cells.discrepancy.displayControl.styles.classes">{{row.cells.discrepancy.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse_name.displayControl.styles.style"
          [ngClass]="row.cells.warehouse_name.displayControl.styles.classes">{{row.cells.warehouse_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_date.displayControl.styles.style"
          [ngClass]="row.cells.created_date.displayControl.styles.classes">{{row.cells.created_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_by.displayControl.styles.style"
          [ngClass]="row.cells.created_by.displayControl.styles.classes">{{row.cells.created_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
