import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService, EModalSize, EToasterType, EToasterPosition } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { Dynamics365BusinessCentral_enviroment_singleComponent } from './Dynamics365BusinessCentral.enviroment_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Dynamics365BusinessCentral_enviroment_singleComponent),
  ],
  selector: 'Dynamics365BusinessCentral-initial_setup_form',
  templateUrl: './Dynamics365BusinessCentral.initial_setup_form.component.html'
})
export class Dynamics365BusinessCentral_initial_setup_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    clientid: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    clientecrets: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    enantid: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    scoope: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    customer_name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    compguid: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    enviroment: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    url: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    clientid: new FieldModel(new TextBoxModel(this.formGroup.controls['clientid'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Client id', true)
,
    clientecrets: new FieldModel(new TextBoxModel(this.formGroup.controls['clientecrets'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Client secret', true)
,
    enantid: new FieldModel(new TextBoxModel(this.formGroup.controls['enantid'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Tenant id', true)
,
    scoope: new FieldModel(new TextBoxModel(this.formGroup.controls['scoope'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Scope', true)
,
    customer_name: new FieldModel(new TextBoxModel(this.formGroup.controls['customer_name'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Company displayr name', true)
,
    compguid: new FieldModel(new TextBoxModel(this.formGroup.controls['compguid'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Company guid (will be populated on step 3)', false)
,
    enviroment: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['enviroment'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Enviroment', true)
,
    url: new FieldModel(new TextBoxModel(this.formGroup.controls['url'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Url', true)
,
    savez: new FieldModel(new ButtonModel('', new ButtonStyles(['creation'], null), false, '1.Save initial values', 'icon-ic_fluent_save_20_regular')
, new ControlContainerStyles(['attention'], null), '', false)
,
    cancel: new FieldModel(new ButtonModel('', new ButtonStyles(['destructive'], null), false, 'Cancel ', 'ms-Icon ms-Icon--Cancel')
, new ControlContainerStyles(['attention'], null), '', false)
,
    save: new FieldModel(new ButtonModel('', new ButtonStyles(['creation'], null), false, '2.Generate token', 'icon-ic_fluent_certificate_20_regular')
, new ControlContainerStyles(null, null), '', false)
,
    guid: new FieldModel(new ButtonModel('', new ButtonStyles(['creation'], null), false, '3.Generate guid', 'ms-Icon ms-Icon--GUID')
, new ControlContainerStyles(null, null), '', false)
,
  };

  fieldsets = {
  group1: new FieldsetModel('Step 1', true, false, true),
  group2: new FieldsetModel('Step 2', true, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    clientid: this.fields.clientid.control.valueChanges
    ,
    clientecrets: this.fields.clientecrets.control.valueChanges
    ,
    enantid: this.fields.enantid.control.valueChanges
    ,
    scoope: this.fields.scoope.control.valueChanges
    ,
    customer_name: this.fields.customer_name.control.valueChanges
    ,
    compguid: this.fields.compguid.control.valueChanges
    ,
    enviroment: this.fields.enviroment.control.valueChanges
    ,
    url: this.fields.url.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Dynamics365BusinessCentral_ShellService,
private datasources: Dynamics365BusinessCentral_DatasourceService,
private flows: Dynamics365BusinessCentral_FlowService,
private reports: Dynamics365BusinessCentral_ReportService,
private localization: Dynamics365BusinessCentral_LocalizationService,
private operations: Dynamics365BusinessCentral_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Initial setup';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    
    
    
    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .clientid
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .clientecrets
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .enantid
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .scoope
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .customer_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .compguid
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .enviroment
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .url
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_clicked(event = null) {
    return this.on_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_clickedInternal(
    $form: Dynamics365BusinessCentral_initial_setup_formComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'initial_setup_form.on_clicked');
  let application_name= "Dynamics365BusinessCentral"
  
  let c = (await $datasources.Dynamics365BusinessCentral.ds_get_customer_name.get({})).result
  
  let ad=''
  let at=''
  let cg=''
  
  
  await $flows.Utilities.configurations_write({
          input: [{ name: "ClientID", string_1: c.customer, value: $form.fields.clientid.control.value }],
          criteria: { application_name: application_name, encrypt: true }
      })
  await $flows.Utilities.configurations_write({
          input: [{ name: "ClientSecret", string_1: c.customer, value:  $form.fields.clientecrets.control.value }],
          criteria: { application_name: application_name, encrypt: true }
      })
  await $flows.Utilities.configurations_write({
          input: [{ name: "Scope", string_1: c.customer, value:  $form.fields.scoope.control.value }],
          criteria: { application_name: application_name, encrypt: false }
      })
  await $flows.Utilities.configurations_write({
          input: [{ name: "TenantID", string_1: c.customer, value:  $form.fields.enantid.control.value }],
          criteria: { application_name: application_name, encrypt: true }
      })
  await $flows.Utilities.configurations_write({
          input: [{ name: "url", string_1: c.customer, string_2: $form.fields.enviroment.control.value, value:  $form.fields.url.control.value}],
          criteria: { application_name: application_name, encrypt: false }
      })
  await $flows.Utilities.configurations_write({
          input: [{ name: "ADate", string_1: c.customer, value: ad }],
          criteria: { application_name: application_name, encrypt: false }
      })
  await $flows.Utilities.configurations_write({
          input: [{ name: "AccessToken", string_1: c.customer, value: at }],
          criteria: { application_name: application_name, encrypt: false }
      })
  await $flows.Utilities.configurations_write({
          input: [{ name: "compguid", string_1: c.customer, value:  $form.fields.compguid.control.value}],
          criteria: { application_name: application_name, encrypt: false }
      })
  
  
  }
  guid_create(event = null) {
    return this.guid_createInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async guid_createInternal(
    $form: Dynamics365BusinessCentral_initial_setup_formComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'initial_setup_form.guid_create');
  await $flows.Dynamics365BusinessCentral.flow_get_compguid({displayName: $form.fields.customer_name.control.value})
  }
  create_tokens(event = null) {
    return this.create_tokensInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async create_tokensInternal(
    $form: Dynamics365BusinessCentral_initial_setup_formComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'initial_setup_form.create_tokens');
  await $flows.Dynamics365BusinessCentral.flow_token_generate({})
  }
  //#endregion private flows
}
