import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Ultraship_ShellService, EModalSize, EToasterType, EToasterPosition } from './Ultraship.shell.service';
import { Ultraship_OperationService } from './Ultraship.operation.service';
import { Ultraship_DatasourceService } from './Ultraship.datasource.index';
import { Ultraship_FlowService } from './Ultraship.flow.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Ultraship_LocalizationService } from './Ultraship.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Ultraship.frontend.types'
import { $frontendTypes as $types} from './Ultraship.frontend.types' 


import { Ultraship_ultraship_messages_gridComponent } from './Ultraship.ultraship_messages_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Ultraship_ultraship_messages_gridComponent),
  ],
  selector: 'Ultraship-ultraship_hub',
  templateUrl: './Ultraship.ultraship_hub.component.html'
})
export class Ultraship_ultraship_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { application_name?: string, column_names?: any, filter_values?: any, user_name?: string } = { application_name: null, column_names: null, filter_values: null, user_name: null };
  //#region Inputs
  @Input('application_name') set $inParams_application_name(v: string) {
    this.inParams.application_name = v;
  }
  get $inParams_application_name(): string {
    return this.inParams.application_name;
  }
  @Input('column_names') set $inParams_column_names(v: any) {
    this.inParams.column_names = v;
  }
  get $inParams_column_names(): any {
    return this.inParams.column_names;
  }
  @Input('filter_values') set $inParams_filter_values(v: any) {
    this.inParams.filter_values = v;
  }
  get $inParams_filter_values(): any {
    return this.inParams.filter_values;
  }
  @Input('user_name') set $inParams_user_name(v: string) {
    this.inParams.user_name = v;
  }
  get $inParams_user_name(): string {
    return this.inParams.user_name;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      logs: new ToolModel(new ButtonModel('logs', new ButtonStyles(null, null), false, 'Logs', 'icon-ic_fluent_notepad_20_regular')
    ),
      configurations: new ToolModel(new SplitButtonModel<{ projects: ButtonModel, http: ButtonModel, sftp: ButtonModel }>(
        'configurations',
        new ButtonStyles(null, null),
        false,
        'Configurations',
        'icon-ic_fluent_settings_20_regular',
        [
          new ButtonModel('projects', new ButtonStyles(null, null), false, 'Projects', ''),
          new ButtonModel('http', new ButtonStyles(null, null), false, 'Http', ''),
          new ButtonModel('sftp', new ButtonStyles(null, null), false, 'SFTP', '')
        ])
    )
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      transactions: new TabItemModel(
        this.rootTabGroup, 
        'Transactions', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_transactions_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.application_name;
      
      return expr;
    }
  
    cacheValueFor_$tabs_transactions_ultraship_messages_grid_inParams_column_names: { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string };
    get $tabs_transactions_ultraship_messages_grid_inParams_column_names(): { number_1?: string, number_2?: string, number_3?: string, number_4?: string, number_5?: string, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.column_names;
      
      if(!isEqual(this.cacheValueFor_$tabs_transactions_ultraship_messages_grid_inParams_column_names, expr)) {
        this.cacheValueFor_$tabs_transactions_ultraship_messages_grid_inParams_column_names = expr;
      }
      return this.cacheValueFor_$tabs_transactions_ultraship_messages_grid_inParams_column_names;
    }
  
    cacheValueFor_$tabs_transactions_ultraship_messages_grid_inParams_filter_values: { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string };
    get $tabs_transactions_ultraship_messages_grid_inParams_filter_values(): { number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string } {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.filter_values;
      
      if(!isEqual(this.cacheValueFor_$tabs_transactions_ultraship_messages_grid_inParams_filter_values, expr)) {
        this.cacheValueFor_$tabs_transactions_ultraship_messages_grid_inParams_filter_values = expr;
      }
      return this.cacheValueFor_$tabs_transactions_ultraship_messages_grid_inParams_filter_values;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_transactions', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_transactions: Ultraship_ultraship_messages_gridComponent;
    //#endregion tabs children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Ultraship Hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Configure integration and monitor transactions`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: Ultraship_ShellService,
  private datasources: Ultraship_DatasourceService,
  private flows: Ultraship_FlowService,
  private reports: Ultraship_ReportService,
  private localization: Ultraship_LocalizationService,
  private operations: Ultraship_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.transactions,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'ultraship_hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_transactions') {
      if (!isNil(this.$tabs_transactions) && !this.tabs.transactions.hidden) {
        this.$tabs_transactions.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  
  let application_name: string =  $settings.Ultraship.ApplicationName.toUpperCase() ?? $hub.inParams.application_name;
  
  $hub.inParams.application_name = application_name
  }
  on_refresh_dropdowns(event = null) {
    return this.on_refresh_dropdownsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_dropdownsInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'ultraship_hub.on_refresh_dropdowns');
  //O.Arias - 06/20/2024
  
  $flows.Utilities.list_applications({ action: "Refresh" });
  $flows.Utilities.list_processes({ action: "Refresh" });
  $flows.Utilities.list_statuses({ action: "Refresh" });
  $flows.Utilities.list_transaction_types({ action: "Refresh" });
  }
  on_logs_button_clicked(event = null) {
    return this.on_logs_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_logs_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  await $shell.Utilities.openlogs_gridDialog({ application_name: `${$hub.inParams.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  on_projects_button_clicked(event = null) {
    return this.on_projects_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_projects_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  await $shell.Ultraship.openultraship_project_configurations_gridDialog({ application_name: `${$hub.inParams.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  on_http_button_clicked(event = null) {
    return this.on_http_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_http_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  await $shell.Ultraship.openultraship_http_configurations_gridDialog({ application_name: `${$hub.inParams.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  on_sftp_button_clicked(event = null) {
    return this.on_sftp_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_sftp_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  await $shell.Ultraship.openultraship_sftp_configurations_gridDialog({ application_name: `${$hub.inParams.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  //#endregion private flows
}
