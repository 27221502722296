<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_confirm($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-pallet_options"
                     *ngIf="!fieldsets.pallet_options.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.pallet_options.collapsible }">
                    <div *ngIf="!fieldsets.pallet_options.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.pallet_options.toggle()">
                      <span class="fieldsetsTitle-text">Pallet options</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.pallet_options.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.pallet_options.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.pallet_options.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.pallet_options.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order" *ngIf="!fields.order.hidden" 
                            class="field-container standard {{fields.order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order.styles.style"
                            [ngClass]="fields.order.styles.classes">
                        <div class="label-container"
                              title="{{fields.order.label}}{{fields.order.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order.label}}">{{fields.order.label}}<span *ngIf="fields.order.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <FootPrintManager-orders_by_project_dd_single 
                            data-cy="selector"
                            [type]="fields.order.control.type"
                            formControlName="order"
                            (displayTextChange)="fields.order.control.displayText=$event"
                            [placeholder]="fields.order.control.placeholder"
                            [styles]="fields.order.control.styles"
                          [project_id]="$fields_order_selector_inParams_project_id"
                        >
                        </FootPrintManager-orders_by_project_dd_single>
                        <ng-container *ngIf="fields.order.invalid">
                          <ng-container *ngFor="let error of fields.order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-pallet_transaction"
                     *ngIf="!fieldsets.pallet_transaction.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.pallet_transaction.collapsible }">
                    <div *ngIf="!fieldsets.pallet_transaction.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.pallet_transaction.toggle()">
                      <span class="fieldsetsTitle-text">Pallet transaction</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.pallet_transaction.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.pallet_transaction.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.pallet_transaction.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.pallet_transaction.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-pallet_class" *ngIf="!fields.pallet_class.hidden" 
                            class="field-container full {{fields.pallet_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pallet_class.styles.style"
                            [ngClass]="fields.pallet_class.styles.classes">
                        <div class="label-container"
                              title="{{fields.pallet_class.label}}{{fields.pallet_class.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.pallet_class.label}}">{{fields.pallet_class.label}}<span *ngIf="fields.pallet_class.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <FootPrintManager-pallet_classes_dd_single 
                            data-cy="selector"
                            [type]="fields.pallet_class.control.type"
                            formControlName="pallet_class"
                            (displayTextChange)="fields.pallet_class.control.displayText=$event"
                            [placeholder]="fields.pallet_class.control.placeholder"
                            [styles]="fields.pallet_class.control.styles"
                          [project_id]="$fields_pallet_class_selector_inParams_project_id"
                        >
                        </FootPrintManager-pallet_classes_dd_single>
                        <ng-container *ngIf="fields.pallet_class.invalid">
                          <ng-container *ngFor="let error of fields.pallet_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inbound" *ngIf="!fields.inbound.hidden" 
                            class="field-container standard {{fields.inbound.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.inbound.styles.style"
                            [ngClass]="fields.inbound.styles.classes">
                        <div class="label-container"
                              title="{{fields.inbound.label}}{{fields.inbound.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.inbound.label}}">{{fields.inbound.label}}<span *ngIf="fields.inbound.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="inbound"
                                matInput
                                numberBox
                                [format]="fields.inbound.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.inbound.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.inbound.control.placeholder}}"
                                [ngStyle]="fields.inbound.control.styles.style"
                                [ngClass]="fields.inbound.control.styles.classes">
                        <ng-container *ngIf="fields.inbound.invalid">
                          <ng-container *ngFor="let error of fields.inbound.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-outbound" *ngIf="!fields.outbound.hidden" 
                            class="field-container standard {{fields.outbound.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.outbound.styles.style"
                            [ngClass]="fields.outbound.styles.classes">
                        <div class="label-container"
                              title="{{fields.outbound.label}}{{fields.outbound.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.outbound.label}}">{{fields.outbound.label}}<span *ngIf="fields.outbound.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="outbound"
                                matInput
                                numberBox
                                [format]="fields.outbound.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.outbound.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.outbound.control.placeholder}}"
                                [ngStyle]="fields.outbound.control.styles.style"
                                [ngClass]="fields.outbound.control.styles.classes">
                        <ng-container *ngIf="fields.outbound.invalid">
                          <ng-container *ngFor="let error of fields.outbound.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-amount" *ngIf="!fields.amount.hidden" 
                            class="field-container standard {{fields.amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.amount.styles.style"
                            [ngClass]="fields.amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.amount.label}}{{fields.amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.amount.label}}">{{fields.amount.label}}<span *ngIf="fields.amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="amount"
                                matInput
                                numberBox
                                [format]="fields.amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.amount.control.placeholder}}"
                                [ngStyle]="fields.amount.control.styles.style"
                                [ngClass]="fields.amount.control.styles.classes">
                        <ng-container *ngIf="fields.amount.invalid">
                          <ng-container *ngFor="let error of fields.amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-date" *ngIf="!fields.date.hidden" 
                            class="field-container standard {{fields.date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.date.styles.style"
                            [ngClass]="fields.date.styles.classes">
                        <div class="label-container"
                              title="{{fields.date.label}}{{fields.date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.date.label}}">{{fields.date.label}}<span *ngIf="fields.date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="date"
                            [format]="fields.date.control.format"
                            [mode]="fields.date.control.mode"
                            [ngStyle]="fields.date.control.styles.style"
                            [ngClass]="fields.date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.date.invalid">
                          <ng-container *ngFor="let error of fields.date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference" *ngIf="!fields.reference.hidden" 
                            class="field-container standard {{fields.reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reference.styles.style"
                            [ngClass]="fields.reference.styles.classes">
                        <div class="label-container"
                              title="{{fields.reference.label}}{{fields.reference.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reference.label}}">{{fields.reference.label}}<span *ngIf="fields.reference.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference.control.placeholder}}"
                                [ngStyle]="fields.reference.control.styles.style"
                                [ngClass]="fields.reference.control.styles.classes"> 
                        <ng-container *ngIf="fields.reference.invalid">
                          <ng-container *ngFor="let error of fields.reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>