<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="confirm" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_confirm_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="cancel" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_cancel_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden" 
            class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.warehouses.styles.style"
            [ngClass]="filters.warehouses.styles.classes">
        <div class="label-container"
              title="{{filters.warehouses.label}}{{filters.warehouses.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.warehouses.label}}">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Locations-warehouses_dd_multi 
            data-cy="selector-multi"
            [type]="filters.warehouses.control.type"
            formControlName="warehouses"
            (displayTextChange)="filters.warehouses.control.displayText=$event"
            [placeholder]="filters.warehouses.control.placeholder"
            [styles]="filters.warehouses.control.styles"
        >
        </Locations-warehouses_dd_multi>
        <ng-container *ngIf="filters.warehouses.invalid">
          <ng-container *ngFor="let error of filters.warehouses.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
            class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.owner.styles.style"
            [ngClass]="filters.owner.styles.classes">
        <div class="label-container"
              title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Owners-owners_dd_single 
            data-cy="selector"
            [type]="filters.owner.control.type"
            formControlName="owner"
            (displayTextChange)="filters.owner.control.displayText=$event"
            [placeholder]="filters.owner.control.placeholder"
            [styles]="filters.owner.control.styles"
          [statusId]="$fields_owner_selector_inParams_statusId"
        >
        </Owners-owners_dd_single>
        <ng-container *ngIf="filters.owner.invalid">
          <ng-container *ngFor="let error of filters.owner.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
            class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.project.styles.style"
            [ngClass]="filters.project.styles.classes">
        <div class="label-container"
              title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Owners-projects_dd_single 
            data-cy="selector"
            [type]="filters.project.control.type"
            formControlName="project"
            (displayTextChange)="filters.project.control.displayText=$event"
            [placeholder]="filters.project.control.placeholder"
            [styles]="filters.project.control.styles"
          [statusId]="$fields_project_selector_inParams_statusId"
          [ownerId]="$fields_project_selector_inParams_ownerId"
        >
        </Owners-projects_dd_single>
        <ng-container *ngIf="filters.project.invalid">
          <ng-container *ngFor="let error of filters.project.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
              title="{{filters.date_from.label}}{{filters.date_from.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.date_from.label}}">{{filters.date_from.label}}<span *ngIf="filters.date_from.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
              title="{{filters.date_to.label}}{{filters.date_to.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.date_to.label}}">{{filters.date_to.label}}<span *ngIf="filters.date_to.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_type" *ngIf="!filters.date_type.hidden" 
            class="field-container standard {{filters.date_type.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_type.styles.style"
            [ngClass]="filters.date_type.styles.classes">
        <div class="label-container"
              title="{{filters.date_type.label}}{{filters.date_type.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.date_type.label}}">{{filters.date_type.label}}<span *ngIf="filters.date_type.required"
                  class="required-asterisk">*</span></label>
        </div>
        <SalesOrders-sales_order_date_types_dd_single 
            data-cy="selector"
            [type]="filters.date_type.control.type"
            formControlName="date_type"
            (displayTextChange)="filters.date_type.control.displayText=$event"
            [placeholder]="filters.date_type.control.placeholder"
            [styles]="filters.date_type.control.styles"
        >
        </SalesOrders-sales_order_date_types_dd_single>
        <ng-container *ngIf="filters.date_type.invalid">
          <ng-container *ngFor="let error of filters.date_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_class">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.order_class.displayControl.styles.style"
          [ngClass]="row.cells.order_class.displayControl.styles.classes">{{row.cells.order_class.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner_reference.displayControl.styles.style"
          [ngClass]="row.cells.owner_reference.displayControl.styles.classes">{{row.cells.owner_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.vendor_reference.displayControl.styles.style"
          [ngClass]="row.cells.vendor_reference.displayControl.styles.classes">{{row.cells.vendor_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="carrier">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.carrier.displayControl.styles.style"
          [ngClass]="row.cells.carrier.displayControl.styles.classes">{{row.cells.carrier.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="service_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.service_type.displayControl.styles.style"
          [ngClass]="row.cells.service_type.displayControl.styles.classes">{{row.cells.service_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="fulfilled_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.fulfilled_on.displayControl.styles.style"
          [ngClass]="row.cells.fulfilled_on.displayControl.styles.classes">{{row.cells.fulfilled_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="requested_delivery_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.requested_delivery_on.displayControl.styles.style"
          [ngClass]="row.cells.requested_delivery_on.displayControl.styles.classes">{{row.cells.requested_delivery_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
