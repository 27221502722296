import { HttpService } from './http.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root'})
export class CleanupLoggerService {
  constructor(private httpService: HttpService) {
  }

  public async log(appReferenceName: string, referenceName: string): Promise<void> {
    try {
      return await this.httpService.post('/api/$treeshakelog', {
        appReferenceName: appReferenceName,
        referenceName: referenceName
      });
    } catch {
      //make sure we don't disrupt the application in case the logging fails
      return Promise.resolve();
    }
  }
}