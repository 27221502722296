import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Notifications_ds_get_auto_email_eligible_ordersService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { orderTypeId?: number, projectId?: number, from_date?: string, $top?: number, $skip?: number }): Promise<{ result?: { order_id?: number, lookup_code?: string, warehouse_id?: number, owner_reference?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Notifications/datasources/ds_get_auto_email_eligible_orders/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderTypeId?: number, projectId?: number, from_date?: string, $keys: number[] }): Promise<{ result?: { order_id?: number, lookup_code?: string, warehouse_id?: number, owner_reference?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Notifications/datasources/ds_get_auto_email_eligible_orders/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
