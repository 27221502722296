import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService, EModalSize, EToasterType, EToasterPosition } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 


import { Dynamics365BusinessCentral_invoices_gridComponent } from './Dynamics365BusinessCentral.invoices_grid.component';
import { Dynamics365BusinessCentral_messages_success_gridComponent } from './Dynamics365BusinessCentral.messages_success_grid.component';
import { Dynamics365BusinessCentral_messages_fail_gridComponent } from './Dynamics365BusinessCentral.messages_fail_grid.component';
import { Dynamics365BusinessCentral_count_by_groups_widgetComponent } from './Dynamics365BusinessCentral.count_by_groups_widget.component';
import { Dynamics365BusinessCentral_number_of_errors_widgetComponent } from './Dynamics365BusinessCentral.number_of_errors_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Dynamics365BusinessCentral_invoices_gridComponent),
    forwardRef(() => Dynamics365BusinessCentral_messages_success_gridComponent),
    forwardRef(() => Dynamics365BusinessCentral_messages_fail_gridComponent),
    forwardRef(() => Dynamics365BusinessCentral_count_by_groups_widgetComponent),
    forwardRef(() => Dynamics365BusinessCentral_number_of_errors_widgetComponent),
  ],
  selector: 'Dynamics365BusinessCentral-Invoice_export_hub',
  templateUrl: './Dynamics365BusinessCentral.Invoice_export_hub.component.html'
})
export class Dynamics365BusinessCentral_Invoice_export_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { Customerr?: string } = { Customerr: null };
  //#region Inputs
  @Input('Customerr') set $inParams_Customerr(v: string) {
    this.inParams.Customerr = v;
  }
  get $inParams_Customerr(): string {
    return this.inParams.Customerr;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { refresh_toggle?: boolean } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      manage: new ToolModel(new SplitButtonModel<{ child1: ButtonModel, child2: ButtonModel, child3: ButtonModel, notiffication: ButtonModel, initial_setup: ButtonModel }>(
        'manage',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_settings_20_regular',
        [
          new ButtonModel('child1', new ButtonStyles(null, null), false, 'Logs', ''),
          new ButtonModel('child2', new ButtonStyles(null, null), false, 'Messages', ''),
          new ButtonModel('child3', new ButtonStyles(null, null), false, 'Config', ''),
          new ButtonModel('notiffication', new ButtonStyles(null, null), false, 'Notiffication', ''),
          new ButtonModel('initial_setup', new ButtonStyles(null, null), false, 'Initial setup', '')
        ])
    )
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      pending: new TabItemModel(
        this.rootTabGroup, 
        'Pending', 
        ),
      tab2: new TabItemModel(
        this.rootTabGroup, 
        'Posted', 
        ),
      tab3: new TabItemModel(
        this.rootTabGroup, 
        'Failed', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_pending_invoices_grid_inParams_statusIds(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = 1;
      
      return expr;
    }
  
    get $tabs_tab2_messages_success_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Dynamics365BusinessCentral';
      
      return expr;
    }
  
    get $tabs_tab2_messages_success_grid_inParams_customerr(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.Customerr;
      
      return expr;
    }
  
    get $tabs_tab3_messages_fail_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Dynamics365BusinessCentral';
      
      return expr;
    }
  
    get $tabs_tab3_messages_fail_grid_inParams_customerr(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.Customerr;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_pending', { read: Dynamics365BusinessCentral_invoices_gridComponent }) $tabs_pending: Dynamics365BusinessCentral_invoices_gridComponent;
      @ViewChild('$tabs_tab2', { read: Dynamics365BusinessCentral_messages_success_gridComponent }) $tabs_tab2: Dynamics365BusinessCentral_messages_success_gridComponent;
      @ViewChild('$tabs_tab3', { read: Dynamics365BusinessCentral_messages_fail_gridComponent }) $tabs_tab3: Dynamics365BusinessCentral_messages_fail_gridComponent;
    //#endregion tabs children
    widgets = {
      invoice_widget1: new WidgetModel(),
      Errors: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_invoice_widget1_inParams_customerr(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.Customerr;
      
      return expr;
    }
  
    get $widgets_invoice_widget1_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Dynamics365BusinessCentral';
      
      return expr;
    }
  
    get $widgets_invoice_widget1_inParams_refresh(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.refresh_toggle;
      
      return expr;
    }
  
    get $widgets_Errors_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = 'Dynamics365BusinessCentral';
      
      return expr;
    }
  
    get $widgets_Errors_inParams_customerr(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.Customerr;
      
      return expr;
    }
  
    get $widgets_Errors_inParams_refresh_control(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.refresh_toggle;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_invoice_widget1', { read:  Dynamics365BusinessCentral_count_by_groups_widgetComponent }) $widgets_invoice_widget1: Dynamics365BusinessCentral_count_by_groups_widgetComponent;
      @ViewChild('$widgets_Errors', { read:  Dynamics365BusinessCentral_number_of_errors_widgetComponent }) $widgets_Errors: Dynamics365BusinessCentral_number_of_errors_widgetComponent;
    //#endregion widgets children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.inParams.Customerr + ' Business Central invoice hub';
  }


  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: Dynamics365BusinessCentral_ShellService,
  private datasources: Dynamics365BusinessCentral_DatasourceService,
  private flows: Dynamics365BusinessCentral_FlowService,
  private reports: Dynamics365BusinessCentral_ReportService,
  private localization: Dynamics365BusinessCentral_LocalizationService,
  private operations: Dynamics365BusinessCentral_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.pending,
      this.tabs.tab2,
      this.tabs.tab3,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = ' Invoice_export_hub';
    
    const $hub = this;
    const $utils = this.utils;


    await this.init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_invoice_widget1') {
      if (!isNil(this.$widgets_invoice_widget1) && !this.widgets.invoice_widget1.hidden) {
        this.$widgets_invoice_widget1.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_Errors') {
      if (!isNil(this.$widgets_Errors) && !this.widgets.Errors.hidden) {
        this.$widgets_Errors.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_pending') {
      if (!isNil(this.$tabs_pending) && !this.tabs.pending.hidden) {
        this.$tabs_pending.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tab2') {
      if (!isNil(this.$tabs_tab2) && !this.tabs.tab2.hidden) {
        this.$tabs_tab2.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_tab3') {
      if (!isNil(this.$tabs_tab3) && !this.tabs.tab3.hidden) {
        this.$tabs_tab3.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  init(event = null) {
    return this.initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async initInternal(
    $hub: Dynamics365BusinessCentral_Invoice_export_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'Invoice_export_hub.init');
  var cn = (await $datasources.Dynamics365BusinessCentral.ds_get_customer_name.get({})).result
  $hub.inParams.Customerr=cn.customer
  
    $hub.toolbar.manage.hidden = false;
  
  $hub.vars.refresh_toggle = false;
  
  }
  on_logs_clicked(event = null) {
    return this.on_logs_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_logs_clickedInternal(
    $hub: Dynamics365BusinessCentral_Invoice_export_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'Invoice_export_hub.on_logs_clicked');
  
  
  await $shell.Utilities.openlogs_gridDialog({ application_name: 'Dynamics365BusinessCentral'}, 'flyout', EModalSize.Xlarge);
   $hub.refresh();
  }
  on_message_click(event = null) {
    return this.on_message_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_message_clickInternal(
    $hub: Dynamics365BusinessCentral_Invoice_export_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'Invoice_export_hub.on_message_click');
  await $shell.Utilities.openmessages_gridDialog({ application_name: 'Dynamics365BusinessCentral'}, 'flyout', EModalSize.Xlarge);
   $hub.refresh();
  
  }
  on_config_click(event = null) {
    return this.on_config_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_config_clickInternal(
    $hub: Dynamics365BusinessCentral_Invoice_export_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'Invoice_export_hub.on_config_click');
  
  let custrr=$settings.Dynamics365BusinessCentral.customer
  await $shell.Utilities.openconfigurations_gridDialog({ application_name: 'Dynamics365BusinessCentral',filter_values:{string_1:custrr}}, 'flyout', EModalSize.Xlarge);
  $hub.refresh();
  
  }
  on_notiff_click(event = null) {
    return this.on_notiff_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_notiff_clickInternal(
    $hub: Dynamics365BusinessCentral_Invoice_export_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'Invoice_export_hub.on_notiff_click');
  
  }
  on_clicked(event = null) {
    return this.on_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_clickedInternal(
    $hub: Dynamics365BusinessCentral_Invoice_export_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'Invoice_export_hub.on_clicked');
  $hub.refresh
  }
  hub_refresh(event = null) {
    return this.hub_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async hub_refreshInternal(
    $hub: Dynamics365BusinessCentral_Invoice_export_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'Invoice_export_hub.hub_refresh');
  $hub.vars.refresh_toggle = !$hub.vars.refresh_toggle;
  
  $hub.refresh();
  }
  on_initial_click(event = null) {
    return this.on_initial_clickInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initial_clickInternal(
    $hub: Dynamics365BusinessCentral_Invoice_export_hubComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'Invoice_export_hub.on_initial_click');
  await $shell.Dynamics365BusinessCentral.openinitial_setup_formDialog()
   $hub.refresh();
  }
  //#endregion private flows
}
