import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';


import { $frontendTypes } from './ExcelProjectImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelProjectImport_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public ExcelProjectImport: ExcelProjectImport_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
}
