<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="confirm" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_confirm_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="cancel" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_cancel_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="separator1" let-tool>
            <div class="tool-separator"></div>
          </ng-template>
          <ng-template toolbarToolDef="average_weights" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_apply_weight_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="serial_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.serial_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.serial_lookupcode.displayControl.styles.classes">{{row.cells.serial_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['serial_lookupcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.serial_lookupcode.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.serial_lookupcode.editControl.placeholder}}"
            [ngStyle]="row.cells.serial_lookupcode.editControl.styles.style"
            [ngClass]="row.cells.serial_lookupcode.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="net_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.net_weight.displayControl.styles.style"
          [ngClass]="row.cells.net_weight.displayControl.styles.classes">{{row.cells.net_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['net_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.net_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.net_weight.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.net_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.net_weight.editControl.styles.style"
            [ngClass]="row.cells.net_weight.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="gross_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.gross_weight.displayControl.styles.style"
          [ngClass]="row.cells.gross_weight.displayControl.styles.classes">{{row.cells.gross_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['gross_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.gross_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.gross_weight.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.gross_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.gross_weight.editControl.styles.style"
            [ngClass]="row.cells.gross_weight.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="uom_weight_unit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.uom_weight_unit.displayControl.styles.style"
          [ngClass]="row.cells.uom_weight_unit.displayControl.styles.classes">{{row.cells.uom_weight_unit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <SerialNumbers-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.uom_weight_unit.editControl.type"
        [formControl]="row.formGroup.controls['uom_weight_unit_edit']"
        (displayTextChange)="row.cells.uom_weight_unit.editControl.displayText=$event"
        [placeholder]="row.cells.uom_weight_unit.editControl.placeholder"
        [styles]="row.cells.uom_weight_unit.editControl.styles"
      [typeId]="row.$fields_uom_weight_unit_selector_inParams_typeId"
    >
    </SerialNumbers-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['length_edit']"
            matInput
            numberBox
            [format]="row.cells.length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.length.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.length.editControl.placeholder}}"
            [ngStyle]="row.cells.length.editControl.styles.style"
            [ngClass]="row.cells.length.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes">{{row.cells.width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['width_edit']"
            matInput
            numberBox
            [format]="row.cells.width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.width.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.width.editControl.placeholder}}"
            [ngStyle]="row.cells.width.editControl.styles.style"
            [ngClass]="row.cells.width.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes">{{row.cells.height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['height_edit']"
            matInput
            numberBox
            [format]="row.cells.height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.height.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.height.editControl.placeholder}}"
            [ngStyle]="row.cells.height.editControl.styles.style"
            [ngClass]="row.cells.height.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="uom_dimensions_unit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.uom_dimensions_unit.displayControl.styles.style"
          [ngClass]="row.cells.uom_dimensions_unit.displayControl.styles.classes">{{row.cells.uom_dimensions_unit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <SerialNumbers-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.uom_dimensions_unit.editControl.type"
        [formControl]="row.formGroup.controls['uom_dimensions_unit_edit']"
        (displayTextChange)="row.cells.uom_dimensions_unit.editControl.displayText=$event"
        [placeholder]="row.cells.uom_dimensions_unit.editControl.placeholder"
        [styles]="row.cells.uom_dimensions_unit.editControl.styles"
      [typeId]="row.$fields_uom_dimensions_unit_selector_inParams_typeId"
    >
    </SerialNumbers-measurement_units_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
