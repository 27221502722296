import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './DockAppointments.frontend.types'

@Injectable({ providedIn: 'root' })
export class DockAppointments_associate_shipment_from_dock_appointment_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { dockAppointmentId: number, shipmentId: number }): Promise<void> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.dockAppointmentId)) {
      missingRequiredInParams.push('\'dockAppointmentId\'');
    }
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/functions/associate_shipment_from_dock_appointment_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

