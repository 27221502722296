import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './DockAppointments.frontend.types'

@Injectable({ providedIn: 'root' })
export class DockAppointments_convert_dates_between_timezones_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { warehouseIds: number[], convertDates: any, isFromUtcToWarehouse: boolean }): Promise<{ timeZoneDates?: { timeZoneId?: string, timeZone?: string, offset?: number, dates?: any, warehouseIds?: number[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseIds)) {
      missingRequiredInParams.push('\'warehouseIds\'');
    }
    if (isNil(inParams.convertDates)) {
      missingRequiredInParams.push('\'convertDates\'');
    }
    if (isNil(inParams.isFromUtcToWarehouse)) {
      missingRequiredInParams.push('\'isFromUtcToWarehouse\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/functions/convert_dates_between_timezones_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

