import { Inject, Injectable, Injector } from '@angular/core';



import { $frontendTypes } from './Reports.frontend.types'

@Injectable({ providedIn: 'root' })
export class Reports_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
}
