import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_flow_message_status_updateService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { m_id: string, inv_id: string }): Promise<void> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.m_id)) {
      missingRequiredInParams.push('\'m_id\'');
    }
    if (isNil(inParams.inv_id)) {
      missingRequiredInParams.push('\'inv_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/functions/flow_message_status_update`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

