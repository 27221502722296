import { Inject, Injectable, Injector } from '@angular/core';


import { EasyPost_create_address_flowService } from './EasyPost.flow.index';
import { EasyPost_get_items_contentService } from './EasyPost.flow.index';
import { EasyPost_is_shipment_manifestable_flowService } from './EasyPost.flow.index';
import { EasyPost_one_call_buy_shipment_request_flowService } from './EasyPost.flow.index';
import { EasyPost_retrieve_tracker_status_request_flowService } from './EasyPost.flow.index';

import { $frontendTypes } from './EasyPost.frontend.types'

@Injectable({ providedIn: 'root' })
export class EasyPost_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public EasyPost: EasyPost_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_address_flow: EasyPost_create_address_flowService;
  public async create_address_flow(inParams: { address?: { street1?: string, street2?: string, city?: string, state?: string, zip?: string, country?: string, company?: string, phone?: string, name?: string }, verify?: boolean, verify_strict?: boolean, easyPostKey: string }): Promise< { address_id?: string, errors?: string[] }> {
    if(!this._create_address_flow) {
      this._create_address_flow = this.injector.get(EasyPost_create_address_flowService);
    }
    return this._create_address_flow.run(inParams);
  }
   
   

   
 
  private _get_items_content: EasyPost_get_items_contentService;
  public async get_items_content(inParams: { shipmentId: number }): Promise< { error?: string, content?: any[] }> {
    if(!this._get_items_content) {
      this._get_items_content = this.injector.get(EasyPost_get_items_contentService);
    }
    return this._get_items_content.run(inParams);
  }
   
   

   
 
  private _is_shipment_manifestable_flow: EasyPost_is_shipment_manifestable_flowService;
  public async is_shipment_manifestable_flow(inParams: { shipmentId: number }): Promise< { status?: string }> {
    if(!this._is_shipment_manifestable_flow) {
      this._is_shipment_manifestable_flow = this.injector.get(EasyPost_is_shipment_manifestable_flowService);
    }
    return this._is_shipment_manifestable_flow.run(inParams);
  }
   
   

   
 
  private _one_call_buy_shipment_request_flow: EasyPost_one_call_buy_shipment_request_flowService;
  public async one_call_buy_shipment_request_flow(inParams: { carrierAccount: string, service: string, parcels: any[], fromAddress: any, toAddress: any, labelSize?: string, labelFormat?: string, printCustomOne?: string, contentsExplanation?: string, referenceNumber?: string, customsItems?: any[], customsSigner?: string, incoterm?: string, thirdPartyAddress?: { street1?: string, street2?: string, city?: string, state?: string, zip?: string, country?: string, phone?: string, email?: string, name?: string }, taxIdentifiers?: any[], buyerAddress?: any }): Promise< { easyPostShipmentId?: string, easyPostTrackerId?: string, easyPostTrackingCode?: string, easyPostParcelWeight?: number, easyPostParcelLength?: number, easyPostParcelWidth?: number, easyPostParcelHeight?: number, easyPostShipmentCreationDate?: string, easyPostSelectedRate?: number, easyPostSelectedRateDeliveryDate?: string, easyPostPostageLabelUrl?: string[], outputDetails?: string, easyPostTrackerPublicUrl?: string, easyPostCommercialInvoiceUrl?: string }> {
    if(!this._one_call_buy_shipment_request_flow) {
      this._one_call_buy_shipment_request_flow = this.injector.get(EasyPost_one_call_buy_shipment_request_flowService);
    }
    return this._one_call_buy_shipment_request_flow.run(inParams);
  }
   
   

   
 
  private _retrieve_tracker_status_request_flow: EasyPost_retrieve_tracker_status_request_flowService;
  public async retrieve_tracker_status_request_flow(inParams: { easyPostTrackerId: string }): Promise< { status?: string, outputDetails?: string }> {
    if(!this._retrieve_tracker_status_request_flow) {
      this._retrieve_tracker_status_request_flow = this.injector.get(EasyPost_retrieve_tracker_status_request_flowService);
    }
    return this._retrieve_tracker_status_request_flow.run(inParams);
  }
   
   

   
}
