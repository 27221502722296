import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { PalletTransactions_ReportService } from './PalletTransactions.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Lots_ReportService } from './Lots.report.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Reports_ReportService } from './Reports.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.PalletTransactions = this.injector.get(PalletTransactions_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.Lots = this.injector.get(Lots_ReportService);
    this.Ultraship = this.injector.get(Ultraship_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.FootPrintManager = this.injector.get(FootPrintManager_ReportService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_ReportService);
    this.Dynamics365BusinessCentral = this.injector.get(Dynamics365BusinessCentral_ReportService);
  }
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public Utilities: Utilities_ReportService;
    public PrintNode: PrintNode_ReportService;
    public Materials: Materials_ReportService;
    public PalletTransactions: PalletTransactions_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public Notifications: Notifications_ReportService;
    public Lots: Lots_ReportService;
    public Ultraship: Ultraship_ReportService;
    public Reports: Reports_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public Invoices: Invoices_ReportService;
    public FootPrintManager: FootPrintManager_ReportService;
    public FootPrintApiManager: FootPrintApiManager_ReportService;
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

