<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="edit" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator1" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="create_inventory" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_create_inventory_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="separator2" let-tool>
                <div class="tool-separator"></div>
              </ng-template>
              <ng-template toolbarToolDef="surveys" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_surveys_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-tools">
              <app-toolbar [toolbar]="actionbar">
                  <ng-template toolbarToolDef="custom_fields" let-tool>
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            (click)="on_custom_fields_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                  </ng-template>
                  <ng-template toolbarToolDef="audit" let-tool>
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            (click)="on_audit_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                  </ng-template>
              </app-toolbar>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.licenseplate_total_packaged_amount_widget.hidden" class="widget-container">
            <Inventory-licenseplate_total_packaged_amount_widget
            #$widgets_licenseplate_total_packaged_amount_widget 
              [licenseplateId]="$widgets_licenseplate_total_packaged_amount_widget_inParams_licenseplateId"
            ($refreshEvent)="refresh(false, false, '$widgets_licenseplate_total_packaged_amount_widget')"
            >
            </Inventory-licenseplate_total_packaged_amount_widget>
          </div>
          <div *ngIf="!widgets.licenseplate_total_gross_weight_widget.hidden" class="widget-container">
            <Inventory-licenseplate_total_gross_weight_widget
            #$widgets_licenseplate_total_gross_weight_widget 
              [licenseplateId]="$widgets_licenseplate_total_gross_weight_widget_inParams_licenseplateId"
            ($refreshEvent)="refresh(false, false, '$widgets_licenseplate_total_gross_weight_widget')"
            >
            </Inventory-licenseplate_total_gross_weight_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inventory.hidden" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.serialnumbers.hidden" class="tab" data-cy="tab-serialnumbers">
              <h2 [className]="tabs.serialnumbers.active? 'active': ''" (click)="tabs.serialnumbers.activate()">{{tabs.serialnumbers.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inventory_licenseplate_grid *ngIf="tabs.inventory.active"
              #$tabs_inventory
              [licenseplateId]="$tabs_inventory_inventory_licenseplate_grid_inParams_licenseplateId"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory')">
              </FootPrintManager-inventory_licenseplate_grid>
              <FootPrintManager-inventory_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [licenseplateId]="$tabs_tasks_inventory_tasks_grid_inParams_licenseplateId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-inventory_tasks_grid>
              <FootPrintManager-serialnumbers_grid *ngIf="tabs.serialnumbers.active"
              #$tabs_serialnumbers
              [licenseplateIds]="$tabs_serialnumbers_serialnumbers_grid_inParams_licenseplateIds"
              [archived]="$tabs_serialnumbers_serialnumbers_grid_inParams_archived"
              ($refreshEvent)="refresh(false, false, '$tabs_serialnumbers')">
              </FootPrintManager-serialnumbers_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>