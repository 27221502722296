import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Attachments_DatasourceService } from './Attachments.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';

import { Dynamics365BusinessCentral_ds_get_billingService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_customer_nameService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_errorsService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_countService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_detailService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_detail_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_detail_grid_sumService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_line_detailService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_line_detail_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_linesService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoice_lines_gridService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_get_invoicesService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_ds_storage_integrationService } from './Dynamics365BusinessCentral.datasource.index';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Attachments = this.injector.get(Attachments_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Attachments: Attachments_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Invoices: Invoices_DatasourceService;
  public Dynamics365BusinessCentral: Dynamics365BusinessCentral_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_billing: Dynamics365BusinessCentral_ds_get_billingService;
  public get ds_get_billing(): Dynamics365BusinessCentral_ds_get_billingService {
    if(!this._ds_get_billing) {
      this._ds_get_billing = this.injector.get(Dynamics365BusinessCentral_ds_get_billingService);
    }
    return this._ds_get_billing;
  }
  private _ds_get_customer_name: Dynamics365BusinessCentral_ds_get_customer_nameService;
  public get ds_get_customer_name(): Dynamics365BusinessCentral_ds_get_customer_nameService {
    if(!this._ds_get_customer_name) {
      this._ds_get_customer_name = this.injector.get(Dynamics365BusinessCentral_ds_get_customer_nameService);
    }
    return this._ds_get_customer_name;
  }
  private _ds_get_errors: Dynamics365BusinessCentral_ds_get_errorsService;
  public get ds_get_errors(): Dynamics365BusinessCentral_ds_get_errorsService {
    if(!this._ds_get_errors) {
      this._ds_get_errors = this.injector.get(Dynamics365BusinessCentral_ds_get_errorsService);
    }
    return this._ds_get_errors;
  }
  private _ds_get_invoice_count: Dynamics365BusinessCentral_ds_get_invoice_countService;
  public get ds_get_invoice_count(): Dynamics365BusinessCentral_ds_get_invoice_countService {
    if(!this._ds_get_invoice_count) {
      this._ds_get_invoice_count = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_countService);
    }
    return this._ds_get_invoice_count;
  }
  private _ds_get_invoice_detail: Dynamics365BusinessCentral_ds_get_invoice_detailService;
  public get ds_get_invoice_detail(): Dynamics365BusinessCentral_ds_get_invoice_detailService {
    if(!this._ds_get_invoice_detail) {
      this._ds_get_invoice_detail = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_detailService);
    }
    return this._ds_get_invoice_detail;
  }
  private _ds_get_invoice_detail_grid: Dynamics365BusinessCentral_ds_get_invoice_detail_gridService;
  public get ds_get_invoice_detail_grid(): Dynamics365BusinessCentral_ds_get_invoice_detail_gridService {
    if(!this._ds_get_invoice_detail_grid) {
      this._ds_get_invoice_detail_grid = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_detail_gridService);
    }
    return this._ds_get_invoice_detail_grid;
  }
  private _ds_get_invoice_detail_grid_sum: Dynamics365BusinessCentral_ds_get_invoice_detail_grid_sumService;
  public get ds_get_invoice_detail_grid_sum(): Dynamics365BusinessCentral_ds_get_invoice_detail_grid_sumService {
    if(!this._ds_get_invoice_detail_grid_sum) {
      this._ds_get_invoice_detail_grid_sum = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_detail_grid_sumService);
    }
    return this._ds_get_invoice_detail_grid_sum;
  }
  private _ds_get_invoice_line_detail: Dynamics365BusinessCentral_ds_get_invoice_line_detailService;
  public get ds_get_invoice_line_detail(): Dynamics365BusinessCentral_ds_get_invoice_line_detailService {
    if(!this._ds_get_invoice_line_detail) {
      this._ds_get_invoice_line_detail = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_line_detailService);
    }
    return this._ds_get_invoice_line_detail;
  }
  private _ds_get_invoice_line_detail_grid: Dynamics365BusinessCentral_ds_get_invoice_line_detail_gridService;
  public get ds_get_invoice_line_detail_grid(): Dynamics365BusinessCentral_ds_get_invoice_line_detail_gridService {
    if(!this._ds_get_invoice_line_detail_grid) {
      this._ds_get_invoice_line_detail_grid = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_line_detail_gridService);
    }
    return this._ds_get_invoice_line_detail_grid;
  }
  private _ds_get_invoice_lines: Dynamics365BusinessCentral_ds_get_invoice_linesService;
  public get ds_get_invoice_lines(): Dynamics365BusinessCentral_ds_get_invoice_linesService {
    if(!this._ds_get_invoice_lines) {
      this._ds_get_invoice_lines = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_linesService);
    }
    return this._ds_get_invoice_lines;
  }
  private _ds_get_invoice_lines_grid: Dynamics365BusinessCentral_ds_get_invoice_lines_gridService;
  public get ds_get_invoice_lines_grid(): Dynamics365BusinessCentral_ds_get_invoice_lines_gridService {
    if(!this._ds_get_invoice_lines_grid) {
      this._ds_get_invoice_lines_grid = this.injector.get(Dynamics365BusinessCentral_ds_get_invoice_lines_gridService);
    }
    return this._ds_get_invoice_lines_grid;
  }
  private _ds_get_invoices: Dynamics365BusinessCentral_ds_get_invoicesService;
  public get ds_get_invoices(): Dynamics365BusinessCentral_ds_get_invoicesService {
    if(!this._ds_get_invoices) {
      this._ds_get_invoices = this.injector.get(Dynamics365BusinessCentral_ds_get_invoicesService);
    }
    return this._ds_get_invoices;
  }
  private _ds_storage_integration: Dynamics365BusinessCentral_ds_storage_integrationService;
  public get ds_storage_integration(): Dynamics365BusinessCentral_ds_storage_integrationService {
    if(!this._ds_storage_integration) {
      this._ds_storage_integration = this.injector.get(Dynamics365BusinessCentral_ds_storage_integrationService);
    }
    return this._ds_storage_integration;
  }
}

