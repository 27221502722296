import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ds_serial_numbers_audit_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { scannedBarcodes: { barcode: string, serialNumber: string, serialNumberId: number, isValid: boolean }[], watchList: { barcode: string, serialNumber: string }[], context: string, fullTextSearch?: string, $top?: number, $skip?: number }): Promise<{ result?: { serialNumber?: string, isFound?: boolean, isValid?: boolean }[], totalCount?: number }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.scannedBarcodes)) {
      missingRequiredInParams.push('\'scannedBarcodes\'');
    }
    if (isNil(inParams.watchList)) {
      missingRequiredInParams.push('\'watchList\'');
    }
    if (isNil(inParams.context)) {
      missingRequiredInParams.push('\'context\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serial_numbers_audit_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { scannedBarcodes?: { barcode: string, serialNumber: string, serialNumberId: number, isValid: boolean }[], watchList?: { barcode: string, serialNumber: string }[], context?: string, fullTextSearch?: string, $keys: string[] }): Promise<{ result?: { serialNumber?: string, isFound?: boolean, isValid?: boolean }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serial_numbers_audit_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
