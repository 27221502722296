<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project_id.displayControl.styles.style"
          [ngClass]="row.cells.project_id.displayControl.styles.classes">{{row.cells.project_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="rule_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.rule_id.displayControl.styles.style"
          [ngClass]="row.cells.rule_id.displayControl.styles.classes">{{row.cells.rule_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="exception">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.exception.displayControl.styles.style"
          [ngClass]="row.cells.exception.displayControl.styles.classes">{{row.cells.exception.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
