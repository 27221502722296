import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { EntityImport_entity_import_library_homeComponent } from './EntityImport.entity_import_library_home.component';
import { EntityImport_entity_import_viewComponent } from './EntityImport.entity_import_view.component';
import { EntityImport_inventory_by_licenseplate_selection_gridComponent } from './EntityImport.inventory_by_licenseplate_selection_grid.component';
import { EntityImport_render_entity_import_request_code_editorComponent } from './EntityImport.render_entity_import_request_code_editor.component';
import { EntityImport_view_json_payloadComponent } from './EntityImport.view_json_payload.component';
import { EntityImport_entity_import_date_types_singleComponent } from './EntityImport.entity_import_date_types_single.component';
import { EntityImport_entity_import_statuses_dd_singleComponent } from './EntityImport.entity_import_statuses_dd_single.component';
import { EntityImport_entity_import_date_types_multiComponent } from './EntityImport.entity_import_date_types_multi.component';
import { EntityImport_entity_import_statuses_dd_multiComponent } from './EntityImport.entity_import_statuses_dd_multi.component';
import { EntityImport_entity_import_total_completed_by_date_widgetComponent } from './EntityImport.entity_import_total_completed_by_date_widget.component';
import { EntityImport_entity_import_total_errors_by_date_widgetComponent } from './EntityImport.entity_import_total_errors_by_date_widget.component';
import { EntityImport_entity_import_total_ready_by_date_widgetComponent } from './EntityImport.entity_import_total_ready_by_date_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class EntityImport_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public EntityImport: EntityImport_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openentity_import_library_home(replaceCurrentView?: boolean) {
    this.logger.log('EntityImport', 'entity_import_library_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'EntityImport_entity_import_library_home',
        component: EntityImport_entity_import_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openentity_import_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('EntityImport', 'entity_import_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EntityImport_entity_import_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openentity_import_view(replaceCurrentView?: boolean) {
    this.logger.log('EntityImport', 'entity_import_view');
    ShellService.openViewRequest$.next(
      {
        title: 'entity_import_view',
        referenceName: 'EntityImport_entity_import_view',
        component: EntityImport_entity_import_viewComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openentity_import_viewDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('EntityImport', 'entity_import_view');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EntityImport_entity_import_viewComponent,
      'entity_import_view',
      mode,
      dialogSize
    )
  }
  public openinventory_by_licenseplate_selection_grid(inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number }, replaceCurrentView?: boolean) {
    this.logger.log('EntityImport', 'inventory_by_licenseplate_selection_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory by LP',
        referenceName: 'EntityImport_inventory_by_licenseplate_selection_grid',
        component: EntityImport_inventory_by_licenseplate_selection_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinventory_by_licenseplate_selection_gridDialog(
    inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ inventory?: { lotId?: number, licenseplateId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }> {
    this.logger.log('EntityImport', 'inventory_by_licenseplate_selection_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EntityImport_inventory_by_licenseplate_selection_gridComponent,
      'Inventory by LP',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openrender_entity_import_request_code_editor(inParams:{ request_id: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Entity import request XML',
        referenceName: 'EntityImport_render_entity_import_request_code_editor',
        component: EntityImport_render_entity_import_request_code_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openrender_entity_import_request_code_editorDialog(
    inParams:{ request_id: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EntityImport_render_entity_import_request_code_editorComponent,
      'Entity import request XML',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_json_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    this.logger.log('EntityImport', 'view_json_payload');
    ShellService.openViewRequest$.next(
      {
        title: 'view_json_payload',
        referenceName: 'EntityImport_view_json_payload',
        component: EntityImport_view_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_json_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('EntityImport', 'view_json_payload');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      EntityImport_view_json_payloadComponent,
      'view_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'EntityImport_entity_import_library_home') {
      this.logger.log('EntityImport', 'entity_import_library_home');
      const title = 'Home';
      const component = EntityImport_entity_import_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_entity_import_view') {
      this.logger.log('EntityImport', 'entity_import_view');
      const title = 'entity_import_view';
      const component = EntityImport_entity_import_viewComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_inventory_by_licenseplate_selection_grid') {
      this.logger.log('EntityImport', 'inventory_by_licenseplate_selection_grid');
      const title = 'Inventory by LP';
      const component = EntityImport_inventory_by_licenseplate_selection_gridComponent;
      const inParams:{ warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number } = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null, packagedAmountRequest: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmountRequest'))) {
        const paramValueString = params.get('packagedAmountRequest');
        inParams.packagedAmountRequest = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_render_entity_import_request_code_editor') {
      const title = 'Entity import request XML';
      const component = EntityImport_render_entity_import_request_code_editorComponent;
      const inParams:{ request_id: string } = { request_id: null };
      if (!isNil(params.get('request_id'))) {
        const paramValueString = params.get('request_id');
        // TODO: date
        inParams.request_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_view_json_payload') {
      this.logger.log('EntityImport', 'view_json_payload');
      const title = 'view_json_payload';
      const component = EntityImport_view_json_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_entity_import_date_types_single') {
      const title = 'entity_import_date_types';
      const component = EntityImport_entity_import_date_types_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_entity_import_statuses_dd_single') {
      const title = 'Entity import statuses';
      const component = EntityImport_entity_import_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_entity_import_date_types_multi') {
      const title = 'entity_import_date_types';
      const component = EntityImport_entity_import_date_types_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_entity_import_statuses_dd_multi') {
      const title = 'Entity import statuses';
      const component = EntityImport_entity_import_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_entity_import_total_completed_by_date_widget') {
      const title = 'Completed today';
      const component = EntityImport_entity_import_total_completed_by_date_widgetComponent;
      const inParams:{ date: string } = { date: null };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_entity_import_total_errors_by_date_widget') {
      const title = 'Errors today';
      const component = EntityImport_entity_import_total_errors_by_date_widgetComponent;
      const inParams:{ date: string } = { date: null };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'EntityImport_entity_import_total_ready_by_date_widget') {
      const title = 'Ready today';
      const component = EntityImport_entity_import_total_ready_by_date_widgetComponent;
      const inParams:{ date: string } = { date: null };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
