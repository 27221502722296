import { Inject, Injectable, Injector } from '@angular/core';


import { Document360_get_document360_widgetService } from './Document360.flow.index';

import { $frontendTypes } from './Document360.frontend.types'

@Injectable({ providedIn: 'root' })
export class Document360_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Document360: Document360_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _get_document360_widget: Document360_get_document360_widgetService;
  public async get_document360_widget(inParams: {  }): Promise< { doc30_scriptContent?: string }> {
    if(!this._get_document360_widget) {
      this._get_document360_widget = this.injector.get(Document360_get_document360_widgetService);
    }
    return this._get_document360_widget.run(inParams);
  }
   
   

   
}
