import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatexFormControl } from './models/datex-form-control';
import { ListItemModel, ELoadingStatus } from './components/list.component';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Discussions_ShellService, EModalSize, EToasterType, EToasterPosition } from './Discussions.shell.service';
import { Discussions_OperationService } from './Discussions.operation.service';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { Discussions_FlowService } from './Discussions.flow.index';
import { Discussions_ReportService } from './Discussions.report.index';
import { Discussions_LocalizationService } from './Discussions.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Discussions.frontend.types'
import { $frontendTypes as $types} from './Discussions.frontend.types' 



import { Discussions_discussion_cardComponent } from './Discussions.discussion_card.component';

interface IDiscussions_discussions_listComponentEntity {
id?: string, entity_name?: string, entity_id?: string, reference?: string, discussion?: string, created_by?: string, created_on?: string, read_on?: string, alerted_on?: string, application_id?: string}

class Discussions_discussions_listComponentItemModel extends ListItemModel {
  list: Discussions_discussions_listComponent;
  entity: IDiscussions_discussions_listComponentEntity;



  get $content_Discussions_discussion_card_inParams_entity_name(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.entity_name;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_entity_id(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.entity_id;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_reference(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.reference;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_discussion(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.discussion;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_created_by(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.created_by;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_created_on(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.created_on;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_read_on(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.read_on;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_alerted_on(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.alerted_on;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_application_id(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.application_id;
    
    return expr;
  }


  get $content_Discussions_discussion_card_inParams_id(): string {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.utils;
    const $list = this.list;
    const expr = $item.entity.id;
    
    return expr;
  }

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Discussions_ShellService,
    private datasources: Discussions_DatasourceService,
    private flows: Discussions_FlowService,
    private reports: Discussions_ReportService,
    private localization: Discussions_LocalizationService,
    private operations: Discussions_OperationService,
    private logger: CleanupLoggerService,
   ) {
    super();
  }

  private $unsubscribe$ = new Subject();
  destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $initializeExisting(list: Discussions_discussions_listComponent, entity: IDiscussions_discussions_listComponentEntity) {
    this.list = list;
    this.entity = entity;

    this.styles = new Styles(
      ['small-card'], 
      { 
        width: '90%', 
        height: '215px' 
      }
    );
  }
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Discussions_discussion_cardComponent)
  ],
  selector: 'Discussions-discussions_list',
  templateUrl: './Discussions.discussions_list.component.html'
})
export class Discussions_discussions_listComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { entity_name?: string, entity_id?: string } = { entity_name: null, entity_id: null };
  //#region Inputs
  @Input('entity_name') set $inParams_entity_name(v: string) {
    this.inParams.entity_name = v;
  }
  get $inParams_entity_name(): string {
    return this.inParams.entity_name;
  }
  @Input('entity_id') set $inParams_entity_id(v: string) {
    this.inParams.entity_id = v;
  }
  get $inParams_entity_id(): string {
    return this.inParams.entity_id;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  topToolbar = {
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, 'Refresh', 'ms-Icon ms-Icon--Refresh')
    )
  };



  entities: IDiscussions_discussions_listComponentEntity[] = [];
  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus: ELoadingStatus = ELoadingStatus.Loading;

  items: Discussions_discussions_listComponentItemModel[] = [];
  
  fullTextSearch: string;

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Discussions_ShellService,
private datasources: Discussions_DatasourceService,
private flows: Discussions_FlowService,
private reports: Discussions_ReportService,
private localization: Discussions_LocalizationService,
private operations: Discussions_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.title = 'Discussions';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {

    const $list = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.refresh();
  }
 
  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    this.$reset();
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    
  }

  $reset() {
    this.pageSkip = 0;
    this.entities = [];
    this.items.forEach(i => i.destroy());
    this.items = [];
  }


  $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $list = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      entity_name:  $list.inParams.entity_name ,
      entity_id:  $list.inParams.entity_id ,
      discussion:  $list.fullTextSearch ,
      application_id:  null ,
      read_on:  null ,
    };
    this.datasources.Discussions.ds_storage_discussions.getList(inParams)
    .then(data => {
      this.entities.push(...data.result);
      this.totalCount = data.totalCount;
      this.$dataLoaded(data.result);
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    })
    .catch(error => {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    });
  }

  async $dataLoaded(data: IDiscussions_discussions_listComponentEntity[]) {
    for (let entity of data) {
      const item = new Discussions_discussions_listComponentItemModel(
        this.utils,
        this.settings,
        this.shell, 
        this.datasources,
        this.flows,
        this.reports,
        this.localization,
        this.operations,
        this.logger);
      item.$initializeExisting(this, entity);
      this.items.push(item);
    } 
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  //#region private flows
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $list: Discussions_discussions_listComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
  $list.refresh();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $list: Discussions_discussions_listComponent,
  
    $shell: Discussions_ShellService,
    $datasources: Discussions_DatasourceService,
    $flows: Discussions_FlowService,
    $reports: Discussions_ReportService,
    $settings: SettingsValuesService,
    $operations: Discussions_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Discussions_LocalizationService,
    $event: any
  ) {
  
  // Delay to give some time for mongo to scale
  
  await new Promise(resolve => setTimeout(resolve, 200));
  }
  //#endregion private flows

  close() {
    this.$finish.emit();
  }
}
