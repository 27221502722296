import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootPrintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_vendor_lot_createService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { materialId: number, lookupCode?: string, description?: string, notes?: string, expirationDate?: string, manufactureDate?: string, vat?: string }): Promise<{ id?: number, success?: boolean, messages?: any[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/functions/vendor_lot_create`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

