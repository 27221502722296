import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { Reports_reports_homeComponent } from './Reports.reports_home.component';
import { Reports_custom_inbound_unloading_tally_reportComponent } from './Reports.custom_inbound_unloading_tally_report.component';
import { Reports_custom_outbound_bill_of_lading_reportComponent } from './Reports.custom_outbound_bill_of_lading_report.component';
import { Reports_custom_receiving_label_case_reportComponent } from './Reports.custom_receiving_label_case_report.component';
import { Reports_custom_receiving_label_crossdock_reportComponent } from './Reports.custom_receiving_label_crossdock_report.component';
import { Reports_custom_receiving_label_hellofreshfactor_reportComponent } from './Reports.custom_receiving_label_hellofreshfactor_report.component';
import { Reports_custom_receiving_reportComponent } from './Reports.custom_receiving_report.component';
import { Reports_custom_shipping_label_reportComponent } from './Reports.custom_shipping_label_report.component';


@Injectable({ providedIn: 'root' })
export class Reports_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
  ) {
    super(dialog, toastr);
  }

  public Reports: Reports_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openreports_home(replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'reports_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Reports',
        referenceName: 'Reports_reports_home',
        component: Reports_reports_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreports_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'reports_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Reports_reports_homeComponent,
      'Reports',
      mode,
      dialogSize
    )
  }
  public opencustom_inbound_unloading_tally_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Unloading Tally Report',
        referenceName: 'Reports_custom_inbound_unloading_tally_report',
        component: Reports_custom_inbound_unloading_tally_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inbound_unloading_tally_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_inbound_unloading_tally_reportComponent,
      'Custom Unloading Tally Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_bill_of_lading_report(inParams:{ orderId: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Outbound Bill of Lading ',
        referenceName: 'Reports_custom_outbound_bill_of_lading_report',
        component: Reports_custom_outbound_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_bill_of_lading_reportDialog(
    inParams:{ orderId: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_outbound_bill_of_lading_reportComponent,
      'Custom Outbound Bill of Lading ',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_label_case_report(inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_receiving_label_case_report');
    ShellService.openViewRequest$.next(
      {
        title: 'custom_receiving_label_case_report',
        referenceName: 'Reports_custom_receiving_label_case_report',
        component: Reports_custom_receiving_label_case_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_label_case_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_receiving_label_case_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_label_case_reportComponent,
      'custom_receiving_label_case_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_label_crossdock_report(inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_receiving_label_crossdock_report');
    ShellService.openViewRequest$.next(
      {
        title: 'custom_receiving_label_crossdock_report',
        referenceName: 'Reports_custom_receiving_label_crossdock_report',
        component: Reports_custom_receiving_label_crossdock_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_label_crossdock_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_receiving_label_crossdock_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_label_crossdock_reportComponent,
      'custom_receiving_label_crossdock_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_label_hellofreshfactor_report(inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_receiving_label_hellofreshfactor_report');
    ShellService.openViewRequest$.next(
      {
        title: 'custom_receiving_label_hellofreshfactor_report',
        referenceName: 'Reports_custom_receiving_label_hellofreshfactor_report',
        component: Reports_custom_receiving_label_hellofreshfactor_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_label_hellofreshfactor_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_receiving_label_hellofreshfactor_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_label_hellofreshfactor_reportComponent,
      'custom_receiving_label_hellofreshfactor_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_receiving_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Receiving Report',
        referenceName: 'Reports_custom_receiving_report',
        component: Reports_custom_receiving_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_receiving_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_receiving_reportComponent,
      'Custom Receiving Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_shipping_label_report(inParams:{ orderId?: number, shipmentId?: number, copies?: number }, replaceCurrentView?: boolean) {
    this.logger.log('Reports', 'custom_shipping_label_report');
    ShellService.openViewRequest$.next(
      {
        title: 'custom_shipping_label_report',
        referenceName: 'Reports_custom_shipping_label_report',
        component: Reports_custom_shipping_label_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_shipping_label_reportDialog(
    inParams:{ orderId?: number, shipmentId?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Reports', 'custom_shipping_label_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Reports_custom_shipping_label_reportComponent,
      'custom_shipping_label_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Reports_reports_home') {
      this.logger.log('Reports', 'reports_home');
      const title = 'Reports';
      const component = Reports_reports_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_inbound_unloading_tally_report') {
      const title = 'Custom Unloading Tally Report';
      const component = Reports_custom_inbound_unloading_tally_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_outbound_bill_of_lading_report') {
      const title = 'Custom Outbound Bill of Lading ';
      const component = Reports_custom_outbound_bill_of_lading_reportComponent;
      const inParams:{ orderId: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_label_case_report') {
      this.logger.log('Reports', 'custom_receiving_label_case_report');
      const title = 'custom_receiving_label_case_report';
      const component = Reports_custom_receiving_label_case_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number } = { orderId: null, shipmentId: null, orderLineNumber: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_label_crossdock_report') {
      this.logger.log('Reports', 'custom_receiving_label_crossdock_report');
      const title = 'custom_receiving_label_crossdock_report';
      const component = Reports_custom_receiving_label_crossdock_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number } = { orderId: null, shipmentId: null, orderLineNumber: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_label_hellofreshfactor_report') {
      this.logger.log('Reports', 'custom_receiving_label_hellofreshfactor_report');
      const title = 'custom_receiving_label_hellofreshfactor_report';
      const component = Reports_custom_receiving_label_hellofreshfactor_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number } = { orderId: null, shipmentId: null, orderLineNumber: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_receiving_report') {
      const title = 'Custom Receiving Report';
      const component = Reports_custom_receiving_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Reports_custom_shipping_label_report') {
      this.logger.log('Reports', 'custom_shipping_label_report');
      const title = 'custom_shipping_label_report';
      const component = Reports_custom_shipping_label_reportComponent;
      const inParams:{ orderId?: number, shipmentId?: number, copies?: number } = { orderId: null, shipmentId: null, copies: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    return result;
  }
}
