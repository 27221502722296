import { Inject, Injectable, Injector } from '@angular/core';


import { Reports_custom_inbound_unloading_tally_reportService } from './Reports.report.index';
import { Reports_custom_outbound_bill_of_lading_reportService } from './Reports.report.index';
import { Reports_custom_receiving_label_case_reportService } from './Reports.report.index';
import { Reports_custom_receiving_label_crossdock_reportService } from './Reports.report.index';
import { Reports_custom_receiving_label_hellofreshfactor_reportService } from './Reports.report.index';
import { Reports_custom_receiving_reportService } from './Reports.report.index';
import { Reports_custom_shipping_label_reportService } from './Reports.report.index';

@Injectable({ providedIn: 'root' })
export class Reports_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_inbound_unloading_tally_report: Reports_custom_inbound_unloading_tally_reportService;
  public get custom_inbound_unloading_tally_report(): Reports_custom_inbound_unloading_tally_reportService {
    if(!this._custom_inbound_unloading_tally_report) {
      this._custom_inbound_unloading_tally_report = this.injector.get(Reports_custom_inbound_unloading_tally_reportService);
    }
    return this._custom_inbound_unloading_tally_report;
  }
  private _custom_outbound_bill_of_lading_report: Reports_custom_outbound_bill_of_lading_reportService;
  public get custom_outbound_bill_of_lading_report(): Reports_custom_outbound_bill_of_lading_reportService {
    if(!this._custom_outbound_bill_of_lading_report) {
      this._custom_outbound_bill_of_lading_report = this.injector.get(Reports_custom_outbound_bill_of_lading_reportService);
    }
    return this._custom_outbound_bill_of_lading_report;
  }
  private _custom_receiving_label_case_report: Reports_custom_receiving_label_case_reportService;
  public get custom_receiving_label_case_report(): Reports_custom_receiving_label_case_reportService {
    if(!this._custom_receiving_label_case_report) {
      this._custom_receiving_label_case_report = this.injector.get(Reports_custom_receiving_label_case_reportService);
    }
    return this._custom_receiving_label_case_report;
  }
  private _custom_receiving_label_crossdock_report: Reports_custom_receiving_label_crossdock_reportService;
  public get custom_receiving_label_crossdock_report(): Reports_custom_receiving_label_crossdock_reportService {
    if(!this._custom_receiving_label_crossdock_report) {
      this._custom_receiving_label_crossdock_report = this.injector.get(Reports_custom_receiving_label_crossdock_reportService);
    }
    return this._custom_receiving_label_crossdock_report;
  }
  private _custom_receiving_label_hellofreshfactor_report: Reports_custom_receiving_label_hellofreshfactor_reportService;
  public get custom_receiving_label_hellofreshfactor_report(): Reports_custom_receiving_label_hellofreshfactor_reportService {
    if(!this._custom_receiving_label_hellofreshfactor_report) {
      this._custom_receiving_label_hellofreshfactor_report = this.injector.get(Reports_custom_receiving_label_hellofreshfactor_reportService);
    }
    return this._custom_receiving_label_hellofreshfactor_report;
  }
  private _custom_receiving_report: Reports_custom_receiving_reportService;
  public get custom_receiving_report(): Reports_custom_receiving_reportService {
    if(!this._custom_receiving_report) {
      this._custom_receiving_report = this.injector.get(Reports_custom_receiving_reportService);
    }
    return this._custom_receiving_report;
  }
  private _custom_shipping_label_report: Reports_custom_shipping_label_reportService;
  public get custom_shipping_label_report(): Reports_custom_shipping_label_reportService {
    if(!this._custom_shipping_label_report) {
      this._custom_shipping_label_report = this.injector.get(Reports_custom_shipping_label_reportService);
    }
    return this._custom_shipping_label_report;
  }
}

