import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Equipment_ShellService, EModalSize, EToasterType, EToasterPosition } from './Equipment.shell.service';
import { Equipment_OperationService } from './Equipment.operation.service';
import { Equipment_DatasourceService } from './Equipment.datasource.index';
import { Equipment_FlowService } from './Equipment.flow.index';
import { Equipment_ReportService } from './Equipment.report.index';
import { Equipment_LocalizationService } from './Equipment.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Equipment.frontend.types'
import { $frontendTypes as $types} from './Equipment.frontend.types' 


import { Equipment_equipment_warehouses_gridComponent } from './Equipment.equipment_warehouses_grid.component';
import { Equipment_equipment_type_dd_singleComponent } from './Equipment.equipment_type_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Equipment_equipment_warehouses_gridComponent),
    forwardRef(() => Equipment_equipment_type_dd_singleComponent),
  ],
  selector: 'Equipment-equipment_editor',
  templateUrl: './Equipment.equipment_editor.component.html'
})
export class Equipment_equipment_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { equipmentId: number } = { equipmentId: null };
  //#region Inputs
  @Input('equipmentId') set $inParams_equipmentId(v: number) {
    this.inParams.equipmentId = v;
  }
  get $inParams_equipmentId(): number {
    return this.inParams.equipmentId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirmed?: boolean } = { confirmed: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, LookupCode?: string, MacAddress?: string, TypeId?: number, LocationContainers?: { LocationContainerId?: number, LocationContainer?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } } }[], Type?: { Id?: number, CanHandleFullLPTasksOperations?: boolean, LookupCode?: string, MobileLaunchPickDropAfterPickp?: boolean, MobilePickingDisableAutoConfirmOnTargetLp?: boolean } };

  formGroup: FormGroup = new FormGroup({
    equipmentName: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    equipmentType: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    macAddress: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm_button: new ToolModel(new ButtonModel('confirm_button', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    equipmentName: new FieldModel(new TextBoxModel(this.formGroup.controls['equipmentName'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Equipment Name', false)
,
    equipmentType: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['equipmentType'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Equipment Type', false)
,
    macAddress: new FieldModel(new TextBoxModel(this.formGroup.controls['macAddress'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'MAC Address', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Details', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      warehouses: new TabItemModel(
        this.rootTabGroup, 
        'Warehouses', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_warehouses_equipment_warehouses_grid_inParams_equipmentId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_warehouses', { read: Equipment_equipment_warehouses_gridComponent }) $tabs_warehouses: Equipment_equipment_warehouses_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    equipmentName: this.fields.equipmentName.control.valueChanges
    ,
    equipmentType: this.fields.equipmentType.control.valueChanges
    ,
    macAddress: this.fields.macAddress.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Equipment_ShellService,
    private datasources: Equipment_DatasourceService,
    private flows: Equipment_FlowService,
    private reports: Equipment_ReportService,
    private localization: Equipment_LocalizationService,
    private operations: Equipment_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.warehouses,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.equipmentId)) {
        this.$missingRequiredInParams.push('equipmentId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Equipment Editor';
    
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      equipmentId:  $editor.inParams.equipmentId 
    };

    const data = await this.datasources.Equipment.ds_equipment_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.equipmentName.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.equipmentType.control as SelectBoxModel).reset($editor.entity.TypeId);
    (this.fields.macAddress.control as TextBoxModel).reset($editor.entity.MacAddress);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_warehouses') {
        if (!isNil(this.$tabs_warehouses) && !this.tabs.warehouses.hidden) {
          this.$tabs_warehouses.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .equipmentName
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .equipmentType
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .macAddress
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: Equipment_equipment_editorComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  try {
  
      $editor.toolbar.confirm_button.control.readOnly = true;
      $editor.toolbar.cancel_button.control.readOnly = true;
  
      //Form validation
      if(!$utils.isDefined($editor.fields.equipmentName.control.value))
          throw new Error('Equipment name is required');
  
      if(!$utils.isDefined($editor.fields.equipmentType.control.value))
          throw new Error('Equipment type is required');
  
      const payload: any = {};
  
      payload.id = $editor.entity.Id;
      payload.lookupCode = $editor.fields.equipmentName.control.value;
      payload.typeId = $editor.fields.equipmentType.control.value;
      payload.macAddress = $editor.fields.macAddress.control.value;
  
      const result = await $flows.Equipment.update_equipment_flow({ equipment: payload });
  
      if(result.reasons.length > 0)
          throw new Error(result.reasons[0]);
      
      $editor.outParams.confirmed = true;
      $editor.close();
  
  } catch(error) {
  
      while ($utils.isDefined(error.error)) {
          if(!$utils.isDefined(error.message))
              break;
  
          error = error.error;
      }
  
      $editor.outParams.confirmed = false;
  
      await $shell.Utilities.openErrorDialog('Error editing equipment', error.message);
  
  } finally {
  
      $editor.toolbar.confirm_button.control.readOnly = false;
      $editor.toolbar.cancel_button.control.readOnly = false;
  
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: Equipment_equipment_editorComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirmed = false;
  $editor.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: Equipment_equipment_editorComponent,
  
    $shell: Equipment_ShellService,
    $datasources: Equipment_DatasourceService,
    $flows: Equipment_FlowService,
    $reports: Equipment_ReportService,
    $settings: SettingsValuesService,
    $operations: Equipment_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Equipment_LocalizationService,
    $event: any
  ) {
    this.logger.log('Equipment', 'equipment_editor.on_init');
  await new Promise (resolve => setTimeout(resolve, 200));
  $editor.fields.equipmentName.control.focus();
  }
  //#endregion private flows
}
