import { Inject, Injectable, Injector } from '@angular/core';


import { Usersnap_get_usersnap_widgetService } from './Usersnap.flow.index';

import { $frontendTypes } from './Usersnap.frontend.types'

@Injectable({ providedIn: 'root' })
export class Usersnap_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public Usersnap: Usersnap_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _get_usersnap_widget: Usersnap_get_usersnap_widgetService;
  public async get_usersnap_widget(inParams: {  }): Promise< { usersnap_scriptContent?: string }> {
    if(!this._get_usersnap_widget) {
      this._get_usersnap_widget = this.injector.get(Usersnap_get_usersnap_widgetService);
    }
    return this._get_usersnap_widget.run(inParams);
  }
   
   

   
}
