import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Ultraship.frontend.types'

@Injectable({ providedIn: 'root' })
export class Ultraship_export_ultraship_outbound_shipmentService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { shipmentWsPayload?: string, messageTransactionId?: string }): Promise<{ responseResult?: $frontendTypes.Ultraship.HttpRequestResponse, outputDetails?: string[] }> 
  {
    let url = `${environment.backendUrl}api/Ultraship/functions/export_ultraship_outbound_shipment`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

