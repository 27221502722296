import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { Locations_timezones_dd_singleComponent } from './Locations.timezones_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_timezones_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
  ],
  selector: 'FootPrintManager-warehouse_editor',
  templateUrl: './FootPrintManager.warehouse_editor.component.html'
})
export class FootPrintManager_warehouse_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { warehouseId: number } = { warehouseId: null };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: { Id?: number, DefaultPickDestinationLocationId?: number, DefaultReceivingLocationId?: number, Name?: string, Notes?: string, TimeZoneId?: string };

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_zone: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    default_receiving_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    default_picking_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    )
  };

  fields = {
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Name', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    time_zone: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_zone'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Time zone', true)
,
    default_receiving_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['default_receiving_location'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Default receiving location', false)
,
    default_picking_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['default_picking_location'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Default picking location', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};


  //#region fields inParams
  get $fields_default_receiving_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.inParams.warehouseId;
    
    return expr;
  }

  get $fields_default_receiving_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_default_receiving_location_selector_inParams_eligibleForAllocation(): boolean {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = false;
    
    return expr;
  }

  get $fields_default_picking_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.inParams.warehouseId;
    
    return expr;
  }

  get $fields_default_picking_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    time_zone: this.fields.time_zone.control.valueChanges
    ,
    default_receiving_location: this.fields.default_receiving_location.control.valueChanges
    ,
    default_picking_location: this.fields.default_picking_location.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Warehouse';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      warehouseId:  $editor.inParams.warehouseId 
    };

    const data = await this.datasources.Locations.ds_warehouse_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);
    (this.fields.default_receiving_location.control as SelectBoxModel).reset($editor.entity.DefaultReceivingLocationId);
    (this.fields.default_picking_location.control as SelectBoxModel).reset($editor.entity.DefaultPickDestinationLocationId);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .time_zone
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .default_receiving_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .default_picking_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_warehouse_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_warehouse_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Check required fields
  const allRequiredFieldsHaveValue = $utils.isAllDefined(
      $editor.fields.time_zone.control.value,
      $editor.fields.name.control.value
  );
  
  var errors = '';
  
  if (allRequiredFieldsHaveValue === false) {
    //  $shell.Locations.openErrorDialog('Save', 'Missing required fields');
      throw new Error('Missing required fields'); // To prevent display mode
  }
  
  
  $editor.toolbar.confirm.control.readOnly = true;
  
  
  // Update warehouse
  let payload: any = {};
  
  
  if ($editor.fields.name.control.isChanged) {
  
      // Check for warehouse duplicates
      const warehouse = (await $datasources.Locations.ds_get_warehouse_by_name.get({
              name: $editor.fields.name.control.value
          })).result;
      if ($utils.isDefined(warehouse)) {
          $editor.toolbar.confirm.control.readOnly = false;
          $shell.Locations.openErrorDialog('Warehouse Edit Error', 'Warehouse name already exists!.');
          return;
  
      } else {
          payload.Name = $editor.fields.name.control.value;
          // To do add logic to update the main location containers zone name
      }
  }
  
  
  if ($editor.fields.notes.control.isChanged) {
      payload.Notes = $editor.fields.notes.control.value;
  }
  
  if ($editor.fields.time_zone.control.isChanged) {
      payload.TimeZoneId = $editor.fields.time_zone.control.value;
  }
  
  if ($editor.fields.default_picking_location.control.isChanged) {
      payload.DefaultPickDestinationLocationId = $editor.fields.default_picking_location.control.value;
  }
  if ($editor.fields.default_receiving_location.control.isChanged) {
      payload.DefaultReceivingLocationId = $editor.fields.default_receiving_location.control.value;
  }
  
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Warehouses', id: $editor.entity.Id, entity: payload });
      $editor.outParams.confirm = true;
      $editor.close();
  
  }
  
  catch (error) {
      $editor.toolbar.confirm.control.readOnly = false;
      $shell.Locations.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_warehouse_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const timezones: string[] = ['Eastern Standard Time',
  'Central Standard Time',
  'Mountain Standard Time',
  'Pacific Standard Time',
  'Alaskan Standard Time',
  'Hawaiian Standard Time',
  'GMT Standard Time',
  'Romance Standard Time',
  'Russian Standard Time',
  'Tokyo Standard Time',
  'China Standard Time',
  'E. Australia Standard Time'];
  
  var timezone: string = $editor.entity.TimeZoneId;
  
  if ($utils.isDefined(timezone)) {
  
      for (let i = 0; i < timezones.length; i++) {
          if (timezones[i] === timezone) {
              $editor.fields.time_zone.control.value = timezone
              break;
          }
  
      }
  }
  else {
      $editor.fields.time_zone.control.value = null;
  }
  
  
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_warehouse_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  }
  //#endregion private flows
}
