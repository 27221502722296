<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="refresh_dropdowns" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        (click)="on_refresh_dropdowns($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="logs" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="configurations" let-tool>
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.configurations.readOnly"
                              [ngStyle]="tool.control.buttons.configurations.styles.style"
                              [ngClass]="tool.control.buttons.configurations.styles.classes"
                              (click)="($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.configurations.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.configurations.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.configurations.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.logs.readOnly"
                              [ngStyle]="tool.control.buttons.logs.styles.style"
                              [ngClass]="tool.control.buttons.logs.styles.classes"
                              (click)="($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.logs.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.logs.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.logs.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-application"
                     *ngIf="!filtersets.application.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.application.collapsible }">
                    <div *ngIf="!filtersets.application.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.application.toggle()">
                      <span class="fieldsetsTitle-text">Application</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.application.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.application.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.application.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.application.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-application_name" *ngIf="!filters.application_name.hidden" 
                                  class="field-container full {{filters.application_name.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.application_name.styles.style"
                                  [ngClass]="filters.application_name.styles.classes">
                              <div class="label-container"
                                    title="{{filters.application_name.label}}{{filters.application_name.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.application_name.label}}">{{filters.application_name.label}}<span *ngIf="filters.application_name.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Utilities-applications_dd_single 
                                  data-cy="selector"
                                  [type]="filters.application_name.control.type"
                                  formControlName="application_name"
                                  (displayTextChange)="filters.application_name.control.displayText=$event"
                                  [placeholder]="filters.application_name.control.placeholder"
                                  [styles]="filters.application_name.control.styles"
                              >
                              </Utilities-applications_dd_single>
                              <ng-container *ngIf="filters.application_name.invalid">
                                <ng-container *ngFor="let error of filters.application_name.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.transactions.hidden" class="tab" data-cy="tab-transactions">
              <h2 [className]="tabs.transactions.active? 'active': ''" (click)="tabs.transactions.activate()">{{tabs.transactions.title}}</h2>
            </div>
            <div *ngIf="!tabs.logs.hidden" class="tab" data-cy="tab-logs">
              <h2 [className]="tabs.logs.active? 'active': ''" (click)="tabs.logs.activate()">{{tabs.logs.title}}</h2>
            </div>
            <div *ngIf="!tabs.projects_configuration.hidden" class="tab" data-cy="tab-projects_configuration">
              <h2 [className]="tabs.projects_configuration.active? 'active': ''" (click)="tabs.projects_configuration.activate()">{{tabs.projects_configuration.title}}</h2>
            </div>
            <div *ngIf="!tabs.http_configuration.hidden" class="tab" data-cy="tab-http_configuration">
              <h2 [className]="tabs.http_configuration.active? 'active': ''" (click)="tabs.http_configuration.activate()">{{tabs.http_configuration.title}}</h2>
            </div>
            <div *ngIf="!tabs.sftp_configuration.hidden" class="tab" data-cy="tab-sftp_configuration">
              <h2 [className]="tabs.sftp_configuration.active? 'active': ''" (click)="tabs.sftp_configuration.activate()">{{tabs.sftp_configuration.title}}</h2>
            </div>
          </div>
        
              <Ultraship-ultraship_messages_grid *ngIf="tabs.transactions.active"
              #$tabs_transactions
              [application_name]="$tabs_transactions_ultraship_messages_grid_inParams_application_name"
              [column_names]="$tabs_transactions_ultraship_messages_grid_inParams_column_names"
              [filter_values]="$tabs_transactions_ultraship_messages_grid_inParams_filter_values"
              ($refreshEvent)="refresh(false, false, '$tabs_transactions')">
              </Ultraship-ultraship_messages_grid>
              <Utilities-logs_grid *ngIf="tabs.logs.active"
              #$tabs_logs
              [application_name]="$tabs_logs_logs_grid_inParams_application_name"
              [column_names]="$tabs_logs_logs_grid_inParams_column_names"
              [filter_values]="$tabs_logs_logs_grid_inParams_filter_values"
              ($refreshEvent)="refresh(false, false, '$tabs_logs')">
              </Utilities-logs_grid>
              <Ultraship-ultraship_project_configurations_grid *ngIf="tabs.projects_configuration.active"
              #$tabs_projects_configuration
              [application_name]="$tabs_projects_configuration_ultraship_project_configurations_grid_inParams_application_name"
              ($refreshEvent)="refresh(false, false, '$tabs_projects_configuration')">
              </Ultraship-ultraship_project_configurations_grid>
              <Ultraship-ultraship_http_configurations_grid *ngIf="tabs.http_configuration.active"
              #$tabs_http_configuration
              [application_name]="$tabs_http_configuration_ultraship_http_configurations_grid_inParams_application_name"
              ($refreshEvent)="refresh(false, false, '$tabs_http_configuration')">
              </Ultraship-ultraship_http_configurations_grid>
              <Ultraship-ultraship_sftp_configurations_grid *ngIf="tabs.sftp_configuration.active"
              #$tabs_sftp_configuration
              [application_name]="$tabs_sftp_configuration_ultraship_sftp_configurations_grid_inParams_application_name"
              ($refreshEvent)="refresh(false, false, '$tabs_sftp_configuration')">
              </Ultraship-ultraship_sftp_configurations_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>