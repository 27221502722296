import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Invoices_reasoncodes_dd_singleComponent } from './Invoices.reasoncodes_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_reasoncodes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-sales_order_pick_task_cancellation_options_form',
  templateUrl: './FootPrintManager.sales_order_pick_task_cancellation_options_form.component.html'
})
export class FootPrintManager_sales_order_pick_task_cancellation_options_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { title?: string, parentId: number, parentEntity: string, cancelOrderRequest?: boolean, autoCancelOrderRequest?: boolean } = { title: null, parentId: null, parentEntity: null, cancelOrderRequest: null, autoCancelOrderRequest: null };
  //#region Inputs
  @Input('title') set $inParams_title(v: string) {
    this.inParams.title = v;
  }
  get $inParams_title(): string {
    return this.inParams.title;
  }
  @Input('parentId') set $inParams_parentId(v: number) {
    this.inParams.parentId = v;
  }
  get $inParams_parentId(): number {
    return this.inParams.parentId;
  }
  @Input('parentEntity') set $inParams_parentEntity(v: string) {
    this.inParams.parentEntity = v;
  }
  get $inParams_parentEntity(): string {
    return this.inParams.parentEntity;
  }
  @Input('cancelOrderRequest') set $inParams_cancelOrderRequest(v: boolean) {
    this.inParams.cancelOrderRequest = v;
  }
  get $inParams_cancelOrderRequest(): boolean {
    return this.inParams.cancelOrderRequest;
  }
  @Input('autoCancelOrderRequest') set $inParams_autoCancelOrderRequest(v: boolean) {
    this.inParams.autoCancelOrderRequest = v;
  }
  get $inParams_autoCancelOrderRequest(): boolean {
    return this.inParams.autoCancelOrderRequest;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean, reasonCodeId?: number, cancelOrderConfirmed?: boolean, cancelOrderReasonCodeId?: number } = { confirm: null, reasonCodeId: null, cancelOrderConfirmed: null, cancelOrderReasonCodeId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean, reasonCodeId?: number, cancelOrderConfirmed?: boolean, cancelOrderReasonCodeId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    reasoncode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    cancel_order: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    cancel_reason_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    reasoncode: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reasoncode'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Reason code', true)
,
    cancel_order: new FieldModel(new CheckBoxModel(this.formGroup.controls['cancel_order'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), '', false)
,
    cancel_reason_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['cancel_reason_code'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Cancel order reason code', true)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
  cancelOrder: new FieldsetModel('Cancel order options', false, false, true),
};

  //#region fields inParams
  get $fields_reasoncode_selector_inParams_parentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.parentId;
    
    return expr;
  }

  get $fields_reasoncode_selector_inParams_parentEntity(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.parentEntity;
    
    return expr;
  }

  get $fields_cancel_reason_code_selector_inParams_parentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 45;
    
    return expr;
  }

  get $fields_cancel_reason_code_selector_inParams_parentEntity(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = 'OperationType';
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    reasoncode: this.fields.reasoncode.control.valueChanges
    ,
    cancel_order: this.fields.cancel_order.control.valueChanges
    ,
    cancel_reason_code: this.fields.cancel_reason_code.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.parentId)) {
        this.$missingRequiredInParams.push('parentId');
      }
      if(isNil(this.inParams.parentEntity)) {
        this.$missingRequiredInParams.push('parentEntity');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Generic Sales Order Pick Task Cancellation ';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.cancel_order.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .reasoncode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .cancel_order
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_cancel_order();
      });
    this.$formGroupFieldValidationObservables
      .cancel_reason_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_sales_order_pick_task_cancellation_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_sales_order_pick_task_cancellation_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Handle double click scenario
  $form.toolbar.confirm.control.readOnly = true;
  
  if ($utils.isDefined($form.fields.cancel_order.control.value) && $form.fields.cancel_order.control.value == true
      && !$utils.isDefined($form.fields.cancel_reason_code.control.value)) {
      await $shell.FootPrintManager.openErrorDialog('Reason code missing', 'Please select a valid order cancellation reason code')
      $form.toolbar.confirm.control.readOnly = false;
  }
  else {
  
      $form.outParams.reasonCodeId = $form.fields.reasoncode.control.value;
      $form.outParams.cancelOrderReasonCodeId = $form.fields.cancel_reason_code.control.value;
  
      if ($form.inParams.parentId === 6) {
          if ($utils.isDefined($form.fields.reasoncode.control.value) && $form.fields.reasoncode.control.value !== '') {
              $form.outParams.confirm = true;
              $form.events.outParamsChange.emit();
              $form.close();
          }
          else {
              await $shell.FootPrintManager.openErrorDialog('Reason code missing', 'Please select a valid reason code')
              $form.toolbar.confirm.control.readOnly = false;
          }
      } else {
  
          $form.outParams.confirm = true;
          $form.events.outParamsChange.emit();
          $form.close();
      }
  
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_sales_order_pick_task_cancellation_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.outParams.cancelOrderConfirmed = false;
  
  var title;
  
  if ($utils.isDefined($form.inParams.title)) {
      title = $form.inParams.title;
  }
  else {
      title = 'Select a reason code';
  }
  
  
  // Set title field
  $form.title = title;
  
  // Hide the cancel order option if not requested
  $form.fields.cancel_reason_code.hidden = true;
  
  if ($utils.isDefined($form.inParams.cancelOrderRequest) && $form.inParams.cancelOrderRequest == true) {
  
      $form.fieldsets.cancelOrder.hidden = false;
  
      if ($utils.isDefined($form.inParams.autoCancelOrderRequest) && $form.inParams.autoCancelOrderRequest == true) {
          $form.fields.cancel_order.label = 'Last pick task, auto cancel.'
          $form.fields.cancel_order.control.value = true;
          $form.fields.cancel_reason_code.hidden = false;
  
      }
      else {
  
          $form.fields.cancel_order.label = 'Cancel remaining pick task?'
      }
  }
  else {
      $form.fieldsets.cancelOrder.hidden = true;
  }
  
  
  }
  on_cancel_order(event = null) {
    return this.on_cancel_orderInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_orderInternal(
    $form: FootPrintManager_sales_order_pick_task_cancellation_options_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isDefined($form.fields.cancel_order.control.value)) {
      if ($form.fields.cancel_order.control.value == true) {
          $form.outParams.cancelOrderConfirmed = true;
  
          $form.fields.cancel_reason_code.hidden = false;
  
      }
      else {
          $form.outParams.cancelOrderConfirmed = false;
          $form.fields.cancel_reason_code.hidden = true;
      }
  }
  else {
      $form.outParams.cancelOrderConfirmed = false;
      $form.fields.cancel_reason_code.hidden = true;
  }
  }
  //#endregion private flows
}
