<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="toggle_add_remove" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_toggle_add_remove_locations_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="add_remove_selected_locations" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_add_remove_selected_locations_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="add_remove_all_locations" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_add_remove_all_locations_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-zones" *ngIf="!filters.zones.hidden" 
            class="field-container full {{filters.zones.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.zones.styles.style"
            [ngClass]="filters.zones.styles.classes">
        <div class="label-container"
              title="{{filters.zones.label}}{{filters.zones.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.zones.label}}">{{filters.zones.label}}<span *ngIf="filters.zones.required"
                  class="required-asterisk">*</span></label>
        </div>
        <InventoryCounts-zones_dd_multi 
            data-cy="selector-multi"
            [type]="filters.zones.control.type"
            formControlName="zones"
            (displayTextChange)="filters.zones.control.displayText=$event"
            [placeholder]="filters.zones.control.placeholder"
            [styles]="filters.zones.control.styles"
          [warehouseId]="$fields_zones_selector_inParams_warehouseId"
        >
        </InventoryCounts-zones_dd_multi>
        <ng-container *ngIf="filters.zones.invalid">
          <ng-container *ngFor="let error of filters.zones.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
            class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.status.styles.style"
            [ngClass]="filters.status.styles.classes">
        <div class="label-container"
              title="{{filters.status.label}}{{filters.status.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.status.label}}">{{filters.status.label}}<span *ngIf="filters.status.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Locations-location_statuses_dd_single 
            data-cy="selector"
            [type]="filters.status.control.type"
            formControlName="status"
            (displayTextChange)="filters.status.control.displayText=$event"
            [placeholder]="filters.status.control.placeholder"
            [styles]="filters.status.control.styles"
        >
        </Locations-location_statuses_dd_single>
        <ng-container *ngIf="filters.status.invalid">
          <ng-container *ngFor="let error of filters.status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-storage_category" *ngIf="!filters.storage_category.hidden" 
            class="field-container standard {{filters.storage_category.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.storage_category.styles.style"
            [ngClass]="filters.storage_category.styles.classes">
        <div class="label-container"
              title="{{filters.storage_category.label}}{{filters.storage_category.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.storage_category.label}}">{{filters.storage_category.label}}<span *ngIf="filters.storage_category.required"
                  class="required-asterisk">*</span></label>
        </div>
        <InventoryCounts-storage_categories_dd_single 
            data-cy="selector"
            [type]="filters.storage_category.control.type"
            formControlName="storage_category"
            (displayTextChange)="filters.storage_category.control.displayText=$event"
            [placeholder]="filters.storage_category.control.placeholder"
            [styles]="filters.storage_category.control.styles"
        >
        </InventoryCounts-storage_categories_dd_single>
        <ng-container *ngIf="filters.storage_category.invalid">
          <ng-container *ngFor="let error of filters.storage_category.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="location_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.location_name.displayControl.styles.style"
          [ngClass]="row.cells.location_name.displayControl.styles.classes">{{row.cells.location_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="parent_location_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.parent_location_name.displayControl.styles.style"
          [ngClass]="row.cells.parent_location_name.displayControl.styles.classes">{{row.cells.parent_location_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status_name.displayControl.styles.style"
          [ngClass]="row.cells.status_name.displayControl.styles.classes">{{row.cells.status_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="temperature_category_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.temperature_category_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.temperature_category_lookupcode.displayControl.styles.classes">{{row.cells.temperature_category_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
