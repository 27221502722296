import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Returns.frontend.types'

@Injectable({ providedIn: 'root' })
export class Returns_create_blind_return_order_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { waitStatus?: string, lookupcode?: string, orderClassId: number, projectId: number, warehouseId: number, ownerReference?: string }): Promise<{ reason?: string, orderId?: number, shipmentId?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderClassId)) {
      missingRequiredInParams.push('\'orderClassId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/functions/create_blind_return_order_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

