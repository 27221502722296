<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-name" *ngIf="!fields.name.hidden" 
                            class="field-container double {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                              title="{{fields.name.label}}{{fields.name.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.name.label}}">{{fields.name.label}}<span *ngIf="fields.name.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container double {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-location_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                        >
                        </Locations-location_statuses_dd_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-storage_category" *ngIf="!fields.storage_category.hidden" 
                            class="field-container double {{fields.storage_category.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.storage_category.styles.style"
                            [ngClass]="fields.storage_category.styles.classes">
                        <div class="label-container"
                              title="{{fields.storage_category.label}}{{fields.storage_category.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.storage_category.label}}">{{fields.storage_category.label}}<span *ngIf="fields.storage_category.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-storage_categories_dd_single 
                            data-cy="selector"
                            [type]="fields.storage_category.control.type"
                            formControlName="storage_category"
                            (displayTextChange)="fields.storage_category.control.displayText=$event"
                            [placeholder]="fields.storage_category.control.placeholder"
                            [styles]="fields.storage_category.control.styles"
                        >
                        </Locations-storage_categories_dd_single>
                        <ng-container *ngIf="fields.storage_category.invalid">
                          <ng-container *ngFor="let error of fields.storage_category.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Settings</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-is_loose" *ngIf="!fields.is_loose.hidden" 
                            class="field-container standard {{fields.is_loose.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_loose.styles.style"
                            [ngClass]="fields.is_loose.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_loose.label}}{{fields.is_loose.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_loose.label}}">{{fields.is_loose.label}}<span *ngIf="fields.is_loose.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_loose"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_loose.control.styles.style"
                                      [ngClass]="fields.is_loose.control.styles.classes">{{fields.is_loose.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_loose.invalid">
                          <ng-container *ngFor="let error of fields.is_loose.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-purge_added_inventory" *ngIf="!fields.purge_added_inventory.hidden" 
                            class="field-container standard {{fields.purge_added_inventory.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.purge_added_inventory.styles.style"
                            [ngClass]="fields.purge_added_inventory.styles.classes">
                        <div class="label-container"
                              title="{{fields.purge_added_inventory.label}}{{fields.purge_added_inventory.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.purge_added_inventory.label}}">{{fields.purge_added_inventory.label}}<span *ngIf="fields.purge_added_inventory.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="purge_added_inventory"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.purge_added_inventory.control.styles.style"
                                      [ngClass]="fields.purge_added_inventory.control.styles.classes">{{fields.purge_added_inventory.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.purge_added_inventory.invalid">
                          <ng-container *ngFor="let error of fields.purge_added_inventory.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-allow_inactive" *ngIf="!fields.allow_inactive.hidden" 
                            class="field-container standard {{fields.allow_inactive.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.allow_inactive.styles.style"
                            [ngClass]="fields.allow_inactive.styles.classes">
                        <div class="label-container"
                              title="{{fields.allow_inactive.label}}{{fields.allow_inactive.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.allow_inactive.label}}">{{fields.allow_inactive.label}}<span *ngIf="fields.allow_inactive.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="allow_inactive"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.allow_inactive.control.styles.style"
                                      [ngClass]="fields.allow_inactive.control.styles.classes">{{fields.allow_inactive.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.allow_inactive.invalid">
                          <ng-container *ngFor="let error of fields.allow_inactive.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-skip_cycle_count" *ngIf="!fields.skip_cycle_count.hidden" 
                            class="field-container standard {{fields.skip_cycle_count.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.skip_cycle_count.styles.style"
                            [ngClass]="fields.skip_cycle_count.styles.classes">
                        <div class="label-container"
                              title="{{fields.skip_cycle_count.label}}{{fields.skip_cycle_count.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.skip_cycle_count.label}}">{{fields.skip_cycle_count.label}}<span *ngIf="fields.skip_cycle_count.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="skip_cycle_count"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.skip_cycle_count.control.styles.style"
                                      [ngClass]="fields.skip_cycle_count.control.styles.classes">{{fields.skip_cycle_count.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.skip_cycle_count.invalid">
                          <ng-container *ngFor="let error of fields.skip_cycle_count.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-skip_validation_rules" *ngIf="!fields.skip_validation_rules.hidden" 
                            class="field-container standard {{fields.skip_validation_rules.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.skip_validation_rules.styles.style"
                            [ngClass]="fields.skip_validation_rules.styles.classes">
                        <div class="label-container"
                              title="{{fields.skip_validation_rules.label}}{{fields.skip_validation_rules.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.skip_validation_rules.label}}">{{fields.skip_validation_rules.label}}<span *ngIf="fields.skip_validation_rules.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="skip_validation_rules"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.skip_validation_rules.control.styles.style"
                                      [ngClass]="fields.skip_validation_rules.control.styles.classes">{{fields.skip_validation_rules.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.skip_validation_rules.invalid">
                          <ng-container *ngFor="let error of fields.skip_validation_rules.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-mixing_restrictions" *ngIf="!fields.mixing_restrictions.hidden" 
                            class="field-container standard {{fields.mixing_restrictions.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.mixing_restrictions.styles.style"
                            [ngClass]="fields.mixing_restrictions.styles.classes">
                        <div class="label-container"
                              title="{{fields.mixing_restrictions.label}}{{fields.mixing_restrictions.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.mixing_restrictions.label}}">{{fields.mixing_restrictions.label}}<span *ngIf="fields.mixing_restrictions.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-mixing_rule_dd_single 
                            data-cy="selector"
                            [type]="fields.mixing_restrictions.control.type"
                            formControlName="mixing_restrictions"
                            (displayTextChange)="fields.mixing_restrictions.control.displayText=$event"
                            [placeholder]="fields.mixing_restrictions.control.placeholder"
                            [styles]="fields.mixing_restrictions.control.styles"
                        >
                        </Locations-mixing_rule_dd_single>
                        <ng-container *ngIf="fields.mixing_restrictions.invalid">
                          <ng-container *ngFor="let error of fields.mixing_restrictions.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-outbound_order_settings"
                     *ngIf="!fieldsets.outbound_order_settings.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.outbound_order_settings.collapsible }">
                    <div *ngIf="!fieldsets.outbound_order_settings.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.outbound_order_settings.toggle()">
                      <span class="fieldsetsTitle-text">Outbound order settings</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.outbound_order_settings.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.outbound_order_settings.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.outbound_order_settings.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.outbound_order_settings.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-eligible_for_allocation" *ngIf="!fields.eligible_for_allocation.hidden" 
                            class="field-container standard {{fields.eligible_for_allocation.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.eligible_for_allocation.styles.style"
                            [ngClass]="fields.eligible_for_allocation.styles.classes">
                        <div class="label-container"
                              title="{{fields.eligible_for_allocation.label}}{{fields.eligible_for_allocation.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.eligible_for_allocation.label}}">{{fields.eligible_for_allocation.label}}<span *ngIf="fields.eligible_for_allocation.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="eligible_for_allocation"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.eligible_for_allocation.control.styles.style"
                                      [ngClass]="fields.eligible_for_allocation.control.styles.classes">{{fields.eligible_for_allocation.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.eligible_for_allocation.invalid">
                          <ng-container *ngFor="let error of fields.eligible_for_allocation.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_primary" *ngIf="!fields.is_primary.hidden" 
                            class="field-container standard {{fields.is_primary.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_primary.styles.style"
                            [ngClass]="fields.is_primary.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_primary.label}}{{fields.is_primary.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_primary.label}}">{{fields.is_primary.label}}<span *ngIf="fields.is_primary.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_primary"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_primary.control.styles.style"
                                      [ngClass]="fields.is_primary.control.styles.classes">{{fields.is_primary.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_primary.invalid">
                          <ng-container *ngFor="let error of fields.is_primary.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pack_verification" *ngIf="!fields.pack_verification.hidden" 
                            class="field-container standard {{fields.pack_verification.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pack_verification.styles.style"
                            [ngClass]="fields.pack_verification.styles.classes">
                        <div class="label-container"
                              title="{{fields.pack_verification.label}}{{fields.pack_verification.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.pack_verification.label}}">{{fields.pack_verification.label}}<span *ngIf="fields.pack_verification.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="pack_verification"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.pack_verification.control.styles.style"
                                      [ngClass]="fields.pack_verification.control.styles.classes">{{fields.pack_verification.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.pack_verification.invalid">
                          <ng-container *ngFor="let error of fields.pack_verification.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-skip_count_back" *ngIf="!fields.skip_count_back.hidden" 
                            class="field-container standard {{fields.skip_count_back.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.skip_count_back.styles.style"
                            [ngClass]="fields.skip_count_back.styles.classes">
                        <div class="label-container"
                              title="{{fields.skip_count_back.label}}{{fields.skip_count_back.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.skip_count_back.label}}">{{fields.skip_count_back.label}}<span *ngIf="fields.skip_count_back.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="skip_count_back"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.skip_count_back.control.styles.style"
                                      [ngClass]="fields.skip_count_back.control.styles.classes">{{fields.skip_count_back.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.skip_count_back.invalid">
                          <ng-container *ngFor="let error of fields.skip_count_back.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-picking_sequence" *ngIf="!fields.picking_sequence.hidden" 
                            class="field-container standard {{fields.picking_sequence.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.picking_sequence.styles.style"
                            [ngClass]="fields.picking_sequence.styles.classes">
                        <div class="label-container"
                              title="{{fields.picking_sequence.label}}{{fields.picking_sequence.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.picking_sequence.label}}">{{fields.picking_sequence.label}}<span *ngIf="fields.picking_sequence.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="picking_sequence"
                                matInput
                                numberBox
                                [format]="fields.picking_sequence.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.picking_sequence.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.picking_sequence.control.placeholder}}"
                                [ngStyle]="fields.picking_sequence.control.styles.style"
                                [ngClass]="fields.picking_sequence.control.styles.classes">
                        <ng-container *ngIf="fields.picking_sequence.invalid">
                          <ng-container *ngFor="let error of fields.picking_sequence.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-allocation_sequence" *ngIf="!fields.allocation_sequence.hidden" 
                            class="field-container standard {{fields.allocation_sequence.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.allocation_sequence.styles.style"
                            [ngClass]="fields.allocation_sequence.styles.classes">
                        <div class="label-container"
                              title="{{fields.allocation_sequence.label}}{{fields.allocation_sequence.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.allocation_sequence.label}}">{{fields.allocation_sequence.label}}<span *ngIf="fields.allocation_sequence.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="allocation_sequence"
                                matInput
                                numberBox
                                [format]="fields.allocation_sequence.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.allocation_sequence.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.allocation_sequence.control.placeholder}}"
                                [ngStyle]="fields.allocation_sequence.control.styles.style"
                                [ngClass]="fields.allocation_sequence.control.styles.classes">
                        <ng-container *ngIf="fields.allocation_sequence.invalid">
                          <ng-container *ngFor="let error of fields.allocation_sequence.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-printer_alias" *ngIf="!fields.printer_alias.hidden" 
                            class="field-container standard {{fields.printer_alias.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.printer_alias.styles.style"
                            [ngClass]="fields.printer_alias.styles.classes">
                        <div class="label-container"
                              title="{{fields.printer_alias.label}}{{fields.printer_alias.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.printer_alias.label}}">{{fields.printer_alias.label}}<span *ngIf="fields.printer_alias.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="printer_alias"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.printer_alias.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.printer_alias.control.placeholder}}"
                                [ngStyle]="fields.printer_alias.control.styles.style"
                                [ngClass]="fields.printer_alias.control.styles.classes"> 
                        <ng-container *ngIf="fields.printer_alias.invalid">
                          <ng-container *ngFor="let error of fields.printer_alias.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-properties"
                     *ngIf="!fieldsets.properties.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.properties.collapsible }">
                    <div *ngIf="!fieldsets.properties.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.properties.toggle()">
                      <span class="fieldsetsTitle-text">Properties</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.properties.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.properties.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.properties.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.properties.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-length" *ngIf="!fields.length.hidden" 
                            class="field-container double {{fields.length.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.length.styles.style"
                            [ngClass]="fields.length.styles.classes">
                        <div class="label-container"
                              title="{{fields.length.label}}{{fields.length.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.length.label}}">{{fields.length.label}}<span *ngIf="fields.length.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="length"
                                matInput
                                numberBox
                                [format]="fields.length.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.length.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.length.control.placeholder}}"
                                [ngStyle]="fields.length.control.styles.style"
                                [ngClass]="fields.length.control.styles.classes">
                        <ng-container *ngIf="fields.length.invalid">
                          <ng-container *ngFor="let error of fields.length.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-width" *ngIf="!fields.width.hidden" 
                            class="field-container double {{fields.width.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.width.styles.style"
                            [ngClass]="fields.width.styles.classes">
                        <div class="label-container"
                              title="{{fields.width.label}}{{fields.width.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.width.label}}">{{fields.width.label}}<span *ngIf="fields.width.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="width"
                                matInput
                                numberBox
                                [format]="fields.width.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.width.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.width.control.placeholder}}"
                                [ngStyle]="fields.width.control.styles.style"
                                [ngClass]="fields.width.control.styles.classes">
                        <ng-container *ngIf="fields.width.invalid">
                          <ng-container *ngFor="let error of fields.width.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-height" *ngIf="!fields.height.hidden" 
                            class="field-container double {{fields.height.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.height.styles.style"
                            [ngClass]="fields.height.styles.classes">
                        <div class="label-container"
                              title="{{fields.height.label}}{{fields.height.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.height.label}}">{{fields.height.label}}<span *ngIf="fields.height.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="height"
                                matInput
                                numberBox
                                [format]="fields.height.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.height.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.height.control.placeholder}}"
                                [ngStyle]="fields.height.control.styles.style"
                                [ngClass]="fields.height.control.styles.classes">
                        <ng-container *ngIf="fields.height.invalid">
                          <ng-container *ngFor="let error of fields.height.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dimension_uom" *ngIf="!fields.dimension_uom.hidden" 
                            class="field-container double {{fields.dimension_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dimension_uom.styles.style"
                            [ngClass]="fields.dimension_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.dimension_uom.label}}{{fields.dimension_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dimension_uom.label}}">{{fields.dimension_uom.label}}<span *ngIf="fields.dimension_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.dimension_uom.control.type"
                            formControlName="dimension_uom"
                            (displayTextChange)="fields.dimension_uom.control.displayText=$event"
                            [placeholder]="fields.dimension_uom.control.placeholder"
                            [styles]="fields.dimension_uom.control.styles"
                          [typeId]="$fields_dimension_uom_selector_inParams_typeId"
                        >
                        </Locations-measurement_units_dd_single>
                        <ng-container *ngIf="fields.dimension_uom.invalid">
                          <ng-container *ngFor="let error of fields.dimension_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_capacity" *ngIf="!fields.weight_capacity.hidden" 
                            class="field-container double {{fields.weight_capacity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight_capacity.styles.style"
                            [ngClass]="fields.weight_capacity.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight_capacity.label}}{{fields.weight_capacity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight_capacity.label}}">{{fields.weight_capacity.label}}<span *ngIf="fields.weight_capacity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="weight_capacity"
                                matInput
                                numberBox
                                [format]="fields.weight_capacity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.weight_capacity.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.weight_capacity.control.placeholder}}"
                                [ngStyle]="fields.weight_capacity.control.styles.style"
                                [ngClass]="fields.weight_capacity.control.styles.classes">
                        <ng-container *ngIf="fields.weight_capacity.invalid">
                          <ng-container *ngFor="let error of fields.weight_capacity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden" 
                            class="field-container double {{fields.weight_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight_uom.label}}{{fields.weight_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight_uom.label}}">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </Locations-measurement_units_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-volume_capacity" *ngIf="!fields.volume_capacity.hidden" 
                            class="field-container double {{fields.volume_capacity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.volume_capacity.styles.style"
                            [ngClass]="fields.volume_capacity.styles.classes">
                        <div class="label-container"
                              title="{{fields.volume_capacity.label}}{{fields.volume_capacity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.volume_capacity.label}}">{{fields.volume_capacity.label}}<span *ngIf="fields.volume_capacity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="volume_capacity"
                                matInput
                                numberBox
                                [format]="fields.volume_capacity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.volume_capacity.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.volume_capacity.control.placeholder}}"
                                [ngStyle]="fields.volume_capacity.control.styles.style"
                                [ngClass]="fields.volume_capacity.control.styles.classes">
                        <ng-container *ngIf="fields.volume_capacity.invalid">
                          <ng-container *ngFor="let error of fields.volume_capacity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-volume_uom" *ngIf="!fields.volume_uom.hidden" 
                            class="field-container double {{fields.volume_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.volume_uom.styles.style"
                            [ngClass]="fields.volume_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.volume_uom.label}}{{fields.volume_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.volume_uom.label}}">{{fields.volume_uom.label}}<span *ngIf="fields.volume_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.volume_uom.control.type"
                            formControlName="volume_uom"
                            (displayTextChange)="fields.volume_uom.control.displayText=$event"
                            [placeholder]="fields.volume_uom.control.placeholder"
                            [styles]="fields.volume_uom.control.styles"
                          [typeId]="$fields_volume_uom_selector_inParams_typeId"
                        >
                        </Locations-measurement_units_dd_single>
                        <ng-container *ngIf="fields.volume_uom.invalid">
                          <ng-container *ngFor="let error of fields.volume_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-child_footprint" *ngIf="!fields.child_footprint.hidden" 
                            class="field-container double {{fields.child_footprint.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.child_footprint.styles.style"
                            [ngClass]="fields.child_footprint.styles.classes">
                        <div class="label-container"
                              title="{{fields.child_footprint.label}}{{fields.child_footprint.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.child_footprint.label}}">{{fields.child_footprint.label}}<span *ngIf="fields.child_footprint.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="child_footprint"
                                matInput
                                numberBox
                                [format]="fields.child_footprint.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.child_footprint.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.child_footprint.control.placeholder}}"
                                [ngStyle]="fields.child_footprint.control.styles.style"
                                [ngClass]="fields.child_footprint.control.styles.classes">
                        <ng-container *ngIf="fields.child_footprint.invalid">
                          <ng-container *ngFor="let error of fields.child_footprint.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-child_stack_height" *ngIf="!fields.child_stack_height.hidden" 
                            class="field-container double {{fields.child_stack_height.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.child_stack_height.styles.style"
                            [ngClass]="fields.child_stack_height.styles.classes">
                        <div class="label-container"
                              title="{{fields.child_stack_height.label}}{{fields.child_stack_height.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.child_stack_height.label}}">{{fields.child_stack_height.label}}<span *ngIf="fields.child_stack_height.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="child_stack_height"
                                matInput
                                numberBox
                                [format]="fields.child_stack_height.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.child_stack_height.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.child_stack_height.control.placeholder}}"
                                [ngStyle]="fields.child_stack_height.control.styles.style"
                                [ngClass]="fields.child_stack_height.control.styles.classes">
                        <ng-container *ngIf="fields.child_stack_height.invalid">
                          <ng-container *ngFor="let error of fields.child_stack_height.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>