import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintApiManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintApiManager.frontend.types' 

import { FootPrintApiManager_processes_dd_singleComponent } from './FootPrintApiManager.processes_dd_single.component'


interface IFootPrintApiManager_logs_gridComponentEntity {
id?: string, level?: number, process?: string, transaction_id?: string, ingress_id?: string, egress_id?: string, notes?: string, created_by?: string, created_on?: string, alerted_on?: string, integration_name?: string}

interface IFootPrintApiManager_logs_gridComponentInParams {
  transaction_id?: string, integration_name?: string, ingress_id?: string, egress_id?: string}


class FootPrintApiManager_logs_gridComponentRowModel extends GridRowModel {
  grid: FootPrintApiManager_logs_gridComponent;
  entity: IFootPrintApiManager_logs_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    integration_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    level: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    process: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    transaction_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    ingress_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    egress_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    created_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    alerted_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintApiManager_ShellService,
private datasources: FootPrintApiManager_DatasourceService,
private flows: FootPrintApiManager_FlowService,
private reports: FootPrintApiManager_ReportService,
private localization: FootPrintApiManager_LocalizationService,
private operations: FootPrintApiManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintApiManager_logs_gridComponent, entity: IFootPrintApiManager_logs_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintApiManager_logs_gridComponent, entity?: IFootPrintApiManager_logs_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      notes:  $grid.fullTextSearch ,
      level:  $grid.filters.level.control.value ,
      process:  $grid.filters.process.control.value ,
      transaction_id:  $grid.inParams.transaction_id ,
      ingress_id:  $grid.inParams.ingress_id ,
      egress_id:  $grid.inParams.egress_id ,
      created_on:  null ,
      integration_name:  $grid.inParams.integration_name ,
    };
    const data = await this.datasources.FootPrintApiManager.ds_storage_logs.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.id;
    (this.cells.integration_name.displayControl as TextModel).text = $row.entity.integration_name;
    (this.cells.level.displayControl as TextModel).text = $row.entity.level?.toString();
    (this.cells.process.displayControl as TextModel).text = $row.entity.process;
    (this.cells.transaction_id.displayControl as TextModel).text = $row.entity.transaction_id;
    (this.cells.ingress_id.displayControl as TextModel).text = $row.entity.ingress_id;
    (this.cells.egress_id.displayControl as TextModel).text = $row.entity.egress_id?.toString();
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.created_by;
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.created_on?.toString();
    (this.cells.alerted_on.displayControl as TextModel).text = $row.entity.alerted_on?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'transaction_id' : {
        this.on_transaction_clicked();
        break;
      }
      case 'ingress_id' : {
        this.on_ingress_clicked();
        break;
      }
      case 'egress_id' : {
        this.on_egress_clicked();
        break;
      }
      case 'notes' : {
        this.on_notes_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_transaction_clicked(event = null) {
    return this.on_transaction_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transaction_clickedInternal(
    $row: FootPrintApiManager_logs_gridComponentRowModel,
  $grid: FootPrintApiManager_logs_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/15/2024
  
  let transaction_id: string = $row.entity.transaction_id;
  
  if ($utils.isDefined(transaction_id)) {
      if (transaction_id !== "") {
          $shell.FootPrintApiManager.opentransaction_hubDialog({ transaction_id: transaction_id, activate_grid: "logs" }, "modal", EModalSize.Xlarge)
      }
  }
  }
  on_notes_clicked(event = null) {
    return this.on_notes_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_notes_clickedInternal(
    $row: FootPrintApiManager_logs_gridComponentRowModel,
  $grid: FootPrintApiManager_logs_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/27/2024
  
  let payload_json: any;
  let payload_xml: any;
  
  let payload_string: string = $row.entity.notes;
  
  try {
      payload_json = JSON.parse(payload_string);
  } catch (e) {
  };
  
  if (!$utils.isDefined(payload_json)) {
      try {
          payload_string = payload_string.trim();
          if (payload_string.startsWith("<") && payload_string.endsWith(">")) {
              payload_json = $utils.parseXml(payload_string)
              if ($utils.isDefined(payload_json)) {
                  payload_xml = payload_string;
                  payload_json = null;
              };
          };
      } catch (e) {
      };
  };
  
  if (!$utils.isDefined(payload_json) && !$utils.isDefined(payload_xml)) {
      $shell.FootPrintApiManager.openInfoDialog("Notes", payload_string);
  };
  try {
      if ($utils.isDefined(payload_json)) {
          await $shell.FootPrintApiManager.openview_json_payloadDialog({ payload: payload_json, title: "Notes" });
      };
      if ($utils.isDefined(payload_xml)) {
          await $shell.FootPrintApiManager.openview_xml_payloadDialog({ payload: payload_xml, title: "Notes" });
      };
  } catch {
      $shell.FootPrintApiManager.openInfoDialog("Notes", payload_string);
  };
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintApiManager_logs_gridComponentRowModel,
  $grid: FootPrintApiManager_logs_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/23/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.created_on)) {
          $row.cells.created_on.displayControl.text = $utils.date.format($row.entity.created_on, format);
      };
      if ($utils.isDefined($row.entity.alerted_on)) {
          $row.cells.alerted_on.displayControl.text = $utils.date.format($row.entity.alerted_on, format);
      };
  };
  
  let notes = $row.entity.notes;
  
  if ($utils.isDefined(notes)) {
      notes = (notes.length > 75) ? notes.substring(0, 75) : notes;
      $row.cells.notes.displayControl.text = notes;
  };
  }
  on_ingress_clicked(event = null) {
    return this.on_ingress_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ingress_clickedInternal(
    $row: FootPrintApiManager_logs_gridComponentRowModel,
  $grid: FootPrintApiManager_logs_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/28/2024
  
  let ingress_id: string = $row.entity.ingress_id;
  
  if ($utils.isDefined(ingress_id)) {
      $shell.FootPrintApiManager.opentransaction_hubDialog({ ingress_id: ingress_id, activate_grid: "logs" });
  }
  }
  on_egress_clicked(event = null) {
    return this.on_egress_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_egress_clickedInternal(
    $row: FootPrintApiManager_logs_gridComponentRowModel,
  $grid: FootPrintApiManager_logs_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/28/2024
  
  let egress_id: string = $row.entity.egress_id;
  
  if ($utils.isDefined(egress_id)) {
      $shell.FootPrintApiManager.opentransaction_hubDialog({ egress_id: egress_id, activate_grid: "logs" });
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintApiManager_processes_dd_singleComponent),
  ],
  selector: 'FootPrintApiManager-logs_grid',
  templateUrl: './FootPrintApiManager.logs_grid.component.html'
})
export class FootPrintApiManager_logs_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintApiManager_logs_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintApiManager_logs_gridComponentInParams = { transaction_id: null, integration_name: null, ingress_id: null, egress_id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'Id', false, false, null),       integration_name: new GridHeaderModel(new HeaderStyles(null, null), 'Integration name', false, false, null),       level: new GridHeaderModel(new HeaderStyles(null, null), 'Level', false, false, null),       process: new GridHeaderModel(new HeaderStyles(null, null), 'Process', false, false, null),       transaction_id: new GridHeaderModel(new HeaderStyles(null, null), 'Transaction id', false, false, null),       ingress_id: new GridHeaderModel(new HeaderStyles(null, null), 'Ingress id', false, false, null),       egress_id: new GridHeaderModel(new HeaderStyles(null, null), 'Egress id', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       alerted_on: new GridHeaderModel(new HeaderStyles(null, null), 'Alerted on', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintApiManager_logs_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('transaction_id') set $inParams_transaction_id(value: any) {
    this.inParams['transaction_id'] = value;
  }
  get $inParams_transaction_id(): any {
    return this.inParams['transaction_id'] ;
  }
  @Input('integration_name') set $inParams_integration_name(value: any) {
    this.inParams['integration_name'] = value;
  }
  get $inParams_integration_name(): any {
    return this.inParams['integration_name'] ;
  }
  @Input('ingress_id') set $inParams_ingress_id(value: any) {
    this.inParams['ingress_id'] = value;
  }
  get $inParams_ingress_id(): any {
    return this.inParams['ingress_id'] ;
  }
  @Input('egress_id') set $inParams_egress_id(value: any) {
    this.inParams['egress_id'] = value;
  }
  get $inParams_egress_id(): any {
    return this.inParams['egress_id'] ;
  }

  topToolbar = {
      delete_log: new ToolModel(new ButtonModel('delete_log', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    level: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    process: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    level: new FieldModel(new NumberBoxModel(this.formGroup.controls['level'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Level', false)
,
    process: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['process'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Process', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintApiManager_ShellService,
    private datasources: FootPrintApiManager_DatasourceService,
    private flows: FootPrintApiManager_FlowService,
    private reports: FootPrintApiManager_ReportService,
    private localization: FootPrintApiManager_LocalizationService,
    private operations: FootPrintApiManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Logs grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      notes:  $grid.fullTextSearch ,
      level:  $grid.filters.level.control.value ,
      process:  $grid.filters.process.control.value ,
      transaction_id:  $grid.inParams.transaction_id ,
      ingress_id:  $grid.inParams.ingress_id ,
      egress_id:  $grid.inParams.egress_id ,
      created_on:  null ,
      integration_name:  $grid.inParams.integration_name ,
    };
    try {
    const data = await this.datasources.FootPrintApiManager.ds_storage_logs.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintApiManager_logs_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintApiManager_logs_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/14/2024
  
  $grid.title = 'FootPrint API Manager Logs Grid'
  
  $grid.headers.id.hidden = true;
  
  let integration_name: string = $grid.inParams.integration_name ?? "";
  
  if (integration_name.toUpperCase() === "NULL") {
      integration_name = "";
  };
  
  if (integration_name !== "") {
      $grid.headers.integration_name.hidden = true;
  };
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintApiManager_logs_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintApiManager', 'logs_grid.on_data_loaded');
  //O.Arias - 03/14/2024
  
  let filter: string[] = [];
  
  filter = $grid.rows.filter(r => $utils.isDefined(r.entity.ingress_id))?.map(r => r.entity.id);
  if (filter.length === 0) {
      $grid.headers.ingress_id.hidden = true;
  };
  
  filter = $grid.rows.filter(r => $utils.isDefined(r.entity.egress_id))?.map(r => r.entity.id);
  if (filter.length === 0) {
      $grid.headers.egress_id.hidden = true;
  };
  
  filter = $grid.rows.filter(r => $utils.isDefined(r.entity.transaction_id))?.map(r => r.entity.id);
  if (filter.length === 0) {
      $grid.headers.transaction_id.hidden = true;
  };
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintApiManager_logs_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/13/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.FootPrintApiManager.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.FootPrintApiManager.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  await log_delete({
                      "id": entity.id
                  }, entity.integration_name);
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  deleted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.FootPrintApiManager.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deleted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
          };
  
          if (deleted_records.length > 0) {
              await log_add({
                  "process": "logs_grid",
                  "level": 1,
                  "transaction_id": selected_rows[0].entity.transaction_id,
                  "ingress_id": selected_rows[0].entity.ingress_id,
                  "egress_id": selected_rows[0].entity.egress_id,
                  "notes": `Deleted logs! Ids: ${JSON.stringify(deleted_records)} Notes: ${selected_rows[0].entity.notes}`
              }, selected_rows[0].entity.integration_name);
          };
      };
  };
  
  $grid.refresh();
  
  const log_schema = (await $flows.FootPrintApiManager.storage_logs({action: "", integration_name: ""})).payload;
  async function log_add(input: typeof log_schema[0], integration_name: string) {
      await $flows.FootPrintApiManager.storage_logs({ action: "Write", payload: input, integration_name: integration_name });
  };
  async function log_delete(input: typeof log_schema[0], integration_name: string) {
      await $flows.FootPrintApiManager.storage_logs({ action: "Delete", payload: input, integration_name: integration_name });
  };
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintApiManager_logs_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 02/05/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_log.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintApiManager_logs_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  if ($grid.rows.length > 0) {
  
      let ids: string[] = [];
  
      if ($utils.isDefined($grid.selectedRows)) {
          if ($grid.selectedRows.length > 0) {
              ids = $grid.selectedRows.map(o => o.entity.id);
          };
      };
  
      let notes = $grid.fullTextSearch;
      let transaction_id = $grid.inParams.transaction_id;
      let level = $grid.filters.level.control.value;
      let process = $grid.filters.process.control.value;
      let integration_name = $grid.inParams.integration_name;
  
      let data = (await $datasources.FootPrintApiManager.ds_storage_logs.getList({
          notes: notes,
          level: level,
          process: process,
          transaction_id: transaction_id,
          integration_name: integration_name
      }));
  
      if (data.totalCount > 0) {
  
          let result = data.result;
  
          if (ids.length > 0) {
              result = result.filter(r => ids.indexOf(r.id) >= 0);
          };
  
          let logs = result.map(r => {
              return {
                  "Integration Name": r.integration_name,
                  "Level": r.level,
                  "Process": r.process,
                  "Transaction Id": r.transaction_id,
                  "Ingress Id": r.ingress_id,
                  "Egress Id": r.egress_id,
                  "Notes": r.notes.substring(0,30000),
                  "Created By": r.created_by,
                  "Created On (UTC)": r.created_on,
                  "Alerted On (UTC)": r.alerted_on
              }
          });
  
          let wb = $event;
          let ws = $utils.excel.json_to_sheet(logs);
          $utils.excel.book_append_sheet(wb, ws, "Logs");
  
      } else {
          $shell.FootPrintApiManager.openInfoDialog('Error', 'The parameters did not return any records to export.')
      };
  } else {
      $shell.FootPrintApiManager.openInfoDialog('Error', 'There are no records to export!')
  };
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintApiManager_logs_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
