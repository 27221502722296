import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Inventory.frontend.types'

@Injectable({ providedIn: 'root' })
export class Inventory_insert_inventory_snapshot_rule_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { ownerId: number, projectId: number, warehouseId: number, snapshotLevel: string, ownerLookupcode: string, projectLookupcode: string, warehouseName: string }): Promise<void> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerId)) {
      missingRequiredInParams.push('\'ownerId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.snapshotLevel)) {
      missingRequiredInParams.push('\'snapshotLevel\'');
    }
    if (isNil(inParams.ownerLookupcode)) {
      missingRequiredInParams.push('\'ownerLookupcode\'');
    }
    if (isNil(inParams.projectLookupcode)) {
      missingRequiredInParams.push('\'projectLookupcode\'');
    }
    if (isNil(inParams.warehouseName)) {
      missingRequiredInParams.push('\'warehouseName\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/functions/insert_inventory_snapshot_rule_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

