import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Instructions_create_instruction_flowService } from './Instructions.flow.index';
import { Instructions_delete_instruction_flowService } from './Instructions.flow.index';
import { Instructions_is_instruction_deletable_flowService } from './Instructions.flow.index';
import { Instructions_update_instruction_flowService } from './Instructions.flow.index';

import { $frontendTypes } from './Instructions.frontend.types'

@Injectable({ providedIn: 'root' })
export class Instructions_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Instructions: Instructions_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_instruction_flow: Instructions_create_instruction_flowService;
  public async create_instruction_flow(inParams: { entityName: string, entityKey1: number, instruction: string, entityKey2?: number, entityKey3?: number, entityKey4?: number, typeId: number }): Promise< { reasons?: string[], instruction_id?: number }> {
    if(!this._create_instruction_flow) {
      this._create_instruction_flow = this.injector.get(Instructions_create_instruction_flowService);
    }
    return this._create_instruction_flow.run(inParams);
  }
   
   

   
 
  private _delete_instruction_flow: Instructions_delete_instruction_flowService;
  public async delete_instruction_flow(inParams: { instructionId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_instruction_flow) {
      this._delete_instruction_flow = this.injector.get(Instructions_delete_instruction_flowService);
    }
    return this._delete_instruction_flow.run(inParams);
  }
   
   

   
 
  private _is_instruction_deletable_flow: Instructions_is_instruction_deletable_flowService;
  public async is_instruction_deletable_flow(inParams: { instructionId: number }): Promise< { reason?: string }> {
    if(!this._is_instruction_deletable_flow) {
      this._is_instruction_deletable_flow = this.injector.get(Instructions_is_instruction_deletable_flowService);
    }
    return this._is_instruction_deletable_flow.run(inParams);
  }
   
   

   
 
  private _update_instruction_flow: Instructions_update_instruction_flowService;
  public async update_instruction_flow(inParams: { instructionId: number, instruction?: string, enabled?: boolean }): Promise< { reasons?: string[] }> {
    if(!this._update_instruction_flow) {
      this._update_instruction_flow = this.injector.get(Instructions_update_instruction_flowService);
    }
    return this._update_instruction_flow.run(inParams);
  }
   
   

   
}
