import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { InventoryTransfers_cancel_inventory_transfers_flowService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_create_inventory_transfer_flowService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_create_inventory_transfer_lines_flowService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_delete_inventory_transfer_flowService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_delete_inventory_transfer_line_flowService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_delete_inventory_transfers_flowService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_execute_inventory_transfer_flowService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_get_inventory_transfer_lines_statesService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_get_inventory_transfers_statesService } from './InventoryTransfers.flow.index';
import { InventoryTransfers_update_inventory_transfer_line_flowService } from './InventoryTransfers.flow.index';

import { $frontendTypes } from './InventoryTransfers.frontend.types'

@Injectable({ providedIn: 'root' })
export class InventoryTransfers_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public InventoryTransfers: InventoryTransfers_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _cancel_inventory_transfers_flow: InventoryTransfers_cancel_inventory_transfers_flowService;
  public async cancel_inventory_transfers_flow(inParams: { inventory_transfer_ids: number[] }): Promise< { results?: { inventory_transfer_id: number, reasons?: string[], is_success: boolean }[] }> {
    if(!this._cancel_inventory_transfers_flow) {
      this._cancel_inventory_transfers_flow = this.injector.get(InventoryTransfers_cancel_inventory_transfers_flowService);
    }
    return this._cancel_inventory_transfers_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_transfer_flow: InventoryTransfers_create_inventory_transfer_flowService;
  public async create_inventory_transfer_flow(inParams: { lookupCode?: string, sourceProjectId: number, targetProjectId: number }): Promise< { reasons?: string[], inventoryTransferId?: number }> {
    if(!this._create_inventory_transfer_flow) {
      this._create_inventory_transfer_flow = this.injector.get(InventoryTransfers_create_inventory_transfer_flowService);
    }
    return this._create_inventory_transfer_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_transfer_lines_flow: InventoryTransfers_create_inventory_transfer_lines_flowService;
  public async create_inventory_transfer_lines_flow(inParams: { inventoryTransferId: number, lines: { materialId?: number, vendorLotId?: number, lotId?: number, packagingId?: number, packagedAmount?: number, licenseplateId?: number, serialnumberId?: number, locationId?: number, warehouseId?: number, allowInactiveInventory?: boolean }[] }): Promise< { reasons?: string[], inventoryTransferLineIds?: number[] }> {
    if(!this._create_inventory_transfer_lines_flow) {
      this._create_inventory_transfer_lines_flow = this.injector.get(InventoryTransfers_create_inventory_transfer_lines_flowService);
    }
    return this._create_inventory_transfer_lines_flow.run(inParams);
  }
   
   

   
 
  private _delete_inventory_transfer_flow: InventoryTransfers_delete_inventory_transfer_flowService;
  public async delete_inventory_transfer_flow(inParams: { inventoryTransferId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_inventory_transfer_flow) {
      this._delete_inventory_transfer_flow = this.injector.get(InventoryTransfers_delete_inventory_transfer_flowService);
    }
    return this._delete_inventory_transfer_flow.run(inParams);
  }
   
   

   
 
  private _delete_inventory_transfer_line_flow: InventoryTransfers_delete_inventory_transfer_line_flowService;
  public async delete_inventory_transfer_line_flow(inParams: { inventoryTransferLineId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_inventory_transfer_line_flow) {
      this._delete_inventory_transfer_line_flow = this.injector.get(InventoryTransfers_delete_inventory_transfer_line_flowService);
    }
    return this._delete_inventory_transfer_line_flow.run(inParams);
  }
   
   

   
 
  private _delete_inventory_transfers_flow: InventoryTransfers_delete_inventory_transfers_flowService;
  public async delete_inventory_transfers_flow(inParams: { inventory_transfer_ids: number[] }): Promise< { results?: { inventory_transfer_id: number, reasons?: string[], is_success: boolean }[] }> {
    if(!this._delete_inventory_transfers_flow) {
      this._delete_inventory_transfers_flow = this.injector.get(InventoryTransfers_delete_inventory_transfers_flowService);
    }
    return this._delete_inventory_transfers_flow.run(inParams);
  }
   
   

   
 
  private _execute_inventory_transfer_flow: InventoryTransfers_execute_inventory_transfer_flowService;
  public async execute_inventory_transfer_flow(inParams: { inventoryTransferId: number }): Promise< { reasons?: string[] }> {
    if(!this._execute_inventory_transfer_flow) {
      this._execute_inventory_transfer_flow = this.injector.get(InventoryTransfers_execute_inventory_transfer_flowService);
    }
    return this._execute_inventory_transfer_flow.run(inParams);
  }
   
   

   
 
  private _get_inventory_transfer_lines_states: InventoryTransfers_get_inventory_transfer_lines_statesService;
  public async get_inventory_transfer_lines_states(inParams: { inventoryTransferLineIds: number[] }): Promise< { lines?: { status: { id: number, name: string, state: string, eventError?: string }, failActions: { updateReasons: string[], deleteReasons: string[], cancelReasons: string[] }, line: { id: number, number: number, inventoryTransferId: number } }[], reasons?: string[] }> {
    if(!this._get_inventory_transfer_lines_states) {
      this._get_inventory_transfer_lines_states = this.injector.get(InventoryTransfers_get_inventory_transfer_lines_statesService);
    }
    return this._get_inventory_transfer_lines_states.run(inParams);
  }
   
   

   
 
  private _get_inventory_transfers_states: InventoryTransfers_get_inventory_transfers_statesService;
  public async get_inventory_transfers_states(inParams: { inventoryTransferIds: number[] }): Promise< { reasons?: string[], inventoryTransfers?: { id: number, status: { id: number, name: string, state: string }, failActions: { updateReasons: string[], deleteReasons: string[], cancelReasons: string[] } }[] }> {
    if(!this._get_inventory_transfers_states) {
      this._get_inventory_transfers_states = this.injector.get(InventoryTransfers_get_inventory_transfers_statesService);
    }
    return this._get_inventory_transfers_states.run(inParams);
  }
   
   

   
 
  private _update_inventory_transfer_line_flow: InventoryTransfers_update_inventory_transfer_line_flowService;
  public async update_inventory_transfer_line_flow(inParams: { inventoryTransferLineId: number, properties: { AllocationStrategyWorkflowCode?: string, AllowInactiveInventory?: boolean, ErrorMessage?: string, SourceLicensePlateId?: number, SourceLotId?: number, SourceMaterialId?: number, SourcePackageAmount?: number, SourcePackageId?: number, SourceSerialNumberId?: number, SourceVendorLotId?: number, StatusId?: number, TransferredTargetMaterialId?: number, UserSelectionRequired?: boolean, WarehouseId?: number } }): Promise< { reasons?: string[], inventoryTransferLineIds?: number[] }> {
    if(!this._update_inventory_transfer_line_flow) {
      this._update_inventory_transfer_line_flow = this.injector.get(InventoryTransfers_update_inventory_transfer_line_flowService);
    }
    return this._update_inventory_transfer_line_flow.run(inParams);
  }
   
   

   
}
