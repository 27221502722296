import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './SalesOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class SalesOrders_pick_on_existing_license_plateService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { taskId?: number, packagedAmount?: number, packagedPackId?: number, sourceLicensePlateId?: number, targetLicensePlateId?: number, serialNumberIds?: number[] }): Promise<{ ExecutionTimeInMilliseconds?: number, TaskId?: number }> 
  {
    let url = `${environment.backendUrl}api/SalesOrders/functions/pick_on_existing_license_plate`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

