import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './BarTender.frontend.types'

@Injectable({ providedIn: 'root' })
export class BarTender_generate_label_to_sftp_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { options: { labelPath: string, printerName: string, quantity?: number }, variables: { name: string, value: any }[], sftp: { path: string, fileName?: string } }): Promise<{ reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.options)) {
      missingRequiredInParams.push('\'options\'');
    }
    if (isNil(inParams.variables)) {
      missingRequiredInParams.push('\'variables\'');
    }
    if (isNil(inParams.sftp)) {
      missingRequiredInParams.push('\'sftp\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/BarTender/functions/generate_label_to_sftp_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

