import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { Equipment_add_warehouse_to_equipment_formComponent } from './Equipment.add_warehouse_to_equipment_form.component';
import { Equipment_create_equipment_formComponent } from './Equipment.create_equipment_form.component';
import { Equipment_create_equipment_type_formComponent } from './Equipment.create_equipment_type_form.component';
import { Equipment_equipment_library_homeComponent } from './Equipment.equipment_library_home.component';
import { Equipment_equipment_editorComponent } from './Equipment.equipment_editor.component';
import { Equipment_equipment_gridComponent } from './Equipment.equipment_grid.component';
import { Equipment_equipment_warehouses_gridComponent } from './Equipment.equipment_warehouses_grid.component';
import { Equipment_equipment_type_dd_singleComponent } from './Equipment.equipment_type_dd_single.component';
import { Equipment_warehouse_dd_singleComponent } from './Equipment.warehouse_dd_single.component';
import { Equipment_equipment_type_dd_multiComponent } from './Equipment.equipment_type_dd_multi.component';
import { Equipment_warehouse_dd_multiComponent } from './Equipment.warehouse_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Equipment_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Equipment: Equipment_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openadd_warehouse_to_equipment_form(inParams:{ equipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Add warehouse to equipment',
        referenceName: 'Equipment_add_warehouse_to_equipment_form',
        component: Equipment_add_warehouse_to_equipment_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openadd_warehouse_to_equipment_formDialog(
    inParams:{ equipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirmed?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Equipment_add_warehouse_to_equipment_formComponent,
      'Add warehouse to equipment',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencreate_equipment_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Equipment',
        referenceName: 'Equipment_create_equipment_form',
        component: Equipment_create_equipment_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencreate_equipment_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirmed?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Equipment_create_equipment_formComponent,
      'Create Equipment',
      mode,
      dialogSize
    )
  }
  public opencreate_equipment_type_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Create Equipment Type',
        referenceName: 'Equipment_create_equipment_type_form',
        component: Equipment_create_equipment_type_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencreate_equipment_type_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ equipmentTypeId?: number, confirmed?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Equipment_create_equipment_type_formComponent,
      'Create Equipment Type',
      mode,
      dialogSize
    )
  }
  public openequipment_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Equipment', 'equipment_library_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Equipment_equipment_library_home',
        component: Equipment_equipment_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openequipment_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Equipment', 'equipment_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Equipment_equipment_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openequipment_editor(inParams:{ equipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Equipment Editor',
        referenceName: 'Equipment_equipment_editor',
        component: Equipment_equipment_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openequipment_editorDialog(
    inParams:{ equipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirmed?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Equipment_equipment_editorComponent,
      'Equipment Editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openequipment_grid(inParams:{ warehouseId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Equipment',
        referenceName: 'Equipment_equipment_grid',
        component: Equipment_equipment_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openequipment_gridDialog(
    inParams:{ warehouseId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Equipment_equipment_gridComponent,
      'Equipment',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openequipment_warehouses_grid(inParams:{ equipmentId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Warehouses',
        referenceName: 'Equipment_equipment_warehouses_grid',
        component: Equipment_equipment_warehouses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openequipment_warehouses_gridDialog(
    inParams:{ equipmentId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Equipment_equipment_warehouses_gridComponent,
      'Warehouses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Equipment_add_warehouse_to_equipment_form') {
      const title = 'Add warehouse to equipment';
      const component = Equipment_add_warehouse_to_equipment_formComponent;
      const inParams:{ equipmentId: number } = { equipmentId: null };
      if (!isNil(params.get('equipmentId'))) {
        const paramValueString = params.get('equipmentId');
        inParams.equipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_create_equipment_form') {
      const title = 'Create Equipment';
      const component = Equipment_create_equipment_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_create_equipment_type_form') {
      const title = 'Create Equipment Type';
      const component = Equipment_create_equipment_type_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_equipment_library_home') {
      this.logger.log('Equipment', 'equipment_library_home');
      const title = 'Home';
      const component = Equipment_equipment_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_equipment_editor') {
      const title = 'Equipment Editor';
      const component = Equipment_equipment_editorComponent;
      const inParams:{ equipmentId: number } = { equipmentId: null };
      if (!isNil(params.get('equipmentId'))) {
        const paramValueString = params.get('equipmentId');
        inParams.equipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_equipment_grid') {
      const title = 'Equipment';
      const component = Equipment_equipment_gridComponent;
      const inParams:{ warehouseId?: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_equipment_warehouses_grid') {
      const title = 'Warehouses';
      const component = Equipment_equipment_warehouses_gridComponent;
      const inParams:{ equipmentId: number } = { equipmentId: null };
      if (!isNil(params.get('equipmentId'))) {
        const paramValueString = params.get('equipmentId');
        inParams.equipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_equipment_type_dd_single') {
      const title = 'equipment_type_dd';
      const component = Equipment_equipment_type_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_warehouse_dd_single') {
      const title = 'warehouse_dd';
      const component = Equipment_warehouse_dd_singleComponent;
      const inParams:{ warehouseId?: number[] } = { warehouseId: [] };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_equipment_type_dd_multi') {
      const title = 'equipment_type_dd';
      const component = Equipment_equipment_type_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Equipment_warehouse_dd_multi') {
      const title = 'warehouse_dd';
      const component = Equipment_warehouse_dd_multiComponent;
      const inParams:{ warehouseId?: number[] } = { warehouseId: [] };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
