<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="assign_user" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_assign_user_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="unassign" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_unassign_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
          <ng-template toolbarToolDef="refresh" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_refresh($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-task_is_assigned" *ngIf="!filters.task_is_assigned.hidden" 
            class="field-container double {{filters.task_is_assigned.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.task_is_assigned.styles.style"
            [ngClass]="filters.task_is_assigned.styles.classes">
        <div class="label-container"
              title="{{filters.task_is_assigned.label}}{{filters.task_is_assigned.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.task_is_assigned.label}}">{{filters.task_is_assigned.label}}<span *ngIf="filters.task_is_assigned.required"
                  class="required-asterisk">*</span></label>
        </div>
        <LaborManagement-is_assigned_dd_single 
            data-cy="selector"
            [type]="filters.task_is_assigned.control.type"
            formControlName="task_is_assigned"
            (displayTextChange)="filters.task_is_assigned.control.displayText=$event"
            [placeholder]="filters.task_is_assigned.control.placeholder"
            [styles]="filters.task_is_assigned.control.styles"
        >
        </LaborManagement-is_assigned_dd_single>
        <ng-container *ngIf="filters.task_is_assigned.invalid">
          <ng-container *ngFor="let error of filters.task_is_assigned.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-operation_code_ids" *ngIf="!filters.operation_code_ids.hidden" 
            class="field-container standard {{filters.operation_code_ids.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.operation_code_ids.styles.style"
            [ngClass]="filters.operation_code_ids.styles.classes">
        <div class="label-container"
              title="{{filters.operation_code_ids.label}}{{filters.operation_code_ids.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.operation_code_ids.label}}">{{filters.operation_code_ids.label}}<span *ngIf="filters.operation_code_ids.required"
                  class="required-asterisk">*</span></label>
        </div>
        <LaborManagement-assigned_task_operation_codes_dd_multi 
            data-cy="selector-multi"
            [type]="filters.operation_code_ids.control.type"
            formControlName="operation_code_ids"
            (displayTextChange)="filters.operation_code_ids.control.displayText=$event"
            [placeholder]="filters.operation_code_ids.control.placeholder"
            [styles]="filters.operation_code_ids.control.styles"
          [warehouseIds]="$fields_operation_code_ids_selector_inParams_warehouseIds"
          [workClassIds]="$fields_operation_code_ids_selector_inParams_workClassIds"
          [ownerId]="$fields_operation_code_ids_selector_inParams_ownerId"
          [projectId]="$fields_operation_code_ids_selector_inParams_projectId"
          [fromDate]="$fields_operation_code_ids_selector_inParams_fromDate"
          [toDate]="$fields_operation_code_ids_selector_inParams_toDate"
        >
        </LaborManagement-assigned_task_operation_codes_dd_multi>
        <ng-container *ngIf="filters.operation_code_ids.invalid">
          <ng-container *ngFor="let error of filters.operation_code_ids.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
            class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.status.styles.style"
            [ngClass]="filters.status.styles.classes">
        <div class="label-container"
              title="{{filters.status.label}}{{filters.status.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.status.label}}">{{filters.status.label}}<span *ngIf="filters.status.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Inventory-task_statuses_dd_multi 
            data-cy="selector-multi"
            [type]="filters.status.control.type"
            formControlName="status"
            (displayTextChange)="filters.status.control.displayText=$event"
            [placeholder]="filters.status.control.placeholder"
            [styles]="filters.status.control.styles"
        >
        </Inventory-task_statuses_dd_multi>
        <ng-container *ngIf="filters.status.invalid">
          <ng-container *ngFor="let error of filters.status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-assigned_user" *ngIf="!filters.assigned_user.hidden" 
            class="field-container standard {{filters.assigned_user.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.assigned_user.styles.style"
            [ngClass]="filters.assigned_user.styles.classes">
        <div class="label-container"
              title="{{filters.assigned_user.label}}{{filters.assigned_user.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.assigned_user.label}}">{{filters.assigned_user.label}}<span *ngIf="filters.assigned_user.required"
                  class="required-asterisk">*</span></label>
        </div>
        <LaborManagement-assigned_users_dd_multi 
            data-cy="selector-multi"
            [type]="filters.assigned_user.control.type"
            formControlName="assigned_user"
            (displayTextChange)="filters.assigned_user.control.displayText=$event"
            [placeholder]="filters.assigned_user.control.placeholder"
            [styles]="filters.assigned_user.control.styles"
          [statusIds]="$fields_assigned_user_selector_inParams_statusIds"
        >
        </LaborManagement-assigned_users_dd_multi>
        <ng-container *ngIf="filters.assigned_user.invalid">
          <ng-container *ngFor="let error of filters.assigned_user.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="task_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.task_id.displayControl.styles.style"
          [ngClass]="row.cells.task_id.displayControl.styles.classes">{{row.cells.task_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="operation_code_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.operation_code_name.displayControl.styles.style"
          [ngClass]="row.cells.operation_code_name.displayControl.styles.classes">{{row.cells.operation_code_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="priority">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.priority.displayControl.styles.style"
          [ngClass]="row.cells.priority.displayControl.styles.classes">{{row.cells.priority.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status_name.displayControl.styles.style"
          [ngClass]="row.cells.status_name.displayControl.styles.classes">{{row.cells.status_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="assigned_user">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.assigned_user.displayControl.styles.style"
          [ngClass]="row.cells.assigned_user.displayControl.styles.classes">{{row.cells.assigned_user.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="task_created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.task_created_on.displayControl.styles.style"
          [ngClass]="row.cells.task_created_on.displayControl.styles.classes">{{row.cells.task_created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project_code.displayControl.styles.style"
          [ngClass]="row.cells.project_code.displayControl.styles.classes">{{row.cells.project_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.order_code.displayControl.styles.style"
          [ngClass]="row.cells.order_code.displayControl.styles.classes">{{row.cells.order_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="wave">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.wave.displayControl.styles.style"
          [ngClass]="row.cells.wave.displayControl.styles.classes">{{row.cells.wave.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material_code.displayControl.styles.style"
          [ngClass]="row.cells.material_code.displayControl.styles.classes">{{row.cells.material_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot_code.displayControl.styles.style"
          [ngClass]="row.cells.lot_code.displayControl.styles.classes">{{row.cells.lot_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.packaging_name.displayControl.styles.style"
          [ngClass]="row.cells.packaging_name.displayControl.styles.classes">{{row.cells.packaging_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.packaged_amount.displayControl.styles.classes">{{row.cells.packaged_amount.displayControl.text | formatText : row.cells.packaged_amount.displayControl.formatType : row.cells.packaged_amount.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse_name.displayControl.styles.style"
          [ngClass]="row.cells.warehouse_name.displayControl.styles.classes">{{row.cells.warehouse_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_location_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_location_name.displayControl.styles.style"
          [ngClass]="row.cells.source_location_name.displayControl.styles.classes">{{row.cells.source_location_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="target_location_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.target_location_name.displayControl.styles.style"
          [ngClass]="row.cells.target_location_name.displayControl.styles.classes">{{row.cells.target_location_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="target_shipping_container_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.target_shipping_container_code.displayControl.styles.style"
          [ngClass]="row.cells.target_shipping_container_code.displayControl.styles.classes">{{row.cells.target_shipping_container_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
