import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Materials_materials_by_project_excl_material_dd_singleComponent } from './Materials.materials_by_project_excl_material_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'


interface IFootPrintManager_material_components_gridComponentEntity {
Id?: number, ComponentMaterialId?: number, ComponentPackagedAmount?: number, ComponentPackagedId?: number, MaterialId?: number, PackagingId?: number, ComponentMaterial?: { LookupCode?: string }}

interface IFootPrintManager_material_components_gridComponentInParams {
  material_id: number, packaging_id: number, project_id: number, suppress_new_row: boolean}


class FootPrintManager_material_components_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_material_components_gridComponent;
  entity: IFootPrintManager_material_components_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    component_material_id_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    component_packaging_id_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    quantity_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    component_material_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['component_material_id_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    component_packaging_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['component_packaging_id_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['quantity_edit'] as DatexFormControl, null, false, '0.00', '')
      ),
    substitute_materials: new GridCellModel(
      new CellStyles(['grid-table-cell-link','center'], null),
      new LabelModel(null, null)
,
null
      ),
  }

  get $fields_component_material_id_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }
  get $fields_component_material_id_selector_inParams_excluded_material_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.material_id;
    
    return expr;
  }
  get $fields_component_packaging_id_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.component_material_id.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['component_material_id_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
  }

  async $initializeExisting(grid: FootPrintManager_material_components_gridComponent, entity: IFootPrintManager_material_components_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_material_components_gridComponent, entity?: IFootPrintManager_material_components_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      material_id:  $grid.inParams.material_id ,
      packaging_id:  $grid.inParams.packaging_id ,
      full_text_search:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.FootPrintManager.ds_material_components_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.component_material_id.displayControl as TextModel).text = $row.entity.ComponentMaterial?.LookupCode;
    (this.cells.component_material_id.editControl as SelectBoxModel).reset($row.entity.ComponentMaterialId);
    (this.cells.component_packaging_id.displayControl as TextModel).text = $row.entity.ComponentPackagedId?.toString();
    (this.cells.component_packaging_id.editControl as SelectBoxModel).reset($row.entity.ComponentPackagedId);
    (this.cells.quantity.displayControl as TextModel).text = $row.entity.ComponentPackagedAmount?.toString();
    (this.cells.quantity.editControl as NumberBoxModel).reset($row.entity.ComponentPackagedAmount);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'substitute_materials' : {
        this.on_substitute_materials_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_material_components_gridComponentRowModel,
  $grid: FootPrintManager_material_components_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const material_id = $grid.inParams.material_id;
  const packaging_id = $grid.inParams.packaging_id;
  
  
  $row.entity = {
      MaterialId: material_id,
      PackagingId: packaging_id
  };
  
  // Lock down packaging cell until a material is selected
  $row.cells.component_packaging_id.editControl.readOnly = true;
  
  
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_material_components_gridComponentRowModel,
  $grid: FootPrintManager_material_components_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var requiredFields: [control: string, display: string][];
  var missingRequiredFields: string[] = [];
  
  requiredFields = [
      [$row.cells.component_material_id.editControl.value, $grid.headers.component_material_id.name],
      [$row.cells.component_packaging_id.editControl.value, $grid.headers.component_packaging_id.name],
      [$row.cells.quantity.editControl.value, $grid.headers.quantity.name]
  ]
  
  for (let required of requiredFields) {
      if (!$utils.isDefined(required[0])) {
          missingRequiredFields.push(required[1])
      }
  }
  
  if (missingRequiredFields.length === 1) {
      $shell.Materials.openErrorDialog('Save', `Missing Required field: ${missingRequiredFields.join('\r\n').trim().toUpperCase()}`);
      throw new Error(`Missing Required field: ${missingRequiredFields.join('\r\n').trim().toUpperCase()}`); // to prevent displayMode 
  
  } else if (missingRequiredFields.length > 1) {
      $shell.Materials.openErrorDialog('Save', `Missing Required fields: ${missingRequiredFields.join(' & ').trim().toUpperCase()}`);
      throw new Error(`Missing Required fields: ${missingRequiredFields.join(' & ').trim().toUpperCase()}`); // to prevent displayMode 
  }
  
  
  try {
  
      const materialPackaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: $row.cells.component_material_id?.editControl.value,
          packagingId: $row.cells.component_packaging_id?.editControl.value
      })).result;
  
      const base_quantity = materialPackaging[0].BasePackagingQuantity;
  
      // Create substitution
      const payload = {
          "MaterialId": $grid.inParams.material_id,
          "PackagingId": $grid.inParams.packaging_id,
          "ComponentMaterialId": $row.cells.component_material_id.editControl.value,
          "ComponentPackagedId": $row.cells.component_packaging_id.editControl.value,
          "ComponentPackagedAmount": $row.cells.quantity.editControl.value,
          "ComponentAmount": $row.cells.quantity.editControl.value * base_quantity
      };
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'Kits', entity: payload });
  
  
  
  
  
      $grid.refresh();
  
  }
  catch (error) {
      $shell.Materials.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_material_components_gridComponentRowModel,
  $grid: FootPrintManager_material_components_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  let payload: any = {};
  
  
  if ($row.cells.component_material_id?.editControl.isChanged) {
      payload.ComponentMaterialId = $row.cells.component_material_id?.editControl.value;
  }
  if ($row.cells.component_packaging_id?.editControl.isChanged) {
      payload.ComponentPackagedId = $row.cells.component_packaging_id?.editControl.value;
  
      const materialPackaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: $row.cells.component_material_id?.editControl.value,
          packagingId: $row.cells.component_packaging_id?.editControl.value
      })).result;
  
      const base_quantity = materialPackaging[0].BasePackagingQuantity;
  
      payload.ComponentAmount = $row.cells.quantity?.editControl.value * base_quantity;
  
  }
  if ($row.cells.quantity?.editControl.isChanged) {
  
      const materialPackaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: $row.cells.component_material_id?.editControl.value,
          packagingId: $row.cells.component_packaging_id?.editControl.value
      })).result;
  
      const base_quantity = materialPackaging[0].BasePackagingQuantity;
  
      payload.ComponentPackagedAmount = $row.cells.quantity?.editControl.value;
      payload.ComponentAmount = $row.cells.quantity?.editControl.value * base_quantity;
  }
  
  
  try {
  
      await $flows.Utilities.crud_update_flow({
          entitySet: 'Kits',
          entity: payload,
          id: $row.entity.Id
      })
  
  
      await $grid.refresh();
  
  }
  catch (error) {
      await $shell.Materials.showErrorDetails('Save', 'Error on save.', error);
      //$shell.Materials.openErrorDialog('Error on save', error.message);
      throw error; // to prevent displayMode 
  }
  
  
  
  
  
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $row: FootPrintManager_material_components_gridComponentRowModel,
  $grid: FootPrintManager_material_components_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Clear related cells
  $row.cells.component_packaging_id.editControl.value = null;
  
  let material_id = $row.cells.component_material_id.editControl.value;
  
  
  if ($utils.isDefined(material_id)) {
  
      $row.cells.component_packaging_id.editControl.readOnly = false;
  
      // Default the packaging
      const default_packaging = (await $flows.Materials.get_material_default_packaging({ materialId: material_id }));
      var packaging_id = default_packaging.packagingId;
  
      if ($utils.isDefined(packaging_id)) {
  
          $row.cells.component_packaging_id.editControl.value = packaging_id;
          $row.cells.quantity.editControl.focus();
  
      }
  
  }
  }
  on_substitute_materials_clicked(event = null) {
    return this.on_substitute_materials_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_substitute_materials_clickedInternal(
    $row: FootPrintManager_material_components_gridComponentRowModel,
  $grid: FootPrintManager_material_components_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  const component_material_id = $row.entity.ComponentMaterialId;
  const component_packaged_id = $row.entity.ComponentPackagedId;
  
  if ($utils.isAllDefined(component_material_id, component_packaged_id)) {
      await $shell.FootPrintManager.openmaterial_substitutions_editorDialog({
          material_id: component_material_id,
          packaging_id: component_packaged_id,
          project_id: $grid.inParams.project_id
      }, "flyout", EModalSize.Large);
  
      $grid.refresh();
  
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_material_components_gridComponentRowModel,
  $grid: FootPrintManager_material_components_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize data
  const component_material_id = $row.entity.ComponentMaterialId;
  const component_material_packaging_id = $row.entity.ComponentPackagedId;
  
  if ($utils.isAllDefined(component_material_id, component_material_packaging_id)) {
  
      // Set the packaging name in the row as the expand does not exist in the grid datasource
      const packaging = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({
          unitId: component_material_packaging_id
      })).result;
  
      const short_name = packaging[0].ShortName;
      $row.cells.component_packaging_id.displayControl.text = `${short_name}`; 
  
  
      const substitutions = (await $datasources.Materials.ds_get_substitutions_by_material_id_packaging_id.get({
          material_id: component_material_id,
          packaging_id: component_material_packaging_id
      })).result;
  
      // Delete related substitutions
      if ($utils.isDefined(substitutions)) {
  
          let material_string = '';
  
          for (let substitute of substitutions) {
  
              const material = substitute.SubstitutionMaterialPackagingLookup?.Material?.LookupCode;
              if ($utils.isDefined(material)) {
                  material_string = ' ' + material + material_string;
              }
  
  
          }
  
          $row.cells.substitute_materials.displayControl.label = `${material_string}`
  
  
      } else {
  
          $row.cells.substitute_materials.displayControl.label = null;
      }
  
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_materials_by_project_excl_material_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
  ],
  selector: 'FootPrintManager-material_components_grid',
  templateUrl: './FootPrintManager.material_components_grid.component.html'
})
export class FootPrintManager_material_components_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_material_components_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fitedWidth', ['compact','noscroll-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_material_components_gridComponentInParams = { material_id: null, packaging_id: null, project_id: null, suppress_new_row: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     component_material_id: new GridHeaderModel(new HeaderStyles(null, null), 'Component', true, false, null),       component_packaging_id: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', true, false, null),       quantity: new GridHeaderModel(new HeaderStyles(null, null), 'Quantity', true, false, null),       substitute_materials: new GridHeaderModel(new HeaderStyles(['center'], null), 'Substitutions', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_material_components_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }
  @Input('packaging_id') set $inParams_packaging_id(value: any) {
    this.inParams['packaging_id'] = value;
  }
  get $inParams_packaging_id(): any {
    return this.inParams['packaging_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('suppress_new_row') set $inParams_suppress_new_row(value: any) {
    this.inParams['suppress_new_row'] = value;
  }
  get $inParams_suppress_new_row(): any {
    return this.inParams['suppress_new_row'] ;
  }

  topToolbar = {
      add_substitute: new ToolModel(new ButtonModel('add_substitute', new ButtonStyles(null, null), false, 'Add substitute', 'ms-Icon ms-Icon--Add')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Material components';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.material_id)) {
        this.$missingRequiredInParams.push('material_id');
      }
      if(isNil(this.inParams.packaging_id)) {
        this.$missingRequiredInParams.push('packaging_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.suppress_new_row)) {
        this.$missingRequiredInParams.push('suppress_new_row');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      material_id:  $grid.inParams.material_id ,
      packaging_id:  $grid.inParams.packaging_id ,
      full_text_search:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.FootPrintManager.ds_material_components_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_material_components_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_material_components_gridComponentEntity) {
    const row = new FootPrintManager_material_components_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_material_components_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $grid.topToolbar.on_delete.control.readOnly = true;
  $grid.topToolbar.add_substitute.control.readOnly = true;
  
  const selected_row_count = $grid.selectedRows.length;
  
  if ($utils.isDefined(selected_row_count) && selected_row_count > 0) {
  
  
      if (selected_row_count === 1) {
  
          const component_material_id = $grid.selectedRows[0].entity.ComponentMaterialId;
          const component_material_packaging_id = $grid.selectedRows[0].entity.ComponentPackagedId;
          const substitutions = (await $datasources.Materials.ds_get_substitutions_by_material_id_packaging_id.get({
              material_id: component_material_id,
              packaging_id: component_material_packaging_id
          })).result;
  
          if ($utils.isDefined(substitutions)) {
              $grid.topToolbar.add_substitute.control.readOnly = true;
          }
          else {
              $grid.topToolbar.add_substitute.control.readOnly = false;
          }
  
          $grid.topToolbar.on_delete.control.readOnly = false;
      }
      else {
          $grid.topToolbar.on_delete.control.readOnly = false;
          $grid.topToolbar.add_substitute.control.readOnly = true;
      }
  
  }
  
  
  
  
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_material_components_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Initialize data
  
      const selected_rows = $grid.selectedRows;
  
      for (let selected_row of selected_rows) {
  
          const id = selected_row.entity.Id;
          const component_material_id = selected_row.entity.ComponentMaterialId;
          const component_material_packaging_id = selected_row.entity.ComponentPackagedId;
          const substitutions = (await $datasources.Materials.ds_get_substitutions_by_material_id_packaging_id.get({
              material_id: component_material_id,
              packaging_id: component_material_packaging_id})).result;
  
          // Delete related substitutions
          if ($utils.isDefined(substitutions)){
  
              for (let substitute of substitutions){
  
                  let substitute_id = substitute.Id;
                  await $flows.Utilities.crud_delete_flow({ entitySet: 'MaterialSubstitutions', id: substitute_id });
              }
          }
  
  
          // Delete Substitution
          await $flows.Utilities.crud_delete_flow({ entitySet: 'Kits', id: id });
  
      }
  
  
  
      $grid.refresh();
  
  }
  
  catch (error) {
  
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
  
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_material_components_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let supress = $grid.inParams.suppress_new_row ?? false;
  
  if (supress) {
  
      $grid.canAdd = false;
  }
  else {
      $grid.canAdd = true;
  }
  }
  on_add_substitute(event = null) {
    return this.on_add_substituteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_substituteInternal(
    $grid: FootPrintManager_material_components_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const component_material_id = $grid.selectedRows[0]?.entity.ComponentMaterialId;
  const component_packaged_id = $grid.selectedRows[0]?.entity.ComponentPackagedId;
  
  
  if ($utils.isAllDefined(component_material_id, component_packaged_id)) {
      await $shell.FootPrintManager.openmaterial_substitutions_editorDialog({
          material_id: component_material_id,
          packaging_id: component_packaged_id,
          project_id: $grid.inParams.project_id
      }, "flyout", EModalSize.Large);
  
      $grid.refresh();
  
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
