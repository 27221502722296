import {
Component,
OnInit,
OnDestroy,
OnChanges,
Input,
SimpleChanges,
Output,
EventEmitter,
Inject
} from '@angular/core';

import {
FormGroup,
Validators
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import {
TextBoxModel,
NumberBoxModel,
SelectBoxModel,
ESelectBoxType,
DateBoxModel,
CheckBoxModel,
TextModel,
LabelModel,
ButtonModel,
SplitButtonModel,
SeparatorModel,
ImageModel,
DrawModel,
CodeBoxModel,
ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import * as vkbeautify from 'vkbeautify';
import { BaseComponent } from './components/base.component';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EntityImport_ShellService, EModalSize, EToasterType, EToasterPosition } from './EntityImport.shell.service';
import { EntityImport_OperationService } from './EntityImport.operation.service';
import { EntityImport_DatasourceService } from './EntityImport.datasource.index';
import { EntityImport_FlowService } from './EntityImport.flow.index';
import { EntityImport_ReportService } from './EntityImport.report.index';
import { EntityImport_LocalizationService } from './EntityImport.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './EntityImport.frontend.types'
import { $frontendTypes as $types} from './EntityImport.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EntityImport-view_json_payload',
  templateUrl: './EntityImport.view_json_payload.component.html'
})
export class EntityImport_view_json_payloadComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges
{

inParams: { payload?: any, title?: string } = { payload: null, title: null };
//#region Inputs
@Input('payload') set $inParams_payload(v: any) {
  this.inParams.payload = v;
}
get $inParams_payload(): any {
  return this.inParams.payload;
}
@Input('title') set $inParams_title(v: string) {
  this.inParams.title = v;
}
get $inParams_title(): string {
  return this.inParams.title;
}
//#endregion Inputs

//#region Outputs
@Output()
$finish = new EventEmitter();
//#endregion

//#region title
// Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
// The title is often meant to be shown from the parent (shell breadcrumb for example)
// and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
// the parent has already been checked and the child now change something on the parent 
// in dev, CD is run twice
$titleChange = new EventEmitter<string>(true);
private $_title: string;
get title(): string {
  return this.$_title;
}
set title(t: string) {
  this.$_title = t;
  this.$titleChange.emit(this.$_title);
}
//#endregion title
codeMirrorOptions = {
theme: 'base16-light',
mode: 'application/json',
lineNumbers: true,
lineWrapping: true,
foldGutter: true,
gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
autoCloseBrackets: true,
matchBrackets: true,
lint: true
};

mode = 'application/json';

value: string;

//#region Variables
//#endregion
//#region Events

//#endregion

toolbar = {
  beautify: new ToolModel(new ButtonModel('beautify', new ButtonStyles(null, null), false, 'Beautify', 'ms-Icon ms-Icon--Balloons')
)
};

constructor(
private utils: UtilsService,
private settings: SettingsValuesService,
private shell: EntityImport_ShellService,
private datasources: EntityImport_DatasourceService,
private flows: EntityImport_FlowService,
private reports: EntityImport_ReportService,
private localization: EntityImport_LocalizationService,
private operations: EntityImport_OperationService,
private logger: CleanupLoggerService,
) {
  super();
}

ngOnInit(): void {
  this.$init();
}

private $isFirstNgOnChanges = true;
ngOnChanges(changes: SimpleChanges): void {
  if (this.$isFirstNgOnChanges) {
    this.$isFirstNgOnChanges = false;
  } else {
    this.$init();
  }
}

private $unsubscribe$ = new Subject();
ngOnDestroy(): void {
  this.$unsubscribe$.next(null);
  this.$unsubscribe$.complete();
}

initialized = false;

async $init() {
this.title = 'view_json_payload';

const $codeEditor = this;
const $utils = this.utils;

this.value = $codeEditor.inParams.payload;

await this.on_init();

this.initialized = true;
}

onCodeMirrorLoaded(editor: CodemirrorComponent){
  //temporary fix to reset the left margin on the gutters. on load an extra left space is being added to the CodeMirror-gutters div element, which causes it to overlap the code content. this refresh re-calculates the space needed and removes the gap. a better implementation could be possible.
  setTimeout(() =>{editor.codeMirror.refresh() }, 300);
}

close() {
this.$finish.emit();
}

beautify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xml(this.value) : vkbeautify.json(this.value);
}

minify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xmlmin(this.value, [, true]) : vkbeautify.jsonmin(this.value);
}

refresh(
skipParent = false,
skipChildren = false,
childToSkip: string = null) {
}

//#region private flows
on_beautify_clicked(event = null) {
  return this.on_beautify_clickedInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_beautify_clickedInternal(
  $codeEditor: EntityImport_view_json_payloadComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
  this.logger.log('EntityImport', 'view_json_payload.on_beautify_clicked');
//O.Arias - 02/06/2024

$codeEditor.beautify();
}
on_init(event = null) {
  return this.on_initInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_initInternal(
  $codeEditor: EntityImport_view_json_payloadComponent,

  $shell: EntityImport_ShellService,
  $datasources: EntityImport_DatasourceService,
  $flows: EntityImport_FlowService,
  $reports: EntityImport_ReportService,
  $settings: SettingsValuesService,
  $operations: EntityImport_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: EntityImport_LocalizationService,
  $event: any
) {
  this.logger.log('EntityImport', 'view_json_payload.on_init');
//O.Arias - 02/06/2024

$codeEditor.beautify();

let title: string = $codeEditor.inParams.title ?? "";

if (title === "") {
    $codeEditor.title = "Code Editor";
} else {
    $codeEditor.title = title;
};
}
//#endregion private flows
}