<datex-list [items]="items"
            [pageSize]="pageSize"
            [(pageSkip)]="pageSkip"
            [totalCount]="totalCount"
            [loadingStatus]="loadingStatus"
            (loadMore)="$dataLoad()">

  <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="refresh" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_refresh($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
    <div class="query-filter"><input data-cy="fullTextSearch" matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-template listItemDef
               let-item>
    <Discussions-discussion_card
    [entity_name]="item.$content_Discussions_discussion_card_inParams_entity_name"
    [entity_id]="item.$content_Discussions_discussion_card_inParams_entity_id"
    [reference]="item.$content_Discussions_discussion_card_inParams_reference"
    [discussion]="item.$content_Discussions_discussion_card_inParams_discussion"
    [created_by]="item.$content_Discussions_discussion_card_inParams_created_by"
    [created_on]="item.$content_Discussions_discussion_card_inParams_created_on"
    [read_on]="item.$content_Discussions_discussion_card_inParams_read_on"
    [alerted_on]="item.$content_Discussions_discussion_card_inParams_alerted_on"
    [application_id]="item.$content_Discussions_discussion_card_inParams_application_id"
    [id]="item.$content_Discussions_discussion_card_inParams_id"
    (refreshList)="on_refresh($event)"
    ($refreshEvent)="$refreshEvent.emit()"
    >
    </Discussions-discussion_card>
  </ng-template>

</datex-list>