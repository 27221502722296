import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_warehouses_navigation_gridComponent } from './FootPrintManager.warehouses_navigation_grid.component';
import { FootPrintManager_locations_replenishments_gridComponent } from './FootPrintManager.locations_replenishments_grid.component';
import { Equipment_equipment_gridComponent } from './Equipment.equipment_grid.component';
import { FootPrintManager_pallet_inventory_by_class_gridComponent } from './FootPrintManager.pallet_inventory_by_class_grid.component';
import { FootPrintManager_pallet_inventory_by_project_gridComponent } from './FootPrintManager.pallet_inventory_by_project_grid.component';
import { FootPrintManager_pallet_transactions_gridComponent } from './FootPrintManager.pallet_transactions_grid.component';
import { Locations_locations_by_count_widgetComponent } from './Locations.locations_by_count_widget.component';
import { Locations_locations_empty_by_count_widgetComponent } from './Locations.locations_empty_by_count_widget.component';
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Locations_timezones_dd_multiComponent } from './Locations.timezones_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_warehouses_navigation_gridComponent),
    forwardRef(() => FootPrintManager_locations_replenishments_gridComponent),
    forwardRef(() => Equipment_equipment_gridComponent),
    forwardRef(() => FootPrintManager_pallet_inventory_by_class_gridComponent),
    forwardRef(() => FootPrintManager_pallet_inventory_by_project_gridComponent),
    forwardRef(() => FootPrintManager_pallet_transactions_gridComponent),
    forwardRef(() => Locations_locations_by_count_widgetComponent),
    forwardRef(() => Locations_locations_empty_by_count_widgetComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Locations_timezones_dd_multiComponent),
  ],
  selector: 'FootPrintManager-warehouses_hub',
  templateUrl: './FootPrintManager.warehouses_hub.component.html'
})
export class FootPrintManager_warehouses_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_zone: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create_warehouse: new ToolModel(new ButtonModel('create_warehouse', new ButtonStyles(null, null), false, 'Create warehouse', 'ms-Icon ms-Icon--Add')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      import_locations: new ToolModel(new ButtonModel('import_locations', new ButtonStyles(null, null), false, 'Import locations', 'ms-Icon ms-Icon--ExcelDocument')
    )
  };

  actionbar = {
  };

 filters = {
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    time_zone: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_zone'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Time zone', false)
,
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      pallets: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      warehouses: new TabItemModel(
        this.rootTabGroup, 
        'Warehouse', 
        ),
      replenishments: new TabItemModel(
        this.rootTabGroup, 
        'Replenishments', 
        ),
      equipment: new TabItemModel(
        this.rootTabGroup, 
        'Equipment', 
        ),
      pallets: new TabItemModel(
        this.rootTabGroup, 
        'Pallets', 
        true,
        {
          pallet_inventory_by_class_grid: new TabItemModel(
          this.subTabGroups.pallets, 
          'On hand by class', 
          ),
          pallet_inventory_by_project_grid: new TabItemModel(
          this.subTabGroups.pallets, 
          'On hand by project', 
          ),
          pallet_transactions_grid: new TabItemModel(
          this.subTabGroups.pallets, 
          'Transactions', 
          ),
        }
        ),
    };
  
    //#region tabs inParams
    get $tabs_warehouses_warehouses_navigation_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_warehouses_warehouses_navigation_grid_inParams_timezone: string[];
    get $tabs_warehouses_warehouses_navigation_grid_inParams_timezone(): string[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.time_zone.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_warehouses_warehouses_navigation_grid_inParams_timezone, expr)) {
        this.cacheValueFor_$tabs_warehouses_warehouses_navigation_grid_inParams_timezone = expr;
      }
      return this.cacheValueFor_$tabs_warehouses_warehouses_navigation_grid_inParams_timezone;
    }
  
    cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds: number[];
    get $tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $utils.isDefined($hub.filters.warehouse.control.value) ? [$hub.filters.warehouse.control.value] : null;
      
      if(!isEqual(this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_replenishments_locations_replenishments_grid_inParams_warehouseIds;
    }
  
    get $tabs_equipment_equipment_grid_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_warehouses', { read: FootPrintManager_warehouses_navigation_gridComponent }) $tabs_warehouses: FootPrintManager_warehouses_navigation_gridComponent;
      @ViewChild('$tabs_replenishments', { read: FootPrintManager_locations_replenishments_gridComponent }) $tabs_replenishments: FootPrintManager_locations_replenishments_gridComponent;
      @ViewChild('$tabs_equipment', { read: Equipment_equipment_gridComponent }) $tabs_equipment: Equipment_equipment_gridComponent;
        @ViewChild('$tabs_pallets_pallet_inventory_by_class_grid', { read: FootPrintManager_pallet_inventory_by_class_gridComponent }) $tabs_pallets_pallet_inventory_by_class_grid: FootPrintManager_pallet_inventory_by_class_gridComponent;
        @ViewChild('$tabs_pallets_pallet_inventory_by_project_grid', { read: FootPrintManager_pallet_inventory_by_project_gridComponent }) $tabs_pallets_pallet_inventory_by_project_grid: FootPrintManager_pallet_inventory_by_project_gridComponent;
        @ViewChild('$tabs_pallets_pallet_transactions_grid', { read: FootPrintManager_pallet_transactions_gridComponent }) $tabs_pallets_pallet_transactions_grid: FootPrintManager_pallet_transactions_gridComponent;
    //#endregion tabs children
    widgets = {
      locations_by_count_widget: new WidgetModel(),
      locations_empty_by_count_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_locations_by_count_widget_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    get $widgets_locations_empty_by_count_widget_inParams_warehouseId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_locations_by_count_widget', { read:  Locations_locations_by_count_widgetComponent }) $widgets_locations_by_count_widget: Locations_locations_by_count_widgetComponent;
      @ViewChild('$widgets_locations_empty_by_count_widget', { read:  Locations_locations_empty_by_count_widgetComponent }) $widgets_locations_empty_by_count_widget: Locations_locations_empty_by_count_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Warehouses hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Your landing page to view warehouses and all related actions.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.warehouses,
      this.tabs.replenishments,
      this.tabs.equipment,
      this.tabs.pallets,
    ]; 
    this.subTabGroups.pallets.tabs = [
        this.tabs.pallets.tabs.pallet_inventory_by_class_grid,
        this.tabs.pallets.tabs.pallet_inventory_by_project_grid,
        this.tabs.pallets.tabs.pallet_transactions_grid,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Warehouses Hub';
    
    const $hub = this;
    const $utils = this.utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_locations_by_count_widget') {
      if (!isNil(this.$widgets_locations_by_count_widget) && !this.widgets.locations_by_count_widget.hidden) {
        this.$widgets_locations_by_count_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_locations_empty_by_count_widget') {
      if (!isNil(this.$widgets_locations_empty_by_count_widget) && !this.widgets.locations_empty_by_count_widget.hidden) {
        this.$widgets_locations_empty_by_count_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_warehouses') {
      if (!isNil(this.$tabs_warehouses) && !this.tabs.warehouses.hidden) {
        this.$tabs_warehouses.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_replenishments') {
      if (!isNil(this.$tabs_replenishments) && !this.tabs.replenishments.hidden) {
        this.$tabs_replenishments.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_equipment') {
      if (!isNil(this.$tabs_equipment) && !this.tabs.equipment.hidden) {
        this.$tabs_equipment.refresh(true, false, null);
      }
    }
      if (childToSkip !== '$tabs_pallets_pallet_inventory_by_class_grid') {
        if (!isNil(this.$tabs_pallets_pallet_inventory_by_class_grid) && !this.tabs.pallets.tabs.pallet_inventory_by_class_grid.hidden) {
          this.$tabs_pallets_pallet_inventory_by_class_grid.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_pallets_pallet_inventory_by_project_grid') {
        if (!isNil(this.$tabs_pallets_pallet_inventory_by_project_grid) && !this.tabs.pallets.tabs.pallet_inventory_by_project_grid.hidden) {
          this.$tabs_pallets_pallet_inventory_by_project_grid.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_pallets_pallet_transactions_grid') {
        if (!isNil(this.$tabs_pallets_pallet_transactions_grid) && !this.tabs.pallets.tabs.pallet_transactions_grid.hidden) {
          this.$tabs_pallets_pallet_transactions_grid.refresh(true, false, null);
        }
      }   
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_warehouse_clicked(event = null) {
    return this.on_create_warehouse_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_warehouse_clickedInternal(
    $hub: FootPrintManager_warehouses_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
   const dialogResult = (await $shell.FootPrintManager.openwarehouse_creation_formDialog(
       'flyout'));
   const userConfirm = dialogResult.confirm;
   const warehouseId = dialogResult.warehouseId;
   if (userConfirm){
       await $hub.refresh();
       if ($utils.isDefined(warehouseId)){
           $shell.FootPrintManager.opensingle_warehouse_hub({warehouseId:warehouseId});
       }
   }
  }
  on_import_locations_clicked(event = null) {
    return this.on_import_locations_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_import_locations_clickedInternal(
    $hub: FootPrintManager_warehouses_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.ExcelLocationImport.openexcel_location_import_grid();
  }
  //#endregion private flows
}
