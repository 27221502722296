import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Surveys_date_filter_dd_singleComponent } from './Surveys.date_filter_dd_single.component'


interface IFootPrintManager_submitted_surveys_gridComponentEntity {
Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, SurveyDefinition_Name?: string, OperationContextType_Name?: string, ContextAppointment_LookupCode?: string, ContextLicensePlate_LookupCode?: string, ContextLocation_Name?: string, ContextProject_LookupCode?: string, ContextShipment_LookupCode?: string, ContextShippingContainer_LookupCode?: string, ContextTask_Id?: number, ContextTask_OperationCode_Name?: string, Equipment_Name?: string}

interface IFootPrintManager_submitted_surveys_gridComponentInParams {
  entities?: { type?: string, ids?: number[] }[], operationContextTypes?: string[], warehouseIds?: number[]}


class FootPrintManager_submitted_surveys_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_submitted_surveys_gridComponent;
  entity: IFootPrintManager_submitted_surveys_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    survey_definition: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    entity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    operation_context_type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    completed_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    completed_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    equipment: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_submitted_surveys_gridComponent, entity: IFootPrintManager_submitted_surveys_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_submitted_surveys_gridComponent, entity?: IFootPrintManager_submitted_surveys_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      entities:  $grid.inParams.entities as { type: string, ids: number[]}[] ,
      operationContextTypes:  $grid.inParams.operationContextTypes ,
      dates:  $grid.vars.dates as { dateFilter: string, fromDate: string, toDate: string} ,
      warehouseIds:  $grid.inParams.warehouseIds ,
    };
    const data = await this.datasources.Surveys.ds_submitted_surveys_grid_temp.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.survey_definition.displayControl as TextModel).text = `${$row.entity.SurveyDefinition_Name} #${$row.entity.Id}`;
    (this.cells.operation_context_type.displayControl as TextModel).text = $row.entity.OperationContextType_Name;
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.CreatedSysDateTime.toString();
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.CreatedSysUser;
    (this.cells.completed_on.displayControl as TextModel).text = $row.entity.ModifiedSysDateTime?.toString();
    (this.cells.completed_by.displayControl as TextModel).text = $row.entity.ModifiedSysUser;
    (this.cells.equipment.displayControl as TextModel).text = $row.entity.Equipment_Name;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'survey_definition' : {
        this.on_survey_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_submitted_surveys_gridComponentRowModel,
  $grid: FootPrintManager_submitted_surveys_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() === '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
      $row.cells.created_on.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
      $row.cells.completed_on.displayControl.text = $utils.date.format($row.entity.ModifiedSysDateTime, format);
  }
  }
  on_survey_clicked(event = null) {
    return this.on_survey_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_survey_clickedInternal(
    $row: FootPrintManager_submitted_surveys_gridComponentRowModel,
  $grid: FootPrintManager_submitted_surveys_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $shell.FootPrintManager.opensingle_survey_hubDialog({ surveyId: $row.entity.Id }, 'modal');
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Surveys_date_filter_dd_singleComponent),
  ],
  selector: 'FootPrintManager-submitted_surveys_grid',
  templateUrl: './FootPrintManager.submitted_surveys_grid.component.html'
})
export class FootPrintManager_submitted_surveys_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_submitted_surveys_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_submitted_surveys_gridComponentInParams = { entities: [], operationContextTypes: [], warehouseIds: [] };


  //#region Variables
  vars: { dates?: { dateFilter?: string, fromDate?: string, toDate?: string } } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     survey_definition: new GridHeaderModel(new HeaderStyles(null, null), 'Survey', false, false, null),       entity: new GridHeaderModel(new HeaderStyles(null, null), 'Entity', false, false, null),       operation_context_type: new GridHeaderModel(new HeaderStyles(null, null), 'Operation', false, false, null),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       completed_on: new GridHeaderModel(new HeaderStyles(null, null), 'Completed on', false, false, null),       completed_by: new GridHeaderModel(new HeaderStyles(null, null), 'Completed by', false, false, null),       equipment: new GridHeaderModel(new HeaderStyles(null, null), 'Equipment', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_submitted_surveys_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('entities') set $inParams_entities(value: any) {
    this.inParams['entities'] = value;
  }
  get $inParams_entities(): any {
    return this.inParams['entities'] ;
  }
  @Input('operationContextTypes') set $inParams_operationContextTypes(value: any) {
    this.inParams['operationContextTypes'] = value;
  }
  get $inParams_operationContextTypes(): any {
    return this.inParams['operationContextTypes'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    date_filter: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    date_filter: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_filter'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Date filter', false)
,
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'From', false)
,
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'To', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Submitted surveys';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.date_filter.control as SelectBoxModel).reset('modifiedSysDateTime');

    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['date_filter']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });
    this.formGroup
      .controls['from_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });
    this.formGroup
      .controls['to_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      entities:  $grid.inParams.entities as { type: string, ids: number[]}[] ,
      operationContextTypes:  $grid.inParams.operationContextTypes ,
      dates:  $grid.vars.dates as { dateFilter: string, fromDate: string, toDate: string} ,
      warehouseIds:  $grid.inParams.warehouseIds ,
    };
    try {
    const data = await this.datasources.Surveys.ds_submitted_surveys_grid_temp.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_submitted_surveys_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_submitted_surveys_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$grid.rows.find(row => row.entity.CreatedSysDateTime !== row.entity.ModifiedSysDateTime)) {
      $grid.headers.created_by.hidden = true;
      $grid.headers.created_on.hidden = true;
  
      // If no variance between created / completed date, lock down date type filter
      $grid.filters.date_filter.control.readOnly = true;
      $grid.filters.date_filter.control.value = 'modifiedSysDateTime';
  }
  else {
      $grid.headers.created_by.hidden = false;
      $grid.headers.created_on.hidden = false;
  }
  
  // Set the entity name
  let result = (await $flows.Surveys.get_survey_context_details_flow({ surveyIds: $grid.rows.map(row => row.entity.Id)}));
  
  $grid.rows.forEach(row => {
      let entity = result.entities.find(entity => entity.surveyId === row.entity.Id);
      row.cells.entity.displayControl.text = `${entity.type} ${entity.name}`;
  });
  
  // Format the username
  $grid.rows.forEach(async row => {
      row.cells.completed_by.displayControl.text = (await $flows.Utilities.format_username_flow({inUserName: row.entity.ModifiedSysUser})).outUserName ?? row.entity.ModifiedSysUser;
  });
  
  $grid.rows.forEach(async row => {
      row.cells.created_by.displayControl.text = (await $flows.Utilities.format_username_flow({inUserName: row.entity.CreatedSysUser})).outUserName ?? row.entity.CreatedSysUser;
  })
  
  $grid.on_filter_changed();
  }
  on_filter_changed(event = null) {
    return this.on_filter_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_filter_changedInternal(
    $grid: FootPrintManager_submitted_surveys_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($grid.filters.date_filter.control.value) && ($utils.isDefined($grid.filters.from_date.control.value) || $utils.isDefined($grid.filters.to_date.control.value))) {
      $grid.vars.dates = {
          dateFilter: $grid.filters.date_filter.control.value,
          fromDate: $grid.filters.from_date.control.value,
          toDate: $grid.filters.to_date.control.value
      };
  }
  else {
      $grid.vars.dates = null;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
