import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Attachments_add_attachment_to_entity_flowService } from './Attachments.flow.index';
import { Attachments_delete_attachment_flowService } from './Attachments.flow.index';
import { Attachments_get_file_content_flowService } from './Attachments.flow.index';

import { $frontendTypes } from './Attachments.frontend.types'

@Injectable({ providedIn: 'root' })
export class Attachments_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Attachments: Attachments_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _add_attachment_to_entity_flow: Attachments_add_attachment_to_entity_flowService;
  public async add_attachment_to_entity_flow(inParams: { entityKeys: { name?: string, value?: any }[], attachmentDetails?: { description?: string, fileName?: string }, fileContent: Blob, entityType: string }): Promise< { reasons?: string[] }> {
    if(!this._add_attachment_to_entity_flow) {
      this._add_attachment_to_entity_flow = this.injector.get(Attachments_add_attachment_to_entity_flowService);
    }
    return this._add_attachment_to_entity_flow.run(inParams);
  }
   
   

   
 
  private _delete_attachment_flow: Attachments_delete_attachment_flowService;
  public async delete_attachment_flow(inParams: { entityType: string, attachmentId: number }): Promise<void> {
    if(!this._delete_attachment_flow) {
      this._delete_attachment_flow = this.injector.get(Attachments_delete_attachment_flowService);
    }
    return this._delete_attachment_flow.run(inParams);
  }
   
   

   
 
  private _get_file_content_flow: Attachments_get_file_content_flowService;
  public async get_file_content_flow(inParams: { entityType: string, attachmentId: number }): Promise< { fileContent?: Blob }> {
    if(!this._get_file_content_flow) {
      this._get_file_content_flow = this.injector.get(Attachments_get_file_content_flowService);
    }
    return this._get_file_content_flow.run(inParams);
  }
   
   

   
}
