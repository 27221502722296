<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="manage" let-tool>
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.child1.readOnly"
                              [ngStyle]="tool.control.buttons.child1.styles.style"
                              [ngClass]="tool.control.buttons.child1.styles.classes"
                              (click)="on_logs_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.child1.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.child1.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.child1.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.child2.readOnly"
                              [ngStyle]="tool.control.buttons.child2.styles.style"
                              [ngClass]="tool.control.buttons.child2.styles.classes"
                              (click)="on_message_click($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.child2.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.child2.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.child2.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.child3.readOnly"
                              [ngStyle]="tool.control.buttons.child3.styles.style"
                              [ngClass]="tool.control.buttons.child3.styles.classes"
                              (click)="on_config_click($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.child3.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.child3.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.child3.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.notiffication.readOnly"
                              [ngStyle]="tool.control.buttons.notiffication.styles.style"
                              [ngClass]="tool.control.buttons.notiffication.styles.classes"
                              (click)="($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.notiffication.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.notiffication.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.notiffication.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div mat-menu-item class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.initial_setup.readOnly"
                              [ngStyle]="tool.control.buttons.initial_setup.styles.style"
                              [ngClass]="tool.control.buttons.initial_setup.styles.classes"
                              (click)="on_initial_click($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.initial_setup.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.initial_setup.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.initial_setup.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.invoice_widget1.hidden" class="widget-container">
            <Dynamics365BusinessCentral-count_by_groups_widget
            #$widgets_invoice_widget1 
              [customerr]="$widgets_invoice_widget1_inParams_customerr"
              [application_name]="$widgets_invoice_widget1_inParams_application_name"
              [refresh]="$widgets_invoice_widget1_inParams_refresh"
            ($refreshEvent)="refresh(false, false, '$widgets_invoice_widget1')"
            >
            </Dynamics365BusinessCentral-count_by_groups_widget>
          </div>
          <div *ngIf="!widgets.Errors.hidden" class="widget-container">
            <Dynamics365BusinessCentral-number_of_errors_widget
            #$widgets_Errors 
              [application_name]="$widgets_Errors_inParams_application_name"
              [customerr]="$widgets_Errors_inParams_customerr"
              [refresh_control]="$widgets_Errors_inParams_refresh_control"
            ($refreshEvent)="refresh(false, false, '$widgets_Errors')"
            >
            </Dynamics365BusinessCentral-number_of_errors_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.pending.hidden" class="tab" data-cy="tab-pending">
              <h2 [className]="tabs.pending.active? 'active': ''" (click)="tabs.pending.activate()">{{tabs.pending.title}}</h2>
            </div>
            <div *ngIf="!tabs.tab2.hidden" class="tab" data-cy="tab-tab2">
              <h2 [className]="tabs.tab2.active? 'active': ''" (click)="tabs.tab2.activate()">{{tabs.tab2.title}}</h2>
            </div>
            <div *ngIf="!tabs.tab3.hidden" class="tab" data-cy="tab-tab3">
              <h2 [className]="tabs.tab3.active? 'active': ''" (click)="tabs.tab3.activate()">{{tabs.tab3.title}}</h2>
            </div>
          </div>
        
              <Dynamics365BusinessCentral-invoices_grid *ngIf="tabs.pending.active"
              #$tabs_pending
              [statusIds]="$tabs_pending_invoices_grid_inParams_statusIds"
              (hub_refresh)="hub_refresh($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_pending')">
              </Dynamics365BusinessCentral-invoices_grid>
              <Dynamics365BusinessCentral-messages_success_grid *ngIf="tabs.tab2.active"
              #$tabs_tab2
              [application_name]="$tabs_tab2_messages_success_grid_inParams_application_name"
              [customerr]="$tabs_tab2_messages_success_grid_inParams_customerr"
              ($refreshEvent)="refresh(false, false, '$tabs_tab2')">
              </Dynamics365BusinessCentral-messages_success_grid>
              <Dynamics365BusinessCentral-messages_fail_grid *ngIf="tabs.tab3.active"
              #$tabs_tab3
              [application_name]="$tabs_tab3_messages_fail_grid_inParams_application_name"
              [customerr]="$tabs_tab3_messages_fail_grid_inParams_customerr"
              (hub_refresh)="hub_refresh($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_tab3')">
              </Dynamics365BusinessCentral-messages_fail_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>