import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService, EModalSize, EToasterType, EToasterPosition } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Notifications.frontend.types'
import { $frontendTypes as $types} from './Notifications.frontend.types' 



interface INotifications_report_inputs_gridComponentEntity {
name?: string, value?: string}

interface INotifications_report_inputs_gridComponentInParams {
  reportName: string, inputs?: { name: string, value: string }[]}

interface INotifications_report_inputs_gridComponentOutParams {
  inputs?: { name: string, value: string }[]}

class Notifications_report_inputs_gridComponentRowModel extends GridRowModel {
  grid: Notifications_report_inputs_gridComponent;
  entity: INotifications_report_inputs_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    value_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    value: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['value_edit'] as DatexFormControl, null, false, '')
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Notifications_report_inputs_gridComponent, entity: INotifications_report_inputs_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.name].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Notifications_report_inputs_gridComponent, entity?: INotifications_report_inputs_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.name].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.name;
    const inParams = {
      $keys:[$resultKey],
      reportName:  $grid.inParams.reportName ,
      inputs:  $grid.inParams.inputs ,
    };
    const data = await this.datasources.Notifications.ds_report_inputs_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.name.displayControl as TextModel).text = $row.entity.name;
    (this.cells.value.displayControl as TextModel).text = $row.entity.value;
    (this.cells.value.editControl as TextBoxModel).reset($row.entity.value);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Notifications_report_inputs_gridComponentRowModel,
  $grid: Notifications_report_inputs_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'report_inputs_grid.on_save_existing_row');
  if (!$utils.isDefined($grid.inParams.inputs)) {
      $grid.inParams.inputs = [];
  }
  
  let existingInput = $grid.inParams.inputs.find(i => i.name === $row.entity.name);
  
  if ($utils.isDefined(existingInput)) {
      existingInput.value = $row.cells.value.editControl.value;
  } else {
      $grid.inParams.inputs.push({ name: $row.entity.name, value: $row.cells.value.editControl.value});
  }
  
  $grid.refresh();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Notifications-report_inputs_grid',
  templateUrl: './Notifications.report_inputs_grid.component.html'
})
export class Notifications_report_inputs_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: INotifications_report_inputs_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['relaxed','fit-content-table']);


  inParams: INotifications_report_inputs_gridComponentInParams = { reportName: null, inputs: [] };

  outParams: INotifications_report_inputs_gridComponentOutParams = { inputs: [] };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ inputs?: { name: string, value: string }[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, null),       value: new GridHeaderModel(new HeaderStyles(null, null), 'Value', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Notifications_report_inputs_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('reportName') set $inParams_reportName(value: any) {
    this.inParams['reportName'] = value;
  }
  get $inParams_reportName(): any {
    return this.inParams['reportName'] ;
  }
  @Input('inputs') set $inParams_inputs(value: any) {
    this.inParams['inputs'] = value;
  }
  get $inParams_inputs(): any {
    return this.inParams['inputs'] ;
  }

  topToolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', 'ms-Icon ms-Icon--Completed12')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Notifications_ShellService,
    private datasources: Notifications_DatasourceService,
    private flows: Notifications_FlowService,
    private reports: Notifications_ReportService,
    private localization: Notifications_LocalizationService,
    private operations: Notifications_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Inputs';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.reportName)) {
        this.$missingRequiredInParams.push('reportName');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      reportName:  $grid.inParams.reportName ,
      inputs:  $grid.inParams.inputs ,
    };
    try {
    const data = await this.datasources.Notifications.ds_report_inputs_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Notifications_report_inputs_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $grid: Notifications_report_inputs_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'report_inputs_grid.on_confirm_clicked');
  for (let row of $grid.rows) { 
      if (row.cells.value.isEdit) { await row.confirm(); } 
  }
  
  $grid.outParams.inputs = $grid.rows.map(r => ({
      name: r.entity.name,
      value: r.cells.value.editControl.value
  }));
  
  $grid.close();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: Notifications_report_inputs_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'report_inputs_grid.on_cancel_clicked');
  $grid.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Notifications_report_inputs_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
    this.logger.log('Notifications', 'report_inputs_grid.on_init');
  $grid.outParams.inputs = $grid.inParams.inputs;
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
