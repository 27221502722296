import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Notifications.frontend.types'

@Injectable({ providedIn: 'root' })
export class Notifications_storage_alerts_filtersService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { action: string, payload?: { id?: string, alert_id?: string, filter_name?: string, filter_value?: string }, skip?: number, take?: number, search?: string, application_name?: string, alert_ids?: string[] }): Promise<{ payload?: { id?: string, application_name?: string, alert_id?: string, filter_name?: string, filter_value?: string }[], success?: boolean, error?: any[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.action)) {
      missingRequiredInParams.push('\'action\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Notifications/functions/storage_alerts_filters`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

