import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { LoadContainers_add_order_flowService } from './LoadContainers.flow.index';
import { LoadContainers_create_loadcontainer_flowService } from './LoadContainers.flow.index';
import { LoadContainers_remove_order_flowService } from './LoadContainers.flow.index';
import { LoadContainers_update_loadcontainer_flowService } from './LoadContainers.flow.index';
import { LoadContainers_validate_load_containers_flowService } from './LoadContainers.flow.index';

import { $frontendTypes } from './LoadContainers.frontend.types'

@Injectable({ providedIn: 'root' })
export class LoadContainers_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public LoadContainers: LoadContainers_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _add_order_flow: LoadContainers_add_order_flowService;
  public async add_order_flow(inParams: { orderId?: number, shipmentId?: number, loadContainerId?: number }): Promise< { result?: string }> {
    if(!this._add_order_flow) {
      this._add_order_flow = this.injector.get(LoadContainers_add_order_flowService);
    }
    return this._add_order_flow.run(inParams);
  }
   
   

   
 
  private _create_loadcontainer_flow: LoadContainers_create_loadcontainer_flowService;
  public async create_loadcontainer_flow(inParams: { lookupcode?: string, orderTypeId: number, yardLocationId?: number, priority?: number, loadContainerContainerTypeId?: number, loadContainerId?: number }): Promise< { reasons?: string[], loadcontainerId?: number }> {
    if(!this._create_loadcontainer_flow) {
      this._create_loadcontainer_flow = this.injector.get(LoadContainers_create_loadcontainer_flowService);
    }
    return this._create_loadcontainer_flow.run(inParams);
  }
   
   

   
 
  private _remove_order_flow: LoadContainers_remove_order_flowService;
  public async remove_order_flow(inParams: { orderId?: number, shipmentId?: number, loadContainerId?: number }): Promise< { result?: string }> {
    if(!this._remove_order_flow) {
      this._remove_order_flow = this.injector.get(LoadContainers_remove_order_flowService);
    }
    return this._remove_order_flow.run(inParams);
  }
   
   

   
 
  private _update_loadcontainer_flow: LoadContainers_update_loadcontainer_flowService;
  public async update_loadcontainer_flow(inParams: { loadcontainerId: number, statusId?: number, yardLocationId?: number, notes?: string, lastOnsiteDate?: string }): Promise< { reasons?: string[] }> {
    if(!this._update_loadcontainer_flow) {
      this._update_loadcontainer_flow = this.injector.get(LoadContainers_update_loadcontainer_flowService);
    }
    return this._update_loadcontainer_flow.run(inParams);
  }
   
   

   
 
  private _validate_load_containers_flow: LoadContainers_validate_load_containers_flowService;
  public async validate_load_containers_flow(inParams: { load_container_id: number }): Promise< { reasons?: string[], validate?: boolean }> {
    if(!this._validate_load_containers_flow) {
      this._validate_load_containers_flow = this.injector.get(LoadContainers_validate_load_containers_flowService);
    }
    return this._validate_load_containers_flow.run(inParams);
  }
   
   

   
}
