import { Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Language, LocalizationService, LocalizationSettings } from './localization.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Document360_LocalizationService } from './Document360.localization.service';
import { Usersnap_LocalizationService } from './Usersnap.localization.service';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { PrintNode_LocalizationService } from './PrintNode.localization.service';
import { Materials_LocalizationService } from './Materials.localization.service';
import { PalletTransactions_LocalizationService } from './PalletTransactions.localization.service';
import { PurchaseOrders_LocalizationService } from './PurchaseOrders.localization.service';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Lots_LocalizationService } from './Lots.localization.service';
import { Ultraship_LocalizationService } from './Ultraship.localization.service';
import { Reports_LocalizationService } from './Reports.localization.service';
import { SalesOrders_LocalizationService } from './SalesOrders.localization.service';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';

@Injectable({
  providedIn: 'root'
})
export class app_LocalizationService
  extends LocalizationService<{  }> {

  public app: app_LocalizationService = this;

  public override get settings(): LocalizationSettings {
    return {"default":"","languages":[]}
  }
  
  private _referencedServices: LocalizationService<any>[] = [];

  constructor(
    httpClient: HttpClient,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingHandler: MissingTranslationHandler,
    public Document360: Document360_LocalizationService,
    public Usersnap: Usersnap_LocalizationService,
    public Utilities: Utilities_LocalizationService,
    public PrintNode: PrintNode_LocalizationService,
    public Materials: Materials_LocalizationService,
    public PalletTransactions: PalletTransactions_LocalizationService,
    public PurchaseOrders: PurchaseOrders_LocalizationService,
    public Notifications: Notifications_LocalizationService,
    public Lots: Lots_LocalizationService,
    public Ultraship: Ultraship_LocalizationService,
    public Reports: Reports_LocalizationService,
    public SalesOrders: SalesOrders_LocalizationService,
    public Invoices: Invoices_LocalizationService,
    public FootPrintManager: FootPrintManager_LocalizationService,
    public FootPrintApiManager: FootPrintApiManager_LocalizationService,
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_LocalizationService
    ) {
    super(
      new TranslateService(
        new TranslateStore(),
        new TranslateHttpLoader(httpClient, '../assets/i18n/app/', '.json'),
        compiler,
        parser,
        missingHandler,
        true,
        true,
        false,
        ''));

    this._referencedServices.push(this.Document360);

    this._referencedServices.push(this.Usersnap);

    this._referencedServices.push(this.Utilities);

    this._referencedServices.push(this.PrintNode);

    this._referencedServices.push(this.Materials);

    this._referencedServices.push(this.PalletTransactions);

    this._referencedServices.push(this.PurchaseOrders);

    this._referencedServices.push(this.Notifications);

    this._referencedServices.push(this.Lots);

    this._referencedServices.push(this.Ultraship);

    this._referencedServices.push(this.Reports);

    this._referencedServices.push(this.SalesOrders);

    this._referencedServices.push(this.Invoices);

    this._referencedServices.push(this.FootPrintManager);

    this._referencedServices.push(this.FootPrintApiManager);

    this._referencedServices.push(this.Dynamics365BusinessCentral);
    
    this.initialize();

  }

  public override setLanguage(lang: Language): void{
    super.setLanguage(lang);
  
    for (let i of this._referencedServices){
      i.setLanguage(lang);
    }
  }
}