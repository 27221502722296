import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Ultraship_ds_get_load_container_details_by_loadContainerIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { loadContainerId: number, orderId?: number, shipmentId?: number }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, InYardDate?: string, LastPierDate?: string, StatusId?: number, OrderType?: { Name?: string }, Status?: { Name?: string }, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, Shipments?: { Id?: number, AvailableDate?: string, DockDate?: string, LookupCode?: string, Priority?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { AccountId?: number, BillingAddresId?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string } } }[], ShipmentLines?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedId?: number, GrossVolume?: number, GrossWeight?: number, LineNumber?: number, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, ActualPackaged?: { Name?: string, ShortName?: string, Weight?: number, WeightUomId?: number, WeightUom?: { Name?: string, Short_name?: string } }, WeightUOM?: { Name?: string, Short_name?: string }, VolumeUOM?: { Name?: string, Short_name?: string } }[] }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerId)) {
      missingRequiredInParams.push('\'loadContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Ultraship/datasources/ds_get_load_container_details_by_loadContainerId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { loadContainerId: number, orderId?: number, shipmentId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, InYardDate?: string, LastPierDate?: string, StatusId?: number, OrderType?: { Name?: string }, Status?: { Name?: string }, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, Shipments?: { Id?: number, AvailableDate?: string, DockDate?: string, LookupCode?: string, Priority?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { AccountId?: number, BillingAddresId?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string } } }[], ShipmentLines?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedId?: number, GrossVolume?: number, GrossWeight?: number, LineNumber?: number, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, ActualPackaged?: { Name?: string, ShortName?: string, Weight?: number, WeightUomId?: number, WeightUom?: { Name?: string, Short_name?: string } }, WeightUOM?: { Name?: string, Short_name?: string }, VolumeUOM?: { Name?: string, Short_name?: string } }[] }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerId)) {
      missingRequiredInParams.push('\'loadContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Ultraship/datasources/ds_get_load_container_details_by_loadContainerId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { loadContainerId: number, orderId?: number, shipmentId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AvailableDate?: string, CallOutDate?: string, CheckOutDate?: string, ContainerSize?: string, InYardDate?: string, LastPierDate?: string, StatusId?: number, OrderType?: { Name?: string }, Status?: { Name?: string }, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Name?: string, ShortName?: string }, Shipments?: { Id?: number, AvailableDate?: string, DockDate?: string, LookupCode?: string, Priority?: number, OrderLookups?: { OrderId?: number, ShipmentId?: number, Order?: { AccountId?: number, BillingAddresId?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string } } }[], ShipmentLines?: { Id?: number, ActualPackagedAmount?: number, ActualPackagedId?: number, GrossVolume?: number, GrossWeight?: number, LineNumber?: number, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, ActualPackaged?: { Name?: string, ShortName?: string, Weight?: number, WeightUomId?: number, WeightUom?: { Name?: string, Short_name?: string } }, WeightUOM?: { Name?: string, Short_name?: string }, VolumeUOM?: { Name?: string, Short_name?: string } }[] }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerId)) {
      missingRequiredInParams.push('\'loadContainerId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Ultraship/datasources/ds_get_load_container_details_by_loadContainerId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
