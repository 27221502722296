import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './DockAppointments.frontend.types'

@Injectable({ providedIn: 'root' })
export class DockAppointments_check_in_dock_appointment_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { driverName: string, driverLicense: string, vehicleLicense: string, scheduledDoorId: number, user: string, dockAppointmentId: number }): Promise<{ reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.driverName)) {
      missingRequiredInParams.push('\'driverName\'');
    }
    if (isNil(inParams.driverLicense)) {
      missingRequiredInParams.push('\'driverLicense\'');
    }
    if (isNil(inParams.vehicleLicense)) {
      missingRequiredInParams.push('\'vehicleLicense\'');
    }
    if (isNil(inParams.scheduledDoorId)) {
      missingRequiredInParams.push('\'scheduledDoorId\'');
    }
    if (isNil(inParams.user)) {
      missingRequiredInParams.push('\'user\'');
    }
    if (isNil(inParams.dockAppointmentId)) {
      missingRequiredInParams.push('\'dockAppointmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/functions/check_in_dock_appointment_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

