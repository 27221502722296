import { Utilities_Types } from './Utilities.frontend.types'

export namespace Ultraship_Types {
  /**
   * Defines the fields in configuration
   */
  export enum Configuration {
    ultraship_projects = 'ultraship_projects',
    ultraship_http_endpoint = 'ultraship_http_endpoint',
    ultraship_http_port = 'ultraship_http_port',
    ultraship_http_username = 'ultraship_http_username',
    ultraship_http_password = 'ultraship_http_password',
    ultraship_sftp_endpoint = 'ultraship_sftp_endpoint',
    ultraship_sftp_port = 'ultraship_sftp_port',
    ultraship_sftp_username = 'ultraship_sftp_username',
    ultraship_sftp_password = 'ultraship_sftp_password',
    ultraship_sftp_path_working = 'ultraship_sftp_path_working',
    ultraship_sftp_path_archive = 'ultraship_sftp_path_archive',
    ultraship_sftp_path_error = 'ultraship_sftp_path_error',
    ultraship_sftp_file_extension = 'ultraship_sftp_file_extension'
  }
  /**
   * Defines group type configuration
   */
  export enum ConfigurationType {
    Projects = 'Projects',
    SFTP = 'SFTP',
    HTTP = 'HTTP'
  }
  /**
   * 
   */
  export enum DocumentType {
    Inbound_Order = 'Inbound_Order',
    Inbound_Shipment = 'Inbound_Shipment',
    Outbound_Order = 'Outbound_Order',
    Outbound_Shipment = 'Outbound_Shipment',
    RawFile = 'RawFile',
    Response = 'Response',
    Outbound_Order_Update = 'Outbound_Order_Update'
  }
  /**
   * Defines the fields from the entity tracker object.
   */
  export interface Entity_update_tracker {
    entity_update_tracker_id?: string;
    entity?: string;
    entity_id?: number;
    modified_on?: string;
    application_name?: string;
    status_name?: string;
    status_details?: string;
  }
  /**
   * Generic Request Response Schema that handles both Order and Shipment
   */
  export interface GenericRequestResponse {
    Status?: string;
    TransmissionIdentifier?: number;
    Errors?: { Error?: $frontendTypes.Ultraship.GenericRequestResponse_Error[] };
  }
  /**
   * Generic Request Response Schema that handles both Order and Shipment
   */
  export interface GenericRequestResponse_Error {
    TransactionNumber?: string;
    OrderNumber?: string;
    ShipmentNumber?: string;
    ErrorMessage?: string;
  }
  /**
   * 
   */
  export interface HttpRequestResponse {
    Status?: string;
    TransmissionIdentifier?: string;
    Errors?: { Error?: { ErrorMessage?: string }[] };
  }
  /**
   * 
   */
  export enum HttpRequestSyncContext {
    Inbound_Order = 'Inbound_Order',
    Inbound_Shipment = 'Inbound_Shipment',
    Outbound_Order = 'Outbound_Order',
    Outbound_Shipment = 'Outbound_Shipment'
  }
  /**
   * Integration statuses for Ultraship
   */
  export enum IntegrationStatus {
    Order_created = 1,
    Order_sent_to_ultraship = 2,
    Order_saved_to_ultraship = 3,
    Load_created = 4,
    Load_exported_to_footprint = 5,
    Load_saved_to_footprint = 6,
    Order_in_exception_status = 7,
    Load_in_exception_status = 8,
    Load_exported_to_ultraship = 9,
    Load_saved_to_ultraship = 10
  }
  /**
   * Order V4 Schema: Order type
   */
  export interface Order {
    FullOrder?: { OrderNumber?: string, ERPCreatedDateTime?: string, ShipmentNumber?: string, PaymentMethod?: string, ShipmentType?: string, PONumber?: string, BOLNumber?: string, SalesRepIdentifier?: string, FrontHaulRevenue?: string, SalesValue?: string, OrderValue?: string, ERPStatus?: string, ParentPlant?: string, CompanyNumber?: string, Division?: string, SalesOrder?: string, RequestedMode?: string, RequestedServiceType?: string, ReleaseNumber?: string, TransferNumber?: string, Comments?: string, Route?: { Name?: string, TotalCount?: string, Sequence?: string }, CustomFields?: { Field?: $frontendTypes.Ultraship.Order_Field[] }, Pickup?: { Location?: $frontendTypes.Ultraship.Order_Location, ScheduledArrival?: { Date?: string, Time?: string }, ScheduledDeparture?: { Date?: string, Time?: string } }, Dropoff?: { Location?: $frontendTypes.Ultraship.Order_Location, ScheduledArrival?: { Date?: string, Time?: string }, ScheduledDeparture?: { Date?: string, Time?: string } }, BillTo?: { Location?: $frontendTypes.Ultraship.Order_Location }, Items?: { Item?: $frontendTypes.Ultraship.Order_Item[] } };
    CancelOrder?: { OrderNumber?: string };
  }
  /**
   * Order Request Response Schema
   */
  export interface OrderRequestResponse {
    Status?: string;
    TransmissionIdentifier?: number;
    Errors?: { Error?: { TransactionNumber?: number, OrderNumber?: string, ErrorMessage?: string }[] };
  }
  /**
   * Order V4 Schema
   */
  export interface OrderWS {
    Order?: $frontendTypes.Ultraship.Order[];
  }
  /**
   * Order V5 Schema: Contact type
   */
  export interface Order_Contact {
    ContactName?: string;
    ContactPhone?: string;
    ContactEmail?: string;
    ContactFax?: string;
  }
  /**
   * Order V5 Schema: Field type
   */
  export interface Order_Field {
    Name?: string;
    Value?: string;
    Type?: string;
  }
  /**
   * Order V5 Schema: Item type
   */
  export interface Order_Item {
    ItemLineNumber?: string;
    ItemNumber?: string;
    ItemSalesOrder?: string;
    ItemProductCode?: string;
    ItemPONumber?: string;
    ItemFreightClass?: string;
    ItemRevenue?: string;
    ItemStackable?: string;
    ItemDimensions?: $frontendTypes.Ultraship.Order_ItemDimensions;
    ItemDescription?: string;
    ItemTemperature?: string;
    ItemCategory?: string;
    CustomFields?: $frontendTypes.Ultraship.Order_Field;
    ItemQuantity?: $frontendTypes.Ultraship.Order_ItemQuantity[];
    ItemWeight?: $frontendTypes.Ultraship.Order_ItemWeight[];
    ItemVolume?: $frontendTypes.Ultraship.Order_ItemVolume;
  }
  /**
   * Order V5 Schema: ItemDimensions type
   */
  export interface Order_ItemDimensions {
    ItemLength?: string;
    ItemWidth?: string;
    ItemHeight?: string;
    UnitOfMeasurement?: string;
  }
  /**
   * Order V5 Schema: ItemQuantity type
   */
  export interface Order_ItemQuantity {
    ContainerType?: string;
    Quantity?: string;
  }
  /**
   * Order V5 Schema: ItemVolume type
   */
  export interface Order_ItemVolume {
    Volume?: string;
    UnitOfMeasurement?: string;
  }
  /**
   * Order V5 Schema: ItemWeight type
   */
  export interface Order_ItemWeight {
    WeightPerUnit?: string;
    TotalWeight?: string;
    WeightQualifier?: string;
    UnitOfMeasurement?: string;
  }
  /**
   * Order V5 Schema: Location type
   */
  export interface Order_Location {
    LocationName?: string;
    LocationERPIdentifier?: string;
    LocationAddress1?: string;
    LocationAddress2?: string;
    LocationCity?: string;
    LocationState?: string;
    LocationProvince?: string;
    LocationPostal?: string;
    LocationSPLC?: string;
    LocationCountry?: string;
    LocationContact?: $frontendTypes.Ultraship.Order_Contact;
  }
  /**
   * Shipment V4 Schema: Shipment type
   */
  export interface Shipment {
    FullShipment?: { Action?: string, ShipmentNumber?: string, ShipmentType?: string, ShipmentStatus?: string, ParentShipmentNumber?: string, ChildShipmentNumber?: string, BOLNumber?: string, CreatedBy?: { ContactName?: string, ContactPhone?: string, ContactEmail?: string, ContactFax?: string }, CreatedDateTime?: string, FileCreatedDateTime?: string, BillTo?: string, Division?: string, ShipmentTotals?: { ShipmentCost?: string, ShipmentLinehaul?: string, ShipmentFSC?: string, ShipmentStopCharges?: string, ShipmentAccessorials?: string, ShipmentMiles?: string, ShipmentWeight?: string, ShipmentVolume?: string, ShipmentPallets?: string, ShipmentQuantity?: string, ShipmentDirectMiles?: string }, CustomFields?: any, Trips?: { Trip?: $frontendTypes.Ultraship.Shipment_Trip[] }, Deliveries?: { Delivery?: $frontendTypes.Ultraship.Shipment_Delivery[] } };
    CancelShipment?: { Action?: string, ShipmentNumber?: string };
    StatusUpdate?: { Action?: string, ShipmentNumber?: string, Stop?: $frontendTypes.Ultraship.Shipment_Stop };
    CarrierUpdate?: { Action?: string, ShipmentNumber?: string, Trip?: $frontendTypes.Ultraship.Shipment_Trip };
  }
  /**
   * 
   */
  export interface ShipmentRequestResponse {
    Status?: string;
    TransmissionIdentifier?: number;
    Errors?: { Error?: { TransactionNumber?: number, ShipmentNumber?: string, ErrorMessage?: string }[] };
  }
  /**
   * Shipment V4 Schema
   */
  export interface ShipmentWS {
    Shipment?: $frontendTypes.Ultraship.Shipment[];
  }
  /**
   * Shipment V4 Schema: Delivery type
   */
  export interface Shipment_Delivery {
    DeliveryNumber?: string;
    DeliveryPickupStopNumber?: string;
    DeliveryDropoffStopNumber?: string;
    DeliveryPONumber?: string;
    SalesRepIdentifier?: string;
    OrderValue?: string;
    ReleaseNumber?: string;
    TransferNumber?: string;
    DeliveryDivision?: string;
    ParentPlant?: string;
    DeliveryPallets?: string;
    DeliveryQuantity?: string;
    DeliveryWeight?: string;
    DeliveryVolume?: string;
    DeliveryDirectMiles?: string;
    CustomFields?: any;
    Items?: { Item?: $frontendTypes.Ultraship.Shipment_Item[] };
  }
  /**
   * Shipment V4 Schema: Item type
   */
  export interface Shipment_Item {
    ItemLineNumber?: string;
    ItemNumber?: string;
    ItemDescription?: string;
    ItemProductCode?: string;
    ItemPONumber?: string;
    ItemCategory?: string;
    CustomFields?: any;
    ItemQuantity?: $frontendTypes.Ultraship.Shipment_ItemQuantity[];
    ItemWeight?: { WeightPerUnit?: string, WeightQualifier?: string, UnitOfMeasurement?: string };
    ItemVolume?: { Volume?: string };
  }
  /**
   * Shipment V4 Schema: ItemQuantity type
   */
  export interface Shipment_ItemQuantity {
    ContainerType?: string;
    Quantity?: string;
  }
  /**
   * Shipment V4 Schema: Stop type
   */
  export interface Shipment_Stop {
    StopNumber?: string;
    StopType?: string;
    StopLocation?: { LocationName?: string, LocationERPIdentifier?: string, LocationAddress1?: string, LocationAddress2?: string, LocationCity?: string, LocationState?: string, LocationProvince?: string, LocationPostal?: string, LocationSPLC?: string, LocationCountry?: string, LocationContact?: { ContactName?: string, ContactPhone?: string, ContactEmail?: string, ContactFax?: string }, StopDistance?: string };
    ScheduledArrival?: { Date?: string, Time?: string };
    ScheduledDeparture?: { Date?: string, Time?: string };
    AppointmentArrival?: { Date?: string, Time?: string };
    AppointmentDeparture?: { Date?: string, Time?: string };
    ActualArrival?: string;
    ActualDeparture?: string;
    Confirmation?: string;
    ApptConfirmation?: string;
  }
  /**
   * Shipment V4 Schema: Trip type
   */
  export interface Shipment_Trip {
    TripNumber?: string;
    TripMode?: string;
    TripModeRequested?: string;
    TripServiceType?: string;
    TripServiceTypeRequested?: string;
    TripCarrier?: string;
    TripCarrierSCAC?: string;
    TripCarrierERPId?: string;
    TripMiles?: string;
    TripCost?: string;
    TripLinehaul?: string;
    TripFSC?: string;
    TripStopCharges?: string;
    TripAccessorials?: string;
    TripDirectMiles?: string;
    TripComments?: string;
    CustomFields?: any;
    Stops?: { Stop?: $frontendTypes.Ultraship.Shipment_Stop[] };
  }
}

export namespace $frontendTypes {
  export import Utilities =  Utilities_Types;
  export import Ultraship = Ultraship_Types;
}




