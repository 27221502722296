import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskId_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { rows: number, taskId: number, $top?: number, $skip?: number }): Promise<{ result?: { id?: number }[], totalCount?: number }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.rows)) {
      missingRequiredInParams.push('\'rows\'');
    }
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_purchase_order_bulk_receiving_by_taskId_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { rows?: number, taskId?: number, $keys: number[] }): Promise<{ result?: { id?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_purchase_order_bulk_receiving_by_taskId_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
