import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { InventoryCounts_cancel_count_task_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_create_duplicate_inventory_count_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_create_inventory_count_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_create_recount_task_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_delete_inventory_count_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_get_count_task_status_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_get_inventory_count_status_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_get_location_filter_xml_string_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_get_material_filter_xml_string_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_resolve_count_tasks_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_schedule_inventory_count_nowService } from './InventoryCounts.flow.index';
import { InventoryCounts_set_inventory_count_cancelled_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_set_inventory_count_completed_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_set_inventory_count_on_hold_flowService } from './InventoryCounts.flow.index';
import { InventoryCounts_set_inventory_count_released_flowService } from './InventoryCounts.flow.index';

import { $frontendTypes } from './InventoryCounts.frontend.types'

@Injectable({ providedIn: 'root' })
export class InventoryCounts_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public InventoryCounts: InventoryCounts_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _cancel_count_task_flow: InventoryCounts_cancel_count_task_flowService;
  public async cancel_count_task_flow(inParams: { countTaskIds: number[] }): Promise< { errors?: string[] }> {
    if(!this._cancel_count_task_flow) {
      this._cancel_count_task_flow = this.injector.get(InventoryCounts_cancel_count_task_flowService);
    }
    return this._cancel_count_task_flow.run(inParams);
  }
   
   

   
 
  private _create_duplicate_inventory_count_flow: InventoryCounts_create_duplicate_inventory_count_flowService;
  public async create_duplicate_inventory_count_flow(inParams: { inventoryCountId: number, copies?: number }): Promise< { errors?: string[], duplicateCountIds?: number[] }> {
    if(!this._create_duplicate_inventory_count_flow) {
      this._create_duplicate_inventory_count_flow = this.injector.get(InventoryCounts_create_duplicate_inventory_count_flowService);
    }
    return this._create_duplicate_inventory_count_flow.run(inParams);
  }
   
   

   
 
  private _create_inventory_count_flow: InventoryCounts_create_inventory_count_flowService;
  public async create_inventory_count_flow(inParams: { lookupCode?: string, warehouseId: number, locationFilter?: { locationIds?: number[], includeEmptyLocations?: boolean, onlyEmptyLocations?: boolean, skipHardAllocationLocations?: boolean, skipLocationsWithReleasedCountTask?: boolean }, materialFilter_ownerId?: number, materialFilter_projectId?: number, materialFilter_materialIds?: number[], materialFilter_vendorLotIds?: number[] }): Promise< { inventoryCountId?: number }> {
    if(!this._create_inventory_count_flow) {
      this._create_inventory_count_flow = this.injector.get(InventoryCounts_create_inventory_count_flowService);
    }
    return this._create_inventory_count_flow.run(inParams);
  }
   
   

   
 
  private _create_recount_task_flow: InventoryCounts_create_recount_task_flowService;
  public async create_recount_task_flow(inParams: { countTaskId: number, isOverride?: boolean }): Promise< { errors?: string[], recountTaskId?: number, discrepancyId?: number }> {
    if(!this._create_recount_task_flow) {
      this._create_recount_task_flow = this.injector.get(InventoryCounts_create_recount_task_flowService);
    }
    return this._create_recount_task_flow.run(inParams);
  }
   
   

   
 
  private _delete_inventory_count_flow: InventoryCounts_delete_inventory_count_flowService;
  public async delete_inventory_count_flow(inParams: { inventoryCountId: number }): Promise< { errors?: string[] }> {
    if(!this._delete_inventory_count_flow) {
      this._delete_inventory_count_flow = this.injector.get(InventoryCounts_delete_inventory_count_flowService);
    }
    return this._delete_inventory_count_flow.run(inParams);
  }
   
   

   
 
  private _get_count_task_status_flow: InventoryCounts_get_count_task_status_flowService;
  public async get_count_task_status_flow(inParams: { taskIds: number[] }): Promise< { tasks?: { statusId?: number, statusName?: string, descriptiveStatusName?: string }[] }> {
    if(!this._get_count_task_status_flow) {
      this._get_count_task_status_flow = this.injector.get(InventoryCounts_get_count_task_status_flowService);
    }
    return this._get_count_task_status_flow.run(inParams);
  }
   
   

   
 
  private _get_inventory_count_status_flow: InventoryCounts_get_inventory_count_status_flowService;
  public async get_inventory_count_status_flow(inParams: { inventoryCountIds: number[], optimalInputs?: { inventoryCountId?: number, statusId?: number, statusName?: string }[] }): Promise< { inventoryCounts?: { id?: number, actualStatusId?: number, actualStatusName?: string, descriptiveStatusName?: string, actionValidations?: { failReleaseReasons?: string[], failHoldReasons?: string[], failDuplicateReasons?: string[], failCompleteReasons?: string[], failCancelReasons?: string[], failDeleteReasons?: string[], allowEdit?: boolean } }[], errors?: string[] }> {
    if(!this._get_inventory_count_status_flow) {
      this._get_inventory_count_status_flow = this.injector.get(InventoryCounts_get_inventory_count_status_flowService);
    }
    return this._get_inventory_count_status_flow.run(inParams);
  }
   
   

   
 
  private _get_location_filter_xml_string_flow: InventoryCounts_get_location_filter_xml_string_flowService;
  public async get_location_filter_xml_string_flow(inParams: { locationIds?: number[], onlyEmptyLocations?: boolean, includeEmptyLocations?: boolean, skipHardAllocationLocations?: boolean, skipLocationsWithReleasedCountTask?: boolean }): Promise< { locationFilterXmlString?: string, errors?: string[] }> {
    if(!this._get_location_filter_xml_string_flow) {
      this._get_location_filter_xml_string_flow = this.injector.get(InventoryCounts_get_location_filter_xml_string_flowService);
    }
    return this._get_location_filter_xml_string_flow.run(inParams);
  }
   
   

   
 
  private _get_material_filter_xml_string_flow: InventoryCounts_get_material_filter_xml_string_flowService;
  public async get_material_filter_xml_string_flow(inParams: { ownerId?: number, projectId?: number, materialIds?: number[], vendorLotIds?: number[] }): Promise< { materialFilterXmlString?: string, errors?: string[] }> {
    if(!this._get_material_filter_xml_string_flow) {
      this._get_material_filter_xml_string_flow = this.injector.get(InventoryCounts_get_material_filter_xml_string_flowService);
    }
    return this._get_material_filter_xml_string_flow.run(inParams);
  }
   
   

   
 
  private _resolve_count_tasks_flow: InventoryCounts_resolve_count_tasks_flowService;
  public async resolve_count_tasks_flow(inParams: { countTaskId: number, packagedAmount: number, autoResolveDiscrepancy?: boolean, isOverride?: boolean }): Promise< { errors?: string[], hasDiscrepancy?: boolean }> {
    if(!this._resolve_count_tasks_flow) {
      this._resolve_count_tasks_flow = this.injector.get(InventoryCounts_resolve_count_tasks_flowService);
    }
    return this._resolve_count_tasks_flow.run(inParams);
  }
   
   

   
 
  private _schedule_inventory_count_now: InventoryCounts_schedule_inventory_count_nowService;
  public async schedule_inventory_count_now(inParams: { inventoryCountId: number }): Promise< { reasons?: string[] }> {
    if(!this._schedule_inventory_count_now) {
      this._schedule_inventory_count_now = this.injector.get(InventoryCounts_schedule_inventory_count_nowService);
    }
    return this._schedule_inventory_count_now.run(inParams);
  }
   
   

   
 
  private _set_inventory_count_cancelled_flow: InventoryCounts_set_inventory_count_cancelled_flowService;
  public async set_inventory_count_cancelled_flow(inParams: { inventoryCountId: number }): Promise< { errors?: string[] }> {
    if(!this._set_inventory_count_cancelled_flow) {
      this._set_inventory_count_cancelled_flow = this.injector.get(InventoryCounts_set_inventory_count_cancelled_flowService);
    }
    return this._set_inventory_count_cancelled_flow.run(inParams);
  }
   
   

   
 
  private _set_inventory_count_completed_flow: InventoryCounts_set_inventory_count_completed_flowService;
  public async set_inventory_count_completed_flow(inParams: { inventoryCountId: number, isCancelOutstanding: boolean }): Promise< { errors?: string[] }> {
    if(!this._set_inventory_count_completed_flow) {
      this._set_inventory_count_completed_flow = this.injector.get(InventoryCounts_set_inventory_count_completed_flowService);
    }
    return this._set_inventory_count_completed_flow.run(inParams);
  }
   
   

   
 
  private _set_inventory_count_on_hold_flow: InventoryCounts_set_inventory_count_on_hold_flowService;
  public async set_inventory_count_on_hold_flow(inParams: { inventoryCountId: number }): Promise< { errors?: string[] }> {
    if(!this._set_inventory_count_on_hold_flow) {
      this._set_inventory_count_on_hold_flow = this.injector.get(InventoryCounts_set_inventory_count_on_hold_flowService);
    }
    return this._set_inventory_count_on_hold_flow.run(inParams);
  }
   
   

   
 
  private _set_inventory_count_released_flow: InventoryCounts_set_inventory_count_released_flowService;
  public async set_inventory_count_released_flow(inParams: { inventoryCountId: number }): Promise< { errors?: string[] }> {
    if(!this._set_inventory_count_released_flow) {
      this._set_inventory_count_released_flow = this.injector.get(InventoryCounts_set_inventory_count_released_flowService);
    }
    return this._set_inventory_count_released_flow.run(inParams);
  }
   
   

   
}
