<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams"
         class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content code-editor">
    <div>
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <ngx-codemirror [(ngModel)]="value"
                        [options]="codeMirrorOptions"
                        [CodeValidator]="mode"
                        (codeMirrorLoaded)="onCodeMirrorLoaded($event)"></ngx-codemirror>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>