import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './InventoryTransfers.frontend.types'

@Injectable({ providedIn: 'root' })
export class InventoryTransfers_get_inventory_transfers_statesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { inventoryTransferIds: number[] }): Promise<{ reasons?: string[], inventoryTransfers?: { id: number, status: { id: number, name: string, state: string }, failActions: { updateReasons: string[], deleteReasons: string[], cancelReasons: string[] } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventoryTransferIds)) {
      missingRequiredInParams.push('\'inventoryTransferIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransfers/functions/get_inventory_transfers_states`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

