import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootPrintApiManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_storage_logsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { payload?: { id?: string, level?: number, process?: string, notes?: string, transaction_id?: string, ingress_id?: string, egress_id?: string, created_by?: string, created_on?: string, alerted_on?: string }, action: string, skip?: number, take?: number, date_from?: string, date_to?: string, integration_name: string }): Promise<{ payload?: { id?: string, level?: number, process?: string, notes?: string, transaction_id?: string, ingress_id?: string, egress_id?: string, created_by?: string, created_on?: string, alerted_on?: string, integration_name?: string }[], success?: boolean, error?: any[], count?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.action)) {
      missingRequiredInParams.push('\'action\'');
    }
    if (isNil(inParams.integration_name)) {
      missingRequiredInParams.push('\'integration_name\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintApiManager/functions/storage_logs`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

