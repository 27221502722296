import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_flow_new_invoice_lines_insertService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { transaction_id: string, invoice_lines: string, customerr: string, lookup: string, id: number, inv_id: string }): Promise<{ transaction_id?: string, lstatus?: string, errmessage?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.transaction_id)) {
      missingRequiredInParams.push('\'transaction_id\'');
    }
    if (isNil(inParams.invoice_lines)) {
      missingRequiredInParams.push('\'invoice_lines\'');
    }
    if (isNil(inParams.customerr)) {
      missingRequiredInParams.push('\'customerr\'');
    }
    if (isNil(inParams.lookup)) {
      missingRequiredInParams.push('\'lookup\'');
    }
    if (isNil(inParams.id)) {
      missingRequiredInParams.push('\'id\'');
    }
    if (isNil(inParams.inv_id)) {
      missingRequiredInParams.push('\'inv_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/functions/flow_new_invoice_lines_insert`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

