import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { ExcelMaterialImport_footprint_generic_json_material_to_entity_import_xml_flowService } from './ExcelMaterialImport.flow.index';
import { ExcelMaterialImport_material_import_stagingService } from './ExcelMaterialImport.flow.index';
import { ExcelMaterialImport_material_import_staging_checkService } from './ExcelMaterialImport.flow.index';
import { ExcelMaterialImport_material_import_staging_confirmService } from './ExcelMaterialImport.flow.index';
import { ExcelMaterialImport_material_import_staging_processService } from './ExcelMaterialImport.flow.index';
import { ExcelMaterialImport_material_import_staging_runService } from './ExcelMaterialImport.flow.index';
import { ExcelMaterialImport_submit_entity_import_json_requestService } from './ExcelMaterialImport.flow.index';
import { ExcelMaterialImport_submit_entity_import_xml_requestService } from './ExcelMaterialImport.flow.index';

import { $frontendTypes } from './ExcelMaterialImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelMaterialImport_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public ExcelMaterialImport: ExcelMaterialImport_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _footprint_generic_json_material_to_entity_import_xml_flow: ExcelMaterialImport_footprint_generic_json_material_to_entity_import_xml_flowService;
  public async footprint_generic_json_material_to_entity_import_xml_flow(inParams: { material?: $frontendTypes.ExcelMaterialImport.DatexMaterialInfo, transaction_id?: string, source_lookup_code?: string, source_platform?: string }): Promise< { messages?: string[], project?: string, payload_xml?: string, payload_json?: any }> {
    if(!this._footprint_generic_json_material_to_entity_import_xml_flow) {
      this._footprint_generic_json_material_to_entity_import_xml_flow = this.injector.get(ExcelMaterialImport_footprint_generic_json_material_to_entity_import_xml_flowService);
    }
    return this._footprint_generic_json_material_to_entity_import_xml_flow.run(inParams);
  }
   
   

   
 
  private _material_import_staging: ExcelMaterialImport_material_import_stagingService;
  public async material_import_staging(inParams: { payload?: $frontendTypes.ExcelMaterialImport.MaterialImportRecord[], action?: string, skip?: number, take?: number, full_text_search?: string, import_status?: string, lookup?: string, project?: string, request_id?: string }): Promise< { payload?: $frontendTypes.ExcelMaterialImport.MaterialImportRecord[], success?: boolean, messages?: any[], count?: number }> {
    if(!this._material_import_staging) {
      this._material_import_staging = this.injector.get(ExcelMaterialImport_material_import_stagingService);
    }
    return this._material_import_staging.run(inParams);
  }
   
   

   
 
  private _material_import_staging_check: ExcelMaterialImport_material_import_staging_checkService;
  public async material_import_staging_check(inParams: { requests?: { Id?: string, ImportRequestId?: string, ImportErrorMessage?: string }[] }): Promise< { messages?: any[], success?: boolean }> {
    if(!this._material_import_staging_check) {
      this._material_import_staging_check = this.injector.get(ExcelMaterialImport_material_import_staging_checkService);
    }
    return this._material_import_staging_check.run(inParams);
  }
   
   

   
 
  private _material_import_staging_confirm: ExcelMaterialImport_material_import_staging_confirmService;
  public async material_import_staging_confirm(inParams: {  }): Promise< { messages?: any[], success?: boolean }> {
    if(!this._material_import_staging_confirm) {
      this._material_import_staging_confirm = this.injector.get(ExcelMaterialImport_material_import_staging_confirmService);
    }
    return this._material_import_staging_confirm.run(inParams);
  }
   
   

   
 
  private _material_import_staging_process: ExcelMaterialImport_material_import_staging_processService;
  public async material_import_staging_process(inParams: { materials?: { lookup?: string, project?: string, payload?: $frontendTypes.ExcelMaterialImport.MaterialImportRecord[] }[] }): Promise< { messages?: any[], success?: boolean }> {
    if(!this._material_import_staging_process) {
      this._material_import_staging_process = this.injector.get(ExcelMaterialImport_material_import_staging_processService);
    }
    return this._material_import_staging_process.run(inParams);
  }
   
   

   
 
  private _material_import_staging_run: ExcelMaterialImport_material_import_staging_runService;
  public async material_import_staging_run(inParams: { material?: $frontendTypes.ExcelMaterialImport.MaterialImportRecord[] }): Promise< { messages?: any[], success?: boolean, time?: number, errors?: number, runners?: number }> {
    if(!this._material_import_staging_run) {
      this._material_import_staging_run = this.injector.get(ExcelMaterialImport_material_import_staging_runService);
    }
    return this._material_import_staging_run.run(inParams);
  }
   
   

   
 
  private _submit_entity_import_json_request: ExcelMaterialImport_submit_entity_import_json_requestService;
  public async submit_entity_import_json_request(inParams: { entityImport?: any, referenceCode?: string, project?: string, warehouse?: string, groupId?: string, transactionGroupId?: string }): Promise< { requestId?: string }> {
    if(!this._submit_entity_import_json_request) {
      this._submit_entity_import_json_request = this.injector.get(ExcelMaterialImport_submit_entity_import_json_requestService);
    }
    return this._submit_entity_import_json_request.run(inParams);
  }
   
   

   
 
  private _submit_entity_import_xml_request: ExcelMaterialImport_submit_entity_import_xml_requestService;
  public async submit_entity_import_xml_request(inParams: { referenceCode?: string, entityImportXml?: string, project?: string, warehouse?: string, groupId?: string, transactionGroupId?: string }): Promise< { requestId?: string }> {
    if(!this._submit_entity_import_xml_request) {
      this._submit_entity_import_xml_request = this.injector.get(ExcelMaterialImport_submit_entity_import_xml_requestService);
    }
    return this._submit_entity_import_xml_request.run(inParams);
  }
   
   

   
}
