import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Lots_lot_date_types_singleComponent } from './Lots.lot_date_types_single.component'


interface IFootPrintManager_lots_grid_flatComponentEntity {
VendorLotId?: number, VendorLotLookupCode?: string, VendorLotDescription?: string, ExpirationDate?: string, ManufactureDate?: string, VendorLotNotes?: string, Vat?: string, VendorLotCreatedSysDateTime?: string, VendorLotModifiedSysDateTime?: string, MaterialId?: number, LotId?: number, LotDescription?: string, LotLookupCode?: string, LastReceiveDate?: string, ReceiveDate?: string, LotNotes?: string, StatusId?: number, LotCreatedSysDateTime?: string, LotModifiedSysDateTime?: string, StatusName?: string, MaterialLookupCode?: string, MaterialName?: string, MaterialDescription?: string, ProjectId?: number, ProjectLookupCode?: string, ProjectName?: string, OwnerId?: number, OwnerLookupCode?: string, OwnerName?: string}

interface IFootPrintManager_lots_grid_flatComponentInParams {
  ownerIds?: number[], projectIds?: number[], materialIds?: number[], statusIds?: number[]}


class FootPrintManager_lots_grid_flatComponentRowModel extends GridRowModel {
  grid: FootPrintManager_lots_grid_flatComponent;
  entity: IFootPrintManager_lots_grid_flatComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Material_LookupCode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    LookupCode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    Status_Name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ReceiveDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    LastReceiveDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    VendorLot_ManufactureDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    VendorLot_ExpirationDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    CreatedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    ModifiedSysDateTime: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Material_Project_Name: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_lots_grid_flatComponent, entity: IFootPrintManager_lots_grid_flatComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.VendorLotId,this.entity.LotId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_lots_grid_flatComponent, entity?: IFootPrintManager_lots_grid_flatComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.VendorLotId,this.entity.LotId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'VendorLotId',this.entity.VendorLotId);
    set($resultKey, 'LotId',this.entity.LotId);
    const inParams = {
      $keys:[$resultKey],
      ownerIds:  $grid.inParams.ownerIds ,
      projectIds:  $grid.inParams.projectIds ,
      materialIds:  $grid.inParams.materialIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      statusIds:  $grid.inParams.statusIds ,
      fromDate:  $grid.filters.date_from.control.value ,
      toDate:  $grid.filters.date_to.control.value ,
      dateType:  $grid.filters.date_type.control.value ,
    };
    const data = await this.datasources.Lots.ds_lots_grid_flat_flow_based.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.Material_LookupCode.displayControl as TextModel).text = `${$row.entity.MaterialLookupCode === $row.entity.MaterialName ? $row.entity.MaterialLookupCode : ($row.entity.MaterialLookupCode + ($row.entity.MaterialName ? " " + $row.entity.MaterialName : ""))}`;
    (this.cells.LookupCode.displayControl as TextModel).text = `${$row.entity.LotLookupCode === $row.entity.VendorLotLookupCode ? $row.entity.LotLookupCode : (($row.entity.LotLookupCode ? $row.entity.LotLookupCode : "No lot") + " / " + $row.entity.VendorLotLookupCode)}`;
    (this.cells.Status_Name.displayControl as TextModel).text = $row.entity.StatusName;
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.LotNotes;
    (this.cells.Material_Project_Name.displayControl as TextModel).text = `${$row.entity.OwnerName} / ${$row.entity.ProjectName}`;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'Material_LookupCode' : {
        this.on_material_clicked();
        break;
      }
      case 'LookupCode' : {
        this.on_lot_clicked();
        break;
      }
      case 'Material_Project_Name' : {
        this.on_project_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_lots_grid_flatComponentRowModel,
  $grid: FootPrintManager_lots_grid_flatComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // O.Arias - 08/31/2023
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
  
      if ($utils.isDefined($row.entity.LotCreatedSysDateTime)) {
          $row.cells.CreatedSysDateTime.displayControl.text = $utils.date.format($row.entity.LotCreatedSysDateTime, format);
      } else if ($utils.isDefined($row.entity.VendorLotCreatedSysDateTime)) {
          $row.cells.CreatedSysDateTime.displayControl.text = $utils.date.format($row.entity.VendorLotCreatedSysDateTime, format);
      };
  
      if ($utils.isDefined($row.entity.LotModifiedSysDateTime)) {
          $row.cells.ModifiedSysDateTime.displayControl.text = $utils.date.format($row.entity.LotModifiedSysDateTime, format);
      } else if ($utils.isDefined($row.entity.VendorLotModifiedSysDateTime)) {
          $row.cells.ModifiedSysDateTime.displayControl.text = $utils.date.format($row.entity.VendorLotModifiedSysDateTime, format);
      };
  
      if ($utils.isDefined($row.entity.LastReceiveDate)) {
          $row.cells.LastReceiveDate.displayControl.text = $utils.date.format($row.entity.LastReceiveDate, format);
      };
  
      if ($utils.isDefined($row.entity.ReceiveDate)) {
          $row.cells.ReceiveDate.displayControl.text = $utils.date.format($row.entity.ReceiveDate, format);
      };
  
      if ($utils.isDefined($row.entity.ManufactureDate)) {
          $row.cells.VendorLot_ManufactureDate.displayControl.text = $utils.date.format($row.entity.ManufactureDate, format);
      };
  
      if ($utils.isDefined($row.entity.ExpirationDate)) {
          $row.cells.VendorLot_ExpirationDate.displayControl.text = $utils.date.format($row.entity.ExpirationDate, format);
      };
  };
  }
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: FootPrintManager_lots_grid_flatComponentRowModel,
  $grid: FootPrintManager_lots_grid_flatComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/31/2023
  
  $shell.FootPrintManager.opensingle_lot_hub({lotId: $row.entity.LotId});
  }
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootPrintManager_lots_grid_flatComponentRowModel,
  $grid: FootPrintManager_lots_grid_flatComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/14/2023
  
  $shell.FootPrintManager.opensingle_material_hub({materialId: $row.entity.MaterialId});
  }
  on_project_clicked(event = null) {
    return this.on_project_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_clickedInternal(
    $row: FootPrintManager_lots_grid_flatComponentRowModel,
  $grid: FootPrintManager_lots_grid_flatComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/31/2023
  
  $shell.FootPrintManager.opensingle_project_hub({projectId: $row.entity.ProjectId});
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Lots_lot_date_types_singleComponent),
  ],
  selector: 'FootPrintManager-lots_grid_flat',
  templateUrl: './FootPrintManager.lots_grid_flat.component.html'
})
export class FootPrintManager_lots_grid_flatComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_lots_grid_flatComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_lots_grid_flatComponentInParams = { ownerIds: [], projectIds: [], materialIds: [], statusIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Material_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Lot / Vendor lot', false, false, null),       Status_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       ReceiveDate: new GridHeaderModel(new HeaderStyles(null, null), 'Receive date', false, false, null),       LastReceiveDate: new GridHeaderModel(new HeaderStyles(null, null), 'Last receive date', false, false, null),       VendorLot_ManufactureDate: new GridHeaderModel(new HeaderStyles(null, null), 'Manufacture date', false, false, null),       VendorLot_ExpirationDate: new GridHeaderModel(new HeaderStyles(null, null), 'Expiration date', false, false, null),       CreatedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'Created date', false, false, null),       ModifiedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'Modified date', false, false, null),       Notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       Material_Project_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Owner / Project', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_lots_grid_flatComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerIds') set $inParams_ownerIds(value: any) {
    this.inParams['ownerIds'] = value;
  }
  get $inParams_ownerIds(): any {
    return this.inParams['ownerIds'] ;
  }
  @Input('projectIds') set $inParams_projectIds(value: any) {
    this.inParams['projectIds'] = value;
  }
  get $inParams_projectIds(): any {
    return this.inParams['projectIds'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }

  topToolbar = {
      update_status: new ToolModel(new ButtonModel('update_status', new ButtonStyles(null, null), false, 'Update status', 'ms-Icon ms-Icon--SyncStatus')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(null, null), false, 'Delete selected', 'icon-ic_fluent_delete_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'Date from', false)
,
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, 'l, LT', 'datetime')
, new ControlContainerStyles(null, null), 'Date to', false)
,
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Date type', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Lots grid from flat data source';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerIds:  $grid.inParams.ownerIds ,
      projectIds:  $grid.inParams.projectIds ,
      materialIds:  $grid.inParams.materialIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      statusIds:  $grid.inParams.statusIds ,
      fromDate:  $grid.filters.date_from.control.value ,
      toDate:  $grid.filters.date_to.control.value ,
      dateType:  $grid.filters.date_type.control.value ,
    };
    try {
    const data = await this.datasources.Lots.ds_lots_grid_flat_flow_based.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_lots_grid_flatComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_select();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_lots_grid_flatComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/01/2023
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      await $shell.FootPrintManager.openErrorDialog('Error deleting lots.', 'No lots selected.');
      return;
  } else {
  
      const candidates = selectedRows.map(R => {
          return {
              "lotId": R.entity.LotId,
              "lotLookupCode": R.entity.LotLookupCode,
              "vendorLotId": R.entity.VendorLotId,
              "vendorLotLookupCode": R.entity.VendorLotLookupCode
          }
      });
  
      //No candidates
      if (candidates.length === 0) {
          await $shell.FootPrintManager.openErrorDialog('Error deleting lots.', 'No lots selected.');
          return;
      } else {
  
          let lots = candidates.filter(c => $utils.isDefined(c.lotId)).map(c => c.lotLookupCode);
          let lot_candidates = "";
          if (lots.length > 0) { lot_candidates = `Lot(s) ${lots.join(', ')} will be deleted.` };
  
          let vendorlots = candidates.filter(c => !$utils.isDefined(c.lotId)).map(c => c.vendorLotLookupCode);
          let vendorlot_candidates = "";
          if (vendorlots.length > 0) { vendorlot_candidates = `Vendor Lot(s) ${vendorlots.join(', ')} will be deleted.` };
  
          let confirmCandidates = ""
          if (lot_candidates !== "" && vendorlot_candidates !== "") {
              confirmCandidates = lot_candidates + " & " + vendorlot_candidates
          };
          if (lot_candidates !== "" && vendorlot_candidates === "") {
              confirmCandidates = lot_candidates
          };
          if (lot_candidates === "" && vendorlot_candidates !== "") {
              confirmCandidates = vendorlot_candidates
          };
  
          let confirm = false;
  
          let title = 'Delete the following lot(s) and vendor lot(s)';
          let message = confirmCandidates;
          confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          message = "";
  
          if (confirm) {
  
              const candidateSuccess = [];
              const candidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
  
                  let lotId = candidate.lotId;
                  let lot = candidate.lotLookupCode;
                  let vendorLotId = candidate.vendorLotId;
                  let vendorLot = candidate.vendorLotLookupCode;
                  let candidate_lookup = ""
  
                  if ($utils.isDefined(lotId) && $utils.isDefined(vendorLotId)) {
                      candidate_lookup = "Lot " + lot + " & Vendor Lot " + vendorLot
                  };
                  if ($utils.isDefined(lotId) && !$utils.isDefined(vendorLotId)) {
                      candidate_lookup = "Lot " + lot
                  };
                  if (!$utils.isDefined(lotId) && $utils.isDefined(vendorLotId)) {
                      candidate_lookup = "Vendor Lot " + vendorLot
                  };
  
                  try {
  
                      let reasons: string[];
  
                      if ($utils.isDefined(lotId)) {
  
                          let success = false;
                          var lot_delete_result = (await $flows.Lots.delete_lot({ lotId: lotId }));
  
                          if ($utils.isDefined(lot_delete_result)) {
                              if ($utils.isDefined(lot_delete_result.lotId)) {
                                  //If we get the lot id as an output then we're sure the lot was deleted
                                  if (lot_delete_result.lotId === lotId) {
                                      success = true;
                                  };
                              };
                              if ($utils.isDefined(lot_delete_result.reason)) {
                                  //Get the reason if the lot was not deleted
                                  if (lot_delete_result.reason !== "") {
                                      reasons = [lot_delete_result.reason];
                                  } else if (!$utils.isDefined(lot_delete_result.lotId)) {
                                      reasons = [`Uncaught exception while attempting to delete lot ${lot} (id ${lotId}) (The lot Id was not confirmed)!`];
                                  };
                              };
                          };
  
                          //If we failed to delete the lot we shouldn't even try to delete the vendor lot
                          if (!success) {
                              vendorLotId = null;
  
                              if (!$utils.isDefined(reasons)) {
                                  reasons = [`Uncaught exception while attempting to delete lot ${lot} (id ${lotId}) (No reasons provided)!`];
                              };
                          };
                      };
  
                      if ($utils.isDefined(vendorLotId)) {
  
                          let success = false;
                          let is_orphan = false;
  
                          //Let's make sure this lot is not associated to any other system lots, only then we'll proceed with the deletion
                          const orphan = (await $datasources.Lots.ds_lots_grid_oprhan_vendor_lots.getList({ vendorLotIds: [vendorLotId] })).result;
  
                          if ($utils.isDefined(orphan)) {
                              if (orphan[0].Id === vendorLotId) {
                                  is_orphan = true;
                              };
                          };
  
                          //Only delete if we're sure the vendor lot is an orphan
                          if (is_orphan) {
  
                              var vendorLot_delete_result = (await $flows.Lots.delete_lot({ vendorLotId: vendorLotId }));
  
                              if ($utils.isDefined(vendorLot_delete_result)) {
                                  if ($utils.isDefined(vendorLot_delete_result.vendorLotId)) {
                                      //If we get the lot id as an output then we're sure the vendor lot was deleted
                                      if (vendorLot_delete_result.vendorLotId === vendorLotId) {
                                          success = true;
                                      };
                                  };
                                  if ($utils.isDefined(vendorLot_delete_result.reason)) {
                                      //Get the reason if the lot was not deleted
                                      if (vendorLot_delete_result.reason !== "") {
                                          reasons = [vendorLot_delete_result.reason];
                                      } else if (!$utils.isDefined(vendorLot_delete_result.vendorLotId)) {
                                          reasons = [`Uncaught exception while attempting to delete vendor lot ${vendorLot} (id ${vendorLotId}) (The vendor lot Id was not confirmed)!`];
                                      };
                                  };
                              };
                          } else {
                              if (!$utils.isDefined(lotId)) {
                                  reasons = [`Cannot delete vendor lot ${vendorLot} (id ${vendorLotId}) it has system lots associated to it!`];
                              };
                          };
  
                          if (!success) {
                              if (!$utils.isDefined(reasons)) {
                                  reasons = [`Uncaught exception while attempting to delete vendor lot ${vendorLot} (id ${vendorLotId}) (No reasons provided)!`];
                              };
                          };
                      };
  
                      if ($utils.isDefined(reasons)) {
                          candidateFailures.push(candidate);
                          errorMsgList.push(`${candidate_lookup} - ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
                      } else {
                          candidateSuccess.push(candidate);
                      };
  
                  } catch (error) {
                      candidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `${candidate_lookup} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  };
              };
  
              let lots_success = candidateSuccess.filter(c => $utils.isDefined(c.lotId)).map(c => c.lotLookupCode);
              let lot_candidates_success = "";
              if (lots_success.length > 0) { lot_candidates_success = `Lot(s) ${lots_success.join(', ')} successfully deleted.` };
  
              let vendorlots_success = candidateSuccess.filter(c => !$utils.isDefined(c.lotId)).map(c => c.vendorLotLookupCode);
              let vendorlot_candidates_success = "";
              if (vendorlots_success.length > 0) { vendorlot_candidates_success = `Vendor Lot(s) ${vendorlots_success.join(', ')} successfully deleted.` };
  
              let candidates_success = ""
              if (lot_candidates_success !== "" && vendorlot_candidates_success !== "") {
                  candidates_success = lot_candidates_success + " & " + vendorlot_candidates_success
              };
              if (lot_candidates_success !== "" && vendorlot_candidates_success === "") {
                  candidates_success = lot_candidates_success
              };
              if (lot_candidates_success === "" && vendorlot_candidates_success !== "") {
                  candidates_success = vendorlot_candidates_success
              };
  
              let lots_failures = candidateFailures.filter(c => $utils.isDefined(c.lotId)).map(c => c.lotLookupCode);
              let lot_candidates_failures = "";
              if (lots_failures.length > 0) { lot_candidates_failures = `Lot(s) ${lots_failures.join(', ')} could not be deleted.` };
  
              let vendorlots_failures = candidateFailures.filter(c => !$utils.isDefined(c.lotId)).map(c => c.vendorLotLookupCode);
              let vendorlot_candidates_failures = "";
              if (vendorlots_failures.length > 0) { vendorlot_candidates_failures = `Vendor Lot(s) ${vendorlots_failures.join(', ')} could not be deleted.` };
  
              let candidates_failures = ""
              if (lot_candidates_failures !== "" && vendorlot_candidates_failures !== "") {
                  candidates_failures = lot_candidates_failures + " & " + vendorlot_candidates_failures
              };
              if (lot_candidates_failures !== "" && vendorlot_candidates_failures === "") {
                  candidates_failures = lot_candidates_failures
              };
              if (lot_candidates_failures === "" && vendorlot_candidates_failures !== "") {
                  candidates_failures = vendorlot_candidates_failures
              };
  
              //All succeeded
              if (candidateSuccess.length === candidates.length) {
  
                  title = 'All record(s) deleted';
                  message = candidates_success;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
  
              } else {
  
                  //All failures
                  if (candidateFailures.length === candidates.length) {
  
                      title = 'All records(s) failed to be deleted';
                      message = candidates_failures;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
  
                  } else {
  
                      title = 'Some records(s) could not be deleted';
                      const success = candidates_success;
                      const errors = candidates_failures;
                      message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
  
                  };
              };
          };
      };
  };
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_lots_grid_flatComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/01/2023
  
  //Start as read only
  $grid.topToolbar.delete_selected.control.readOnly = true;
  $grid.topToolbar.update_status.control.readOnly = true;
  }
  on_select(event = null) {
    return this.on_selectInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selectInternal(
    $grid: FootPrintManager_lots_grid_flatComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/01/2023
  
  if ($grid.selectedRows.length === 0) {
      $grid.topToolbar.delete_selected.control.readOnly = true;
      $grid.topToolbar.update_status.control.readOnly = true;
  } else {
      $grid.topToolbar.delete_selected.control.readOnly = false;
      $grid.topToolbar.update_status.control.readOnly = false;
  };
  }
  on_update_status(event = null) {
    return this.on_update_statusInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_statusInternal(
    $grid: FootPrintManager_lots_grid_flatComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 09/08/2023
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      await $shell.FootPrintManager.openErrorDialog('Error updating lots.', 'No lots selected.');
      return;
  } else {
  
      const candidates = selectedRows.filter(F => $utils.isDefined(F.entity.LotId)).map(R => {
          return {
              "lotId": R.entity.LotId,
              "lotLookupCode": R.entity.LotLookupCode
          }
      });
  
      //No candidates
      if (candidates.length === 0) {
          await $shell.FootPrintManager.openErrorDialog('Error updating lots.', 'No lots selected.');
          return;
      } else {
  
          let lots = candidates.filter(c => $utils.isDefined(c.lotId)).map(c => c.lotLookupCode);
          let lot_candidates = "";
          if (lots.length > 0) { lot_candidates = `Lot(s) ${lots.join(', ')} will be updated.` };
  
          let confirmCandidates = ""
          if (lot_candidates !== "") {
              confirmCandidates = lot_candidates
          };
  
          let confirm = false;
  
          let title = 'Update the following lot(s)';
          let message = confirmCandidates;
  
          let status = (await $shell.FootPrintManager.openlot_bulk_update_status_formDialog({message}))
  
          if ($utils.isDefined(status)) {
              if ($utils.isDefined(status.statusId)) {
                  if (status.statusId > 0) {
                      confirm = true;
                  };
              };
          };
  
          message = "";
  
          if (confirm) {
  
              const candidateSuccess = [];
              const candidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
  
                  let lotId = candidate.lotId;
                  let lot = candidate.lotLookupCode;
                  let candidate_lookup = ""
  
                  if ($utils.isDefined(lotId)) {
                      candidate_lookup = "Lot " + lot
                  };
  
                  try {
  
                      let reasons: string[];
  
                      if ($utils.isDefined(lotId)) {
  
                          let success = false;
                          var lot_update_result = (await $flows.Lots.update_lot_status({ lotId: lotId, statusId: status.statusId, reasonCodeIds: status.reasonCodeIds }));
  
                          if ($utils.isDefined(lot_update_result)) {
                              if ($utils.isDefined(lot_update_result.lotId)) {
                                  //If we get the lot id as an output then we're sure the lot was deleted
                                  if (lot_update_result.lotId === lotId) {
                                      success = true;
                                  };
                              };
                              if ($utils.isDefined(lot_update_result.reason)) {
                                  //Get the reason if the lot was not deleted
                                  if (lot_update_result.reason !== "") {
                                      reasons = [lot_update_result.reason];
                                  } else if (!$utils.isDefined(lot_update_result.lotId)) {
                                      reasons = [`Uncaught exception while attempting to update lot ${lot} (id ${lotId}) (The lot Id was not confirmed)!`];
                                  };
                              };
                          };
  
                          //If we failed to delete the lot we shouldn't even try to delete the vendor lot
                          if (!success) {
                              if (!$utils.isDefined(reasons)) {
                                  reasons = [`Uncaught exception while attempting to update lot ${lot} (id ${lotId}) (No reasons provided)!`];
                              };
                          };
                      };
  
                      if ($utils.isDefined(reasons)) {
                          candidateFailures.push(candidate);
                          errorMsgList.push(`${candidate_lookup} - ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
                      } else {
                          candidateSuccess.push(candidate);
                      };
  
                  } catch (error) {
                      candidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `${candidate_lookup} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  };
              };
  
              let lots_success = candidateSuccess.filter(c => $utils.isDefined(c.lotId)).map(c => c.lotLookupCode);
              let lot_candidates_success = "";
              if (lots_success.length > 0) { lot_candidates_success = `Lot(s) ${lots_success.join(', ')} successfully updated.` };
  
              let candidates_success = ""
              if (lot_candidates_success !== "") {
                  candidates_success = lot_candidates_success
              };
  
              let lots_failures = candidateFailures.filter(c => $utils.isDefined(c.lotId)).map(c => c.lotLookupCode);
              let lot_candidates_failures = "";
              if (lots_failures.length > 0) { lot_candidates_failures = `Lot(s) ${lots_failures.join(', ')} could not be updated.` };
  
              let candidates_failures = ""
              if (lot_candidates_failures !== "") {
                  candidates_failures = lot_candidates_failures
              };
  
              //All succeeded
              if (candidateSuccess.length === candidates.length) {
  
                  title = 'All record(s) updated';
                  message = candidates_success;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
  
              } else {
  
                  //All failures
                  if (candidateFailures.length === candidates.length) {
  
                      title = 'All records(s) failed to be updated';
                      message = candidates_failures;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
  
                  } else {
  
                      title = 'Some records(s) could not be updated';
                      const success = candidates_success;
                      const errors = candidates_failures;
                      message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
  
                  };
              };
          };
      };
  };
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
