import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ds_warehouse_transfer_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { warehouseTransferId?: number }): Promise<{ result?: { id?: number, lookupcode?: string, ownerId?: number, projectId?: number, sourceWarehouseId?: number, targetWarehouseId?: number, outboundOrderId?: number, inboundOrderId?: number, outboundShipmentId?: number, inboundShipmentId?: number, statusId?: number, notes?: string, inboundOrderLookupcode?: string, outboundOrderLookupcode?: string, outboundOrderStatusId?: number, inboundOrderStatusId?: number, statusName?: string } }> {
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_warehouse_transfer_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
