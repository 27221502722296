import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Surveys_get_survey_context_details_flowService } from './Surveys.flow.index';

import { $frontendTypes } from './Surveys.frontend.types'

@Injectable({ providedIn: 'root' })
export class Surveys_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Surveys: Surveys_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _get_survey_context_details_flow: Surveys_get_survey_context_details_flowService;
  public async get_survey_context_details_flow(inParams: { surveyIds: number[] }): Promise< { entities?: { type?: string, name?: string, surveyId?: number }[] }> {
    if(!this._get_survey_context_details_flow) {
      this._get_survey_context_details_flow = this.injector.get(Surveys_get_survey_context_details_flowService);
    }
    return this._get_survey_context_details_flow.run(inParams);
  }
   
   

   
}
