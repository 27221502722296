<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template toolbarToolDef="delete_selected" let-tool>
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-owners_dd_single 
        data-cy="selector"
        [type]="row.cells.owner.editControl.type"
        [formControl]="row.formGroup.controls['owner_edit']"
        (displayTextChange)="row.cells.owner.editControl.displayText=$event"
        [placeholder]="row.cells.owner.editControl.placeholder"
        [styles]="row.cells.owner.editControl.styles"
      [statusId]="row.$fields_owner_selector_inParams_statusId"
      [projectId]="row.$fields_owner_selector_inParams_projectId"
    >
    </Inventory-owners_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-projects_dd_single 
        data-cy="selector"
        [type]="row.cells.project.editControl.type"
        [formControl]="row.formGroup.controls['project_edit']"
        (displayTextChange)="row.cells.project.editControl.displayText=$event"
        [placeholder]="row.cells.project.editControl.placeholder"
        [styles]="row.cells.project.editControl.styles"
      [statusId]="row.$fields_project_selector_inParams_statusId"
      [ownerId]="row.$fields_project_selector_inParams_ownerId"
    >
    </Inventory-projects_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-warehouse_dd_single 
        data-cy="selector"
        [type]="row.cells.warehouse.editControl.type"
        [formControl]="row.formGroup.controls['warehouse_edit']"
        (displayTextChange)="row.cells.warehouse.editControl.displayText=$event"
        [placeholder]="row.cells.warehouse.editControl.placeholder"
        [styles]="row.cells.warehouse.editControl.styles"
    >
    </Inventory-warehouse_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="snapshot_level">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.snapshot_level.displayControl.styles.style"
          [ngClass]="row.cells.snapshot_level.displayControl.styles.classes">{{row.cells.snapshot_level.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-inventory_snapshot_levels_dd_single 
        data-cy="selector"
        [type]="row.cells.snapshot_level.editControl.type"
        [formControl]="row.formGroup.controls['snapshot_level_edit']"
        (displayTextChange)="row.cells.snapshot_level.editControl.displayText=$event"
        [placeholder]="row.cells.snapshot_level.editControl.placeholder"
        [styles]="row.cells.snapshot_level.editControl.styles"
    >
    </Inventory-inventory_snapshot_levels_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
