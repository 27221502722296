import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { InventoryCounts_ShellService, EModalSize, EToasterType, EToasterPosition } from './InventoryCounts.shell.service';
import { InventoryCounts_OperationService } from './InventoryCounts.operation.service';
import { InventoryCounts_DatasourceService } from './InventoryCounts.datasource.index';
import { InventoryCounts_FlowService } from './InventoryCounts.flow.index';
import { InventoryCounts_ReportService } from './InventoryCounts.report.index';
import { InventoryCounts_LocalizationService } from './InventoryCounts.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './InventoryCounts.frontend.types'
import { $frontendTypes as $types} from './InventoryCounts.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'InventoryCounts-count_accuracy_widget',
  templateUrl: './InventoryCounts.count_accuracy_widget.component.html'
})
export class InventoryCounts_count_accuracy_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { warehouseIds?: number[], statusIds?: number[] } = { warehouseIds: [], statusIds: [] };
  //#region Inputs
  @Input('warehouseIds') set $inParams_warehouseIds(v: number[]) {
    this.inParams.warehouseIds = v;
  }
  get $inParams_warehouseIds(): number[] {
    return this.inParams.warehouseIds;
  }
  @Input('statusIds') set $inParams_statusIds(v: number[]) {
    this.inParams.statusIds = v;
  }
  get $inParams_statusIds(): number[] {
    return this.inParams.statusIds;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { accuracyPercentage?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: InventoryCounts_ShellService,
private datasources: InventoryCounts_DatasourceService,
private flows: InventoryCounts_FlowService,
private reports: InventoryCounts_ReportService,
private localization: InventoryCounts_LocalizationService,
private operations: InventoryCounts_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.suffix = '%';

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      warehouseIds:  $widget.inParams.warehouseIds ,
      statusIds:  $widget.inParams.statusIds 
    };

    const data = await this.datasources.InventoryCounts.ds_count_accuracy_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = $widget.entity.accuracyPercentage;

    await this.on_data_loaded();
    
    this.originalValue = $widget.entity.accuracyPercentage;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $widget: InventoryCounts_count_accuracy_widgetComponent,
  
    $shell: InventoryCounts_ShellService,
    $datasources: InventoryCounts_DatasourceService,
    $flows: InventoryCounts_FlowService,
    $reports: InventoryCounts_ReportService,
    $settings: SettingsValuesService,
    $operations: InventoryCounts_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: InventoryCounts_LocalizationService,
    $event: any
  ) {
  if ($widget.entity.accuracyPercentage==100)
  {$widget.styles.setStyle('background-color','rgba(72, 164, 41, 1)');}
  else
  {$widget.styles.setStyle('background-color','rgba(235, 116, 37, 1)');}
  
  
  
  }
  //#endregion private flows
}
