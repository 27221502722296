import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_dock_doors_listComponent } from './FootPrintManager.dock_doors_list.component';
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_dock_doors_listComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
  ],
  selector: 'FootPrintManager-dock_doors_hub',
  templateUrl: './FootPrintManager.dock_doors_hub.component.html'
})
export class FootPrintManager_dock_doors_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseIds?: number[] } = { warehouseIds: [] };
  //#region Inputs
  @Input('warehouseIds') set $inParams_warehouseIds(v: number[]) {
    this.inParams.warehouseIds = v;
  }
  get $inParams_warehouseIds(): number[] {
    return this.inParams.warehouseIds;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentDate?: string, appointmentList_fromDate?: string, appointmentList_toDate?: string, appointmentList_dockDoorIds?: number[], calendar_viewDate?: string, calendar_dockDoorIds?: number[] } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    active: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  toolbar = {
      create_dock_door: new ToolModel(new ButtonModel('create_dock_door', new ButtonStyles(null, null), false, 'Create dock door', 'icon-ic_fluent_add_20_regular')
    ),
      appointment_types: new ToolModel(new ButtonModel('appointment_types', new ButtonStyles(null, null), false, 'Manage appointment types', 'icon-ic_fluent_calendar_ltr_20_regular')
    )
  };

  actionbar = {
  };

 filters = {
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    active: new FieldModel(new CheckBoxModel(this.formGroup.controls['active'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Enabled', false)
,
  };


  filtersets = {
  newGroup1: new FieldsetModel('Filters', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      dock_doors: new TabItemModel(
        this.rootTabGroup, 
        'Dock doors', 
        ),
    };
  
    //#region tabs inParams
    cacheValueFor_$tabs_dock_doors_dock_doors_list_inParams_warehouseIds: number[];
    get $tabs_dock_doors_dock_doors_list_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_dock_doors_dock_doors_list_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_dock_doors_dock_doors_list_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_dock_doors_dock_doors_list_inParams_warehouseIds;
    }
  
    get $tabs_dock_doors_dock_doors_list_inParams_active(): boolean {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.active.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_dock_doors', { read: FootPrintManager_dock_doors_listComponent }) $tabs_dock_doors: FootPrintManager_dock_doors_listComponent;
    //#endregion tabs children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Dock doors hub`;
  }


  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.dock_doors,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Dock Doors Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.active.control as CheckBoxModel).reset(true);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_dock_doors') {
      if (!isNil(this.$tabs_dock_doors) && !this.tabs.dock_doors.hidden) {
        this.$tabs_dock_doors.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_dock_doors_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $hub.filters.warehouse.control.value = $hub.inParams.warehouseIds;
  }
  on_create_dock_door_clicked(event = null) {
    return this.on_create_dock_door_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_dock_door_clickedInternal(
    $hub: FootPrintManager_dock_doors_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Initialize the first warehouse selected
  var warehouseId = null;
  
  if ($utils.isDefined($hub.filters.warehouse.control.value)) {
      warehouseId = $hub.filters.warehouse.control.value[0];
  }
  else {
      $shell.FootPrintManager.openErrorDialog('Create Dock Doors', 'Please first select a warehouse.')
      return;
  }
  
  
  const dialogResult = await $shell.FootPrintManager.opendock_door_creation_formDialog({
      warehouseId: warehouseId
  
  }, 'flyout');
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      $hub.refresh()
  }
  }
  on_manage_dock_appointment_types(event = null) {
    return this.on_manage_dock_appointment_typesInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_dock_appointment_typesInternal(
    $hub: FootPrintManager_dock_doors_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.opendock_appointment_types_gridDialog('modal')
  
  $hub.refresh();
  }
  //#endregion private flows
}
