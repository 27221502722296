import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { DockAppointments_associate_loadContainers_from_dock_appointment_flowService } from './DockAppointments.flow.index';
import { DockAppointments_associate_orders_from_dock_appointment_flowService } from './DockAppointments.flow.index';
import { DockAppointments_associate_shipment_from_dock_appointment_flowService } from './DockAppointments.flow.index';
import { DockAppointments_check_in_dock_appointment_flowService } from './DockAppointments.flow.index';
import { DockAppointments_convert_dates_between_timezones_flowService } from './DockAppointments.flow.index';
import { DockAppointments_convert_dates_by_warehouse_timezonesService } from './DockAppointments.flow.index';
import { DockAppointments_create_dock_appointment_flowService } from './DockAppointments.flow.index';
import { DockAppointments_create_dock_appointment_type_flowService } from './DockAppointments.flow.index';
import { DockAppointments_create_reasoncode_flowService } from './DockAppointments.flow.index';
import { DockAppointments_date_get_warehouse_flowService } from './DockAppointments.flow.index';
import { DockAppointments_diassociate_orders_from_dock_appointment_flowService } from './DockAppointments.flow.index';
import { DockAppointments_get_formatted_dock_appointmentService } from './DockAppointments.flow.index';
import { DockAppointments_get_utc_date_by_timezoneService } from './DockAppointments.flow.index';

import { $frontendTypes } from './DockAppointments.frontend.types'

@Injectable({ providedIn: 'root' })
export class DockAppointments_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public DockAppointments: DockAppointments_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _associate_loadContainers_from_dock_appointment_flow: DockAppointments_associate_loadContainers_from_dock_appointment_flowService;
  public async associate_loadContainers_from_dock_appointment_flow(inParams: { dockAppointmentId: number, loadContainerId: number }): Promise<void> {
    if(!this._associate_loadContainers_from_dock_appointment_flow) {
      this._associate_loadContainers_from_dock_appointment_flow = this.injector.get(DockAppointments_associate_loadContainers_from_dock_appointment_flowService);
    }
    return this._associate_loadContainers_from_dock_appointment_flow.run(inParams);
  }
   
   

   
 
  private _associate_orders_from_dock_appointment_flow: DockAppointments_associate_orders_from_dock_appointment_flowService;
  public async associate_orders_from_dock_appointment_flow(inParams: { dockAppointmentId: number, orderId: number }): Promise<void> {
    if(!this._associate_orders_from_dock_appointment_flow) {
      this._associate_orders_from_dock_appointment_flow = this.injector.get(DockAppointments_associate_orders_from_dock_appointment_flowService);
    }
    return this._associate_orders_from_dock_appointment_flow.run(inParams);
  }
   
   

   
 
  private _associate_shipment_from_dock_appointment_flow: DockAppointments_associate_shipment_from_dock_appointment_flowService;
  public async associate_shipment_from_dock_appointment_flow(inParams: { dockAppointmentId: number, shipmentId: number }): Promise<void> {
    if(!this._associate_shipment_from_dock_appointment_flow) {
      this._associate_shipment_from_dock_appointment_flow = this.injector.get(DockAppointments_associate_shipment_from_dock_appointment_flowService);
    }
    return this._associate_shipment_from_dock_appointment_flow.run(inParams);
  }
   
   

   
 
  private _check_in_dock_appointment_flow: DockAppointments_check_in_dock_appointment_flowService;
  public async check_in_dock_appointment_flow(inParams: { driverName: string, driverLicense: string, vehicleLicense: string, scheduledDoorId: number, user: string, dockAppointmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._check_in_dock_appointment_flow) {
      this._check_in_dock_appointment_flow = this.injector.get(DockAppointments_check_in_dock_appointment_flowService);
    }
    return this._check_in_dock_appointment_flow.run(inParams);
  }
   
   

   
 
  private _convert_dates_between_timezones_flow: DockAppointments_convert_dates_between_timezones_flowService;
  public async convert_dates_between_timezones_flow(inParams: { warehouseIds: number[], convertDates: any, isFromUtcToWarehouse: boolean }): Promise< { timeZoneDates?: { timeZoneId?: string, timeZone?: string, offset?: number, dates?: any, warehouseIds?: number[] }[] }> {
    if(!this._convert_dates_between_timezones_flow) {
      this._convert_dates_between_timezones_flow = this.injector.get(DockAppointments_convert_dates_between_timezones_flowService);
    }
    return this._convert_dates_between_timezones_flow.run(inParams);
  }
   
   

   
 
  private _convert_dates_by_warehouse_timezones: DockAppointments_convert_dates_by_warehouse_timezonesService;
  public async convert_dates_by_warehouse_timezones(inParams: { warehouseIds: number[], dates: any }): Promise< { timeZoneDates?: { timeZoneId?: string, timeZone?: string, offset?: number, dates?: any, warehouseIds?: number[] }[] }> {
    if(!this._convert_dates_by_warehouse_timezones) {
      this._convert_dates_by_warehouse_timezones = this.injector.get(DockAppointments_convert_dates_by_warehouse_timezonesService);
    }
    return this._convert_dates_by_warehouse_timezones.run(inParams);
  }
   
   

   
 
  private _create_dock_appointment_flow: DockAppointments_create_dock_appointment_flowService;
  public async create_dock_appointment_flow(inParams: { order_ids?: number[], load_container_id?: number, lookupcode?: string, warehouse_id: number, scheduled_arrival?: string, scheduled_departure?: string, reference_number?: string, notes?: string, carrier_id?: number, scheduled_owner_id?: number, scheduled_project_id?: number, appointment_type_id?: number, dock_door_id?: number, user?: string }): Promise< { dock_appointment_id: number, reason?: string, dockappointment?: any }> {
    if(!this._create_dock_appointment_flow) {
      this._create_dock_appointment_flow = this.injector.get(DockAppointments_create_dock_appointment_flowService);
    }
    return this._create_dock_appointment_flow.run(inParams);
  }
   
   

   
 
  private _create_dock_appointment_type_flow: DockAppointments_create_dock_appointment_type_flowService;
  public async create_dock_appointment_type_flow(inParams: { name: string, options: string }): Promise< { reasons?: string[], dockAppointmentTypeId?: number }> {
    if(!this._create_dock_appointment_type_flow) {
      this._create_dock_appointment_type_flow = this.injector.get(DockAppointments_create_dock_appointment_type_flowService);
    }
    return this._create_dock_appointment_type_flow.run(inParams);
  }
   
   

   
 
  private _create_reasoncode_flow: DockAppointments_create_reasoncode_flowService;
  public async create_reasoncode_flow(inParams: { properties?: any }): Promise< { reasons?: string[], reasonCodeId?: number }> {
    if(!this._create_reasoncode_flow) {
      this._create_reasoncode_flow = this.injector.get(DockAppointments_create_reasoncode_flowService);
    }
    return this._create_reasoncode_flow.run(inParams);
  }
   
   

   
 
  private _date_get_warehouse_flow: DockAppointments_date_get_warehouse_flowService;
  public async date_get_warehouse_flow(inParams: { warehouseId: number, utcDate?: string }): Promise< { date?: string }> {
    if(!this._date_get_warehouse_flow) {
      this._date_get_warehouse_flow = this.injector.get(DockAppointments_date_get_warehouse_flowService);
    }
    return this._date_get_warehouse_flow.run(inParams);
  }
   
   

   
 
  private _diassociate_orders_from_dock_appointment_flow: DockAppointments_diassociate_orders_from_dock_appointment_flowService;
  public async diassociate_orders_from_dock_appointment_flow(inParams: { dockAppointmentId: number, orderId: number }): Promise<void> {
    if(!this._diassociate_orders_from_dock_appointment_flow) {
      this._diassociate_orders_from_dock_appointment_flow = this.injector.get(DockAppointments_diassociate_orders_from_dock_appointment_flowService);
    }
    return this._diassociate_orders_from_dock_appointment_flow.run(inParams);
  }
   
   

   
 
  private _get_formatted_dock_appointment: DockAppointments_get_formatted_dock_appointmentService;
  public async get_formatted_dock_appointment(inParams: { dockAppointmentId: number, optimalInputs?: { scheduledLocationName?: string, assignedLocationName?: string, statusId?: number, lookupCode?: string, scheduledArrival?: string } }): Promise< { formattedDockAppointment?: string }> {
    if(!this._get_formatted_dock_appointment) {
      this._get_formatted_dock_appointment = this.injector.get(DockAppointments_get_formatted_dock_appointmentService);
    }
    return this._get_formatted_dock_appointment.run(inParams);
  }
   
   

   
 
  private _get_utc_date_by_timezone: DockAppointments_get_utc_date_by_timezoneService;
  public async get_utc_date_by_timezone(inParams: { dateTime: string, timezone: string }): Promise< { utcDate?: string }> {
    if(!this._get_utc_date_by_timezone) {
      this._get_utc_date_by_timezone = this.injector.get(DockAppointments_get_utc_date_by_timezoneService);
    }
    return this._get_utc_date_by_timezone.run(inParams);
  }
   
   

   
}
