import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { Instructions_instructions_library_homeComponent } from './Instructions.instructions_library_home.component';
import { Instructions_instruction_types_dd_singleComponent } from './Instructions.instruction_types_dd_single.component';
import { Instructions_instruction_types_dd_multiComponent } from './Instructions.instruction_types_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Instructions_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Instructions: Instructions_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openinstructions_library_home(replaceCurrentView?: boolean) {
    this.logger.log('Instructions', 'instructions_library_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Instructions_instructions_library_home',
        component: Instructions_instructions_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinstructions_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('Instructions', 'instructions_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Instructions_instructions_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Instructions_instructions_library_home') {
      this.logger.log('Instructions', 'instructions_library_home');
      const title = 'Home';
      const component = Instructions_instructions_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Instructions_instruction_types_dd_single') {
      const title = 'Instruction Types';
      const component = Instructions_instruction_types_dd_singleComponent;
      const inParams:{ typeIds?: number[] } = { typeIds: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Instructions_instruction_types_dd_multi') {
      const title = 'Instruction Types';
      const component = Instructions_instruction_types_dd_multiComponent;
      const inParams:{ typeIds?: number[] } = { typeIds: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
