<div class="fat-container" [ngClass]="styles.classes" [ngStyle]="styles.style">
  <div data-cy="widget-title" class="fat-title">Errors today</div>
  <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams"
        class="fat-content"
        [ngClass]="getStyleClass()">
    <span *ngIf="prefix"
          data-cy="widget-prefix"
          class="fat-prefix">{{prefix}}</span>
    <span data-cy="widget-number" 
          matTooltip="{{originalValue}}"
          matTooltipShowDelay="500"
          matTooltipHideDelay="250"
          class="fat-number">{{value}}</span>
    <span *ngIf="suffix"
          data-cy="widget-suffix"
          class="fat-suffix">{{suffix}}</span>
  </div>
  <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
    <span>Please provide {{$missingRequiredInParams.toString()}}</span>
  </div>
  <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
    <span>No data to display</span>
  </div>  
</div>