import { Inject, Injectable, Injector }from '@angular/core';


import { Reports_custom_ds_details_receiving_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_get_inbound_totals_by_linenumber_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_non_fixed_weight_details_by_shipmentIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_non_fixed_weight_details_by_shipmentId_completedService } from './Reports.datasource.index';
import { Reports_custom_ds_get_orderLines_with_totals_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_account_address_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_address_by_orderId_top1Service } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_by_orderIdService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_ship_to_account_vs_order_addressService } from './Reports.datasource.index';
import { Reports_custom_ds_get_order_signature_attachmentService } from './Reports.datasource.index';
import { Reports_custom_ds_get_pallet_detailsService } from './Reports.datasource.index';
import { Reports_custom_ds_get_shipment_by_orderId_top1Service } from './Reports.datasource.index';
import { Reports_custom_ds_header_receiving_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_details_bill_of_lading_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService } from './Reports.datasource.index';
import { Reports_custom_ds_outbound_header_bill_of_lading_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_receiving_label_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_receiving_label_report_multipleService } from './Reports.datasource.index';
import { Reports_custom_ds_shipping_label_reportService } from './Reports.datasource.index';
import { Reports_custom_ds_shipping_label_report_multipleService } from './Reports.datasource.index';

@Injectable({ providedIn: 'root' })
export class Reports_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Reports: Reports_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_details_receiving_report: Reports_custom_ds_details_receiving_reportService;
  public get custom_ds_details_receiving_report(): Reports_custom_ds_details_receiving_reportService {
    if(!this._custom_ds_details_receiving_report) {
      this._custom_ds_details_receiving_report = this.injector.get(Reports_custom_ds_details_receiving_reportService);
    }
    return this._custom_ds_details_receiving_report;
  }
  private _custom_ds_get_inbound_totals_by_linenumber_orderId: Reports_custom_ds_get_inbound_totals_by_linenumber_orderIdService;
  public get custom_ds_get_inbound_totals_by_linenumber_orderId(): Reports_custom_ds_get_inbound_totals_by_linenumber_orderIdService {
    if(!this._custom_ds_get_inbound_totals_by_linenumber_orderId) {
      this._custom_ds_get_inbound_totals_by_linenumber_orderId = this.injector.get(Reports_custom_ds_get_inbound_totals_by_linenumber_orderIdService);
    }
    return this._custom_ds_get_inbound_totals_by_linenumber_orderId;
  }
  private _custom_ds_get_non_fixed_weight_details_by_shipmentId: Reports_custom_ds_get_non_fixed_weight_details_by_shipmentIdService;
  public get custom_ds_get_non_fixed_weight_details_by_shipmentId(): Reports_custom_ds_get_non_fixed_weight_details_by_shipmentIdService {
    if(!this._custom_ds_get_non_fixed_weight_details_by_shipmentId) {
      this._custom_ds_get_non_fixed_weight_details_by_shipmentId = this.injector.get(Reports_custom_ds_get_non_fixed_weight_details_by_shipmentIdService);
    }
    return this._custom_ds_get_non_fixed_weight_details_by_shipmentId;
  }
  private _custom_ds_get_non_fixed_weight_details_by_shipmentId_completed: Reports_custom_ds_get_non_fixed_weight_details_by_shipmentId_completedService;
  public get custom_ds_get_non_fixed_weight_details_by_shipmentId_completed(): Reports_custom_ds_get_non_fixed_weight_details_by_shipmentId_completedService {
    if(!this._custom_ds_get_non_fixed_weight_details_by_shipmentId_completed) {
      this._custom_ds_get_non_fixed_weight_details_by_shipmentId_completed = this.injector.get(Reports_custom_ds_get_non_fixed_weight_details_by_shipmentId_completedService);
    }
    return this._custom_ds_get_non_fixed_weight_details_by_shipmentId_completed;
  }
  private _custom_ds_get_orderLines_with_totals_by_orderId: Reports_custom_ds_get_orderLines_with_totals_by_orderIdService;
  public get custom_ds_get_orderLines_with_totals_by_orderId(): Reports_custom_ds_get_orderLines_with_totals_by_orderIdService {
    if(!this._custom_ds_get_orderLines_with_totals_by_orderId) {
      this._custom_ds_get_orderLines_with_totals_by_orderId = this.injector.get(Reports_custom_ds_get_orderLines_with_totals_by_orderIdService);
    }
    return this._custom_ds_get_orderLines_with_totals_by_orderId;
  }
  private _custom_ds_get_order_account_address_by_orderId: Reports_custom_ds_get_order_account_address_by_orderIdService;
  public get custom_ds_get_order_account_address_by_orderId(): Reports_custom_ds_get_order_account_address_by_orderIdService {
    if(!this._custom_ds_get_order_account_address_by_orderId) {
      this._custom_ds_get_order_account_address_by_orderId = this.injector.get(Reports_custom_ds_get_order_account_address_by_orderIdService);
    }
    return this._custom_ds_get_order_account_address_by_orderId;
  }
  private _custom_ds_get_order_address_by_orderId_top1: Reports_custom_ds_get_order_address_by_orderId_top1Service;
  public get custom_ds_get_order_address_by_orderId_top1(): Reports_custom_ds_get_order_address_by_orderId_top1Service {
    if(!this._custom_ds_get_order_address_by_orderId_top1) {
      this._custom_ds_get_order_address_by_orderId_top1 = this.injector.get(Reports_custom_ds_get_order_address_by_orderId_top1Service);
    }
    return this._custom_ds_get_order_address_by_orderId_top1;
  }
  private _custom_ds_get_order_by_orderId: Reports_custom_ds_get_order_by_orderIdService;
  public get custom_ds_get_order_by_orderId(): Reports_custom_ds_get_order_by_orderIdService {
    if(!this._custom_ds_get_order_by_orderId) {
      this._custom_ds_get_order_by_orderId = this.injector.get(Reports_custom_ds_get_order_by_orderIdService);
    }
    return this._custom_ds_get_order_by_orderId;
  }
  private _custom_ds_get_order_ship_to_account_vs_order_address: Reports_custom_ds_get_order_ship_to_account_vs_order_addressService;
  public get custom_ds_get_order_ship_to_account_vs_order_address(): Reports_custom_ds_get_order_ship_to_account_vs_order_addressService {
    if(!this._custom_ds_get_order_ship_to_account_vs_order_address) {
      this._custom_ds_get_order_ship_to_account_vs_order_address = this.injector.get(Reports_custom_ds_get_order_ship_to_account_vs_order_addressService);
    }
    return this._custom_ds_get_order_ship_to_account_vs_order_address;
  }
  private _custom_ds_get_order_signature_attachment: Reports_custom_ds_get_order_signature_attachmentService;
  public get custom_ds_get_order_signature_attachment(): Reports_custom_ds_get_order_signature_attachmentService {
    if(!this._custom_ds_get_order_signature_attachment) {
      this._custom_ds_get_order_signature_attachment = this.injector.get(Reports_custom_ds_get_order_signature_attachmentService);
    }
    return this._custom_ds_get_order_signature_attachment;
  }
  private _custom_ds_get_pallet_details: Reports_custom_ds_get_pallet_detailsService;
  public get custom_ds_get_pallet_details(): Reports_custom_ds_get_pallet_detailsService {
    if(!this._custom_ds_get_pallet_details) {
      this._custom_ds_get_pallet_details = this.injector.get(Reports_custom_ds_get_pallet_detailsService);
    }
    return this._custom_ds_get_pallet_details;
  }
  private _custom_ds_get_shipment_by_orderId_top1: Reports_custom_ds_get_shipment_by_orderId_top1Service;
  public get custom_ds_get_shipment_by_orderId_top1(): Reports_custom_ds_get_shipment_by_orderId_top1Service {
    if(!this._custom_ds_get_shipment_by_orderId_top1) {
      this._custom_ds_get_shipment_by_orderId_top1 = this.injector.get(Reports_custom_ds_get_shipment_by_orderId_top1Service);
    }
    return this._custom_ds_get_shipment_by_orderId_top1;
  }
  private _custom_ds_header_receiving_report: Reports_custom_ds_header_receiving_reportService;
  public get custom_ds_header_receiving_report(): Reports_custom_ds_header_receiving_reportService {
    if(!this._custom_ds_header_receiving_report) {
      this._custom_ds_header_receiving_report = this.injector.get(Reports_custom_ds_header_receiving_reportService);
    }
    return this._custom_ds_header_receiving_report;
  }
  private _custom_ds_outbound_details_bill_of_lading_report: Reports_custom_ds_outbound_details_bill_of_lading_reportService;
  public get custom_ds_outbound_details_bill_of_lading_report(): Reports_custom_ds_outbound_details_bill_of_lading_reportService {
    if(!this._custom_ds_outbound_details_bill_of_lading_report) {
      this._custom_ds_outbound_details_bill_of_lading_report = this.injector.get(Reports_custom_ds_outbound_details_bill_of_lading_reportService);
    }
    return this._custom_ds_outbound_details_bill_of_lading_report;
  }
  private _custom_ds_outbound_details_bill_of_lading_report_advanced: Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService;
  public get custom_ds_outbound_details_bill_of_lading_report_advanced(): Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService {
    if(!this._custom_ds_outbound_details_bill_of_lading_report_advanced) {
      this._custom_ds_outbound_details_bill_of_lading_report_advanced = this.injector.get(Reports_custom_ds_outbound_details_bill_of_lading_report_advancedService);
    }
    return this._custom_ds_outbound_details_bill_of_lading_report_advanced;
  }
  private _custom_ds_outbound_header_bill_of_lading_report: Reports_custom_ds_outbound_header_bill_of_lading_reportService;
  public get custom_ds_outbound_header_bill_of_lading_report(): Reports_custom_ds_outbound_header_bill_of_lading_reportService {
    if(!this._custom_ds_outbound_header_bill_of_lading_report) {
      this._custom_ds_outbound_header_bill_of_lading_report = this.injector.get(Reports_custom_ds_outbound_header_bill_of_lading_reportService);
    }
    return this._custom_ds_outbound_header_bill_of_lading_report;
  }
  private _custom_ds_receiving_label_report: Reports_custom_ds_receiving_label_reportService;
  public get custom_ds_receiving_label_report(): Reports_custom_ds_receiving_label_reportService {
    if(!this._custom_ds_receiving_label_report) {
      this._custom_ds_receiving_label_report = this.injector.get(Reports_custom_ds_receiving_label_reportService);
    }
    return this._custom_ds_receiving_label_report;
  }
  private _custom_ds_receiving_label_report_multiple: Reports_custom_ds_receiving_label_report_multipleService;
  public get custom_ds_receiving_label_report_multiple(): Reports_custom_ds_receiving_label_report_multipleService {
    if(!this._custom_ds_receiving_label_report_multiple) {
      this._custom_ds_receiving_label_report_multiple = this.injector.get(Reports_custom_ds_receiving_label_report_multipleService);
    }
    return this._custom_ds_receiving_label_report_multiple;
  }
  private _custom_ds_shipping_label_report: Reports_custom_ds_shipping_label_reportService;
  public get custom_ds_shipping_label_report(): Reports_custom_ds_shipping_label_reportService {
    if(!this._custom_ds_shipping_label_report) {
      this._custom_ds_shipping_label_report = this.injector.get(Reports_custom_ds_shipping_label_reportService);
    }
    return this._custom_ds_shipping_label_report;
  }
  private _custom_ds_shipping_label_report_multiple: Reports_custom_ds_shipping_label_report_multipleService;
  public get custom_ds_shipping_label_report_multiple(): Reports_custom_ds_shipping_label_report_multipleService {
    if(!this._custom_ds_shipping_label_report_multiple) {
      this._custom_ds_shipping_label_report_multiple = this.injector.get(Reports_custom_ds_shipping_label_report_multipleService);
    }
    return this._custom_ds_shipping_label_report_multiple;
  }
}

