import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 



interface IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentEntity {
Id?: number, LookupCode?: string, LotId?: number, PackagingId?: number, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, Lot?: { LookupCode?: string, MaterialId?: number, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Material?: { Description?: string, LookupCode?: string } }, Packaging?: { ShortName?: string }}

interface IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentInParams {
  warehouseId: number, materialId: number, packagingId: number, lotId?: number, projectId: number}

interface IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentOutParams {
  inventory?: { serialnumberId?: number, lotId?: number, vendorLotId?: number }[]}

class FootPrintManager_inventory_by_serialnumber_single_selection_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent;
  entity: IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    select_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    select_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    select: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['select_display'] as DatexFormControl, null, false, '')
,
      new CheckBoxModel(this.formGroup.controls['select_edit'] as DatexFormControl, null, false, '')
      ),
    lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    available: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    material_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    expiration_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    manufacture_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    material_description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
    this.formGroup
      .controls['select_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_selection_clicked();
      });
  }

  async $initializeExisting(grid: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent, entity: IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent, entity?: IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouseId:  $grid.inParams.warehouseId ,
      materialId:  $grid.inParams.materialId ,
      packagingId:  $grid.inParams.packagingId ,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      lotId:  null ,
    };
    const data = await this.datasources.Inventory.ds_inventory_by_serialnumber_single_selection_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Packaging?.ShortName;
    (this.cells.material_lookupcode.displayControl as TextModel).text = $row.entity.Lot?.Material?.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.Lot?.VendorLot?.LookupCode;
    (this.cells.material_description.displayControl as TextModel).text = $row.entity.Lot?.Material?.Description;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponentRowModel,
  $grid: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  var lotId = $row.entity.LotId;
  var packagedId = $row.entity.PackagingId;
  var serialnumberId = $row.entity.Id;
  var warehouseId = $grid.inParams.warehouseId;
  var materialId = $row.entity.Lot?.MaterialId;
  
  // Apply date format
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ExpirationDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var expirationDate = $utils.date.format($row.entity.Lot?.VendorLot?.ExpirationDate, dateFormat);
      $row.cells.expiration_date.displayControl.text = expirationDate;
  }
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ManufactureDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var manufactureDate = $utils.date.format($row.entity.Lot?.VendorLot?.ManufactureDate, dateFormat);
      $row.cells.manufacture_date.displayControl.text = manufactureDate;
  }
  
  // Set the available cell
  const inventory = (await $flows.SalesOrders.get_sales_order_line_availability_flow({
      serialnumberId: serialnumberId,
      lotId: lotId,
      packagedId: packagedId,
      warehouseId: warehouseId,
      materialId: materialId
  }));
  if ($utils.isDefined(inventory?.availableBySerialnumber)) {
      const availBySn = inventory.availableBySerialnumber.toString();
      const availByLot = $utils.isDefined(inventory.availableByLot) ? inventory.availableByLot.toString() : '0';
      const helper = $utils.isDefined(inventory.availableHelper) ? inventory.availableHelper : '';
      const available = `Sn: ${availBySn} Lot: ${availByLot} ${helper}`
      $row.cells.available.displayControl.text = available;
  }
  else {
      $row.cells.available.displayControl.text = '0'
  }
  
  
  // Load quantity cell from outParam
  
  if ($utils.isDefined($grid.outParams.inventory)) {
  
      const rowFound = $grid.outParams.inventory.filter(
          element => element.serialnumberId === $row.entity.Id
  
      );
  
      if ($utils.isDefined(rowFound)) {
  
          $row.cells.select.editControl.value = true;
          $row.cells.select.displayControl.value = true;
        
  
      }
  
  }
  
  
  if ($utils.isDefined($grid.vars.selectedRows)) {
      const rowFound = $grid.vars.selectedRows.find(selectedRow =>
          selectedRow.entity.Id === $row.entity.Id
      );
  
      if ($grid.rows.length === 0) {
          $grid.rows = $grid.rows.concat($grid.vars.selectedRows);
      }
  
      if ($utils.isDefined(rowFound)) {
          $grid.rows.splice($grid.rows.indexOf(rowFound), 1);
      }
  
      $grid.sort_grid();
  
  }
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(row: any) {
      return row.cells.select.editControl.value === true;
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponentRowModel,
  $grid: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  if ($utils.isDefined($grid.outParams.inventory)) {
  
      const rowFound = $grid.outParams.inventory.filter(
          element => element.serialnumberId === $row.entity.Id
  
      );
  
      if ($utils.isDefined(rowFound)) {
  
          if ($utils.isDefined($row.cells.select.editControl.value) && $row.cells.select.editControl.value == true) {
              $row.cells.select.displayControl.value = true;
              $row.cells.select.editControl.value = true;
  
  
          } else {
  
              $grid.outParams.inventory.splice($grid.outParams.inventory.indexOf(rowFound[0]), 1);
              $row.cells.select.editControl.value = null;
              $row.cells.select.displayControl.value = null;
  
  
          }
  
      } else {
  
          let message = `Only one selected row allowed!`;
          $shell.FootPrintManager.openErrorDialog('Cannot select inventory', message);
          throw new Error(message);
  
  
      }
  
  } else {
  
      $grid.outParams.inventory.push(
          {
              serialnumberId: $row.entity.Id,
              lotId: $row.entity.LotId,
              vendorLotId: $row.entity.Lot.VendorLotId
          }
      )
  
      $row.cells.select.displayControl.value = true;
      $row.cells.select.editControl.value = true;
  
  
  }
  
  addToSelectedRows($row);
  
  $grid.events.outParamsChange.emit();
  
  $grid.rows.sort((a, b) => isRowSelected(a) ? isRowSelected(b) ? 0 : -1 : isRowSelected(b) ? 1 : 0);
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(row: any) {
      return row.cells.select.editControl.value === true; 
  }
  
  function addToSelectedRows(row: any) {
      if ($utils.isDefined($grid.vars.selectedRows)) {
  
          const rowFound = $grid.vars.selectedRows.find(
              existingRow => existingRow.entity.Id === $row.entity.Id
          );
  
          if ($utils.isDefined(rowFound)) {
  
              if (isRowSelected($row)) {
                  rowFound.cells.select.editControl.value = true;
                  rowFound.cells.select.displayControl.value = true;
  
              } else {
                  $grid.vars.selectedRows.splice($grid.vars.selectedRows.indexOf(rowFound), 1);
                  rowFound.cells.select.editControl.value = false;
                  rowFound.cells.select.displayControl.value = false;
              }
  
          } else {
  
              $grid.vars.selectedRows.push($row);
          }
  
      } else {
  
          $grid.vars.selectedRows.push($row);
      }
  }
  }
  on_selection_clicked(event = null) {
    return this.on_selection_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selection_clickedInternal(
    $row: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponentRowModel,
  $grid: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $row.confirm();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-inventory_by_serialnumber_single_selection_grid',
  templateUrl: './FootPrintManager.inventory_by_serialnumber_single_selection_grid.component.html'
})
export class FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentEntity[];

  pageSize = 15;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentInParams = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null };

  outParams: IFootPrintManager_inventory_by_serialnumber_single_selection_gridComponentOutParams = { inventory: [] };

  //#region Variables
  vars: { selectedRows?: any[] } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ inventory?: { serialnumberId?: number, lotId?: number, vendorLotId?: number }[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     select: new GridHeaderModel(new HeaderStyles(null, null), ' ', false, false, null),       lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Lookup', false, false, null),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),       available: new GridHeaderModel(new HeaderStyles(null, null), 'Available', false, false, null),       material_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', false, false, null),       expiration_date: new GridHeaderModel(new HeaderStyles(null, null), 'Expiration date', false, false, null),       manufacture_date: new GridHeaderModel(new HeaderStyles(null, null), 'Manufacture date', false, false, null),       material_description: new GridHeaderModel(new HeaderStyles(null, null), 'Description', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('packagingId') set $inParams_packagingId(value: any) {
    this.inParams['packagingId'] = value;
  }
  get $inParams_packagingId(): any {
    return this.inParams['packagingId'] ;
  }
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Inventory by sn single';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseId:  $grid.inParams.warehouseId ,
      materialId:  $grid.inParams.materialId ,
      packagingId:  $grid.inParams.packagingId ,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      lotId:  null ,
    };
    try {
    const data = await this.datasources.Inventory.ds_inventory_by_serialnumber_single_selection_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_inventory_by_serialnumber_single_selection_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.vars.selectedRows = [];
  }
  sort_grid(event = null) {
    return this.sort_gridInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async sort_gridInternal(
    $grid: FootPrintManager_inventory_by_serialnumber_single_selection_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await delay(125);
  
  $grid.rows.sort((a, b) => isRowSelected(a) >= 0 ? isRowSelected(b) >= 0 ? isRowSelected(a) < isRowSelected(b) ? -1 : 1 : -1 : isRowSelected(b) >= 0 ? 1 : 0);
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(inputRow: any) {
      return $grid.vars.selectedRows.indexOf($grid.vars.selectedRows.find(selectedRow => 
          selectedRow.entity.Id === inputRow.entity.Id
      ));
  }
  
  function delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
