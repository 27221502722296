import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Replenishments_cancel_replenishment_task_flowService } from './Replenishments.flow.index';
import { Replenishments_create_location_replenishment_threshold_flowService } from './Replenishments.flow.index';
import { Replenishments_delete_location_replenishment_threshold_flowService } from './Replenishments.flow.index';
import { Replenishments_update_location_replenishment_threshold_flowService } from './Replenishments.flow.index';

import { $frontendTypes } from './Replenishments.frontend.types'

@Injectable({ providedIn: 'root' })
export class Replenishments_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Replenishments: Replenishments_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _cancel_replenishment_task_flow: Replenishments_cancel_replenishment_task_flowService;
  public async cancel_replenishment_task_flow(inParams: { taskIds: number[] }): Promise< { reasons?: string[] }> {
    if(!this._cancel_replenishment_task_flow) {
      this._cancel_replenishment_task_flow = this.injector.get(Replenishments_cancel_replenishment_task_flowService);
    }
    return this._cancel_replenishment_task_flow.run(inParams);
  }
   
   

   
 
  private _create_location_replenishment_threshold_flow: Replenishments_create_location_replenishment_threshold_flowService;
  public async create_location_replenishment_threshold_flow(inParams: { locationId: number, materialId: number, minimumPackagedAmount: number, minimumPackagingId: number, replenishmentPackagedAmount: number, replenishmentPackagingId: number, maximumPackagedAmount?: number, maximumPackingId?: number, onDemand?: boolean, systemTriggered?: boolean, comparisonTypeId?: number }): Promise< { reasons?: string[], locationReplenishmentId?: number }> {
    if(!this._create_location_replenishment_threshold_flow) {
      this._create_location_replenishment_threshold_flow = this.injector.get(Replenishments_create_location_replenishment_threshold_flowService);
    }
    return this._create_location_replenishment_threshold_flow.run(inParams);
  }
   
   

   
 
  private _delete_location_replenishment_threshold_flow: Replenishments_delete_location_replenishment_threshold_flowService;
  public async delete_location_replenishment_threshold_flow(inParams: { locationReplenishmentId: number }): Promise< { reason?: string }> {
    if(!this._delete_location_replenishment_threshold_flow) {
      this._delete_location_replenishment_threshold_flow = this.injector.get(Replenishments_delete_location_replenishment_threshold_flowService);
    }
    return this._delete_location_replenishment_threshold_flow.run(inParams);
  }
   
   

   
 
  private _update_location_replenishment_threshold_flow: Replenishments_update_location_replenishment_threshold_flowService;
  public async update_location_replenishment_threshold_flow(inParams: { locationReplenishmentId: number, minimumPackagedAmount?: number, minimumPackagingId?: number, replenishmentPackagedAmount?: number, replenishmentPackagingId?: number, maximumPackagedAmount?: number, maximumPackingId?: number, onDemand?: boolean, systemTriggered?: boolean, comparisonTypeId?: number }): Promise< { reasons?: string[] }> {
    if(!this._update_location_replenishment_threshold_flow) {
      this._update_location_replenishment_threshold_flow = this.injector.get(Replenishments_update_location_replenishment_threshold_flowService);
    }
    return this._update_location_replenishment_threshold_flow.run(inParams);
  }
   
   

   
}
