import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { Addresses_contact_types_dd_singleComponent } from './Addresses.contact_types_dd_single.component'
import { Addresses_account_types_dd_singleComponent } from './Addresses.account_types_dd_single.component'
import { Addresses_contact_types_dd_multiComponent } from './Addresses.contact_types_dd_multi.component'


interface IFootPrintManager_accounts_gridComponentEntity {
Id?: number, AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, ReferenceCode?: string, TypeId?: number, AccountsContactsLookup?: { AccountId?: number, ContactId?: number, Account?: { Id?: number, LookupCode?: string, Name?: string, TypeId?: number, Type?: { Name?: string }, OwnersAccountsLookups?: { OwnerId?: number }[] } }[], Address?: { Id?: number, AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string }, Type?: { Name?: string }}

interface IFootPrintManager_accounts_gridComponentInParams {
  ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean}

interface IFootPrintManager_accounts_gridComponentOutParams {
  accountId?: number, contactId?: number, addressId?: number, confirm?: boolean, ownerId?: number}

class FootPrintManager_accounts_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_accounts_gridComponent;
  entity: IFootPrintManager_accounts_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    account_lookupcode_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    account_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    reference_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    first_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    last_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    line1_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    line2_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    city_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    state_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    postal_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    country_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    attention_of_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    primary_telephone_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    primary_email_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['type_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    account_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['account_lookupcode_edit'] as DatexFormControl, null, false, '')
      ),
    account_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['account_name_edit'] as DatexFormControl, null, false, '')
      ),
    reference_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['reference_code_edit'] as DatexFormControl, null, false, '')
      ),
    first_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['first_name_edit'] as DatexFormControl, null, false, '')
      ),
    last_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['last_name_edit'] as DatexFormControl, null, false, '')
      ),
    line1: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['line1_edit'] as DatexFormControl, null, false, '')
      ),
    line2: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['line2_edit'] as DatexFormControl, null, false, '')
      ),
    city: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['city_edit'] as DatexFormControl, null, false, '')
      ),
    state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['state_edit'] as DatexFormControl, null, false, '')
      ),
    postal_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['postal_code_edit'] as DatexFormControl, null, false, '')
      ),
    country: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['country_edit'] as DatexFormControl, null, false, '')
      ),
    attention_of: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['attention_of_edit'] as DatexFormControl, null, false, '')
      ),
    primary_telephone: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['primary_telephone_edit'] as DatexFormControl, null, false, '')
      ),
    primary_email: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['primary_email_edit'] as DatexFormControl, null, false, '')
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '')
      ),
    error: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }

  cacheValueFor_$fields_type_selector_inParams_typeIds: number[];
  get $fields_type_selector_inParams_typeIds(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.filters.contact_types.control.value;
    
    if(!isEqual(this.cacheValueFor_$fields_type_selector_inParams_typeIds, expr)) {
      this.cacheValueFor_$fields_type_selector_inParams_typeIds = expr;
    }
    return this.cacheValueFor_$fields_type_selector_inParams_typeIds;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_accounts_gridComponent, entity: IFootPrintManager_accounts_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_accounts_gridComponent, entity?: IFootPrintManager_accounts_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      contactTypeIds:  $grid.filters.contact_types.control.value ,
      accountTypeId:  $grid.filters.account_type.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      accountIds:  null ,
      ownerId:  $grid.inParams.ownerId ,
      advanced:  $grid.filters.advanced.control.value ,
    };
    const data = await this.datasources.Addresses.ds_contact_accounts_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.type.displayControl as TextModel).text = $row.entity.Type?.Name;
    (this.cells.type.editControl as SelectBoxModel).reset($row.entity.TypeId);
    (this.cells.account_lookupcode.displayControl as TextModel).text = $row.entity.AccountsContactsLookup[0]?.Account?.LookupCode;
    (this.cells.account_lookupcode.editControl as TextBoxModel).reset($row.entity.AccountsContactsLookup[0]?.Account?.LookupCode);
    (this.cells.account_name.displayControl as TextModel).text = $row.entity.AccountsContactsLookup[0]?.Account?.Name;
    (this.cells.account_name.editControl as TextBoxModel).reset($row.entity.AccountsContactsLookup[0]?.Account?.Name);
    (this.cells.reference_code.displayControl as TextModel).text = $row.entity.ReferenceCode;
    (this.cells.reference_code.editControl as TextBoxModel).reset($row.entity.ReferenceCode);
    (this.cells.first_name.displayControl as TextModel).text = $row.entity.FirstName;
    (this.cells.first_name.editControl as TextBoxModel).reset($row.entity.FirstName);
    (this.cells.last_name.displayControl as TextModel).text = $row.entity.LastName;
    (this.cells.last_name.editControl as TextBoxModel).reset($row.entity.LastName);
    (this.cells.line1.displayControl as TextModel).text = $row.entity.Address?.Line1;
    (this.cells.line1.editControl as TextBoxModel).reset($row.entity.Address?.Line1);
    (this.cells.line2.displayControl as TextModel).text = $row.entity.Address?.Line2;
    (this.cells.line2.editControl as TextBoxModel).reset($row.entity.Address?.Line2);
    (this.cells.city.displayControl as TextModel).text = $row.entity.Address?.City;
    (this.cells.city.editControl as TextBoxModel).reset($row.entity.Address?.City);
    (this.cells.state.displayControl as TextModel).text = $row.entity.Address?.State;
    (this.cells.state.editControl as TextBoxModel).reset($row.entity.Address?.State);
    (this.cells.postal_code.displayControl as TextModel).text = $row.entity.Address?.PostalCode;
    (this.cells.postal_code.editControl as TextBoxModel).reset($row.entity.Address?.PostalCode);
    (this.cells.country.displayControl as TextModel).text = $row.entity.Address?.Country;
    (this.cells.country.editControl as TextBoxModel).reset($row.entity.Address?.Country);
    (this.cells.attention_of.displayControl as TextModel).text = $row.entity.Address?.AttentionOf;
    (this.cells.attention_of.editControl as TextBoxModel).reset($row.entity.Address?.AttentionOf);
    (this.cells.primary_telephone.displayControl as TextModel).text = $row.entity.PrimaryTelephone;
    (this.cells.primary_telephone.editControl as TextBoxModel).reset($row.entity.PrimaryTelephone);
    (this.cells.primary_email.displayControl as TextModel).text = $row.entity.PrimaryEmail;
    (this.cells.primary_email.editControl as TextBoxModel).reset($row.entity.PrimaryEmail);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Notes);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_accounts_gridComponentRowModel,
  $grid: FootPrintManager_accounts_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.entity = {
      AccountsContactsLookup: []
  };
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_accounts_gridComponentRowModel,
  $grid: FootPrintManager_accounts_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Update carrier service type
  let payload: any = {};
  let payloadAddress: any = {};
  let payloadAccount: any = {};
  
  /** Validate Duplicates ***/
  // Duplicate Address
  if ($grid.rows.find(cl =>
      cl !== $row
      && trim(cl?.entity.Address.Line1?.trim().toUpperCase(), 128) === trim($row.cells.line1.editControl.value?.trim().toUpperCase(), 128)
      && trim(cl?.entity.Address.Line2?.trim().toUpperCase(), 128) === trim($row.cells.line2.editControl.value?.trim().toUpperCase(), 128)
      && trim(cl?.entity.Address.City?.trim().toUpperCase(), 64) === trim($row.cells.city.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.entity.Address.State?.trim().toUpperCase(), 64) === trim($row.cells.state.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.entity.Address.PostalCode?.trim().toUpperCase(), 64) === trim($row.cells.postal_code.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.entity.Address.Country?.trim().toUpperCase(), 32) === trim($row.cells.country.editControl.value?.trim().toUpperCase(), 32)
  )) {
      let message = `Entered address already exists.`;
      $shell.Addresses.openErrorDialog('Save', message);
      throw new Error(message);
  }
  
  // Duplicate Account
  if ($grid.rows.find(r => r !== $row && trim(r.entity.AccountsContactsLookup[0].Account.Name?.trim().toUpperCase(), 256) === trim($row.cells.account_name.editControl.value.trim().toUpperCase(), 256))) {
      let message = `Account with name '${$row.cells.account_name.editControl.value}' already exists.`;
      $shell.Addresses.openErrorDialog('Save', message);
      throw new Error(message);
  }
  
  // Address Validation
  let errors = (await $flows.Addresses.validate_address_flow({
      line1: $row.cells.line1.editControl.value,
      line2: $row.cells.line2.editControl.value,
      city: $row.cells.city.editControl.value,
      state: $row.cells.state.editControl.value,
      postalCode: $row.cells.postal_code.editControl.value,
      country: $row.cells.country.editControl.value
  })).errors;
  
  if ($utils.isDefined(errors)) {
      $shell.Addresses.openErrorDialog('Save', errors.join(' & '));
      throw new Error(errors.join(' & '));
  }
  
  if ($row.cells.account_lookupcode.editControl.isChanged) {
      payloadAccount.LookupCode = trim($row.cells.account_lookupcode.editControl.value, 128);
  }
  if ($row.cells.account_name.editControl.isChanged) {
      payloadAccount.Name = trim($row.cells.account_name.editControl.value, 256);
  }
  if ($row.cells.reference_code.editControl.isChanged) {
      payload.ReferenceCode = trim($row.cells.reference_code.editControl.value, 128);
  }
  if ($row.cells.first_name.editControl.isChanged) {
      payload.FirstName = trim($row.cells.first_name.editControl.value, 32);
  }
  if ($row.cells.last_name.editControl.isChanged) {
      payload.LastName = trim($row.cells.last_name.editControl.value, 32);
  }
  if ($row.cells.primary_email.editControl.isChanged) {
      payload.PrimaryEmail = trim($row.cells.primary_email.editControl.value, 128);
  }
  if ($row.cells.primary_telephone.editControl.isChanged) {
      payload.PrimaryTelephone = trim($row.cells.primary_telephone.editControl.value, 50);
  }
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  
  
  if ($row.cells.line1.editControl.isChanged) {
      payloadAddress.Line1 = trim($row.cells.line1.editControl.value, 128);
  }
  if ($row.cells.line2.editControl.isChanged) {
      payloadAddress.Line2 = trim($row.cells.line2.editControl.value, 128);
  }
  if ($row.cells.attention_of.editControl.isChanged) {
      payloadAddress.AttentionOf = trim($row.cells.attention_of.editControl.value, 64);
  }
  if ($row.cells.city.editControl.isChanged) {
      payloadAddress.City = trim($row.cells.city.editControl.value, 64);
  }
  if ($row.cells.state.editControl.isChanged) {
      payloadAddress.State = trim($row.cells.state.editControl.value, 64);
  }
  if ($row.cells.postal_code.editControl.isChanged) {
      payloadAddress.PostalCode = trim($row.cells.postal_code.editControl.value, 64);
  }
  if ($row.cells.country.editControl.isChanged) {
      payloadAddress.Country = trim($row.cells.country.editControl.value, 32);
  }
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Contacts', id: $row.entity.Id, entity: payload });
      await $flows.Utilities.crud_update_flow({ entitySet: 'Addresses', id: $row.entity.AddressId, entity: payloadAddress });
      await $flows.Utilities.crud_update_flow({ entitySet: 'Accounts', id: $row.entity.AccountsContactsLookup[0].AccountId, entity: payloadAccount });
      await $row.refresh();
  
  }
  
  catch (error) {
      $shell.Addresses.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  function trim(candidate: any, length: number) {
  
      if ((length ?? 0) > 0) {
          if ($utils.isDefined(candidate)) {
              if (typeof candidate === 'string') {
                  let string_candidate = candidate?.toString();
                  if (string_candidate.length > length) {
                      string_candidate = string_candidate.substring(0, length);
                      candidate = string_candidate;
                  }
              }
          }
      }
      return candidate;
  }
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_accounts_gridComponentRowModel,
  $grid: FootPrintManager_accounts_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $row.cells.type.editControl.value,
      $row.cells.first_name.editControl.value,
      $row.cells.last_name.editControl.value,
      $row.cells.line1.editControl.value
  );
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Addresses.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  /** Validations  ***/
  // Duplicate Address
  if ($grid.rows.find(cl =>
      cl !== $row
      && trim(cl?.entity.Address?.Line1?.trim().toUpperCase(), 128) === trim($row.cells.line1.editControl.value?.trim().toUpperCase(), 128)
      && trim(cl?.entity.Address?.Line2?.trim().toUpperCase(), 128) === trim($row.cells.line2.editControl.value?.trim().toUpperCase(), 128)
      && trim(cl?.entity.Address?.City?.trim().toUpperCase(), 64) === trim($row.cells.city.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.entity.Address?.State?.trim().toUpperCase(), 64) === trim($row.cells.state.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.entity.Address?.PostalCode?.trim().toUpperCase(), 64) === trim($row.cells.postal_code.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.entity.Address?.Country?.trim().toUpperCase(), 32) === trim($row.cells.country.editControl.value?.trim().toUpperCase(), 32)
  )) {
      let message = `Entered address already exists.`;
      $shell.Addresses.openErrorDialog('Save', message);
      throw new Error(message);
  }
  
  // Duplicate Account
  if ($grid.rows.find(r => r !== $row && trim(r.entity.AccountsContactsLookup[0]?.Account?.Name?.trim().toUpperCase(), 256) === trim($row.cells.account_name.editControl.value?.trim().toUpperCase(), 256))) {
      let message = `Account with name '${$row.cells.account_name.editControl.value}' already exists.`;
      $shell.Addresses.openErrorDialog('Save', message);
      throw new Error(message);
  }
  
  // Address Validation
  let errors = (await $flows.Addresses.validate_address_flow({
      line1: $row.cells.line1.editControl.value,
      line2: $row.cells.line2.editControl.value,
      city: $row.cells.city.editControl.value,
      state: $row.cells.state.editControl.value,
      postalCode: $row.cells.postal_code.editControl.value,
      country: $row.cells.country.editControl.value
  })).errors;
  
  if ($utils.isDefined(errors)) {
      $shell.Addresses.openErrorDialog('Save', errors.join(' & '));
      throw new Error(errors.join(' & '));
  }
  
  try {
  
      const nextAccountId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Account' })).nextId;
  
      // Set Account.LookupCode
      let accountLookupcode: string;
      if ($utils.isDefinedTrimmed($row.cells.account_lookupcode.editControl.value)) {
          accountLookupcode = $row.cells.account_lookupcode.editControl.value.trim();
      }
      else {
          accountLookupcode = nextAccountId.toString()
  
          let duplicateCount = $grid.rows.filter(r => r.entity.AccountsContactsLookup[0]?.Account?.LookupCode?.trim() === accountLookupcode).length;
  
          if (duplicateCount > 0) {
              accountLookupcode += `_${duplicateCount + 1}`;
          }
      }
  
      // Account
      const payloadAccount = {
          "Id": nextAccountId,
          "EnterpriseId": 1,
          "TypeId": $grid.inParams.accountTypeId,
          "LookupCode": trim(accountLookupcode, 128),
          "Name": trim($row.cells.account_name.editControl.value ?? accountLookupcode, 256),
          "Active": true,
          "AllocationPriority": 0,
          "AllowReceiving": false,
          "AllowShipping": false,
          "AllowReturnsIn": false,
          "RequiresReceivingRma": false,
          "TaxScheduleId": 0,
          "RequiresShippingRma": false
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'Accounts', entity: payloadAccount });
  
      // Address
      const nextAddressId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Address' })).nextId;
      const payloadAddress = {
          "Id": nextAddressId,
          "Line1": trim($row.cells.line1.editControl.value, 128),
          "Line2": trim($row.cells.line2?.editControl.value, 128),
          "City": trim($row.cells.city?.editControl.value, 64),
          "State": trim($row.cells.state?.editControl.value, 64),
          "PostalCode": trim($row.cells.postal_code?.editControl.value, 64),
          "Country": trim($row.cells.country?.editControl.value, 32),
          "AttentionOf": trim($row.cells.attention_of?.editControl.value, 64),
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'Addresses', entity: payloadAddress });
  
      // Contact
      const nextContactId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Contact' })).nextId;
      const payloadContacts = {
          "Id": nextContactId,
          "OwnerEntity": "Account",
          "OwnerId": nextAccountId,
          "TypeId": $row.cells.type.editControl.value,
          "AddressId": nextAddressId,
          "ReferenceCode": trim($row.cells.reference_code.editControl.value, 128),
          "FirstName": trim($row.cells.first_name.editControl.value, 32),
          "LastName": trim($row.cells.last_name.editControl.value, 32),
          "PrimaryEmail": trim($row.cells.primary_email?.editControl.value, 128),
          "PrimaryTelephone": trim($row.cells.primary_telephone?.editControl.value, 50),
          "Notes": $row.cells.notes?.editControl.value
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'Contacts', entity: payloadContacts });
  
      // OwnersAccounts
      const payload = {
          "OwnerId": $grid.inParams.ownerId,
          "AccountId": nextAccountId,
          "IsDefaultAccount": false
      }
      await $flows.Utilities.crud_create_flow({ entitySet: 'OwnersAccountsLookup', entity: payload });
  
      $row.entity.Id = nextContactId;
      await $row.refresh();
  
  } catch (error) {
      $shell.Addresses.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  function trim(candidate: any, length: number) {
  
      if ((length ?? 0) > 0) {
          if ($utils.isDefined(candidate)) {
              if (typeof candidate === 'string') {
                  let string_candidate = candidate?.toString();
                  if (string_candidate.length > length) {
                      string_candidate = string_candidate.substring(0, length);
                      candidate = string_candidate;
                  }
              }
          }
      }
      return candidate;
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Addresses_contact_types_dd_singleComponent),
    forwardRef(() => Addresses_account_types_dd_singleComponent),
    forwardRef(() => Addresses_contact_types_dd_multiComponent),
  ],
  selector: 'FootPrintManager-accounts_grid',
  templateUrl: './FootPrintManager.accounts_grid.component.html'
})
export class FootPrintManager_accounts_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_accounts_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_accounts_gridComponentInParams = { ownerId: null, accountTypeId: null, typeIds: [], allowSelection: null };

  outParams: IFootPrintManager_accounts_gridComponentOutParams = { accountId: null, contactId: null, addressId: null, confirm: null, ownerId: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ accountId?: number, contactId?: number, addressId?: number, confirm?: boolean, ownerId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', true, false, null),       account_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Lookup', false, false, null),       account_name: new GridHeaderModel(new HeaderStyles(null, null), 'Account name', false, false, null),       reference_code: new GridHeaderModel(new HeaderStyles(null, null), 'Reference code', false, false, null),       first_name: new GridHeaderModel(new HeaderStyles(null, null), 'First name', true, false, null),       last_name: new GridHeaderModel(new HeaderStyles(null, null), 'Last name', true, false, null),       line1: new GridHeaderModel(new HeaderStyles(null, null), 'Line 1', true, false, null),       line2: new GridHeaderModel(new HeaderStyles(null, null), 'Line 2', false, false, null),       city: new GridHeaderModel(new HeaderStyles(null, null), 'City', false, false, null),       state: new GridHeaderModel(new HeaderStyles(null, null), 'State', false, false, null),       postal_code: new GridHeaderModel(new HeaderStyles(null, null), 'Postal code', false, false, null),       country: new GridHeaderModel(new HeaderStyles(null, null), 'Country', false, false, null),       attention_of: new GridHeaderModel(new HeaderStyles(null, null), 'Attention of', false, false, null),       primary_telephone: new GridHeaderModel(new HeaderStyles(null, null), 'Phone', false, false, null),       primary_email: new GridHeaderModel(new HeaderStyles(null, null), 'Email', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       error: new GridHeaderModel(new HeaderStyles(null, null), 'Error', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_accounts_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('accountTypeId') set $inParams_accountTypeId(value: any) {
    this.inParams['accountTypeId'] = value;
  }
  get $inParams_accountTypeId(): any {
    return this.inParams['accountTypeId'] ;
  }
  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }
  @Input('allowSelection') set $inParams_allowSelection(value: any) {
    this.inParams['allowSelection'] = value;
  }
  get $inParams_allowSelection(): any {
    return this.inParams['allowSelection'] ;
  }

  topToolbar = {
      select: new ToolModel(new ButtonModel('select', new ButtonStyles(null, null), false, 'Select', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      linecount: new ToolModel(new ButtonModel('linecount', new ButtonStyles(null, null), false, 'Line count', '')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    advanced: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    account_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    contact_types: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    advanced: new FieldModel(new CheckBoxModel(this.formGroup.controls['advanced'] as DatexFormControl, null, false, 'Search all accounts')
, new ControlContainerStyles(null, null), '', false)
,
    account_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['account_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Account type', false)
,
    contact_types: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['contact_types'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Contact types', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Addresses';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.ownerId)) {
        this.$missingRequiredInParams.push('ownerId');
      }
      if(isNil(this.inParams.accountTypeId)) {
        this.$missingRequiredInParams.push('accountTypeId');
      }
      if(isNil(this.inParams.typeIds)) {
        this.$missingRequiredInParams.push('typeIds');
      }
      if(isNil(this.inParams.allowSelection)) {
        this.$missingRequiredInParams.push('allowSelection');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.advanced.control as CheckBoxModel).reset(false);
    (this.filters.account_type.control as SelectBoxModel).reset($grid.inParams.accountTypeId);
    (this.filters.contact_types.control as SelectBoxModel).reset($grid.inParams.typeIds);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['advanced']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_search_toggle();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      contactTypeIds:  $grid.filters.contact_types.control.value ,
      accountTypeId:  $grid.filters.account_type.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      accountIds:  null ,
      ownerId:  $grid.inParams.ownerId ,
      advanced:  $grid.filters.advanced.control.value ,
    };
    try {
    const data = await this.datasources.Addresses.ds_contact_accounts_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_accounts_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_accounts_gridComponentEntity) {
    const row = new FootPrintManager_accounts_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_accounts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Addresses.openErrorDialog('Address Delete Error', 'No Address selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              addressId: row.entity.AddressId
          };
          const result = await $flows.Addresses.is_address_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Address ${row.entity.FirstName} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete Address errors';
          const errorMessage = `Address ${failures.map(c => c.entity.FirstName).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.Addresses.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Address ${candidates.map(c => c.entity.FirstName).join(',')} - Once deleted, the Address cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some Addresses cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.Addresses.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following Addresses';
              const message = confirmCandidates;
              confirm = await $shell.Addresses.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams =  {
                      addressId: candidate.entity.AddressId,
                      contactId: candidate.entity.Account.ContactId,
                      accountId: candidate.entity.AccountId,
                      ownerId: $grid.inParams.ownerId
                      }
                      const result = await $flows.Addresses.delete_owneraccount_flow(flowParams);
                    
                    processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Address ${candidate.entity.FirstName} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All the selected Addresses deleted';
                  const message = `Addresses ${processSuccess.map(c => c.entity.FirstName).join(',')} deleted`;
                  await $shell.Addresses.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All the selected Addresses failed to delete';
                      const message = `Addresses ${processFailures.map(c => c.entity.FirstName).join(',')} could not be deleted`;
                      await $shell.Addresses.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some Addresses could not be deleted';
                      const deleted = `Addresses ${processSuccess.map(c => c.entity.FirstName).join(',')} were deleted.`;
                      const errors = `The following Addresses could not be deleted: ${processFailures.map(c => c.entity.Account.FirstName).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.Addresses.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_accounts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  }
  else{
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  
  
  if (selectedRowsCount == 1) {
      $grid.topToolbar.select.control.readOnly = false;
  }
  else{
      $grid.topToolbar.select.control.readOnly = true;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_accounts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.linecount.hidden = true;
  
  $grid.outParams.confirm = false;
  
  // Hide select toolbar button based on allowSelection input
  if ($grid.inParams.allowSelection == true) {
      $grid.topToolbar.select.hidden = false;
  } else {
      $grid.topToolbar.select.hidden = true;
  }
  
  $grid.filters.account_type.hidden = true;
  $grid.filters.contact_types.hidden = true;
  }
  on_select(event = null) {
    return this.on_selectInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selectInternal(
    $grid: FootPrintManager_accounts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Addresses.openErrorDialog('Address Selection Error', 'No Address selected.');
      return;
  }
  else if (selectedRows.length > 1) {
      $shell.Addresses.openErrorDialog('Address Selection Error', 'More than one Address selected.');
      return;
  }
  else {
  
      let OwnersAccountsLookups = selectedRows[0].entity.AccountsContactsLookup[0].Account.OwnersAccountsLookups
      let OwnerId: number = 0;
  
      if ($utils.isDefined(OwnersAccountsLookups)) {
          OwnerId = OwnersAccountsLookups[0]?.OwnerId ?? 0;
      }
  
      $grid.outParams.accountId = selectedRows[0].entity.AccountsContactsLookup[0].AccountId;
      $grid.outParams.contactId = selectedRows[0].entity.Id;
      $grid.outParams.addressId = selectedRows[0].entity.AddressId;
      $grid.outParams.ownerId = OwnerId;
      $grid.outParams.confirm = true;
      $grid.close();
  }
  
  }
  on_excel_import(event = null) {
    return this.on_excel_importInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_importInternal(
    $grid: FootPrintManager_accounts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const wb = $event;
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  
  const _batchSize = 500;
  
  $grid.topToolbar.linecount.hidden = false;
  $grid.topToolbar.linecount.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  $grid.topToolbar.linecount.control.label = 'Extracting Data..';
  
  const Addresses = $utils.excel.sheet_to_json<{
      Type: string,
      LookupCode: string,
      Name: string,
      ReferenceCode: string,
      FirstName: string,
      LastName: string,
      Line1: string,
      Line2: string,
      City: string,
      State: string,
      PostalCode: string,
      Country: string,
      AttentionOf: string,
      Phone: string,
      Email: string,
      Notes: string
  
  }>(ws);
  
  $grid.topToolbar.linecount.control.label = 'Preparing Data...';
  
  // Initialize grid and data
  const candidates = [];
  var linecount = 0;
  var confirmLineCount = 0;
  var totalLineCount = Addresses.length;
  
  
  for (const Address of Addresses) {
  
      linecount = linecount + 1;
      $grid.topToolbar.linecount.control.label = 'Validating Line ' + linecount.toString() + ' of ' + totalLineCount.toString();
  
      var errors = '';
  
      const row = await $grid.addRow({AccountsContactsLookup: []}); //Initialize the row.
      
      if ($utils.isDefined(Address.Type)) {
          const addressType = Address.Type;
          const types = (await $datasources.Addresses.ds_get_contact_types_by_name.get({ name: addressType })).result;
          if ($utils.isDefined(types)) {
  
              row.cells.type.editControl.value = types[0].Id;
          }
          else {
              errors += ' Address type not valid. '
          }
      }
      else {
          errors += ' Address type required. '
      }
  
      // Address Validation
      let validationErrors = (await $flows.Addresses.validate_address_flow({
          line1: row.cells.line1.editControl.value,
          line2: row.cells.line2.editControl.value,
          city: row.cells.city.editControl.value,
          state: row.cells.state.editControl.value,
          postalCode: row.cells.postal_code.editControl.value,
          country: row.cells.country.editControl.value
      })).errors;
  
      if ($utils.isDefined(validationErrors)) {
          errors += validationErrors.join(' & ')
      }
  
      row.cells.account_lookupcode.editControl.value = Address.LookupCode
      row.cells.account_name.editControl.value = Address.Name
      row.cells.reference_code.editControl.value = Address.ReferenceCode
      row.cells.first_name.editControl.value = Address.FirstName
      row.cells.last_name.editControl.value = Address.LastName
  
      row.cells.line1.editControl.value = Address.Line1;
      row.cells.line2.editControl.value = Address.Line2;
      row.cells.city.editControl.value = Address.City;
      row.cells.state.editControl.value = Address.State;
      row.cells.postal_code.editControl.value = Address.PostalCode;
      row.cells.country.editControl.value = Address.Country;
  
      row.cells.attention_of.editControl.value = Address.AttentionOf;
      row.cells.primary_telephone.editControl.value = Address.Phone;
      row.cells.primary_email.editControl.value = Address.Email;
      row.cells.notes.editControl.value = Address.Notes;
  
      row.cells.error.displayControl.text = errors;
      candidates.push({ row: row, errors: errors });
  }
  
  var totalConfirmLineCount = candidates.length;
  
  for (const candidate of candidates) {
      const noErrors = candidate.errors === '';
      if (noErrors) {
  
  
          try {
              confirmLineCount = confirmLineCount + 1;
              $grid.topToolbar.linecount.control.label = 'Creating Address ' + confirmLineCount.toString() + ' of ' + totalConfirmLineCount.toString();
  
  
              const nextAccountId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Account' })).nextId;
  
              // Set Account.LookupCode
              let accountLookupcode: string;
              if ($utils.isDefinedTrimmed(candidate.row.cells.account_lookupcode.editControl.value)) {
                  accountLookupcode = candidate.row.cells.account_lookupcode.editControl.value.trim();
              }
              else {
                  accountLookupcode = nextAccountId.toString();
              }
  
              // Account
              const payloadAccount = {
                  "Id": nextAccountId,
                  "EnterpriseId": 1,
                  "TypeId": $grid.inParams.accountTypeId,
                  "LookupCode": accountLookupcode,
                  "Name": candidate.row.cells.account_name.editControl.value ?? accountLookupcode,
                  "Active": true,
                  "AllocationPriority": 0,
                  "AllowReceiving": false,
                  "AllowShipping": false,
                  "AllowReturnsIn": false,
                  "RequiresReceivingRma": false,
                  "TaxScheduleId": 0,
                  "RequiresShippingRma": false
              };
              await $flows.Utilities.crud_create_flow({ entitySet: 'Accounts', entity: payloadAccount });
  
  
              // Address
              const nextAddressId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Address' })).nextId;
              const payloadAddress = {
                  "Id": nextAddressId,
                  "Line1": candidate.row.cells.line1.editControl.value,
                  "Line2": candidate.row.cells.line2?.editControl.value,
                  "City": candidate.row.cells.city?.editControl.value,
                  "State": candidate.row.cells.state?.editControl.value,
                  "PostalCode": candidate.row.cells.postal_code?.editControl.value,
                  "Country": candidate.row.cells.country?.editControl.value,
                  "AttentionOf": candidate.row.cells.attention_of?.editControl.value,
              };
              await $flows.Utilities.crud_create_flow({ entitySet: 'Addresses', entity: payloadAddress });
  
  
              // Contact
              const nextContactId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Contact' })).nextId;
              const payloadContacts = {
                  "Id": nextContactId,
                  "OwnerEntity": "Account",
                  "OwnerId": nextAccountId,
                  "TypeId": candidate.row.cells.type.editControl.value,
                  "AddressId": nextAddressId,
                  "ReferenceCode": candidate.row.cells.reference_code.editControl.value,
                  "FirstName": candidate.row.cells.first_name.editControl.value,
                  "LastName": candidate.row.cells.last_name.editControl.value,
                  "PrimaryEmail": candidate.row.cells.primary_email?.editControl.value,
                  "PrimaryTelephone": candidate.row.cells.primary_telephone?.editControl.value,
                  "Notes": candidate.row.cells.notes?.editControl.value
              };
              await $flows.Utilities.crud_create_flow({ entitySet: 'Contacts', entity: payloadContacts });
  
              // OwnersAccounts
              const payload = {
                  "OwnerId": $grid.inParams.ownerId,
                  "AccountId": nextAccountId,
                  "IsDefaultAccount": false
              }
              await $flows.Utilities.crud_create_flow({ entitySet: 'OwnersAccountsLookup', entity: payload });
  
              candidate.row.entity.Id = nextContactId;
              
              candidate.row.refresh();
              candidate.row.setDisplayMode();
              candidate.row.isNew = false;
              // Lock down read only cells upon creation
              candidate.row.cells.type.editControl.readOnly = true;
          }
  
          catch (error) {
              errors += 'Error calling calling crud Api'
              candidate.row.cells.error.displayControl.text = errors;
  
              $grid.topToolbar.linecount.hidden = true;
              throw error; // to prevent displayMode 
  
          }
      }
  }
  
  $grid.topToolbar.linecount.hidden = true;
  }
  on_excel_export(event = null) {
    return this.on_excel_exportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_exportInternal(
    $grid: FootPrintManager_accounts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const wb = $event;
  
  const addresses = [];
  
  addresses.push({
      Type: 'Required',
      LookupCode: '',
      Name: '',
      ReferenceCode: '',
      FirstName: 'Required',
      LastName: 'Required',
      Line1: 'Required',
      Line2: '',
      City: '',
      State: '',
      PostalCode: '',
      Country: '',
      AttentionOf: '',
      Phone: '',
      Email: '',
      Notes: ''
  
  });
  
  const ws = $utils.excel.json_to_sheet(addresses);
  
  $utils.excel.book_append_sheet(wb, ws, 'AddressesImport');
  }
  on_search_toggle(event = null) {
    return this.on_search_toggleInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_search_toggleInternal(
    $grid: FootPrintManager_accounts_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let advanced: boolean = $grid.filters.advanced.control.value ?? false;
  
  if (advanced) {
      $grid.filters.account_type.hidden = false;
      $grid.filters.contact_types.hidden = false;
  } else {
      $grid.filters.contact_types.control.value = $grid.inParams.typeIds;
      $grid.filters.contact_types.hidden = true;
      $grid.filters.account_type.control.value = $grid.inParams.accountTypeId;
      $grid.filters.account_type.hidden = true;
  };
  }
  //#endregion private flows

  $importExcel(fileInput: HTMLInputElement) {
    if(fileInput.files?.length !== 1) {
      throw new Error('Cannot import multiple files');
    }
    const file = fileInput.files[0];
    if(file.size > 15 * 1000 * 1000) {
      throw new Error('Cannot import a file with size greater than 15MB');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const ab: ArrayBuffer = e.target?.result as ArrayBuffer;
      const wb: WorkBook = readExcelFile(ab);
      this.on_excel_import(wb);
    };
    reader.readAsArrayBuffer(file);
    fileInput.value = '';
  }

  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export(wb);

    writeExcelFile(wb, 'FootPrintManager_accounts_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
