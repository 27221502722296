import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Ultraship.frontend.types'

@Injectable({ providedIn: 'root' })
export class Ultraship_entity_update_trackers_distinctService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { input?: $frontendTypes.Ultraship.Entity_update_tracker[], criteria?: { application_name?: string, full_text_search?: string }, field: string, top?: number }): Promise<{ output?: { application_name?: string, value?: any }[], success?: boolean, error?: any[], count?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.field)) {
      missingRequiredInParams.push('\'field\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Ultraship/functions/entity_update_trackers_distinct`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

