import { Subject } from 'rxjs';
import { ControlModel } from './control';

// TODO: To discuss if we want to do the same as field for tools
export class ToolModel<TC extends ControlModel> {
  control: TC;
  private _hidden: boolean;
  hiddenChange: Subject<void> = new Subject<void>();

  constructor(control: TC) {
    this.control = control;
  }

  get hidden(): boolean {
    return this._hidden;
  }

  set hidden(value: boolean) {
    this._hidden = value;
    this.hiddenChange.next();  // Emit event when hidden changes
  }
}